import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Chart from 'react-chartjs-2'
import { formatPrice } from 'utils/formatter'
import {
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

// const TWOHOME_COLOR = 'rgba(255, 99, 132)'
const HOST_COLOR = 'rgba(255, 159, 64)'
const TICK_SIZE = 30000000

const dataFormat = {
  labels: [],
  datasets: [
    {
      type: 'bar',
      label: 'Doanh thu',
      data: [],
      backgroundColor: [],
      borderWidth: 1,
      order: 2,
    },
  ],
}

const RoomRevenue = ({
  data,
  classes,
}) => {
  const [graphData, setGraphData] = useState(dataFormat)
  const [period, setPeriod] = useState([])
  const [page, setPage] = useState(-1)

  const getOptions = () => {
    const options = {
      scales: {
        xAxes: [{
          stacked: true,
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            callback: (value) => {
              switch (period) {
                case 'week': // moment index from 1
                  return `Tuần ${value}`
                case 'month': // moment index from 0
                  return `Tháng ${Number(value) + 1}`
                case 'quarter': // moment index from 1
                  return `Quý ${value}`
                case 'year':
                  return `Năm ${value}`
                default:
                  return value
              }
            },
          },
        }],
        yAxes: [{
          display: false,
          stacked: true,
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            beginAtZero: true,
            callback: value => formatPrice(value),
            stepSize: TICK_SIZE,
          },
        }],
      },
      title: {
        display: true,
        position: 'top',
        text: 'DOANH THU PHÒNG',
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem) => {
            switch (period) {
              case 'week': // moment index from 1
                return `Tuần ${tooltipItem[0]?.label}`
              case 'month': // moment index from 0
                return `Tháng ${Number(tooltipItem[0]?.label) + 1}`
              case 'quarter': // moment index from 1
                return `Quý ${tooltipItem[0]?.label}`
              case 'year':
                return `Năm ${tooltipItem[0]?.label}`
              default:
                return tooltipItem[0]?.label
            }
          },
          label: (tooltipItem, d) => `${d.datasets[tooltipItem?.datasetIndex]?.label}: ${formatPrice(tooltipItem.yLabel || 0)}`,
        },
      },
    }
    return options
  }

  useEffect(() => {
    setPage(0)
    setPeriod(Object.keys(data))
    return () => {
    }
  }, [data])

  useEffect(() => {
    const clone = { ...graphData }
    clone.datasets[0].data = []
    clone.datasets[0].backgroundColor = []
    clone.labels = period.filter((p, i) => i >= page * 7 && i < (page + 1) * 7)
    clone.labels.forEach((item) => {
      clone.datasets[0].data.push(data[item].roomHost)
      clone.datasets[0].backgroundColor.push(HOST_COLOR)
    })
    setGraphData(clone)
  }, [page, period])

  const getTime = () => {
    if (graphData?.labels?.length === 1) {
      return graphData.labels[0]
    }
    if (graphData?.labels?.length > 1) {
      return (
        <>
          <b>{graphData.labels[0]}</b>
          {' tới '}
          <b>{graphData.labels[graphData.labels.length - 1]}</b>
        </>
      )
    }
    return null
  }

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} md={8} className="d-flex align-items-center">
          {page > 0 && (
          <IconButton>
            <ChevronLeft style={{ color: 'gray' }} onClick={() => setPage(page - 1)} />
          </IconButton>
          )}
          <Typography className="mx-2" variant="body1">
            {getTime()}
          </Typography>
          {page >= 0 && period.length > 7 && page < period?.length / 7 && (
          <IconButton>
            <ChevronRight style={{ color: 'gray' }} onClick={() => setPage(page + 1)} />
          </IconButton>
          )}
        </Grid>
      </Grid>
      <Chart type="bar" data={graphData} options={getOptions()} />
    </div>
  )
}

RoomRevenue.propTypes = {
  data: PropTypes.shape(),
  classes: PropTypes.shape(),
}

export default withStyles(theme => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: 'white',
  },
}))(RoomRevenue)
