import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from 'components/TimeRange'
import moment from 'moment'

const Date = ({ onApply, startdate, enddate }) => {
  const [startDate, setStartdate] = useState(startdate)
  const [endDate, setEnddate] = useState(enddate)

  useEffect(() => {
    if (startdate) {
      setStartdate(startdate)
    }
  }, [startdate])

  useEffect(() => {
    if (enddate) {
      setEnddate(enddate)
    }
  }, [enddate])

  const onDateChange = (sltDate) => {
    setStartdate(sltDate?.startDate)
    setEnddate(sltDate?.endDate)
    onApply(sltDate?.startDate, sltDate?.endDate)
  }

  const onDateClear = () => {
    setStartdate(null)
    setEnddate(null)
    onApply(null, null)
  }

  return (
    <DateRangePicker
      value={{ startDate, endDate }}
      onApply={onDateChange}
      onClear={onDateClear}
      maxDate={moment().valueOf()}
    />
  )
}

Date.propTypes = {
  onApply: PropTypes.func,
  startdate: PropTypes.number,
  enddate: PropTypes.number,
}

export default Date
