import React, { } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Grid, Typography, List, ListItem, ListItemAvatar, ListItemText,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '900',
    marginBottom: 15,
  },
  gridList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    backgroundColor: 'white',
  },
  customerInfo: {
    color: '#3f51b5',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  infoLabel: {
    fontWeight: 'bold',
    color: 'black',
  },
  textRight: {
    textAlign: 'right',
  },
  separator: {
    display: 'flex',
    width: '100%',
    margin: '15px auto 15px auto',
    borderBottom: '2px solid #dad6cf',
  },
}))

const ReservationInfo = () => {
  const classes = useStyles()


  return (
    <div className={classes.root}>
      <Typography variant="h2">
      Danh sách đặt phòng
      </Typography>
      <List className="mt-3">
        <ListItem className="p-0">
          <ListItemAvatar className="mr-3">
            <img src="https://via.placeholder.com/100" alt="" style={{ borderRadius: 5 }} />
          </ListItemAvatar>
          <ListItemText className="align-self-start">
            <div style={{ display: 'flex', fontWeight: 500, fontSize: 22 }}>
              <div style={{
                cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
              }}
              >
           The Gavani 5 - Times City
              </div>
            </div>
            <div style={{
              textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', marginTop: 10,
            }}
            >
              <span>458 Phố Minh Khai, Khu đô thị Times City, Vĩnh Tuy, Hai Bà Trưng, Hà Nội, Việt Nam</span>
            </div>
            <div style={{
              fontSize: 20, marginBottom: 'auto', fontWeight: 500, marginTop: 25, color: '#bcab89',
            }}
            >
750.000 VNĐ
            </div>
          </ListItemText>
        </ListItem>
      </List>
      {/* Thông tin đơn đặt phòng */}
      <Grid container spacing={2} className="mt-2">
        <Grid item xl={6} lg={6} md={6} xs={6}>
          <Typography variant="h3" style={{ fontSize: 18, color: '#bcab89' }}>
      Thông tin đơn đặt phòng
          </Typography>
          <Grid container spacing={2} className="mt-2">
            <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Mã đặt phòng</span></Grid>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Mã đặt phòng</span></Grid>
          </Grid>
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Họ tên</span></Grid>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Họ tên</span></Grid>
          </Grid>
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Điện thoại</span></Grid>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Điện thoại</span></Grid>
          </Grid>
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Thời gian</span></Grid>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Thời gian</span></Grid>
          </Grid>
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Số giờ</span></Grid>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Số giờ</span></Grid>
          </Grid>
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Ngày đặt hàng</span></Grid>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Ngày đặt hàng</span></Grid>
          </Grid>
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Số khách</span></Grid>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Số khách</span></Grid>
          </Grid>
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Ghi chú</span></Grid>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Ghi chú</span></Grid>
          </Grid>
          <div className={classes.separator} />
        </Grid>

        <Grid item xl={5} lg={5} md={5} xs={5} style={{ marginLeft: 'auto' }}>
          <Typography variant="h3" style={{ fontSize: 18, color: '#bcab89' }}>
          Lịch sử đặt phòng
          </Typography>
          <Grid container spacing={2} className="mt-2">
            <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Thanh toán</span></Grid>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Thanh toán</span></Grid>
          </Grid>
          <Grid />
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>N/A</span></Grid>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>N/A</span></Grid>
          </Grid>
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Đơn hàng</span></Grid>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Đơn hàng</span></Grid>
          </Grid>
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>N/A</span></Grid>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>N/A</span></Grid>
          </Grid>
          <div className={classes.separator} />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xl={6} lg={6} md={6} xs={6}>
          <Typography variant="h3" style={{ fontSize: 18, color: '#bcab89' }}>
      Chi tiết đơn đặt phòng
          </Typography>
          <Grid container spacing={2} className="mt-2">
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Phòng: Dreamy Apartment 2 (R75)</span></Grid>
            <Grid item xl={3} lg={4} md={5} xs={4} className={classes.textRight}><span className={classes.infoLabel}>960,000đ</span></Grid>
          </Grid>
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Dịch vụ: Set Phòng Ngủ (S2), Thời gian khóa: 15 phút</span></Grid>
            <Grid item xl={3} lg={4} md={5} xs={4} className={classes.textRight}><span className={classes.infoLabel}>1,000,000đ</span></Grid>
          </Grid>
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Dịch vụ: Set Tiệc Mặn Basic (S4), Thời gian khóa: 15 phút</span></Grid>
            <Grid item xl={3} lg={4} md={5} xs={4} className={classes.textRight}><span className={classes.infoLabel}>1,500,000đ</span></Grid>
          </Grid>
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Dịch vụ: Set Phòng Bóng Basic (S6), Thời gian khóa: 30 phút</span></Grid>
            <Grid item xl={3} lg={4} md={5} xs={4} className={classes.textRight}><span className={classes.infoLabel}>1,500,000đ</span></Grid>
          </Grid>
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Dịch vụ: Bánh gato (S19), Thời gian khóa: 0 phút</span></Grid>
            <Grid item xl={3} lg={4} md={5} xs={4} className={classes.textRight}><span className={classes.infoLabel}>500,000đ</span></Grid>
          </Grid>
          <div className={classes.separator} />
          <Grid container spacing={2}>
            <Grid item xl={9} lg={8} md={7} xs={8}><span className={classes.infoLabel}>Tổng tiền</span></Grid>
            <Grid item xl={3} lg={4} md={5} xs={4} className={classes.textRight}><span className={classes.infoLabel}>5,460,000đ</span></Grid>
          </Grid>
          <div className={classes.separator} />
        </Grid>

        <Grid item xl={5} lg={5} md={5} xs={5} />
      </Grid>
    </div>
  )
}
ReservationInfo.propTypes = {

}

export default ReservationInfo
