import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/styles'
import AddIcon from '@material-ui/icons/Add'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'
import {
  Typography,
  Button,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  useMediaQuery,
} from '@material-ui/core'
import * as roomApi from 'provider/room'
import config from 'config'
import { formatPrice } from 'utils/formatter'
import {
  FiberManualRecord,
  Search,
} from '@material-ui/icons'
import { SearchTextInput } from 'components'
import DatePicker from 'components/DatePicker2'
import { useSnackbar } from 'notistack'
import { Autocomplete } from '@material-ui/lab'
import CheckinIcon from 'assets/icons/icon-date-in.png'
import CheckoutIcon from 'assets/icons/icon-date-out.png'
import Filters from './Filters'
import AppConfig from 'config'
import { copy } from 'utils/service'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
  codeCell: {
    color: '#111',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  content: {
    marginTop: theme.spacing(1),
  },
  subContent: {
    padding: theme.spacing(0),
  },
  whiteBg: {
    backgroundColor: 'white',
  },
  cardImg: {
    maxWidth: 350,
  },
  media: {
    height: 250,
  },
}))

const RoomList = ({
  location,
  history,
  find,
  user,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })
  const [rooms, setRooms] = useState({})
  const [fltData, setFltData] = useState({ active: 0 })
  const [sltRoom, setSltRoom] = useState(null)
  const [action, setAction] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [openLockDialog, setOpenLockDialog] = useState(false)
  const [checkin, setCheckin] = useState(null)
  const [checkout, setCheckout] = useState(null)

  const fetchRooms = async (query) => {
    try {
      const resp = await roomApi.find({ ...query, limit: 100 })
      setRooms(resp)
    } catch (e) {
      // console.log(e)
    }
  }

  const updateUrl = (query) => {
    const url = `${location.pathname}?${applyFiltersToUrl(query)}`
    history.push(url, { replace: false })
  }

  const filter = (data) => {
    const query = getFiltersFromUrl(location.search)
    setFltData(data)
    const {
      district,
      building,
    } = data
    fetchRooms({
      district: district || undefined,
      building: building || undefined,
    })
    updateUrl({
      ...query,
      district: district || undefined,
      building: building || undefined,
    })
  }

  const onSearch = (data) => {
    filter(data)
  }

  useEffect(() => {
    fetchRooms()
  }, [])

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    find({ ...query, active: query.active === undefined ? 0 : Number(query.active) })
    updateUrl({ ...query, active: query.active === undefined ? 0 : Number(query.active) })
    return () => {}
    // eslint-disable-next-line
  }, [])

  const onUpdateRoomStatus = (roomItem, status) => {
    setAction(status)
    setSltRoom(roomItem)
    setOpenDialog(true)
  }

  const changeRoomStatus = async () => {
    console.log(action, sltRoom)
    try {
      await roomApi.updateStatus(sltRoom.id, action)
      const query = getFiltersFromUrl(location.search)
      fetchRooms(query)
      setOpenDialog(false)
      setAction(null)
      enqueueSnackbar('Cập nhật trạng thái phòng thành công', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Cập nhật trạng thái phòng không thành công', { variant: 'error' })
    }
  }

  const openLockRoomDialog = (roomItem) => {
    setSltRoom(roomItem)
    setOpenLockDialog(true)
  }

  const lockRoom = async () => {
    if (!sltRoom || !checkin || !checkout) {
      enqueueSnackbar('Không thể khoá phòng, cần đầy đủ thông tin (phòng, thời gian khoá)', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      return
    }
    try {
      await roomApi.lock(sltRoom.id, checkin, checkout)
      enqueueSnackbar('Khóa phòng thành công', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      setSltRoom(null)
      setCheckin(null)
      setCheckout(null)
    } catch (e) {
      // console.log(e.response)
      enqueueSnackbar(`Không thể khoá phòng, ${e.response?.data}.`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    setOpenLockDialog(false)
  }

  const duplicate = (id) => {
    window.open(`/room/${id}/duplicate`, '_blank')
  }

  return (
    <>
      {/* Dialog xác nhận khoá phòng */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle disableTypography>
          <h3>Xác nhận</h3>
        </DialogTitle>
        <DialogContent style={{ minWidth: isDesktop ? 600 : '80vw', minHeight: 80 }}>
          {['active', 'inactive'].includes(action) && (
          <div
            dangerouslySetInnerHTML={{
              __html: `Xác nhận ${action === 'inactive'
                ? 'ẩn phòng'
                : action === 'active'
                  ? 'mở phòng'
                  : null
              }?`,
            }}
          />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="default">
          Thoát
          </Button>
          <Button onClick={changeRoomStatus} color="primary">
          Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      {/* Thêm lịch khoá phòng */}
      <Dialog open={openLockDialog} onClose={() => setOpenLockDialog(false)} scroll="paper" PaperProps={{ style: { overflowY: 'unset', top: isDesktop ? 0 : -100 } }}>
        <DialogTitle disableTypography>
          <h3>Khoá phòng tạm thời</h3>
        </DialogTitle>
        <DialogContent style={{ minWidth: isDesktop ? 600 : '80vw', minHeight: 80 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
              Lưu ý: Không thể khoá phòng nếu có đơn đã thanh toán trong khoảng thời gian khoá hoặc thời gian khoá trùng với đơn khoá phòng khác.
              Sau khi khoá phòng, các đơn chưa thanh toán sẽ bị huỷ.
            </Grid>
          </Grid>
          <br/>
          <Grid container spacing={2}>
            {/* Phòng */}
            <Grid item lg={12} md={12} xs={12}>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                fullWidth
                size="small"
              >
                <Autocomplete
                  value={sltRoom}
                  options={rooms?.data || []}
                  getOptionLabel={opt => `${opt.name} (${opt.code})`}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Phòng"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Chọn phòng ..."
                      size="small"
                    />
                  )}
                  onChange={(e, sltItem) => setSltRoom(sltItem)}
                />
              </FormControl>
            </Grid>
            {/* Thời gian khoá */}
            <Grid item lg={6} md={6} xs={12}>
              <DatePicker
                type="datetime"
                fullWidth
                icon={<img src={CheckinIcon} style={{ width: 20, marginRight: 10 }} alt="checkin-icon" />}
                label="Từ ngày/giờ"
                value={checkin}
                onSelect={value => setCheckin(value)}
                size="small"
                picked={sltRoom?.reservations?.map(r => ({ start: r.checkin, end: r.checkout }))}
              />
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <DatePicker
                type="datetime"
                fullWidth
                icon={<img src={CheckoutIcon} style={{ width: 20, marginRight: 10 }} alt="checkout-icon" />}
                label="Đến ngày/giờ"
                value={checkout}
                onSelect={value => setCheckout(value)}
                size="small"
                min={checkin}
                picked={sltRoom?.reservations?.map(r => ({ start: r.checkin, end: r.checkout }))}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={lockRoom} type="primary" variant='outlined'>
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.root}>
        <div className={classes.content}>
          <Grid container xs={12} spacing={1}>
            {/* Chính sách */}
            <Grid item md={3} xs={12}>
              <Grid item xs={12}>
                <Typography variant="h3" gutterBottom>Chính sách</Typography>
              </Grid>
              <Grid item xs={12} className={`${classes.subContent} ${classes.whiteBg}`}>
                <Typography variant="h4">Chính sách chung</Typography>
                <Divider className="w-100 my-3" />
                <Typography variant="h5">Chính sách huỷ</Typography>
                <Typography variant="body1" style={{ color: '#432300' }}>
                  {
                    user?.data?.policy === 'owner'
                      ? 'Sử dụng chính sách huỷ phòng riêng'
                      : 'Sử dụng chính sách huỷ phòng của 2home'
                  }
                </Typography>
                {/* <br />
                <Typography variant="h5">Đặt cọc</Typography>
                <Typography variant="body1" style={{ color: '#432300' }}>Không thu thêm tiền cọc từ khách hàng</Typography> */}
                <br />
                <div className="w-100 text-right">
                  <Button
                    size="small"
                    variant="contained" style={{ backgroundColor: '#e9d1bd', color: '#432300', textTransform: 'none' }}
                    onClick={() => window.open('/profile', '_blank')}
                  >
                    Chỉnh sửa
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} className={`${classes.subContent} ${classes.whiteBg} my-3`}>
                <Typography variant="h4">Căn hộ</Typography>
                <Divider className="w-100 my-3" />
                <div className="d-flex align-items-center">
                  <FiberManualRecord className="mr-2" style={{ fontSize: '0.8rem', color: '#55aa4b' }} />
                  <Typography variant="body1">Đang hoạt động</Typography>
                  <Typography variant="body1" className="ml-auto" style={{ color: 'gray' }}>{rooms?.data?.filter(item => item.status === 'active')?.length}</Typography>
                </div>
                <div className="d-flex align-items-center">
                  <FiberManualRecord className="mr-2" style={{ fontSize: '0.8rem', color: '#b97d38' }} />
                  <Typography variant="body1">Đang xem xét</Typography>
                  <Typography variant="body1" className="ml-auto" style={{ color: 'gray' }}>{rooms?.data?.filter(item => item.status === 'pending')?.length}</Typography>
                </div>
                <div className="d-flex align-items-center">
                  <FiberManualRecord className="mr-2" style={{ fontSize: '0.8rem', color: '#7e7e7e' }} />
                  <Typography variant="body1">Đã ẩn</Typography>
                  <Typography variant="body1" className="ml-auto" style={{ color: 'gray' }}>{rooms?.data?.filter(item => item.status === 'inactive')?.length}</Typography>
                </div>
              </Grid>
              <RouterLink to="/room/create" style={{ textDecoration: 'none' }}>
                <Button variant="contained" className="w-100 py-2" style={{ backgroundColor: 'rgb(65 204 120)', color: 'white' }}>
                  <AddIcon />
                  <span>Tạo phòng</span>
                </Button>
              </RouterLink>
            </Grid>
            {/* Danh sách */}
            <Grid item md={9} xs={12}>
              <Grid item xs={12}>
                <Typography variant="h3" gutterBottom>Danh sách căn hộ</Typography>
              </Grid>
              <Grid item xs={12} className={`${classes.subContent} ${classes.whiteBg}`}>
                {/* Bộ lọc tìm kiếm */}
                <Grid item container xs={12} spacing={2}>
                  {/* Khu vực */}
                  <Grid item lg={3} md={3} xs={12} className="mt-auto">
                    <Filters.District
                      value={fltData.district}
                      onApply={value => filter({ ...fltData, district: value })}
                    />
                  </Grid>
                  {/* Toà nhà */}
                  <Grid item lg={3} md={3} xs={12} className="mt-auto">
                    <Filters.Building
                      value={fltData.room}
                      onApply={value => filter({ ...fltData, room: value })}
                    />
                  </Grid>
                  {/* Tìm kiếm */}
                  <Grid item lg={4} md={4} xs={12}  className="ml-auto">
                    <SearchTextInput
                      fullWidth
                      className={classes.searchInput}
                      placeholder="Tìm kiếm (theo mã, tên phòng, ...)"
                      initValue={fltData.searchTxt}
                      debounceTime={800}
                      onChange={str => onSearch({ ...fltData, searchTxt: str })}
                      leftIcon={<Search />}
                      size="small"
                      style={{ marginBottom: 0 }}
                    />
                  </Grid>
                </Grid>
                <Divider className="w-100 my-3" style={{ backgroundColor: '#d1d1d1' }} />
                {/* Danh sách phòng */}
                {rooms?.data?.map((item, index) => (
                  <>
                    <Grid container>
                      {/* Ảnh phòng */}
                      <Grid item md={3} xs={12}>
                        <RouterLink to={`/room/${item.code}/update`} className={classes.codeCell}>
                          <div
                            className="d-flex position-relative w-100"
                            style={{
                              cursor: 'pointer',
                              borderRadius: 3,
                              paddingBottom: '70%',
                              backgroundImage: `url(${config.BASE_IMG_URL}/${item.image})`,
                              backgroundSize: 'cover',
                              backgroundPosition: '50% 50%',
                            }}
                          />
                        </RouterLink>
                      </Grid>
                      {/* Thông tin phòng */}
                      <Grid item md={9} xs={12} className={isDesktop ? 'pl-3' : 'pt-2'}>
                        <div className="d-flex font-weight-bold tex-truncate">
                          <RouterLink to={`/room/${item.code}/update`} className={classes.codeCell}>
                            <Typography variant="h4">{item.name}</Typography>
                          </RouterLink>
                        </div>
                        <div className="d-flex text-truncate">
                          <Typography variant="body1">{`Mã phòng ${item.code}`}</Typography>
                        </div>
                        <div className="text-truncate">
                          <Typography variant="body1">{item.address}</Typography>
                        </div>
                        <div className="d-flex mt-2">
                          {
                          item.hourFirstBaseFee !== 0 && (
                          <Typography variant="h5">
                            <span>{`${formatPrice(item.hourFirstBaseFee * item.hourFirstAmount)}`}</span>
                            <span>{` đ/${item.hourFirstAmount}Giờ`}</span>
                            <span style={{ padding: '0 5px' }}>-</span>
                          </Typography>
                          )
                        }
                          {
                          item.dayBaseFee !== 0 && (
                          <Typography variant="h5">
                            <span>{`${formatPrice(item.dayBaseFee)}`}</span>
                            <span> đ/Ngày</span>
                          </Typography>
                          )
                        }
                        </div>
                        {item.status === 'active' && (
                        <div className="d-flex flex-wrap">
                          <Button className="mt-2 mr-1" variant="outlined" size="small" style={{ textTransform: 'none' }}>
                            <FiberManualRecord className="mr-2" style={{ fontSize: '0.8rem', color: '#55aa4b' }} />
                            <Typography variant="body1">Đang hoạt động</Typography>
                          </Button>
                          <Button className="mt-2 mx-1" variant="outlined" size="small" style={{ textTransform: 'none' }} onClick={() => window.open(`${config.endpoint.customer}/phong-can-ho/${item.code}`, '_blank')}>
                            <Typography variant="body1">Hiển thị</Typography>
                          </Button>
                          <Button className="mt-2 mx-1" variant="outlined" size="small" style={{ textTransform: 'none' }} onClick={() => window.open(`/reservations?room=["${item.id}"]`, '_blank')}>
                            <Typography variant="body1">Xem lịch</Typography>
                          </Button>
                          <Button className="mt-2 mx-1" variant="outlined" size="small" style={{ textTransform: 'none' }} onClick={() => openLockRoomDialog(item)}>
                            <Typography variant="body1">Thêm lịch khoá</Typography>
                          </Button>
                          <Button className="mt-2 mx-1" variant="outlined" size="small" style={{ textTransform: 'none' }} onClick={() => onUpdateRoomStatus(item, 'inactive')}>
                            <Typography variant="body1">Ẩn phòng</Typography>
                          </Button>
                          {/* <Button className="mt-2 ml-1" variant="outlined" size="small" style={{ textTransform: 'none' }}>
                            <Typography variant="body1">Xem đánh giá</Typography>
                          </Button> */}
                        </div>
                        )}
                        {item.status === 'inactive' && (
                        <div className="d-flex flex-wrap">
                          <Button className="mt-2 mr-1" variant="outlined" size="small" style={{ textTransform: 'none' }}>
                            <FiberManualRecord className="mr-2" style={{ fontSize: '0.8rem', color: '#7e7e7e' }} />
                            <Typography variant="body1">Đã ẩn</Typography>
                          </Button>
                          <Button className="mt-2 mx-1" variant="outlined" size="small" style={{ textTransform: 'none' }} onClick={() => onUpdateRoomStatus(item, 'active')}>
                            <Typography variant="body1">Kích hoạt phòng</Typography>
                          </Button>
                          {/* <Button className="mt-2 ml-1" variant="outlined" size="small" style={{ textTransform: 'none' }}>
                            <Typography variant="body1">Xem đánh giá</Typography>
                          </Button> */}
                        </div>
                        )}
                        {item.status === 'pending' && (
                        <div className="d-flex flex-wrap">
                          <Button className="mt-2 mr-1" variant="outlined" size="small" style={{ textTransform: 'none' }}>
                            <FiberManualRecord className="mr-2" style={{ fontSize: '0.8rem', color: '#b97d38' }} />
                            <Typography variant="body1">Đang xem xét</Typography>
                          </Button>
                        </div>
                        )}
                        <div className="d-flex flex-wrap">
                          <Button className="mt-2 mr-1" variant="outlined" size="small" style={{ textTransform: 'none' }} onClick={() => duplicate(item.code)}>
                            <Typography variant="body1">Tạo phòng tương tự</Typography>
                          </Button>
                          {item.status === 'active' ? (<Button 
                            className="mt-2 mr-1" 
                            variant="outlined" size="small" 
                            style={{ textTransform: 'none' }} 
                            onClick={() => copy(`${AppConfig.endpoint.customer}/phong-can-ho/${item.code}`, () => enqueueSnackbar('Copy link đặt phòng', { variant: 'success' }))}
                          >
                            <Typography variant="body1">Copy link</Typography>
                          </Button>)
                          : null}
                        </div>
                      </Grid>
                    </Grid>
                    {index < rooms?.data?.length - 1 ? <Divider className="w-100 my-3" style={{ backgroundColor: '#d1d1d1' }} /> : null}
                  </>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

RoomList.propTypes = {
  find: PropTypes.func,
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  user: PropTypes.shape(),
}

export default RoomList
