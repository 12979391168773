import * as reservationApi from 'provider/reservation'
import { RESERVATION_ACTION } from '../type'

export const find = query => async (dispatch, getState) => {
  const { page: pageProp, limit: limitProp } = getState()
    .getIn(['reservation', 'meta'])
    .toJS()
  const data = getState().getIn(['reservation', 'data']).toJS()
  const pageQuery = getState().getIn(['reservation', 'query']).toJS()
  const convertedQuery = {
    page: pageProp,
    limit: limitProp,
    ...query,
  }
  if (
    !data
    || data.length === 0
    || JSON.stringify(convertedQuery) !== JSON.stringify(pageQuery)
  ) {
    dispatch({
      type: RESERVATION_ACTION.FETCH,
    })
    try {
      const reservation = await reservationApi.find(query)
      dispatch({
        type: RESERVATION_ACTION.FETCH_OK,
        payload: { query, ...reservation },
      })
    } catch (e) {
      dispatch({
        type: RESERVATION_ACTION.FETCH_ERR,
        payload: { message: 'Something went wrong' },
      })
    }
  }
}

export const pay = (id, paymentType) => async (dispatch) => {
  dispatch({
    type: RESERVATION_ACTION.PAY,
  })
  try {
    const reservation = await reservationApi.pay(id, paymentType)
    dispatch({
      type: RESERVATION_ACTION.PAY_OK,
      payload: reservation,
    })
  } catch (e) {
    dispatch({
      type: RESERVATION_ACTION.PAY_ERR,
      payload: { message: 'Something went wrong' },
    })
  }
}

export const reject = (id, data) => async (dispatch) => {
  dispatch({
    type: RESERVATION_ACTION.REJECT,
  })
  try {
    const reservation = await reservationApi.reject(id, data)
    dispatch({
      type: RESERVATION_ACTION.REJECT_OK,
      payload: reservation,
    })
  } catch (e) {
    dispatch({
      type: RESERVATION_ACTION.REJECT_ERR,
      payload: { message: 'Something went wrong' },
    })
  }
}

export const confirm = id => async (dispatch) => {
  dispatch({
    type: RESERVATION_ACTION.CONFIRM,
  })
  try {
    const reservation = await reservationApi.confirm(id)
    dispatch({
      type: RESERVATION_ACTION.CONFIRM_OK,
      payload: reservation,
    })
  } catch (e) {
    dispatch({
      type: RESERVATION_ACTION.CONFIRM_ERR,
      payload: { message: 'Something went wrong' },
    })
  }
}

export const undo = id => async (dispatch) => {
  dispatch({
    type: RESERVATION_ACTION.UNDO,
  })
  try {
    const reservation = await reservationApi.undo(id)
    dispatch({
      type: RESERVATION_ACTION.UNDO_OK,
      payload: reservation,
    })
  } catch (e) {
    dispatch({
      type: RESERVATION_ACTION.UNDO_ERR,
      payload: { message: 'Something went wrong' },
    })
  }
}
