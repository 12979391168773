import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Apartment, Close, Remove } from '@material-ui/icons'
import { SearchSelectionInput } from 'components'
import * as roomApi from 'provider/room'
import { Grid, Typography } from '@material-ui/core'

const Building = ({ value, onApply }) => {
  const [sltBuilding, setSltBuilding] = useState({})
  const [buildingSuggestion, setBuildingSuggestion] = useState([])
  const [allBuildings, setAllBuildings] = useState([])
  const [buildings, setBuildings] = useState([])

  const fetchBuilding = async (ids) => {
    const resp = await roomApi.find({ ids })
    setBuildings(
      resp.data.map(opt => ({
        id: opt.id,
        title: `${opt.name} (${opt.code})`,
        subTitle: `${opt.address}`,
      })),
    )
  }

  const fetchAllBuilding = async () => {
    const resp = await roomApi.find({})
    setAllBuildings(
      resp.data.map(opt => ({
        id: opt.id,
        title: `${opt.name} (${opt.code})`,
        subTitle: `${opt.address}`,
      })),
    )
  }

  useEffect(() => {
    fetchAllBuilding()
    return () => {
    }
  }, [])

  useEffect(() => {
    try {
      if (!value) {
        setBuildings([])
        return () => {}
      }
      const ids = value
      if (ids && ids.length) {
        fetchBuilding(ids)
      }
    } catch (e) {
      setBuildings([])
      onApply(undefined)
    }
    return () => {}
  }, [value])

  const onBuildingChange = async (str) => {
    const suggestions = allBuildings.filter(r => r.title?.toLowerCase().includes(str?.toLowerCase()))
    setBuildingSuggestion(suggestions)
  }

  const onBuildingSelect = async (item) => {
    if (!item) return
    setSltBuilding(item)
    if (!buildings.find(c => item && c.id === item.id)) {
      setBuildings([...buildings].concat(item))
    }
  }

  const renderBuildingOption = (option) => {
    const { title, subTitle } = option
    return (
      <div>
        <div style={{ fontSize: 16 }}>
          <strong>{title}</strong>
        </div>
        <div>
          <small>{subTitle}</small>
        </div>
      </div>
    )
  }

  return (
    <PopupState variant="popover" popupId="customer-filter">
      {popupState => (
        <div className="w-100">
          <Button className="w-100" variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
            <Apartment />
            {buildings.filter(c => value && value.includes(c.id)).length
              ? buildings.filter(c => value && value.includes(c.id)).map(c => c.title).join(', ')
              : 'Toà nhà'}
            {value && value.length
              ? (
                <Close onClick={(e) => {
                  e.stopPropagation()
                  onApply(undefined)
                }}
                />
              )
              : null}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 400 }}>
              <SearchSelectionInput
                label="Toà nhà"
                placeholder="Nhập thông tin toà nhà"
                value={sltBuilding.title}
                renderOption={renderBuildingOption}
                suggestions={buildingSuggestion}
                onChange={str => onBuildingChange(str)}
                onSelect={onBuildingSelect}
              />
              {/* Selected buildings */}
              <div>
                {buildings.length ? <Typography>Danh sách toà nhà tìm kiếm:</Typography> : ''}
                {
                  buildings.map((c, i) => (
                    <Grid container>
                      <Typography variant="body1" style={{ fontWeight: 500 }}>{`${i + 1}. ${c.title}`}</Typography>
                      <Remove style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setBuildings([...buildings].filter(item => item.id !== c.id))} />
                    </Grid>
                  ))
                }
              </div>
              <Button
                onClick={() => {
                  onApply(buildings && buildings.length ? JSON.stringify(buildings.map(c => c.id)) : undefined)
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

Building.propTypes = {
  onApply: PropTypes.func,
  value: PropTypes.string,
}

export default Building
