import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core'
import * as commonApi from 'provider/common'

const useStyles = makeStyles(() => ({
  root: {},
}))

const AccountDetails = (props) => {
  const classes = useStyles()
  const {
    className, user, updateInfo, ...rest
  } = props
  const [values, setValues] = useState({
    ...user,
    city: user.city ? user.city.id : null,
    district: user.district ? user.district.id : null,
  })
  const [cities, setCities] = useState([])
  const [districts, setDistricts] = useState([])

  useEffect(() => {
    const fetchCity = async () => {
      let resp = await commonApi.getCity()
      if (resp && resp.data) {
        setCities(
          resp.data.map(c => ({
            value: c.id,
            label: c.name,
          })),
        )
        const sltCity = values.city
        resp = await commonApi.getDistrict(sltCity)
        setDistricts(
          resp.data.map(d => ({
            value: d.id,
            label: d.name,
          })),
        )
      }
    }
    fetchCity()
    return () => {}
  }, [])

  const handleChange = async (event) => {
    if (event.target.name === 'city') {
      const sltCity = event.target.value
      const resp = await commonApi.getDistrict(sltCity)
      setDistricts(
        resp.data.map(d => ({
          value: d.id,
          label: d.name,
        })),
      )
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('TODO: Update profile of user')
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <CardHeader
          subheader="Các thông tin dưới đây có thể chỉnh sửa"
          title="Thông tin"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            {/* Name */}
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText=""
                label="Họ tên"
                margin="dense"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            {/* Email */}
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Địa chỉ Email"
                margin="dense"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            {/* Phone */}
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Số điện thoại"
                margin="dense"
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            {/* City */}
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Thành phố"
                margin="dense"
                name="city"
                onChange={handleChange}
                required
                select
                value={values.city}
                variant="outlined"
              >
                {cities.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            {/* District */}
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Quận"
                margin="dense"
                name="district"
                onChange={handleChange}
                required
                select
                value={values.district}
                variant="outlined"
              >
                {districts.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button type="submit" color="primary" variant="contained">
            Lưu
          </Button>
        </CardActions>
      </form>
    </Card>
  )
}

AccountDetails.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape(),
  updateInfo: PropTypes.func,
}

export default AccountDetails
