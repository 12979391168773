import moment from 'moment'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
} from '@material-ui/core'
import { ArrowRightAlt } from '@material-ui/icons'
import CheckinIcon from 'assets/icons/icon-date-in.png'
import DatePicker from 'components/DatePicker2'

const ExpireDate = ({
  // classes,
  // errors,
  // touched,
  values,
  // handleChange,
  setFieldValue,
}) => {
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)

  useEffect(() => {
    if (values.startdate) {
      setStart(values.startdate)
    }
  }, [values.startdate])

  useEffect(() => {
    if (values.enddate) {
      setEnd(values.enddate)
    }
  }, [values.enddate])

  return (
    <>
      <Typography variant="h6" className="mb-3">Thời hạn sử dụng</Typography>
      <div className="d-flex justify-content-beetween">
        <DatePicker
          icon={<img src={CheckinIcon} style={{ width: 20, marginRight: 10 }} alt="checkin-icon" />}
          type="date"
          value={start}
          label="Bắt đầu áp dụng"
          onSelect={(sltDate) => {
            setStart(moment(sltDate).startOf('day').valueOf())
            setFieldValue('startdate', sltDate ? moment(sltDate).startOf('day').valueOf() : undefined)
          }}
          calendarStyle={{ position: 'fixed' }}
          fixed
        />
        <ArrowRightAlt style={{ margin: 'auto 10px' }} />
        <DatePicker
          icon={<img src={CheckinIcon} style={{ width: 20, marginRight: 10 }} alt="checkout-icon" />}
          type="date"
          value={end}
          label="Kết thúc áp dụng"
          onSelect={(sltDate) => {
            setEnd(moment(sltDate).endOf('day').valueOf())
            setFieldValue('enddate', sltDate ? moment(sltDate).endOf('day').valueOf() : undefined)
          }}
          calendarStyle={{ position: 'fixed' }}
          fixed
        />
      </div>
    </>
  )
}

ExpireDate.propTypes = {
  // classes: PropTypes.shape(),
  values: PropTypes.shape(),
  // errors: PropTypes.shape(),
  // touched: PropTypes.shape(),
  // handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default ExpireDate
