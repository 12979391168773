import moment from 'moment'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core'
import { formatPrice } from 'utils/formatter'
import { DATETIME_FORMAT } from 'config'

const PriceInfo = ({
  values,
  classes,
  roomPrice,
  coupon,
}) => {
  const getTotal = () => {
    let total = 0
    roomPrice.forEach((item) => {
      total += Number(item.price)
    })
    // services.forEach((item) => {
    //   total += Number(item.price)
    // })
    if (!values?.applyCoupon && !values?.prevState && coupon && coupon?.creator === 'host') {
      switch (coupon?.valueType) {
        case 'money':
          total -= coupon?.value
          break
        case 'percent':
          total -= ((100 - coupon?.value) * total) / 100
          break
        default:
      }
    }
    if (values?.setupFee) {
      total += values?.setupFee
    }
    return total
  }

  const getExtraCheckout = () => values.addCheckoutPoint?.filter(item => item.status !== 'reject')?.map(item => item.value).reduce((a, b) => a + b, 0) || 0

  const renderPriceItem = (title, price) => (
    <Grid container lg={12} md={12} xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
      <Grid item lg={6} md={6} xs={6}>
        <InputLabel>{title}</InputLabel>
      </Grid>
      {price
        ? (
          <Grid item lg={6} md={6} xs={6} style={{ textAlign: 'right' }}>
            <InputLabel>{`${price} VND`}</InputLabel>
          </Grid>
        )
        : null}
    </Grid>
  )

  if (['lock', 'reject-lock'].includes(values.status)) {
    return null
  }

  return (
    <>
      <Typography className={classes.subTitle}>Giá phòng chi tiết:</Typography>
      {/* Giá phòng */}
      {roomPrice?.map(item => renderPriceItem(item.title, formatPrice(item.price)))}
      {
      values?.setupFee
        ? (
          <Grid container lg={12} md={12} xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
            <Grid item lg={6} md={6} xs={6}>
              <InputLabel>{`2home thuê ${Math.ceil(values?.setupTime / 60)} giờ`}</InputLabel>
            </Grid>
            <Grid item lg={6} md={6} xs={6} style={{ textAlign: 'right' }}>
              <InputLabel>{`${formatPrice(values?.setupFee)} VND`}</InputLabel>
            </Grid>
          </Grid>
        )
        : null
      }
      {/* Đặt cọc */}
      {
        values.depositWith === '2home' && values.depositAmount
          ? (
            <Grid container lg={12} md={12} xs={12} className="my-2">
              <Grid item lg={6} md={6} xs={6}>
                <InputLabel>Đặt cọc qua 2home</InputLabel>
              </Grid>
              <Grid item lg={6} md={6} xs={6} style={{ textAlign: 'right' }}>
                <InputLabel>
                  {`${formatPrice((values.depositWith === '2home' ? (values.depositAmount || 0) : 0))} VND`}
                </InputLabel>
              </Grid>
            </Grid>
          )
          : null
      }
      {
        values.depositWith === 'host'
          ? (
            <Grid container lg={12} md={12} xs={12} className="my-2">
              <Grid item lg={6} md={6} xs={12}>
                <InputLabel>Đặt cọc trực tiếp</InputLabel>
              </Grid>
            </Grid>
          )
          : null
      }
      {/* Khuyến mãi */}
      {!values?.applyCoupon && !values?.prevState && coupon && coupon?.creator === 'host' ? renderPriceItem('Khuyến mại', `- ${formatPrice(coupon.value)}`) : null}
      <hr />
      {/* Tổng tiền */}
      <Grid container lg={12} md={12} xs={12} className="my-2">
        <Grid item lg={6} md={6} xs={6}>
          <InputLabel>Tổng tiền:</InputLabel>
        </Grid>
        <Grid item lg={6} md={6} xs={6} style={{ textAlign: 'right' }}>
          <InputLabel>{`${formatPrice(getTotal())} VND`}</InputLabel>
        </Grid>
      </Grid>
      {/* Đặt ở thêm */}
      {
        values.addCheckoutPoint?.filter(item => item.status !== 'reject')?.length
          ? (
            values.addCheckoutPoint?.filter(item => item.status !== 'reject')?.map(item => renderPriceItem(`Ở thêm tới ${moment(item.checkout).format(DATETIME_FORMAT)}`, `+${formatPrice(item.value)}`))
          )
          : null
      }
      {
        values.addCheckoutPoint?.filter(item => item.status !== 'reject')?.length
          ? (
            <>
              <hr />
              <Grid container lg={12} md={12} xs={12} className="my-2">
                <Grid item lg={6} md={6} xs={6}>
                  <InputLabel>Tất cả:</InputLabel>
                </Grid>
                <Grid item lg={6} md={6} xs={6} style={{ textAlign: 'right' }}>
                  <InputLabel>{`${formatPrice(getTotal() + getExtraCheckout())} VND`}</InputLabel>
                </Grid>
              </Grid>
            </>
          )
          : null
      }
      {/* 2home chi hộ phát sinh */}
      {['paid', 'using'].includes(values.status)
        ? (
          <>
            {values.payOnBehaft?.length ? <Typography className={classes.subTitle}>Chi hộ phát sinh:</Typography> : null}
            {
              values.payOnBehaft?.map((item, index) => (
                <Grid key={index} container spacing={2} className="mb-2">
                  <Grid item xs={9}><InputLabel>{`- ${item.content}: `}</InputLabel></Grid>
                  <Grid item xs={3}><InputLabel className="text-right">{`${formatPrice(item.value)} VND`}</InputLabel></Grid>
                </Grid>
              ))
            }
          </>
        )
        : null}
      <br />
      {/* {services?.length ? <Typography className={classes.subTitle}>Dịch vụ sử dụng:</Typography> : null} */}
      {/* Giá dịch vụ */}
      {/* {services?.map(item => renderPriceItem(item.name, null))} */}
    </>
  )
}

PriceInfo.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  roomPrice: PropTypes.arrayOf(PropTypes.shape()),
  coupon: PropTypes.shape(),
}

export default PriceInfo
