import React, { } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Grid, TextField, Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '900',
    marginBottom: 15,
  },
  gridList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    backgroundColor: 'white',
  },
  customerInfo: {
    color: '#3f51b5',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  infoLabel: {
    fontWeight: 'bold',
    color: 'black',
  },
  textRight: {
    textAlign: 'right',
  },
  separator: {
    display: 'flex',
    width: '100%',
    margin: '15px auto 15px auto',
    borderBottom: '2px solid #dad6cf',
  },
}))
const options = [
  'Option 1', 'Option 2',
]
const ReservationSetRoom = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="h2">
        Lịch đặt phòng
      </Typography>

      <p className="mb-0 mt-3 font-weight-bold">Lịch đặt phòng</p>
      <Grid container spacing={2} className={classes.rowAlign}>
        {/* Khu vực */}
        <Grid item lg={4} md={4}>
          <Autocomplete
            style={{ marginTop: '10px' }}
            options={options}
            renderInput={params => <TextField {...params} label="Khu vực" variant="outlined" size="small" />}
          />
        </Grid>
        {/* Phòng */}
        <Grid item lg={4} md={4}>
          <Autocomplete
            style={{ marginTop: '10px' }}
            options={options}
            renderInput={params => <TextField {...params} label="Phòng" variant="outlined" size="small" />}
          />
        </Grid>
      </Grid>
      {/* <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
      /> */}

    </div>
  )
}
ReservationSetRoom.propTypes = {

}

export default ReservationSetRoom
