import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import RichEditor from 'components/RichEditor'

const Instruction = ({
  classes,
  errors,
  touched,
  values,
  // handleChange,
  setFieldValue,
}) => (
  <>
    <Typography className={classes.subTitle}>
      Hướng dẫn nhận phòng:
    </Typography>
    <Grid container item lg={12} md={12}>
      <RichEditor
        style={{ backgroundColor: 'white' }}
        label="Viết hướng dẫn nhận phòng ..."
        name="help"
        fullWidth
        value={values.help}
        onChange={value => setFieldValue('help', value)}
        error={errors?.help && touched?.help}
        helperText={errors?.help && touched?.help && errors?.help}
        placeholder="Nhập hướng dẫn nhận phòng"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
  </>
)

Instruction.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  // handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default Instruction
