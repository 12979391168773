import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink } from 'react-router-dom'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'
import { Fab, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import * as couponApi from 'provider/coupon'
import { validateJson } from 'utils/service'
import CouponTable from './CouponTable'
import Toolbar from './Toolbar'
import styles from './styled'
import CouponHistoryTable from './CouponHistoryTable'

const CouponList = ({
  classes,
  location,
  history,
}) => {
  const [coupons, setCoupons] = useState([])
  const [fltData, setFltData] = useState({
    status: 'valid',
    type: 'created',
  })

  // Fetch coupon
  const fetchCoupon = async (query) => {
    try {
      let resp = []
      switch (query.type) {
        case 'created':
          resp = await couponApi.find({
            ...query,
          })
          break
        case 'used':
          resp = await couponApi.fetchHistory({
            ...query,
          })
          break
        default:
      }
      setCoupons(resp)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    const url = `${location.pathname}?${applyFiltersToUrl({
      ...query,
      status: query.status || 'valid',
      type: query.type || 'created',
      room: query?.room || undefined,
    })}`
    history.push(url, { replace: false })
    fetchCoupon({
      ...query,
      type: query.type || 'created',
      status: query.status || 'valid',
      room: query?.room || undefined,
    })
    setFltData({
      ...query,
      type: query.type || 'created',
      status: query.status || 'valid',
      room: validateJson(query?.room) ? JSON.parse(query?.room) : [],
    })
    return () => {}
    // eslint-disable-next-line
  }, [])

  const updateUrl = (query) => {
    const url = `${location.pathname}?${applyFiltersToUrl({
      ...query,
      room: query.room && query.room.length ? JSON.stringify(query.room) : undefined,
    })}`
    history.push(url, { replace: false })
    fetchCoupon({
      ...query,
      status: query.status || 'valid',
      room: query?.room || undefined,
    })
    setFltData({
      ...query,
      type: query.type || 'created',
      room: validateJson(query?.room?.toString()) ? JSON.parse(query?.room) : [],
    })
  }

  const applyFilter = (data) => {
    const query = {
      ...data,
      page: 1,
    }
    updateUrl(query)
    setCoupons([])
  }

  const changePage = (page) => {
    const query = { ...fltData, page }
    updateUrl(query)
  }

  const changeRowsPerPage = (limit) => {
    const query = { ...fltData, page: 1, limit }
    updateUrl(query)
  }

  return (
    <div className={classes.root}>
      <Toolbar
        classes={classes}
        applyFilter={applyFilter}
        filterData={fltData}
        location={location}
      />
      <div className={classes.content}>
        {
          fltData.type === 'created'
            ? (
              <CouponTable
                classes={classes}
                coupon={coupons?.data}
                meta={coupons?.meta}
                loading={coupons?.loading}
                onPageChange={changePage}
                onRowsPerPageChange={changeRowsPerPage}
              />
            )
            : null
        }
        {
          fltData.type === 'used'
            ? (
              <CouponHistoryTable
                classes={classes}
                coupon={coupons?.data}
                meta={coupons?.meta}
                loading={coupons?.loading}
                onPageChange={changePage}
                onRowsPerPageChange={changeRowsPerPage}
              />
            )
            : null
        }
      </div>
      <RouterLink to="/coupon/create">
        <Fab color="primary" aria-label="add" className={classes.fab}>
          <AddIcon />
        </Fab>
      </RouterLink>
    </div>
  )
}

CouponList.propTypes = {
  classes: PropTypes.shape(),
  location: PropTypes.shape(),
  history: PropTypes.shape(),
}

export default withStyles(styles)(CouponList)
