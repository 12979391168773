import moment from 'moment'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  FormHelperText,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import CurrencyInput from 'components/CurrencyInput'
import DatePicker from 'components/DatePicker'
import { Add, Delete, FileCopy } from '@material-ui/icons'
import { formatPrice } from 'utils/formatter'

const WEEK_DAY = [
  {
    value: 0,
    label: 'Chủ nhật',
  },
  {
    value: 1,
    label: 'Thứ hai',
  },
  {
    value: 2,
    label: 'Thứ ba',
  },
  {
    value: 3,
    label: 'Thứ tư',
  },
  {
    value: 4,
    label: 'Thứ năm',
  },
  {
    value: 5,
    label: 'Thứ sáu',
  },
  {
    value: 6,
    label: 'Thứ bảy',
  },
]

const SpecialPrice = ({
  classes,
  values,
  // errors,
  // touched,
  // handleChange,
  setFieldValue,
}) => {
  const [dayOfWeeks, setDayOfWeeks] = useState([])
  const [dayOfMonths, setDayOfMonths] = useState([])
  const [limitRoomBedFee, setLimitRoomBedFee] = useState({})
  const [rate, setRate] = useState(1.25)

  useEffect(() => {
    setRate(1 / ((100 - values.commission) / 100))
  }, [values.commission])

  useEffect(() => {
    const {
      specialDayFee,
      limitRoomBedFee: limitRoomFee,
    } = values || {}
    // Giá theo ngày trong tuần
    if (specialDayFee && specialDayFee.dayOfWeeks) {
      const sltDayPrice = []
      Object.keys(specialDayFee.dayOfWeeks).forEach((key) => {
        const convertPrice = {
          ...specialDayFee.dayOfWeeks[key],
          // hourFirstBaseFee: specialDayFee.dayOfWeeks[key].hourFirstBaseFee * specialDayFee.dayOfWeeks[key].hourFirstAmount,
          // hourExtraMax: specialDayFee.dayOfWeeks[key].hourExtraMax + specialDayFee.dayOfWeeks[key].hourFirstAmount,
        }
        sltDayPrice.push({
          day: key,
          ...convertPrice,
        })
      })
      setDayOfWeeks(sltDayPrice)
    }
    // Giá ngày đặc biệt
    if (specialDayFee && specialDayFee.specialDays) {
      const sltDayPrice = []
      Object.keys(specialDayFee.specialDays).forEach((key) => {
        const convertPrice = {
          ...specialDayFee.specialDays[key],
          // hourFirstBaseFee: specialDayFee.specialDays[key].hourFirstBaseFee * specialDayFee.specialDays[key].hourFirstAmount,
          // hourExtraMax: specialDayFee.specialDays[key].hourExtraMax + specialDayFee.specialDays[key].hourFirstAmount,
        }
        sltDayPrice.push({
          day: moment(key, 'DD-MM').valueOf(),
          ...convertPrice,
        })
      })
      setDayOfMonths(sltDayPrice)
    }
    // Giá phòng giới hạn (1 phòng ngủ)
    setLimitRoomBedFee({
      ...(limitRoomFee || {}),
      // hourFirstBaseFee: limitRoomFee ? limitRoomFee.hourFirstBaseFee * limitRoomFee.hourFirstAmount : 0,
      // hourExtraMax: limitRoomFee ? limitRoomFee.hourExtraMax + limitRoomFee.hourFirstAmount : 0,
    })
    return () => {
    }
  }, [values.id])

  useEffect(() => {
    const specialDayFee = {
      dayOfWeeks: {},
      specialDays: {},
    }
    // Ngày trong tuần
    if (dayOfWeeks && dayOfWeeks.length) {
      dayOfWeeks.forEach((item) => {
        if (item !== null && item !== undefined) {
          specialDayFee.dayOfWeeks[item.day] = {
            dayBaseFee: item.dayBaseFee,
            dayHourExtraMax: item.dayHourExtraMax,
            dayHourExtraBaseFee: item.dayHourExtraBaseFee,
            hourFirstBaseFee: item.hourFirstBaseFee,
            hourFirstAmount: item.hourFirstAmount,
            hourExtraBaseFee: item.hourExtraBaseFee,
            hourExtraMax: item.hourExtraMax,
            overnightFee: item.overnightFee,
            dayRangeFee: item.dayRangeFee,
          }
        }
      })
    }
    // Ngày đặc biệt theo tháng
    if (dayOfMonths && dayOfMonths.length) {
      dayOfMonths.forEach((item) => {
        if (item !== null && item !== undefined) {
          specialDayFee.specialDays[moment(item.day).format('DD-MM')] = {
            dayBaseFee: item.dayBaseFee,
            dayHourExtraMax: item.dayHourExtraMax,
            dayHourExtraBaseFee: item.dayHourExtraBaseFee,
            hourFirstBaseFee: item.hourFirstBaseFee,
            hourFirstAmount: item.hourFirstAmount,
            hourExtraBaseFee: item.hourExtraBaseFee,
            hourExtraMax: item.hourExtraMax,
            overnightFee: item.overnightFee,
            dayRangeFee: item.dayRangeFee,
          }
        }
      })
    }
    setFieldValue('specialDayFee', specialDayFee)
    setFieldValue('limitRoomBedFee', limitRoomBedFee)
    return () => {
    }
  }, [dayOfWeeks, dayOfMonths, limitRoomBedFee])

  useEffect(() => {
    // Giá theo ngày trong tuần
    const cloneDayOfWeeks = [...dayOfWeeks]
    if (cloneDayOfWeeks && cloneDayOfWeeks.length) {
      cloneDayOfWeeks.forEach((day, index) => {
        if (values.has2homeFee) {
          cloneDayOfWeeks[index].dayBaseFee /= rate
          cloneDayOfWeeks[index].dayHourExtraBaseFee /= rate
          cloneDayOfWeeks[index].hourFirstBaseFee /= rate
          cloneDayOfWeeks[index].hourExtraBaseFee /= rate
          cloneDayOfWeeks[index].overnightFee /= rate
          cloneDayOfWeeks[index].dayRangeFee /= rate
        } else {
          cloneDayOfWeeks[index].dayBaseFee *= rate
          cloneDayOfWeeks[index].dayHourExtraBaseFee *= rate
          cloneDayOfWeeks[index].hourFirstBaseFee *= rate
          cloneDayOfWeeks[index].hourExtraBaseFee *= rate
          cloneDayOfWeeks[index].overnightFee *= rate
          cloneDayOfWeeks[index].dayRangeFee *= rate
        }
      })
      setDayOfWeeks(cloneDayOfWeeks)
    }
    // Giá ngày đặc biệt
    const cloneDayOfMonths = [...dayOfMonths]
    if (cloneDayOfMonths && cloneDayOfMonths.length) {
      cloneDayOfMonths.forEach((day, index) => {
        if (values.has2homeFee) {
          cloneDayOfMonths[index].dayBaseFee /= rate
          cloneDayOfMonths[index].dayHourExtraBaseFee /= rate
          cloneDayOfMonths[index].hourFirstBaseFee /= rate
          cloneDayOfMonths[index].hourExtraBaseFee /= rate
          cloneDayOfMonths[index].overnightFee /= rate
          cloneDayOfMonths[index].dayRangeFee /= rate
        } else {
          cloneDayOfMonths[index].dayBaseFee *= rate
          cloneDayOfMonths[index].dayHourExtraBaseFee *= rate
          cloneDayOfMonths[index].hourFirstBaseFee *= rate
          cloneDayOfMonths[index].hourExtraBaseFee *= rate
          cloneDayOfMonths[index].overnightFee *= rate
          cloneDayOfMonths[index].dayRangeFee *= rate
        }
      })
      setDayOfMonths(cloneDayOfMonths)
    }
    // Giá phòng giới hạn (1 phòng ngủ)
    const cloneLimitRoomBedFee = { ...limitRoomBedFee }
    if (limitRoomBedFee.dayBaseFee) {
      if (values.has2homeFee) {
        cloneLimitRoomBedFee.dayBaseFee /= rate
        cloneLimitRoomBedFee.dayHourExtraBaseFee /= rate
        cloneLimitRoomBedFee.hourFirstBaseFee /= rate
        cloneLimitRoomBedFee.hourExtraBaseFee /= rate
        cloneLimitRoomBedFee.overnightFee /= rate
        cloneLimitRoomBedFee.dayRangeFee /= rate
      } else {
        cloneLimitRoomBedFee.dayBaseFee *= rate
        cloneLimitRoomBedFee.dayHourExtraBaseFee *= rate
        cloneLimitRoomBedFee.hourFirstBaseFee *= rate
        cloneLimitRoomBedFee.hourExtraBaseFee *= rate
        cloneLimitRoomBedFee.overnightFee *= rate
        cloneLimitRoomBedFee.dayRangeFee *= rate
      }
      setLimitRoomBedFee(cloneLimitRoomBedFee)
    }
    return () => {
    }
  }, [values.id, values.has2homeFee])

  const addDayOfMonth = () => {
    setDayOfMonths([...dayOfMonths, {
      day: moment().valueOf(),
      dayBaseFee: 0,
      dayHourExtraMax: 0,
      dayHourExtraBaseFee: 0,
      hourFirstBaseFee: 0,
      hourFirstAmount: 0,
      hourExtraBaseFee: 0,
      hourExtraMax: 0,
      overnightFee: 0,
      dayRangeFee: 0,
    }])
  }

  const addDayOfWeeks = () => {
    setDayOfWeeks([...dayOfWeeks, {
      day: moment().valueOf(),
      dayBaseFee: 0,
      dayHourExtraMax: 0,
      dayHourExtraBaseFee: 0,
      hourFirstBaseFee: 0,
      hourFirstAmount: 0,
      hourExtraBaseFee: 0,
      hourExtraMax: 0,
      overnightFee: 0,
      dayRangeFee: 0,
    }])
  }

  const deleteDayOfWeeks = (dayIdx) => {
    setDayOfWeeks([...dayOfWeeks].filter((item, index) => index !== dayIdx))
  }

  const deleteDayOfMonths = (dayIdx) => {
    setDayOfMonths([...dayOfMonths].filter((item, index) => index !== dayIdx))
  }

  const deleteLimitRoomBedFee = () => {
    setLimitRoomBedFee({})
  }

  const copyDayOfWeeks = (data) => {
    setDayOfWeeks([...dayOfWeeks, {
      ...data,
      day: null,
    }])
  }

  const copyDayOfMonth = (data) => {
    setDayOfMonths([...dayOfMonths, {
      ...data,
      day: null,
    }])
  }

  return (
    <>
      <Typography variant="h6" className="mb-2">Giá ngày đặc biệt trong tuần:</Typography>
      {/* Ngày trong tuần */}
      {dayOfWeeks.map((item, index) => (
        <Grid container item lg={8} md={8} xs={12} className={`${classes.rowAlign} ${classes.priceBox}`}>
          <Grid container spacing={2} item lg={12} md={12} xs={12} className="pt-2">
            {/* Chọn ngày trong tuần */}
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                error={false}
                style={{ backgroundColor: 'white' }}
                size="small"
              >
                <InputLabel style={{ backgroundColor: 'white', padding: '0px 5px' }} id="host-owner">
                  Ngày trong tuần
                </InputLabel>
                <Select
                  labelId="host-owner"
                  id="host-owner-label"
                  value={item.day}
                  onChange={(e) => {
                    const clone = [...dayOfWeeks]
                    clone[index].day = e.target.value
                    setDayOfWeeks(clone)
                  }}
                >
                  {WEEK_DAY.map(({ value, label }) => (
                    <MenuItem value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText style={{ color: 'red' }} />
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12} />
            {/* Giá theo ngày */}
            <Grid container item lg={6} md={6} xs={12} sm={12} style={{ borderRight: 'solid 1px #a1a1a1' }}>
              {/* Giá theo ngày */}
              <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                <Grid item lg={6} md={6} xs={12}>
                  <Typography>Giá theo ngày</Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <CurrencyInput
                    style={{ backgroundColor: 'white' }}
                    variant="outlined"
                    fullWidth
                    value={(item.dayBaseFee || 0) / (values.has2homeFee ? 1 : rate)}
                    onChange={(value) => {
                      const clone = [...dayOfWeeks]
                      clone[index].dayBaseFee = value * (values.has2homeFee ? 1 : rate)
                      setDayOfWeeks(clone)
                    }}
                    error={false}
                    helperText=""
                    size="small"
                    placeholder="Nhập số tiền"
                  />
                  {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(dayOfWeeks[index].dayBaseFee)} VND`}</Typography> : null}
                </Grid>
              </Grid>
              {/* Giá 1 giờ vào sớm/ra muộn */}
              <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                <Grid item lg={6} md={6} xs={12}>
                  <Typography>Giá 1 giờ vào sớm/ra muộn</Typography>
                  <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>(Chỉ áp dụng cho khung giờ qua đêm)</Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <CurrencyInput
                    style={{ backgroundColor: 'white' }}
                    variant="outlined"
                    fullWidth
                    value={(item.dayHourExtraBaseFee || 0) / (values.has2homeFee ? 1 : rate)}
                    onChange={(value) => {
                      const dayHourExtraBaseFee = value * (values.has2homeFee ? 1 : rate)
                      const clone = [...dayOfWeeks]
                      clone[index].dayHourExtraBaseFee = dayHourExtraBaseFee
                      if (!clone[index].dayHourExtraMax) {
                        clone[index].dayHourExtraMax = Math.floor(clone[index].dayBaseFee / dayHourExtraBaseFee)
                      }
                      setDayOfWeeks(clone)
                    }}
                    error={false}
                    helperText=""
                    size="small"
                    placeholder="Nhập số tiền"
                  />
                  {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(dayOfWeeks[index].dayHourExtraBaseFee)} VND`}</Typography> : null}
                </Grid>
              </Grid>
              {/* Vào sớm/ra muộn tối đa */}
              <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                <Grid item lg={6} md={6} xs={12}>
                  <Typography>Vào sớm/ra muộn tối đa</Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    type="number"
                    style={{ backgroundColor: 'white', textAlign: 'right' }}
                    variant="outlined"
                    fullWidth
                    value={item.dayHourExtraMax}
                    onChange={(e) => {
                      const clone = [...dayOfWeeks]
                      clone[index].dayHourExtraMax = e.target.value ? Number(e.target.value) : 0
                      setDayOfWeeks(clone)
                    }}
                    error={false}
                    size="small"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Giờ</InputAdornment>,
                    }}
                    placeholder="Nhập số giờ"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              {/* Ưu đãi đêm */}
              {values.hasNightPromote
                ? (
                  <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>Ưu đãi đêm</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <CurrencyInput
                        style={{ backgroundColor: 'white' }}
                        variant="outlined"
                        fullWidth
                        value={(item.overnightFee || 0) / (values.has2homeFee ? 1 : rate)}
                        onChange={(value) => {
                          const overnightFee = value * (values.has2homeFee ? 1 : rate)
                          const clone = [...dayOfWeeks]
                          clone[index].overnightFee = overnightFee
                          setDayOfWeeks(clone)
                        }}
                        error={false}
                        helperText=""
                        size="small"
                        placeholder="Nhập số tiền"
                      />
                      {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(dayOfWeeks[index].overnightFee)} VND`}</Typography> : null}
                    </Grid>
                  </Grid>
                )
                : null}
              {/* Phí trong ngày */}
              {
                !values.isFlexible && values.hasRentByHour && values.applyTimeRange
                  ? (
                    <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                      <Grid item lg={6} md={6} xs={12}>
                        <Typography>Trong ngày</Typography>
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <CurrencyInput
                          style={{ backgroundColor: 'white' }}
                          variant="outlined"
                          fullWidth
                          value={(item.dayRangeFee || 0) / (values.has2homeFee ? 1 : rate)}
                          onChange={(value) => {
                            const dayRangeFee = value * (values.has2homeFee ? 1 : rate)
                            const clone = [...dayOfWeeks]
                            clone[index].dayRangeFee = dayRangeFee
                            setDayOfWeeks(clone)
                          }}
                          error={false}
                          helperText=""
                          size="small"
                          placeholder="Nhập số tiền"
                        />
                        {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(dayOfWeeks[index].dayRangeFee)} VND`}</Typography> : null}
                      </Grid>
                    </Grid>
                  )
                  : null
              }
            </Grid>
            {/* Giá theo giờ */}
            {values.hasRentByHour
              ? (
                <Grid container item lg={6} md={6} xs={12} sm={12}>
                  {/* Giá theo giờ đầu */}
                  <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>Giá giờ đầu</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <CurrencyInput
                        style={{ backgroundColor: 'white' }}
                        variant="outlined"
                        fullWidth
                        value={item.hourFirstBaseFee / (values.has2homeFee ? 1 : rate)}
                        onChange={(value) => {
                          const hourFirstBaseFee = value * (values.has2homeFee ? 1 : rate)
                          const clone = [...dayOfWeeks]
                          clone[index].hourFirstBaseFee = hourFirstBaseFee
                          setDayOfWeeks(clone)
                        }}
                        error={false}
                        helperText=""
                        size="small"
                        placeholder="Nhập số tiền"
                      />
                      {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(dayOfWeeks[index].hourFirstBaseFee)} VND`}</Typography> : null}
                    </Grid>
                  </Grid>
                  {/* Sô giờ đầu */}
                  <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>Số giờ đầu</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        style={{ backgroundColor: 'white' }}
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={item.hourFirstAmount}
                        onChange={(e) => {
                          const clone = [...dayOfWeeks]
                          const { value } = e.target
                          if (clone[index].hourFirstBaseFee && clone[index].hourExtraBaseFee && value) {
                            clone[index].hourExtraMax = Math.floor((clone[index].dayBaseFee - clone[index].hourFirstBaseFee) / clone[index].hourExtraBaseFee) + Number(value)
                          }
                          clone[index].hourFirstAmount = value ? Number(value) : 0
                          setDayOfWeeks(clone)
                        }}
                        error={false}
                        size="small"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">Giờ</InputAdornment>,
                        }}
                        placeholder="Nhập số giờ"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                  {/* Giá 1 tiếng ở thêm */}
                  <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>Giá 1 giờ ở thêm</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <CurrencyInput
                        style={{ backgroundColor: 'white' }}
                        variant="outlined"
                        fullWidth
                        value={item.hourExtraBaseFee / (values.has2homeFee ? 1 : rate)}
                        onChange={(value) => {
                          const hourExtraBaseFee = value * (values.has2homeFee ? 1 : rate)
                          const clone = [...dayOfWeeks]
                          clone[index].hourExtraBaseFee = hourExtraBaseFee
                          if (value && clone[index].hourFirstBaseFee && clone[index].dayBaseFee && clone[index].hourFirstAmount) {
                            clone[index].hourExtraMax = Math.floor((clone[index].dayBaseFee - clone[index].hourFirstBaseFee) / hourExtraBaseFee) + Number(clone[index].hourFirstAmount)
                          }
                          setDayOfWeeks(clone)
                        }}
                        error={false}
                        size="small"
                        placeholder="Nhập số tiền"
                      />
                      {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(dayOfWeeks[index].hourExtraBaseFee)} VND`}</Typography> : null}
                    </Grid>
                  </Grid>
                  {/* Cho thuê theo giờ tối đa */}
                  <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>Thuê theo giờ tối đa</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        type="number"
                        style={{ backgroundColor: 'white' }}
                        variant="outlined"
                        fullWidth
                        value={item.hourExtraMax}
                        onChange={(e) => {
                          const clone = [...dayOfWeeks]
                          clone[index].hourExtraMax = Number(e.target.value)
                          setDayOfWeeks(clone)
                        }}
                        error={false}
                        size="small"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">Giờ</InputAdornment>,
                        }}
                        placeholder="Nhập số giờ"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
              : null}
            <Grid container item lg={12} md={12} xs={12} sm={12}>
              <Typography variant="body2" className="mb-2">* Các phí khác (nếu có) áp dụng như ngày thường.</Typography>
              <div className="ml-auto">
                <Button className="mx-1" onClick={() => deleteDayOfWeeks(index)} size="small" variant="contained" color="danger">
                  <Delete />
                  <span>Xoá</span>
                </Button>
                <Button
                  className="mx-1"
                  onClick={() => copyDayOfWeeks(item)}
                  size="small"
                  variant="contained"
                >
                  <FileCopy />
                  <span>Sao chép</span>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Button
        className="mb-4"
        onClick={addDayOfWeeks}
        size="small"
        variant="contained"
      >
        <Add />
        <span>Thêm mới</span>
      </Button>
      <Typography variant="h6" className="mb-2">Giá ngày đặc biệt trong năm:</Typography>
      {/* Ngày trong tháng */}
      {dayOfMonths.map((item, index) => (
        <Grid container item lg={8} md={8} xs={12} className={`${classes.rowAlign} ${classes.priceBox}`}>
          <Grid container spacing={2} item lg={12} md={12} xs={12} className="pt-2">
            {/* Chọn ngày trong tháng */}
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <DatePicker
                type="date"
                fullWidth
                label="Ngày trong tháng"
                value={item.day}
                onChange={(value) => {
                  const clone = [...dayOfMonths]
                  clone[index].day = value
                  setDayOfMonths(clone)
                }}
                size="small"
              />
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12} />
            {/* Giá theo ngày */}
            <Grid container item lg={6} md={6} xs={12} sm={12} style={{ borderRight: 'solid 1px #a1a1a1' }}>
              {/* Giá theo ngày */}
              <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                <Grid item lg={6} md={6} xs={12}>
                  <Typography>Giá theo ngày</Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <CurrencyInput
                    style={{ backgroundColor: 'white' }}
                    variant="outlined"
                    fullWidth
                    value={(item.dayBaseFee || 0) / (values.has2homeFee ? 1 : rate)}
                    onChange={(value) => {
                      const dayBaseFee = value * (values.has2homeFee ? 1 : rate)
                      const clone = [...dayOfMonths]
                      clone[index].dayBaseFee = dayBaseFee
                      setDayOfMonths(clone)
                    }}
                    error={false}
                    helperText=""
                    size="small"
                    placeholder="Nhập số tiền"
                  />
                  {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(dayOfMonths[index].dayBaseFee)} VND`}</Typography> : null}
                </Grid>
              </Grid>
              {/* Phí 1 giờ ở thêm */}
              <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                <Grid item lg={6} md={6} xs={12}>
                  <Typography>Giá 1 giờ vào sớm/ra muộn</Typography>
                  <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>(Chỉ áp dụng cho khung giờ qua đêm)</Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <CurrencyInput
                    style={{ backgroundColor: 'white' }}
                    variant="outlined"
                    fullWidth
                    value={(item.dayHourExtraBaseFee || 0) / (values.has2homeFee ? 1 : rate)}
                    onChange={(value) => {
                      const dayHourExtraBaseFee = value * (values.has2homeFee ? 1 : rate)
                      const clone = [...dayOfMonths]
                      clone[index].dayHourExtraBaseFee = dayHourExtraBaseFee
                      if (!clone[index].dayHourExtraMax) {
                        clone[index].dayHourExtraMax = Math.floor(clone[index].dayBaseFee / dayHourExtraBaseFee)
                      }
                      setDayOfMonths(clone)
                    }}
                    error={false}
                    helperText=""
                    size="small"
                    placeholder="Nhập số tiền"
                  />
                  {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(dayOfMonths[index].dayHourExtraBaseFee)} VND`}</Typography> : null}
                </Grid>
              </Grid>
              {/* Quá giờ theo ngày tối đa */}
              <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                <Grid item lg={6} md={6} xs={12}>
                  <Typography>Vào sớm/ra muộn tối đa</Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    style={{ backgroundColor: 'white' }}
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={item.dayHourExtraMax}
                    onChange={(e) => {
                      const clone = [...dayOfMonths]
                      clone[index].dayHourExtraMax = e.target.value ? Number(e.target.value) : 0
                      setDayOfMonths(clone)
                    }}
                    error={false}
                    size="small"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Giờ</InputAdornment>,
                    }}
                    placeholder="Nhập số giờ"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              {/* Ưu đãi đêm */}
              {values.hasNightPromote
                ? (
                  <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>Ưu đãi đêm</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <CurrencyInput
                        style={{ backgroundColor: 'white' }}
                        variant="outlined"
                        fullWidth
                        value={(item.overnightFee || 0) / (values.has2homeFee ? 1 : rate)}
                        onChange={(value) => {
                          const overnightFee = value * (values.has2homeFee ? 1 : rate)
                          const clone = [...dayOfMonths]
                          clone[index].overnightFee = overnightFee
                          setDayOfMonths(clone)
                        }}
                        error={false}
                        helperText=""
                        size="small"
                        placeholder="Nhập số tiền"
                      />
                      {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(dayOfMonths[index].overnightFee)} VND`}</Typography> : null}
                    </Grid>
                  </Grid>
                )
                : null}
              {/* Phí trong ngày */}
              {
                !values.isFlexible && values.hasRentByHour && values.applyTimeRange
                  ? (
                    <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                      <Grid item lg={6} md={6} xs={12}>
                        <Typography>Trong ngày</Typography>
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <CurrencyInput
                          style={{ backgroundColor: 'white' }}
                          variant="outlined"
                          fullWidth
                          value={(item.dayRangeFee || 0) / (values.has2homeFee ? 1 : rate)}
                          onChange={(value) => {
                            const dayRangeFee = value * (values.has2homeFee ? 1 : rate)
                            const clone = [...dayOfMonths]
                            clone[index].dayRangeFee = dayRangeFee
                            setDayOfMonths(clone)
                          }}
                          error={false}
                          helperText=""
                          size="small"
                          placeholder="Nhập số tiền"
                        />
                        {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(dayOfMonths[index].dayRangeFee)} VND`}</Typography> : null}
                      </Grid>
                    </Grid>
                  )
                  : null
              }
            </Grid>
            {/* Giá theo giờ */}
            {values.hasRentByHour
              ? (
                <Grid container item lg={6} md={6} xs={12} sm={12}>
                  {/* Giá theo giờ đầu */}
                  <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>Giá giờ đầu</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <CurrencyInput
                        style={{ backgroundColor: 'white' }}
                        variant="outlined"
                        fullWidth
                        value={(item.hourFirstBaseFee || 0) / (values.has2homeFee ? 1 : rate)}
                        onChange={(value) => {
                          const hourFirstBaseFee = value * (values.has2homeFee ? 1 : rate)
                          const clone = [...dayOfMonths]
                          clone[index].hourFirstBaseFee = hourFirstBaseFee
                          setDayOfMonths(clone)
                        }}
                        error={false}
                        helperText=""
                        size="small"
                        placeholder="Nhập số tiền"
                      />
                      {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(dayOfMonths[index].hourFirstBaseFee)} VND`}</Typography> : null}
                    </Grid>
                  </Grid>
                  {/* Sô giờ đầu */}
                  <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>Số giờ đầu</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        style={{ backgroundColor: 'white' }}
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={item.hourFirstAmount}
                        onChange={(e) => {
                          const clone = [...dayOfMonths]
                          const { value } = e.target
                          if (clone[index].hourFirstBaseFee && clone[index].hourExtraBaseFee && value) {
                            clone[index].hourExtraMax = Math.floor((clone[index].dayBaseFee - clone[index].hourFirstBaseFee) / clone[index].hourExtraBaseFee) + Number(value)
                          }
                          clone[index].hourFirstAmount = value ? Number(value) : 0
                          setDayOfMonths(clone)
                        }}
                        error={false}
                        size="small"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">Giờ</InputAdornment>,
                        }}
                        placeholder="Nhập số giờ"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                  {/* Giá 1 tiếng ở thêm */}
                  <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>Giá 1 giờ tiếp theo</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <CurrencyInput
                        style={{ backgroundColor: 'white' }}
                        variant="outlined"
                        fullWidth
                        value={(item.hourExtraBaseFee || 0) / (values.has2homeFee ? 1 : rate)}
                        onChange={(value) => {
                          const hourExtraBaseFee = value * (values.has2homeFee ? 1 : rate)
                          const clone = [...dayOfMonths]
                          clone[index].hourExtraBaseFee = hourExtraBaseFee
                          if (value && clone[index].hourFirstBaseFee && clone[index].dayBaseFee && clone[index].hourFirstAmount) {
                            clone[index].hourExtraMax = Math.floor((clone[index].dayBaseFee - clone[index].hourFirstBaseFee) / hourExtraBaseFee) + Number(clone[index].hourFirstAmount)
                          }
                          setDayOfMonths(clone)
                        }}
                        error={false}
                        helperText=""
                        size="small"
                        placeholder="Nhập số tiền"
                      />
                      {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(dayOfMonths[index].hourExtraBaseFee)} VND`}</Typography> : null}
                    </Grid>
                  </Grid>
                  {/* Cho thuê theo giờ tối đa */}
                  <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>Cho thuê theo giờ tối đa</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        type="number"
                        style={{ backgroundColor: 'white' }}
                        variant="outlined"
                        fullWidth
                        value={item.hourExtraMax}
                        onChange={(e) => {
                          const clone = [...dayOfMonths]
                          clone[index].hourExtraMax = Number(e.target.value)
                          setDayOfMonths(clone)
                        }}
                        error={false}
                        size="small"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">Giờ</InputAdornment>,
                        }}
                        placeholder="Nhập số giờ"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
              : null}
            {/* Xoá dữ liệu đã chọn */}
            <Grid container item lg={12} md={12} xs={12} sm={12}>
              <Typography variant="body2" className="mb-2">* Ưu đãi đêm và phí khác (nếu có) áp dụng như ngày thường.</Typography>
              <div className="ml-auto">
                <Button className="mx-1" onClick={() => deleteDayOfMonths(index)} size="small" variant="contained" color="danger">
                  <Delete />
                  <span>Xoá</span>
                </Button>
                <Button
                  className="mx-1"
                  onClick={() => copyDayOfMonth(item)}
                  size="small"
                  variant="contained"
                >
                  <FileCopy />
                  <span>Sao chép</span>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Button
        className="mb-4"
        onClick={addDayOfMonth}
        size="small"
        variant="contained"
      >
        <Add />
        <span>Thêm</span>
      </Button>
      {/* Giá đặc biệt theo số phòng giới hạn */}
      {values.bedroomNum > 1
        ? (
          <>
            <Typography variant="h6">Giá 1 phòng ngủ:</Typography>
            <Typography variant="body2" className="mb-2">Chỉ hiện lựa chọn này cho các đơn đặt phòng: Số khách không quá 2 người và thời gian thuê không quá 6 ngày</Typography>
            <Grid container item lg={8} md={8} xs={12} className={`${classes.rowAlign} ${classes.priceBox}`}>
              <Grid container spacing={2} item lg={12} md={12} xs={12} className="pt-2">
                {/* Giá theo ngày */}
                <Grid container item lg={6} md={6} xs={12} sm={12} style={{ borderRight: 'solid 1px #a1a1a1' }}>
                  <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>Giá theo ngày</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <CurrencyInput
                        style={{ backgroundColor: 'white' }}
                        variant="outlined"
                        fullWidth
                        value={(limitRoomBedFee.dayBaseFee || 0) / (values.has2homeFee ? 1 : rate)}
                        onChange={(value) => {
                          const dayBaseFee = value * (values.has2homeFee ? 1 : rate)
                          const clone = { ...limitRoomBedFee }
                          clone.dayBaseFee = dayBaseFee
                          setLimitRoomBedFee(clone)
                        }}
                        error={false}
                        helperText=""
                        size="small"
                        placeholder="Nhập số tiền"
                      />
                      {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(limitRoomBedFee.dayBaseFee)} VND`}</Typography> : null}
                    </Grid>
                  </Grid>
                  {/* Phí 1 giờ ở thêm */}
                  <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>Giá 1 giờ vào sớm/ra muộn</Typography>
                      <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>(Chỉ áp dụng cho khung giờ qua đêm)</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <CurrencyInput
                        style={{ backgroundColor: 'white' }}
                        variant="outlined"
                        fullWidth
                        value={(limitRoomBedFee.dayHourExtraBaseFee || 0) / (values.has2homeFee ? 1 : rate)}
                        onChange={(value) => {
                          const dayHourExtraBaseFee = value * (values.has2homeFee ? 1 : rate)
                          const clone = { ...limitRoomBedFee }
                          clone.dayHourExtraBaseFee = dayHourExtraBaseFee
                          if (!clone.dayHourExtraMax) {
                            clone.dayHourExtraMax = Math.floor(clone.dayBaseFee / dayHourExtraBaseFee)
                          }
                          setLimitRoomBedFee(clone)
                        }}
                        error={false}
                        helperText=""
                        size="small"
                        placeholder="Nhập số tiền"
                      />
                      {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(limitRoomBedFee.dayHourExtraBaseFee)} VND`}</Typography> : null}
                    </Grid>
                  </Grid>
                  {/* Quá giờ theo ngày tối đa */}
                  <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography>Vào sớm/ra muộn tối đa</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        style={{ backgroundColor: 'white' }}
                        type="number"
                        label="Quá giờ theo ngày tối đa"
                        variant="outlined"
                        fullWidth
                        value={limitRoomBedFee.dayHourExtraMax}
                        onChange={(e) => {
                          const clone = { ...limitRoomBedFee }
                          clone.dayHourExtraMax = e.target.value ? Number(e.target.value) : 0
                          setLimitRoomBedFee(clone)
                        }}
                        error={false}
                        size="small"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">Giờ</InputAdornment>,
                        }}
                        placeholder="Nhập số giờ"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                  {/* Ưu đãi đêm */}
                  {values.hasNightPromote
                    ? (
                      <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                        <Grid item lg={6} md={6} xs={12}>
                          <Typography>Ưu đãi đêm</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                          <CurrencyInput
                            style={{ backgroundColor: 'white' }}
                            variant="outlined"
                            fullWidth
                            value={(limitRoomBedFee.overnightFee || 0) / (values.has2homeFee ? 1 : rate)}
                            onChange={(value) => {
                              const overnightFee = value * (values.has2homeFee ? 1 : rate)
                              const clone = { ...limitRoomBedFee }
                              clone.overnightFee = overnightFee
                              setLimitRoomBedFee(clone)
                            }}
                            error={false}
                            helperText=""
                            size="small"
                            placeholder="Nhập số tiền"
                          />
                          {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(limitRoomBedFee.overnightFee)} VND`}</Typography> : null}
                        </Grid>
                      </Grid>
                    )
                    : null}
                  {/* Phí trong ngày */}
                  {
                    !values.isFlexible && values.hasRentByHour && values.applyTimeRange
                      ? (
                        <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                          <Grid item lg={6} md={6} xs={12}>
                            <Typography>Trong ngày</Typography>
                          </Grid>
                          <Grid item lg={6} md={6} xs={12}>
                            <CurrencyInput
                              style={{ backgroundColor: 'white' }}
                              variant="outlined"
                              fullWidth
                              value={(limitRoomBedFee.dayRangeFee || 0) / (values.has2homeFee ? 1 : rate)}
                              onChange={(value) => {
                                const dayRangeFee = value * (values.has2homeFee ? 1 : rate)
                                const clone = { ...limitRoomBedFee }
                                clone.dayRangeFee = dayRangeFee
                                setLimitRoomBedFee(clone)
                              }}
                              error={false}
                              helperText=""
                              size="small"
                              placeholder="Nhập số tiền"
                            />
                            {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(limitRoomBedFee.dayRangeFee)} VND`}</Typography> : null}
                          </Grid>
                        </Grid>
                      )
                      : null
                  }
                </Grid>
                {/* Giá theo giờ */}
                {values.hasRentByHour
                  ? (
                    <Grid container item lg={6} md={6} xs={12} sm={12}>
                      {/* Giá theo giờ đầu */}
                      <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                        <Grid item lg={6} md={6} xs={12}>
                          <Typography>Giá giờ đầu</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                          <CurrencyInput
                            style={{ backgroundColor: 'white' }}
                            variant="outlined"
                            fullWidth
                            value={(limitRoomBedFee.hourFirstBaseFee || 0) / (values.has2homeFee ? 1 : rate)}
                            onChange={(value) => {
                              const hourFirstBaseFee = value * (values.has2homeFee ? 1 : rate)
                              const clone = { ...limitRoomBedFee }
                              clone.hourFirstBaseFee = hourFirstBaseFee
                              setLimitRoomBedFee(clone)
                            }}
                            error={false}
                            helperText=""
                            size="small"
                            placeholder="Nhập số tiền"
                          />
                          {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(limitRoomBedFee.hourFirstBaseFee)} VND`}</Typography> : null}
                        </Grid>
                      </Grid>
                      {/* Sô giờ đầu */}
                      <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                        <Grid item lg={6} md={6} xs={12}>
                          <Typography>Số giờ đầu</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                          <TextField
                            style={{ backgroundColor: 'white' }}
                            type="number"
                            variant="outlined"
                            fullWidth
                            value={limitRoomBedFee.hourFirstAmount}
                            onChange={(e) => {
                              const clone = { ...limitRoomBedFee }
                              const { value } = e.target
                              if (clone.hourFirstBaseFee && clone.hourExtraBaseFee && value) {
                                clone.hourExtraMax = Math.floor((clone.dayBaseFee - clone.hourFirstBaseFee) / clone.hourExtraBaseFee) + Number(value)
                              }
                              clone.hourFirstAmount = value ? Number(value) : 0
                              setLimitRoomBedFee(clone)
                            }}
                            error={false}
                            size="small"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">Giờ</InputAdornment>,
                            }}
                            placeholder="Nhập số giờ"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>
                      {/* Giá 1 tiếng ở thêm */}
                      <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                        <Grid item lg={6} md={6} xs={12}>
                          <Typography>Giá 1 giờ tiếp theo</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                          <CurrencyInput
                            style={{ backgroundColor: 'white' }}
                            variant="outlined"
                            fullWidth
                            value={(limitRoomBedFee.hourExtraBaseFee || 0) / (values.has2homeFee ? 1 : rate)}
                            onChange={(value) => {
                              const hourExtraBaseFee = value * (values.has2homeFee ? 1 : rate)
                              const clone = { ...limitRoomBedFee }
                              clone.hourExtraBaseFee = hourExtraBaseFee
                              if (value && clone.hourFirstBaseFee && clone.dayBaseFee && clone.hourFirstAmount) {
                                clone.hourExtraMax = Math.floor((clone.dayBaseFee - clone.hourFirstBaseFee) / hourExtraBaseFee) + Number(clone.hourFirstAmount)
                              }
                              setLimitRoomBedFee(clone)
                            }}
                            error={false}
                            helperText=""
                            size="small"
                            placeholder="Nhập số tiền"
                          />
                          {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(limitRoomBedFee.hourExtraBaseFee)} VND`}</Typography> : null}
                        </Grid>
                      </Grid>
                      {/* Cho thuê theo giờ tối đa */}
                      <Grid container item lg={12} md={12} xs={12} sm={12} className="mb-2">
                        <Grid item lg={6} md={6} xs={12}>
                          <Typography>Cho thuê theo giờ tối đa</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                          <TextField
                            type="number"
                            style={{ backgroundColor: 'white' }}
                            variant="outlined"
                            fullWidth
                            value={limitRoomBedFee.hourExtraMax}
                            onChange={(e) => {
                              const clone = { ...limitRoomBedFee }
                              clone.hourExtraMax = Number(e.target.value)
                              setLimitRoomBedFee(clone)
                            }}
                            error={false}
                            size="small"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">Giờ</InputAdornment>,
                            }}
                            placeholder="Nhập số giờ"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                  : null}
                {/* Xoá dữ liệu đã chọn */}
                <Grid container item lg={12} md={12} xs={12} sm={12}>
                  <Typography variant="body2" className="mb-2">* Ưu đãi đêm và phí khác (nếu có) áp dụng như ngày thường.</Typography>
                  <Typography variant="body2">* Giá 1 phòng ngủ được áp dụng cho tất cả các ngày, bao gồm cả ngày đặc biệt (nếu có).</Typography>
                  {false && (
                  <Button className="ml-auto" onClick={() => deleteLimitRoomBedFee()} size="small" variant="contained" color="danger">
                    <Delete />
                    <span>Xoá</span>
                  </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )
        : null}
    </>
  )
}

SpecialPrice.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  // errors: PropTypes.shape(),
  // touched: PropTypes.shape(),
  // handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default SpecialPrice
