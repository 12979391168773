import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import HostInfo from './HostInfo'
import RoomList from './RoomList'
import ReservationList from './ReservationList'

const useStyles = makeStyles(() => ({
  titleAlign: {
    marginTop: 10,
    marginBottom: 10,
  },
}))

const HostForm = (props) => {
  const { history, id, action } = props
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const onHostInfoUpdate = (type) => {
    switch (type) {
      case 'success':
        enqueueSnackbar('Cập nhật thông tin chủ nhà thành công!', { variant: type })
        break
      case 'error':
        enqueueSnackbar('Cập nhật thông tin chủ nhà không thành công!', { variant: type })
        break
      case 'info':
        enqueueSnackbar('Thêm mới chủ nhà thành công!', { variant: type })
        break
      default:
    }
  }

  return (
    <Grid container>
      <Grid item lg={4} md={12} style={{ padding: 5 }}>
        <HostInfo history={history} id={id} action={action} onUpdate={onHostInfoUpdate} />
      </Grid>
      {/* Room and Reservation */}
      <Grid item lg={8} md={12} style={{ padding: 5 }}>
        <Typography variant="h4" className={classes.titleAlign}>
          Danh sách phòng
        </Typography>
        <RoomList owner={id} />
        <br />
        <Typography variant="h4" className={classes.titleAlign}>
          Đơn đặt phòng
        </Typography>
        <ReservationList host={id} />
      </Grid>
    </Grid>
  )
}

HostForm.propTypes = {
  history: PropTypes.shape(),
  id: PropTypes.string,
  action: PropTypes.string,
}

export default HostForm
