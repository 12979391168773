import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from './styled'

const InstructionDialog = ({
  // classes,
  text,
  open,
  onClose,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle disableTypography>
      <h3>Hướng dẫn nhận phòng</h3>
    </DialogTitle>
    <DialogContent className="p-4">
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Đóng
      </Button>
    </DialogActions>
  </Dialog>
)

InstructionDialog.defaultProps = {
  // classes: {},
  text: '',
  open: false,
  onClose: () => {},
}

InstructionDialog.propTypes = {
  // classes: PropTypes.shape(),
  text: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default withStyles(styled)(InstructionDialog)
