
export const REPORT_GRAPH_PERIOD = 14
export const NOTIFY_TYPE = {
  NEW_ORDER: 'NEW_ORDER',
  PAID_ORDER: 'PAID_ORDER',
  UPDATE_ORDER: 'UPDATE_ORDER',
  REJECT_ORDER: 'REJECT_ORDER',
  SERVICE_ORDER: 'SERVICE_ORDER',
  CUSTOMER: 'CUSTOMER',
  PREPAID_PACKAGE: 'PREPAID_PACKAGE',
  HOST: 'HOST',
  ROOM: 'ROOM',
  DEPOSIT: 'DEPOSIT',
}
