import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Avatar,
  Button,
  // Button,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import InputIcon from '@material-ui/icons/Input'
import * as notifyApi from 'provider/notification'
import { NOTIFY_TYPE } from 'contants'
import { SOCKET_ACTION } from 'redux/type'
import { Add } from '@material-ui/icons'
import NotificationList from './NotificationList'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  iconSize: {
    fontSize: '1rem',
    fontFamily: 'inherit',
  },
}))

const Topbar = ({
  className,
  socket,
  onSidebarOpen,
  signout,
  resetSocket,
  // notifications,
  // updateNotification,
}) => {
  const classes = useStyles()
  const [newOrders, setNewOrders] = useState([])
  const [newOrderUnseen, setNewOrderUnseen] = useState(0)
  const [newOrderTotal, setNewOrderTotal] = useState(-1)
  const [newOrderPage, setNewOrderPage] = useState(0)
  const [paidOrders, setPaidOrders] = useState([])
  const [paidOrderUnseen, setPaidOrderUnseen] = useState(0)
  const [paidOrderTotal, setPaidOrderTotal] = useState(-1)
  const [paidOrderPage, setPaidOrderPage] = useState(1)
  const [updateOrders, setUpdateOrders] = useState([])
  const [updateOrderUnseen, setUpdateOrderUnseen] = useState(0)
  const [updateOrderTotal, setUpdateOrderTotal] = useState(-1)
  const [updateOrderPage, setUpdateOrderPage] = useState(1)
  const [rejectOrders, setRejectOrders] = useState([])
  const [rejectOrderUnseen, setRejectOrderUnseen] = useState(0)
  const [rejectOrderTotal, setRejectOrderTotal] = useState(-1)
  const [rejectOrderPage, setRejectOrderPage] = useState(1)

  const fetchNotification = async (type, isReset) => {
    try {
      const query = {}
      let resp = []
      switch (type) {
        case NOTIFY_TYPE.NEW_ORDER:
          query.type = 'reservation'
          query.action = 'create'
          query.page = newOrderPage + 1
          resp = await notifyApi.find(query)
          setNewOrders(isReset
            ? resp.data?.map(item => ({
              ...item,
              icon: <Avatar src={item.customer?.avatar} />,
              link: `/reservation/${item.subjectId}/view`,
            }))
            : [
              ...newOrders,
              ...resp.data?.map(item => ({
                ...item,
                icon: <Avatar src={item.customer?.avatar} />,
                link: `/reservation/${item.subjectId}/view`,
              })),
            ])
          setNewOrderTotal(resp.total)
          setNewOrderPage(resp.page)
          setNewOrderUnseen(resp.unseen)
          break

        case NOTIFY_TYPE.PAID_ORDER:
          query.type = 'reservation'
          query.action = ['paid', 'paid-add-checkout']
          resp = await notifyApi.find(query)
          setPaidOrders(isReset
            ? resp.data?.map(item => ({
              ...item,
              icon: <Avatar src={item.customer?.avatar} />,
              link: `/reservation/${item.subjectId}/view`,
            }))
            : [
              ...paidOrders,
              ...resp.data?.map(item => ({
                ...item,
                icon: <Avatar src={item.customer?.avatar} />,
                link: `/reservation/${item.subjectId}/view`,
              })),
            ])
          setPaidOrderTotal(resp.total)
          setPaidOrderPage(resp.page)
          setPaidOrderUnseen(resp.unseen)
          break

        case NOTIFY_TYPE.UPDATE_ORDER:
          query.type = 'reservation'
          query.action = 'update'
          resp = await notifyApi.find(query)
          setUpdateOrders(isReset
            ? resp.data?.map(item => ({
              ...item,
              icon: <Avatar src={item.customer?.avatar} />,
              link: `/reservation/${item.subjectId}/view`,
            }))
            : [
              ...updateOrders,
              ...resp.data?.map(item => ({
                ...item,
                icon: <Avatar src={item.customer?.avatar} />,
                link: `/reservation/${item.subjectId}/view`,
              })),
            ])
          setUpdateOrderTotal(resp.total)
          setUpdateOrderPage(resp.page)
          setUpdateOrderUnseen(resp.unseen)
          break

        case NOTIFY_TYPE.REJECT_ORDER:
          query.type = 'reservation'
          query.action = ['reject', 'reject-by-host', 'reject-waiting', 'reject-processing', 'reject-norefund', 'reject-refund']
          resp = await notifyApi.find(query)
          setRejectOrders(isReset
            ? resp.data?.map(item => ({
              ...item,
              icon: <Avatar src={item.customer?.avatar} />,
              link: `/reservation/${item.subjectId}/view`,
            }))
            : [
              ...rejectOrders,
              ...resp.data?.map(item => ({
                ...item,
                icon: <Avatar src={item.customer?.avatar} />,
                link: `/reservation/${item.subjectId}/view`,
              })),
            ])
          setRejectOrderTotal(resp.total)
          setRejectOrderPage(resp.page)
          setRejectOrderUnseen(resp.unseen)
          break

        // TODO: Thông báo liên quan tới cọc
        case NOTIFY_TYPE.DEPOSIT:
          query.type = 'deposit'
          query.action = []
          break

        // TODO: Thông báo các đơn dịch vụ
        case NOTIFY_TYPE.SERVICE_ORDER:
          break

        default:
          break
      }
    } catch (e) {
      console.log(e)
    }
  }

  const updateNotification = async (id) => {
    try {
      await notifyApi.update([id])
    } catch (e) {
      console.log(e)
    }
  }

  // const getNotifications = () => {
  //   switch (mode) {
  //     case NOTIFY_TYPE.NEW_ORDER:
  //       return newOrders

  //     default:
  //       return []
  //   }
  // }

  const setAsRead = async (type) => {
    try {
      switch (type) {
        case NOTIFY_TYPE.NEW_ORDER:
          await notifyApi.setAsRead({
            type: 'reservation',
            action: 'create',
          })
          setNewOrders(newOrders.map(item => ({ ...item, status: true })))
          setNewOrderUnseen(0)
          break

        case NOTIFY_TYPE.PAID_ORDER:
          await notifyApi.setAsRead({
            type: 'reservation',
            action: ['paid', 'paid-add-checkout'],
          })
          setPaidOrders(paidOrders.map(item => ({ ...item, status: true })))
          setPaidOrderUnseen(0)
          break

        case NOTIFY_TYPE.UPDATE_ORDER:
          await notifyApi.setAsRead({
            type: 'reservation',
            action: 'update',
          })
          setUpdateOrders(updateOrders.map(item => ({ ...item, status: true })))
          setUpdateOrderUnseen(0)
          break

        case NOTIFY_TYPE.REJECT_ORDER:
          await notifyApi.setAsRead({
            type: 'reservation',
            action: ['reject', 'reject-by-host', 'reject-waiting', 'reject-processing', 'reject-norefund', 'reject-refund'],
          })
          setRejectOrders(rejectOrders.map(item => ({ ...item, status: true })))
          setRejectOrderUnseen(0)
          break

        default:
          break
      }
      resetSocket(type)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/2h-logo.png"
            style={{ height: 40 }}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <RouterLink to="/room/create" style={{ textDecoration: 'none' }}>
            <Button variant="contained" className="w-100 py-2" style={{ backgroundColor: 'rgb(65 204 120)', color: 'white' }}>
              <Add />
              <span>Tạo phòng</span>
            </Button>
          </RouterLink>
          {/* Đơn mới */}
          <NotificationList
            label="Đơn mới"
            icon={<i className="fas fa-calendar" />}
            count={socket?.order?.new || 0}
            data={newOrders}
            unseen={newOrderUnseen}
            total={newOrderTotal}
            page={newOrderPage}
            resetSocket={() => {
              fetchNotification(NOTIFY_TYPE.NEW_ORDER, true)
              resetSocket(SOCKET_ACTION.RESET_ORDER_NEW)
            }}
            onFetchData={() => fetchNotification(NOTIFY_TYPE.NEW_ORDER)}
            update={updateNotification}
            setAsRead={() => setAsRead(NOTIFY_TYPE.NEW_ORDER)}
          />
          {/* Đã thanh toán */}
          <NotificationList
            label="Đơn thanh toán"
            icon={<i className="fas fa-calendar-check" />}
            count={socket?.order?.paid || 0}
            data={paidOrders}
            unseen={paidOrderUnseen}
            total={paidOrderTotal}
            page={paidOrderPage}
            resetSocket={() => {
              fetchNotification(NOTIFY_TYPE.PAID_ORDER, true)
              resetSocket(SOCKET_ACTION.RESET_ORDER_PAID)
            }}
            onFetchData={() => fetchNotification(NOTIFY_TYPE.PAID_ORDER)}
            update={updateNotification}
            setAsRead={() => setAsRead(NOTIFY_TYPE.PAID_ORDER)}
          />
          {/* Chỉnh sửa đơn */}
          <NotificationList
            label="Đơn chỉnh sửa"
            icon={<i className="fas fa-calendar-edit" />}
            count={socket?.order?.update || 0}
            data={updateOrders}
            unseen={updateOrderUnseen}
            total={updateOrderTotal}
            page={updateOrderPage}
            resetSocket={() => {
              fetchNotification(NOTIFY_TYPE.UPDATE_ORDER, true)
              resetSocket(SOCKET_ACTION.RESET_ORDER_UPDATE)
            }}
            onFetchData={() => fetchNotification(NOTIFY_TYPE.UPDATE_ORDER)}
            update={updateNotification}
            setAsRead={() => setAsRead(NOTIFY_TYPE.UPDATE_ORDER)}
          />
          {/* Huỷ không hoàn tiền */}
          <NotificationList
            label="Đơn huỷ"
            icon={<i className="fas fa-trash" />}
            count={socket?.order?.reject || 0}
            data={rejectOrders}
            unseen={rejectOrderUnseen}
            total={rejectOrderTotal}
            page={rejectOrderPage}
            resetSocket={() => {
              fetchNotification(NOTIFY_TYPE.REJECT_ORDER, true)
              resetSocket(SOCKET_ACTION.RESET_ORDER_REJECT)
            }}
            onFetchData={() => fetchNotification(NOTIFY_TYPE.REJECT_ORDER)}
            update={updateNotification}
            setAsRead={() => setAsRead(NOTIFY_TYPE.REJECT_ORDER)}
          />
          {/* Đơn có dịch vụ */}
          {/* <Button color="inherit" className={classes.iconSize}>
            <i className="fas fa-birthday-cake" />
            <div className="ml-1 text-center text-capitalize">
              <small>Đơn dịch vụ</small>
            </div>
          </Button> */}
          {/* Signout */}
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={signout}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          {/* Notification */}
          {/* <NotificationList notifications={getNotifications()} updateNotification={updateNotification} /> */}
          {/* Side-panel */}
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  socket: PropTypes.shape(),
  onSidebarOpen: PropTypes.func,
  signout: PropTypes.func,
  resetSocket: PropTypes.func,
  // notifications: PropTypes.arrayOf(PropTypes.shape()),
  // updateNotification: PropTypes.func,
}

export default Topbar
