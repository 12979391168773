import moment from 'moment'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Table } from 'components'
import { formatPrice } from 'utils/formatter'
import * as reservationApi from 'provider/reservation'
import {
  Done, Delete, AttachMoney,
} from '@material-ui/icons'
import {
  Typography,
  Chip,
  Tooltip,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  DialogContent,
  IconButton,
  Grid,
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import MediaUpload from 'components/MediaUpload'
import AppConfig from 'config'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
  },
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050,
  },
  link: {
    fontSize: '1rem',
    color: '#3e51b5',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  codeCell: {
    '& a>p': {
      color: '#3f51b5',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
  },
  roomCell: {
    '& div': {
      color: '#3e51b5',
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& a': {
      color: '#3e51b5',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  priceCell: {
    '& a': {
      color: '#3e51b5',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  timeCell: {
    '& div': {
      display: 'inline-block',
    },
  },
  serviceThumbnail: {
    width: 70,
    height: 70,
    borderRadius: 0,
    marginRight: theme.spacing(2),
  },
  serviceName: {
    '& span': {
      fontSize: '1rem',
    },
  },
  servicePrice: {
    textAlign: 'right',
    '& span': {
      fontSize: '1rem',
      fontWeight: '500',
    },
  },
  actions: {
    justifyContent: 'flex-end',
  },
  formControl: {
    backgroundColor: 'white',
    marginTop: 10,
  },
  groupFilter: {
    marginBottom: 10,
    marginTop: 10,
  },
}))

const PAYMENT_TYPE = [
  {
    value: '2home',
    label: 'Hoàn tiền tới tài khoản 2home',
  },
  {
    value: 'bank-transfer',
    label: 'Hoàn tiền tới tài khoản ngân hàng',
  },
]

const MIN_HOUR_REJECT = 48
const MAX_HOUR_REJECT = 96

const FROM_48H_TO_NOW = `
Theo chính sách của căn hộ, sau thời điểm 48h trước khi nhận phòng, 
các thay đổi trên đơn đặt phòng của khách sẽ không được hoàn tiền, 
bạn sẽ phải thanh toán lại đơn đặt phòng mới.
`
const FROM_4DAYS_TO_48H = `
Theo chính sách của căn hộ, trong thời gian từ 4 ngày đến 48h trước khi nhận phòng, 
các thay đổi trên đơn đặt phòng của khách sẽ không được hoàn 50% tiền phòng và 100% tiền dịch vụ, 
phần chênh lệch sau khi thay đổi sẽ được hoàn lại hoặc thanh toán thêm dựa trên chính sách trên.
`
const BEFORE_4DAYS = `
Theo chính sách của căn hộ, trước thời điểm 4 ngày trước khi nhận phòng, 
các thay đổi trên đơn đặt phòng của khách sẽ không được hoàn 100% tiền phòng và 100% tiền dịch vụ, 
phần chênh lệch sau khi thay đổi sẽ được hoàn lại hoặc thanh toán thêm dựa trên chính sách trên.
`

const ReservationsList = ({ customer }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [sltReservation, setSltReservation] = useState(undefined)
  const [openServiceDialog, setOpenServiceDialog] = useState(false)
  const [services, setServices] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [total, setTotal] = useState(0)
  const [reservations, setReservations] = useState([])
  const [loading, setLoading] = useState(false)
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false)
  const [paymentType, setPaymentType] = useState('2home')
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [rejectNote, setRejectNote] = useState(undefined)
  const [refundUser, setRefundUser] = useState(undefined)
  const [refundBankAccount, setRefundBankAccount] = useState(undefined)
  const [refundBankName, setRefundBankName] = useState(undefined)
  const [rejectImage, setRejectImage] = useState(undefined)
  const [sltActive, setSltActive] = useState(0) // 0: active; 1: completed; 2: reject,expire

  const fetchReservation = async (p, l, active) => {
    if (customer) {
      setLoading(true)
      try {
        const resp = await reservationApi.find({
          customer: JSON.stringify([customer]),
          active: active || 0,
          page: p,
          limit: l,
        })
        setTotal(resp.total)
        setReservations(resp.data)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
      }
    }
  }

  useEffect(() => {
    fetchReservation(page, limit, 0)
    return () => {}
  }, [customer])

  const handlePageChange = async (p) => {
    setPage(p)
    await fetchReservation(p, limit, sltActive)
  }

  const handleRowsPerPageChange = async (l) => {
    setLimit(l)
    setPage(1)
    await fetchReservation(1, l, sltActive)
  }

  const renderRoomType = (type) => {
    switch (type) {
      case 'request':
        return 'Yêu cầu xác nhận'
      case 'instant':
        return 'Đặt nhanh'
      default:
        return ''
    }
  }

  const renderStatus = (status) => {
    switch (status) {
      case 'reject':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Đã huỷ"
          />
        )
      case 'processing':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#ffa854',
            }}
            label="Chờ xác nhận"
          />
        )
      case 'confirmed':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#ff1744',
            }}
            label="Chờ thanh toán"
          />
        )
      case 'paid':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#00b0ff',
            }}
            label="Đã thanh toán"
          />
        )
      case 'using':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#00e676',
            }}
            label="Đang sử dụng"
          />
        )
      case 'completed':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#3e51b5',
            }}
            label="Hoàn thành"
          />
        )
      case 'expired':
      default:
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#9e9e9e',
            }}
            label="Hết hạn"
          />
        )
    }
  }

  const confirm = async (id) => {
    try {
      const resp = await reservationApi.confirm(id)
      let clone = [...reservations]
      clone = clone.map(item => item.id === id ? resp : item)
      setReservations(clone)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const pay = async () => {
    try {
      const resp = await reservationApi.pay(sltReservation, paymentType)
      setSltReservation(undefined)
      setPaymentType(undefined)
      setOpenPaymentDialog(false)
      let clone = [...reservations]
      clone = clone.map(item => item.id === sltReservation ? resp : item)
      setReservations(clone)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const reject = async () => {
    try {
      const resp = await reservationApi.reject(sltReservation, 'customer', {
        rejectNote,
        refundBankName,
        refundBankAccount,
        refundUser,
        paymentType,
        rejectImage,
      })
      setSltReservation(undefined)
      setRejectNote(undefined)
      setRefundUser(undefined)
      setRefundBankAccount(undefined)
      setRefundBankName(undefined)
      setPaymentType(undefined)
      setRejectImage(undefined)
      setOpenRejectDialog(false)
      let clone = [...reservations]
      clone = clone.map(item => item.id === sltReservation ? resp : item)
      setReservations(clone)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const renderAction = (index, status) => {
    switch (status) {
      case 'processing':
        return (
          <>
            <Tooltip
              title="Xác nhận đơn đặt phòng"
              aria-label="Xác nhận đơn đặt phòng"
            >
              <IconButton
                aria-label="confirm"
                className={classes.margin}
                onClick={() => confirm(reservations[index].id)}
              >
                <Done />
              </IconButton>
            </Tooltip>
            <Tooltip title="Huỷ đơn đặt phòng" aria-label="Huỷ đơn đặt phòng">
              <IconButton
                aria-label="delete"
                className={classes.margin}
                onClick={() => {
                  setOpenRejectDialog(true)
                  setSltReservation(reservations[index].id)
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      case 'confirmed':
        return (
          <>
            <Tooltip
              title="Xác nhận thanh toán"
              aria-label="Xác nhận thanh toán"
            >
              <IconButton
                aria-label="confirm"
                className={classes.margin}
                onClick={() => {
                  setOpenPaymentDialog(true)
                  setSltReservation(reservations[index].id)
                }}
              >
                <AttachMoney />
              </IconButton>
            </Tooltip>
            <Tooltip title="Huỷ đơn đặt phòng" aria-label="Huỷ đơn đặt phòng">
              <IconButton
                aria-label="delete"
                className={classes.margin}
                onClick={() => {
                  setOpenRejectDialog(true)
                  setSltReservation(reservations[index].id)
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      case 'paid':
        return (
          <>
            <Tooltip title="Huỷ đơn đặt phòng" aria-label="Huỷ đơn đặt phòng">
              <IconButton
                aria-label="delete"
                className={classes.margin}
                onClick={() => {
                  setOpenRejectDialog(true)
                  setSltReservation(reservations[index].id)
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      case 'using':
      case 'completed':
      case 'expired':
      case 'reject':
      default:
        return null
    }
  }

  const onUploadImageSuccess = (files) => {
    setRejectImage(files[0])
  }

  const getWarning = () => {
    if (!sltReservation) return ''
    const reservation = reservations.find(r => r.id === sltReservation)
    const diffToNow = moment(reservation.checkin).diff(
      moment().valueOf(),
      'hours',
    )
    if (diffToNow < MIN_HOUR_REJECT) {
      return FROM_48H_TO_NOW
    } if (diffToNow > MAX_HOUR_REJECT) {
      return BEFORE_4DAYS
    }
    return FROM_4DAYS_TO_48H
  }

  const groupByStatus = (active) => {
    setSltActive(active)
    setPage(1)
    fetchReservation(page, limit, active)
  }

  return (
    <>
      {/* Service */}
      <Dialog
        open={openServiceDialog}
        onClose={() => setOpenServiceDialog(false)}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle disableTypography>
          <h3>Chi tiết dịch vụ</h3>
        </DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <List>
            {services
              && services.map(service => (
                <ListItem key={service.id}>
                  <ListItemAvatar>
                    <Avatar
                      className={classes.serviceThumbnail}
                      src={service.image}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.serviceName}
                    primary={(
                      <RouterLink
                        to=""
                        target="_blank"
                        className={classes.link}
                      >
                        {service.name}
                      </RouterLink>
                    )}
                  />
                  <ListItemText
                    className={classes.servicePrice}
                    primary={`${formatPrice(service.price)} VND`}
                  />
                </ListItem>
              ))}
          </List>
        </DialogContent>
      </Dialog>
      {/* Payment dialog */}
      <Dialog
        open={openPaymentDialog}
        onClose={() => {
          setOpenPaymentDialog(false)
          setPaymentType(undefined)
          setSltReservation(undefined)
        }}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle disableTypography>
          <h3>Thanh toán đơn đặt phòng</h3>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80 }}>
          <Grid container spacing={3}>
            <Grid item lg={6}>
              <Button
                fullWidth
                variant="contained"
                color={paymentType === '2home' ? 'primary' : 'default'}
                size="large"
                onClick={() => setPaymentType('2home')}
              >
                Tài khoản 2home
              </Button>
            </Grid>
            <Grid item lg={6}>
              <Button
                fullWidth
                variant="contained"
                color={paymentType === 'bank-transfer' ? 'primary' : 'default'}
                size="large"
                onClick={() => setPaymentType('bank-transfer')}
              >
                Tài khoản ngân hàng
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={pay}
            color="primary"
            disabled={!paymentType}
          >
            Thanh toán
          </Button>
        </DialogActions>
      </Dialog>
      {/* Reject dialog */}
      <Dialog
        open={openRejectDialog}
        onClose={() => {
          setOpenRejectDialog(false)
          setSltReservation(undefined)
        }}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle disableTypography>
          <h3>Huỷ đơn đặt phòng</h3>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80 }}>
          <Grid container>
            {/* Warning */}
            {getWarning()}
          </Grid>
          <br />
          <Grid container spacing={2}>
            {/* Refund info */}
            <Grid item lg={6}>
              {/* Payment type: 2home/bank-transfer */}
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel
                  style={{ backgroundColor: 'white', padding: '0px 5px' }}
                  id="refund-payment-type"
                >
                      Hình thức hoàn tiền
                </InputLabel>
                <Select
                  labelId="refund-payment-type"
                  id="refund-payment-type-label"
                  value={paymentType}
                  onChange={e => setPaymentType(e.target.value)}
                >
                  {PAYMENT_TYPE.map(item => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Bank information */}
              {paymentType === 'bank-transfer' ? (
                <>
                  <TextField
                    label="Chủ tài khoản"
                    variant="outlined"
                    fullWidth
                    value={refundUser}
                    onChange={e => setRefundUser(e.target.value)}
                    className={classes.formControl}
                  />
                  <TextField
                    label="Số tài khoản"
                    variant="outlined"
                    fullWidth
                    value={refundBankAccount}
                    onChange={e => setRefundBankAccount(e.target.value)}
                    className={classes.formControl}
                  />
                  <TextField
                    label="Ngân hàng"
                    variant="outlined"
                    fullWidth
                    value={refundBankName}
                    onChange={e => setRefundBankName(e.target.value)}
                    className={classes.formControl}
                  />
                </>
              ) : null}
            </Grid>
            {/* Refund image */}
            <Grid item lg={6} style={{ textAlign: 'center' }}>
              {rejectImage ? (
                <div
                  style={{ display: 'flex', width: '100%', height: 300 }}
                >
                  <img
                    alt="reject-evidence"
                    src={rejectImage}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      margin: 'auto',
                    }}
                  />
                </div>
              ) : null}
              <MediaUpload
                title="Tải ảnh"
                multiple
                onSuccess={onUploadImageSuccess}
              />
            </Grid>
          </Grid>
          <Grid container>
            <TextField
              label="Lý do huỷ"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              value={rejectNote}
              onChange={e => setRejectNote(e.target.value)}
              className={classes.formControl}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={reject}
            color="primary"
          >
            Huỷ đơn
          </Button>
        </DialogActions>
      </Dialog>
      {/* Active type */}
      <Grid container spacing={1} className={classes.groupFilter}>
        <Grid item lg={3} md={4}>
          <Button
            fullWidth
            size="small"
            variant={`${sltActive === 0 ? 'contained' : 'default'}`}
            color={`${sltActive === 0 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus(0)}
          >
            Hoạt động
          </Button>
        </Grid>
        <Grid item lg={3} md={4}>
          <Button
            fullWidth
            size="small"
            variant={`${sltActive === 1 ? 'contained' : 'default'}`}
            color={`${sltActive === 1 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus(1)}
          >
            Hoàn thành
          </Button>
        </Grid>
        <Grid item lg={3} md={4}>
          <Button
            fullWidth
            size="small"
            variant={`${sltActive === 2 ? 'contained' : 'default'}`}
            color={`${sltActive === 2 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus(2)}
          >
            Huỷ và hết hạn
          </Button>
        </Grid>
      </Grid>
      {/* List reservations */}
      <Table
        className={classes.root}
        data={reservations}
        columns={[
          {
            Head: 'Mã đơn',
            Cell: (value, origin) => (
              <>
                <RouterLink
                  to={`/reservation/${value}/${
                    ['completed', 'expired', 'reject'].includes(
                      origin.status,
                    )
                      ? 'view'
                      : 'update'
                  }`}
                  target="_blank"
                >
                  <Typography variant="body1">
                    {value}
                  </Typography>
                </RouterLink>
                <Typography variant="body2">
                  {moment(origin.createdAt).format('DD/MM/YYYY')}
                </Typography>
              </>
            ),
            accessor: 'code',
            sortable: false,
          },
          {
            Head: 'Phòng',
            Cell: (value, origin) => (
              <>
                <a href={`${AppConfig.endpoint.customer}/phong-can-ho/${origin.room.id}`} target="_blank" rel="noopener noreferrer">
                  <Tooltip
                    title={value ? value.name : ''}
                    aria-label={value ? value.name : ''}
                  >
                    <Typography variant="body1">
                      {value ? value.name : ''}
                    </Typography>
                  </Tooltip>
                </a>
                <Typography variant="body2">
                  {`Mã: ${value ? value.code : ''} - `}
                  {renderRoomType(value ? value.bookingType : '')}
                </Typography>
                {origin.host ? (
                  <Typography variant="body2">
                    <Tooltip title="Xem thông tin chủ nhà">
                      <RouterLink
                        to={`/host/${origin.host.id}/update`}
                        target="_blank"
                      >
                        {origin.host.name}
                        {` (${origin.host.code})`}
                      </RouterLink>
                    </Tooltip>
                  </Typography>
                ) : null}
              </>
            ),
            accessor: 'room',
            sortable: false,
          },
          {
            Head: 'Số tiền',
            Cell: (value, origin) => (
              <>
                <Typography variant="body1">
                  {`${formatPrice(value)}VND`}
                </Typography>
                <Typography variant="body2">
                  <a
                    onClick={() => {
                      setOpenServiceDialog(true)
                      setServices(origin.services)
                    }}
                    type="button"
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    {`${
                      origin.services
                              && origin.services.length
                        ? `${origin.services.length} dịch vụ`
                        : ''
                    }`}
                  </a>
                </Typography>
              </>
            ),
            accessor: 'totalPrice',
            sortable: false,
          },
          {
            Head: 'Thời gian',
            Cell: (value, origin) => (
              <Typography variant="body1" style={{ display: 'flex' }}>
                <div>
                  <p>
                    {moment(origin.checkout).format('HH:mm')}
                  </p>
                  <div>
                    {moment(origin.checkout).format(
                      'DD/MM/YYYY',
                    )}
                  </div>
                </div>
                <div style={{ margin: 'auto 20px' }}>-</div>
                <div>
                  <p>{moment(origin.checkin).format('HH:mm')}</p>
                  <div>
                    {moment(origin.checkin).format('DD/MM/YYYY')}
                  </div>
                </div>
              </Typography>
            ),
            accessor: '',
            sortable: false,
          },
          {
            Head: 'Trạng thái',
            Cell: value => renderStatus(value),
            accessor: 'status',
            sortable: false,
          },
          {
            Head: '',
            Cell: (value, origin, index) => renderAction(index, value),
            accessor: 'status',
            sortable: false,
          },
        ]}
        loading={loading}
        page={page}
        limit={limit}
        total={total}
        // defaultSort={defaultSort}
        // onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  )
}

ReservationsList.propTypes = {
  customer: PropTypes.string,
}

export default ReservationsList
