/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';

import './style.css';
import './main.css';
import RegisterPopup from './RegisterPopup';

function LandingPage() {
  const [openRegister, setOpenRegister] = useState(false);
  const onOpenRegister = useCallback(() => setOpenRegister(true), [])

  useEffect(() => {
    document.body.classList.add('two-home-body', 'bg-white');

    return () => {
      document.body.classList.remove('two-home-body', 'bg-white');
    }
  }, [])

  return (
    <div className="two-home">
      <RegisterPopup
        isOpen={openRegister}
        onClose={() => setOpenRegister(false)}
      />

        <div className="t-container">
          <header className="t-navbar t-bg-base-100 t-p-0">
            <div className="t-navbar-start">
              <a className="t-block t-mr-8" href="/">
                <img className="t-w-28" src="/landing-assets/2home.52774de7.png" width="1154" height="490" alt="2Home" />
              </a>

              <ul className="t-menu t-menu-horizontal t-p-0 t-hidden lg:t-flex">
                <li><a href="/">Về 2Home</a></li>
                <li><a href="https://2home.vn/huong-dan/">Điều khoản hợp tác</a></li>
                <li><a href="https://2home.vn/huong-dan/6">Câu hỏi thường gặp</a></li>
              </ul>
            </div>

            <div className="t-navbar-end">
              <div className="t-hidden lg:t-flex t-gap-3">
                <ul className="t-menu t-menu-horizontal t-p-0">
                  <li><a href="/sign-in">Đăng nhập</a></li>
                </ul>

                <a href="#" onClick={onOpenRegister} className="t-btn t-btn-primary">Đăng kí trở thành chủ nhà</a>
              </div>

              <div className="t-dropdown t-dropdown-end">
                <label tabIndex="0" className="t-btn t-btn-ghost t-btn-circle t-pt-0 lg:t-hidden">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
                  </svg>
                </label>

                <ul tabIndex="0" className="t-menu t-dropdown-content t-mt-3 t-p-2 t-shadow t-bg-base-100 t-w-52">
                  <li><a href="/">Về 2Home</a></li>
                  <li><a href="https://2home.vn/huong-dan/">Điều khoản hợp tác</a></li>
                  <li><a href="https://2home.vn/huong-dan/6">Câu hỏi thường gặp</a></li>
                  <li><a href="/sign-in">Đăng nhập</a></li>
                  <li><a href="#" onClick={onOpenRegister}>Đăng kí trở thành chủ nhà</a></li>
                </ul>
              </div>
            </div>
          </header>
        </div>

        <div className="t-container">
          <section className="t-hero t-place-end-center t-shadow-2xl t-min-h-[calc(100vh-4.5rem)] lg:t-min-h-[500px] t-place-items" style={{ backgroundImage: 'url(/landing-assets/banner.jpg)' }}>
            <div className="t-hero-overlay t-bg-opacity-20"></div>

            <div className="t-hero-content t-text-center t-text-white t-items-end t-pb-12">
              <div className="t-max-w-xl">
                <h1 className="t-mb-2 t-text-4xl lg:t-text-5xl t-font-bold">ĐĂNG PHÒNG TRÊN 2HOME</h1>
                <h2 className="t-mb-5 t-text-2xl t-font-medium">QUẢN LÍ DỄ DÀNG</h2>

                <p className="t-mb-5">
                  NỀN TẢNG ĐẶT PHÒNG MỚI, HIỆN ĐẠI, TIÊN PHONG TRONG <br /> BỘ CÔNG THỨC ĐẶT PHÒNG THEO GIỜ, THEO NGÀY!
                </p>

                <button className="t-btn t-btn-secondary t-shadow-lg hover:t-shadow-orange-500/50">Nhận tư vấn</button>
              </div>
            </div>
          </section>

          <section className="t-section t-text-center">
            <div className="lg:t-max-w-screen-lg t-mx-auto">
              <h2 className="t-mb-12 t-text-3xl t-font-bold dash-underline">QUYỀN LỢI CỦA CHỦ NHÀ</h2>

              <div className="t-grid t-grid-cols-1 sm:t-grid-cols-2 md:t-grid-cols-3 t-gap-16 t-mb-12">
                <div className="t-box">
                  <div className="t-avatar t-mb-8">
                    <div className="t-rounded-full t-ring t-ring-4 t-ring-gray t-ring-offset-base-100 t-ring-offset-2 t-overflow-hidden">
                      <img src="/landing-assets/canva-tiet-kiem.jpg" alt="" className="t-transition hover:t-contrast-200" />
                    </div>
                  </div>

                  <h3 className="t-mb-2 t-text-xl t-font-semibold">Tiết kiệm</h3>
                  <p className="t-text-md">Chỉ việc đăng phòng, Marketing khó, có 2HOME lo</p>
                </div>

                <div className="t-box">
                  <div className="t-avatar t-mb-8">
                    <div className="t-rounded-full t-ring t-ring-4 t-ring-gray t-ring-offset-base-100 t-ring-offset-2 t-overflow-hidden">
                      <img src="/landing-assets/canva-tang-don.jpg" alt="" className="t-transition hover:t-contrast-200" />
                    </div>
                  </div>

                  <h3 className="t-mb-2 t-text-xl t-font-semibold">Tối đa đơn đặt phòng</h3>
                  <p className="t-text-md">
                    2Home là <strong>nền tảng đầu tiên tại</strong> Việt Nam hoàn thiện <strong>bộ công thức tính giá phòng theo giờ</strong>, đáp ứng <strong>hầu hết nhu cầu</strong> khách hàng.
                  </p>
                </div>

                <div className="t-box">
                  <div className="t-avatar t-mb-8">
                    <div className="t-rounded-full t-ring t-ring-4 t-ring-gray t-ring-offset-base-100 t-ring-offset-2 t-overflow-hidden">
                      <img src="/landing-assets/canva-quan-ly.jpg" alt="" className="t-transition hover:t-contrast-200" />
                    </div>
                  </div>

                  <h3 className="t-mb-2 t-text-xl t-font-semibold">Quản lý dễ dàng</h3>
                  <p className="t-text-md">Chủ nhà theo dõi được đơn đặt phòng, doanh thu thông qua trang website dành riêng cho chủ nhà</p>
                </div>
              </div>

              <div>
                <a
                  className="t-btn lg:t-btn-lg t-btn-primary t-shadow-lg t-hover:shadow-orange-500/50"
                  href="#"
                  onClick={onOpenRegister}
                >
                  Đăng kí trở thành chủ nhà
                </a>
              </div>
            </div>
          </section>
        </div>

        <div className="t-container t-container-xxl">
          <section>
            <div className="t-hero t-min-h-[680px]" style={{ backgroundImage: 'url(/landing-assets/banner-2.jpg)' }}>
              <div className="t-hero-overlay t-bg-opacity-20"></div>

              <div className="t-hero-content t-text-center t-text-white t-max-w-screen-md lg:t-mt-[-100px] lg:t-ml-[-30%]">
                <div className="t-text-left">
                  <h1 className="t-mb-5 t-text-4xl lg:t-text-7xl t-font-bold t-drop-shadow-lg">VỀ 2HOME</h1>

                  <ul className="t-text-left t-font-medium lg:t-text-xl t-list-disc t-pl-4 t-drop-shadow">
                    <li className="t-underline">Cao cấp riêng tư</li>
                    <li className="">100% căn hộ thuộc các chung cư cao cấp, sang trọng và hiện đại bậc nhất, hầm gửi xe 24/7!</li>
                    <li className="t-underline">Đa dạng dịch vụ</li>
                    <li className="">Không gian đẳng cấp, lãng mạn và mới lạ dành cho dịp đổi gió, sinh nhật, kỷ niệm, cầu hôn của cặp đôi!</li>
                    <li className="t-underline">Đặt phòng dễ dàng</li>
                    <li className="">Dễ dàng khám phá các căn hộ đẹp nhất tại khu vực mà bạn lựa chọn!</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="t-card t-rounded-none lg:t-card-side lg:t-flex-row-reverse">
              <figure className="lg:t-w-1/2 lg:t-flex-shrink-0">
                <img src="/landing-assets/fea-1.jpg" width="967" height="453" className="t-object-cover t-w-full t-h-3/4 lg:t-h-[450px]" loading="lazy" alt="" />
              </figure>

              <div className="t-card-body t-max-w-xl">
                <div className="lg:t-pt-8 lg:t-pr-8">
                  <span className="t-headline t-text-red-500 t-font-bold t-text-7xl t-leading-none t-mb-4 t-block">01</span>
                  <h2 className="t-card-title t-text-3xl t-mb-4">Nền tảng đặt phòng theo giờ, theo ngày</h2>
                  <p>2HOME tiên phong trong lĩnh vực cho thuê căn hộ, homestay với bộ công thức tính giờ thông minh, linh hoạt, đáp ứng mọi nhu cầu đặt phòng của khách hàng. Hệ thống hơn 500 căn hộ, trải rộng khắp các khu vực tại Hà Nội, luôn là sự lựa chọn đầu tiên của khách hàng khi họ có nhu cầu đặt phòng.</p>
                </div>
              </div>
            </div>

            <div className="t-card t-rounded-none lg:t-card-side">
              <figure className="lg:t-w-1/2 lg:t-flex-shrink-0">
                <img src="/landing-assets/fea-2.jpg" width="967" height="453" className="t-object-cover t-w-full t-h-3/4 lg:t-h-[450px]" loading="lazy" alt="" />
              </figure>

              <div className="t-card-body t-max-w-xl">
                <div className="lg:t-pt-8 lg:t-pl-8">
                  <span className="t-headline t-text-red-500 t-font-bold t-text-7xl t-leading-none t-mb-4 t-block">02</span>
                  <h2 className="t-card-title t-text-3xl t-mb-4">Dịch vụ setup tiệc, trang trí</h2>
                  <p>2HOME cung cấp dịch vụ setup tiệc, trang trí khi khách hàng đặt phòng qua 2HOME. Setup sang trọng, lãng mạn trong không gian ấm cúng, nhiều lựa chọn cho khách hàng, thỏa mãn cả những vị khách khó tính nhất.</p>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="t-hero t-max-w-screen-lg t-mx-auto t-min-h-[680px]" style={{ backgroundImage: 'url(/landing-assets/banner-3.jpg)' }}>
              <div className="t-hero-overlay t-bg-opacity-10"></div>

              <div className="t-hero-content t-text-center t-text-white">
                <div className="t-max-w-md">
                  <h2 className="t-mb-5 t-text-5xl t-font-bold t-drop-shadow">CẨM NANG DÀNH CHO CHỦ NHÀ</h2>
                  <a className="t-btn t-btn-secondary" href="https://2home.vn/huong-dan">Tìm hiểu thêm</a>
                </div>
              </div>
            </div>
          </section>
        </div>

        <footer className="t-bg-primary t-text-white">
          <div className="t-container t-max-w-screen-md">
            <div className="t-footer t-py-16 lg:t-py-[100px]">
              <div className="t-space-y-6">
                <div className="t-flex t-gap-4">
                  <div className="t-flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="rgba(255,255,255,0.43)">
                      <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"></path>
                    </svg>
                  </div>

                  <div className="lg:max-w-sm">
                    <p className="t-footer-title t-opacity-100">Địa chỉ văn phòng</p>
                    <a className="t-opacity-70" href="https://goo.gl/maps/pBG5sP9sdY5foVEG9" target="_blank">Tầng 3, số 48 Kim Mã Thượng, phường Cống Vị, quận Ba Đình, Hà Nội</a>
                  </div>
                </div>

                <div className="t-flex t-gap-4">
                  <div className="t-flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="rgba(255,255,255,0.43)">
                      <path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"></path>
                    </svg>
                  </div>

                  <div>
                    <p className="t-footer-title t-opacity-100">Điện thoại</p>
                    <a className="t-opacity-70 hover:t-underline" href="tel:0944910499">094 491 04 99</a>
                  </div>
                </div>

                <div className="t-flex t-gap-4">
                  <div className="t-flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="rgba(255,255,255,0.43)">
                      <path d="M20,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6A2,2 0 0,0 20,4M20,18H4V8L12,13L20,8V18M20,6L12,11L4,6V6H20V6Z"></path>
                    </svg>
                  </div>

                  <div>
                    <p className="t-footer-title t-opacity-100">Email</p>
                    <a className="t-opacity-70 hover:t-underline" href="mailto:always2home@gmail.com">always2home@gmail.com</a>
                  </div>
                </div>
              </div>

              <div>
                <div className="t-flex t-gap-4">
                  <div className="t-flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 16 16" fill="rgba(255,255,255,0.43)">
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                  </div>

                  <div>
                    <p className="t-footer-title t-mb-4 t-opacity-100">Dành cho chủ nhà</p>

                    <div className="footer-links t-space-y-4">
                      <p><a className="t-opacity-70 t-hover:underline" href="https://2home.vn/huong-dan/">Điều khoản hợp tác</a></p>
                      <p><a className="t-opacity-70 t-hover:underline" href="https://2home.vn/huong-dan/">Hướng dẫn chủ nhà</a></p>
                      <p><a className="t-opacity-70 t-hover:underline" href="https://2home.vn/huong-dan/6">Câu hỏi thường gặp</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="t-px-0 t-py-8 lg:t-px-10 lg:t-py-10 t-border-t t-border-t-primary-content/20">
              <div className="t-text-center">
                <p className="t-text-sm">&copy; {(new Date()).getFullYear()} - Công ty TNHH 2home</p>
              </div>

              <nav className="t-flex t-gap-5 t-justify-center t-mt-8">
                <a href="https://www.tiktok.com/@2homevietnam" target="_blank" title="Tiktok">
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 2859 3333" fillRule="evenodd" clipRule="evenodd" fill="rgba(255,255,255,0.43)">
                    <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
                  </svg>
                </a>

                <a href="https://www.facebook.com/2home.vn" target="_blank" name="Facebook">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="rgba(255,255,255,0.43)">
                    <path d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z"></path>
                  </svg>
                </a>

                <a href="https://instagram.com/2home.vn?igshid=YmMyMTA2M2Y=" target="_blank" title="Intagram">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="rgba(255,255,255,0.43)">
                    <path d="M20,6.5A0.5,0.5 0 0,1 19.5,7H17.5A0.5,0.5 0 0,1 17,6.5V4.5A0.5,0.5 0 0,1 17.5,4H19.5A0.5,0.5 0 0,1 20,4.5M4.5,20A0.5,0.5 0 0,1 4,19.5V11H6.09C6.03,11.32 6,11.66 6,12A6,6 0 0,0 12,18A6,6 0 0,0 18,12C18,11.66 17.96,11.32 17.91,11H20V19.5A0.5,0.5 0 0,1 19.5,20M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M20,2H4C2.89,2 2,2.89 2,4V20A2,2 0 0,0 4,22H20A2,2 0 0,0 22,20V4C22,2.89 21.1,2 20,2Z"></path>
                  </svg>
                </a>
              </nav>

            </div>
          </div>
        </footer>
      </div>
  );
}

export default LandingPage
