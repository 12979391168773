import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { getFiltersFromUrl } from 'utils/url'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { SearchTextInput } from 'components'
import Filter from './Filters'

// ['pending', 'paid', 'reject']
const STATUS = [
  {
    value: 'pending',
    label: 'Chờ thanh toán',
  },
  {
    value: 'paid',
    label: 'Đã thanh toán',
  },
  {
    value: 'reject',
    label: 'Đã huỷ',
  },
]

const useStyles = makeStyles(theme => ({
  root: {},
  filter: {
    '& > div': {
      margin: theme.spacing(1),
    },
  },
}))

const UsersToolbar = (props) => {
  const {
    className, applyFilter, location, ...rest
  } = props
  const classes = useStyles()
  const [fltData, setFltData] = useState({})

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    if (!query.active) {
      query.active = 0
    } else {
      // eslint-disable-next-line no-restricted-globals
      query.active = !isNaN(query.active) ? Number(query.active) : 0
    }
    setFltData(query || {})
    return () => {}
  }, [])

  const filter = (data) => {
    setFltData(data)
    const {
      searchTxt,
      status,
      startdate,
      enddate,
      prepaidPackage,
      customer,
    } = data
    applyFilter({
      searchTxt,
      status,
      startdate,
      enddate,
      prepaidPackage,
      customer,
    })
  }

  const onSearch = async (data) => {
    filter(data)
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={1}>
        <Typography variant="h3" gutterBottom>Lịch sử mua gói trả trước</Typography>
      </Grid>
      <Grid container spacing={1} className={classes.filter}>
        {/* Customer */}
        <Filter.Customer
          value={fltData.customer}
          onApply={customer => filter({ ...fltData, customer })}
        />
        {/* Package */}
        <Filter.PrepaidPackage
          value={fltData.prepaidPackage}
          onApply={prepaidPackage => filter({ ...fltData, prepaidPackage })}
        />
        {/* Status */}
        <Filter.Status
          data={STATUS}
          value={fltData.status}
          onApply={status => filter({ ...fltData, status })}
        />
        {/* Date */}
        <Filter.BookingDate
          startLabel="Từ ngày"
          endLabel="Đến ngày"
          startdate={fltData.startdate}
          enddate={fltData.enddate}
          onApply={(startdate, enddate) => filter({ ...fltData, startdate, enddate })}
        />
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={3} md={4} style={{ marginLeft: 'auto' }}>
          <SearchTextInput
            size="small"
            fullWidth
            className={classes.searchInput}
            placeholder="Tìm kiếm (theo mã, theo khách hàng, theo phòng, ...)"
            initValue={fltData.searchTxt}
            debounceTime={800}
            onChange={searchTxt => onSearch({ ...fltData, searchTxt })}
            leftIcon={<Search />}
            style={{ margin: 0 }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

UsersToolbar.propTypes = {
  className: PropTypes.string,
  applyFilter: PropTypes.func,
  location: PropTypes.shape(),
}

export default UsersToolbar
