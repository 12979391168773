import user from './user'
import staff from './staff'
import reservation from './reservation'
import room from './room'
import customer from './customer'
import host from './host'
import prepaidHistory from './prepaidHistory'
import service from './service'
import notification from './notification'
import socket from './socket'

export default {
  user,
  staff,
  reservation,
  room,
  customer,
  host,
  prepaidHistory,
  service,
  notification,
  socket,
}
