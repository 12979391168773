import qs from 'query-string'

export const applyFiltersToUrl = (query) => {
  const convertQuery = { ...query }
  Object.keys(convertQuery).forEach(key => convertQuery[key] == null && delete convertQuery[key])
  return qs.stringify(convertQuery)
}


export const getFiltersFromUrl = path => qs.parse(path)
