import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from './styled'

const ConfirmDialog = ({
  // classes,
  order,
  open,
  onClose,
  onConfirm,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle disableTypography>
      <h3>Xác nhận đơn đặt phòng</h3>
    </DialogTitle>
    <DialogContent className="p-4">
      <p>{`Xác nhận đơn đặt phòng ${order?.code}?`}</p>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm} color="primary">
          Xác nhận
      </Button>
    </DialogActions>
  </Dialog>
)

ConfirmDialog.propTypes = {
  // classes: PropTypes.shape(),
  order: PropTypes.shape(),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default withStyles(styled)(ConfirmDialog)
