import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
} from '@material-ui/core'

const Instruction = ({
  // classes,
  values,
}) => (
  <>
    {['lock', 'reject-lock'].includes(values.status)
      ? null
      : (
        <Grid container className="mb-3">
          <Typography variant="h5">Hướng dẫn nhận phòng:</Typography>
          <div className="border p-2 mt-2" style={{ backgroundColor: 'white' }} dangerouslySetInnerHTML={{ __html: values.room.help }} />
        </Grid>
      )
    }
  </>
)

Instruction.propTypes = {
  // classes: PropTypes.shape(),
  values: PropTypes.shape(),
}

export default Instruction
