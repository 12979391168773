import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Chart from 'react-chartjs-2'
import { formatPrice } from 'utils/formatter'
import {
  // FormControl,
  Grid,
  IconButton,
  // InputLabel,
  // MenuItem,
  // Select,
  Typography,
} from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

const MULTI_ORDER_COLOR = 'rgba(255, 99, 132)'
const SERVICE_COLOR = 'rgba(255, 159, 64)'
const TICK_SIZE = 5

const dataFormat = {
  labels: [],
  datasets: [
    {
      type: 'line',
      label: 'Đặt 2 lần',
      data: [],
      fill: false,
      lineTension: 0,
      hidden: false,
      borderColor: SERVICE_COLOR,
    },
    {
      type: 'line',
      label: 'Đặt dịch vụ',
      data: [],
      fill: false,
      lineTension: 0,
      hidden: true,
      borderColor: MULTI_ORDER_COLOR,
    },
  ],
}

const CustomerOrder = ({
  data,
  classes,
  // onPeriodChange,
}) => {
  const [graphData, setGraphData] = useState(dataFormat)
  const [period, setPeriod] = useState([])
  const [page, setPage] = useState(-1)
  const [mode, setMode] = useState(0) // 0: Đặt 2 lần | 1: Đặt dịch vụ

  const getOptions = () => {
    const options = {
      legend: {
        onClick: (obj, legendItem) => setMode(legendItem.datasetIndex),
      },
      scales: {
        xAxes: [{
          stacked: true,
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            callback: (value) => {
              switch (period) {
                case 'week': // moment index from 1
                  return `Tuần ${value}`
                case 'month': // moment index from 0
                  return `Tháng ${Number(value) + 1}`
                case 'quarter': // moment index from 1
                  return `Quý ${value}`
                case 'year':
                  return `Năm ${value}`
                default:
                  return value
              }
            },
          },
        }],
        yAxes: [{
          display: false,
          stacked: true,
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            beginAtZero: true,
            callback: value => formatPrice(value),
            stepSize: TICK_SIZE,
          },
        }],
      },
      title: {
        display: true,
        position: 'top',
        text: 'SỐ KHÁCH ĐẶT',
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem) => {
            switch (period) {
              case 'week': // moment index from 1
                return `Tuần ${tooltipItem[0]?.label}`
              case 'month': // moment index from 0
                return `Tháng ${Number(tooltipItem[0]?.label) + 1}`
              case 'quarter': // moment index from 1
                return `Quý ${tooltipItem[0]?.label}`
              case 'year':
                return `Năm ${tooltipItem[0]?.label}`
              default:
                return tooltipItem[0]?.label
            }
          },
          label: (tooltipItem, d) => `${d.datasets[tooltipItem?.datasetIndex]?.label}: ${tooltipItem.yLabel || 0} khách`,
        },
      },
    }
    return options
  }

  useEffect(() => {
    setPage(0)
    setPeriod(Object.keys(data))
    return () => {
    }
  }, [data])

  useEffect(() => {
    const clone = { ...graphData }
    clone.datasets[0].data = []
    clone.datasets[1].data = []
    clone.labels = period.filter((p, i) => i >= page * 7 && i < (page + 1) * 7)
    clone.labels.forEach((item) => {
      clone.datasets[0].data.push(data[item].customer2Orders)
      clone.datasets[1].data.push(data[item].customerServices)
    })
    setGraphData(clone)
  }, [page, period])

  useEffect(() => {
    const clone = { ...graphData }
    if (mode === 0) {
      clone.datasets[0].hidden = false
      clone.datasets[1].hidden = true
    } else {
      clone.datasets[0].hidden = true
      clone.datasets[1].hidden = false
    }
    setGraphData(clone)
    return () => {
    }
  }, [mode])

  const getTime = () => {
    if (graphData?.labels?.length === 1) {
      return graphData.labels[0]
    }
    if (graphData?.labels?.length > 1) {
      return (
        <>
          <b>{graphData.labels[0]}</b>
          {' tới '}
          <b>{graphData.labels[graphData.labels.length - 1]}</b>
        </>
      )
    }
    return null
  }

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} md={8} className="d-flex align-items-center">
          {page > 0 && (
          <IconButton>
            <ChevronLeft style={{ color: 'gray' }} onClick={() => setPage(page - 1)} />
          </IconButton>
          )}
          <Typography className="mx-2" variant="body1">
            {getTime()}
          </Typography>
          {page >= 0 && period.length > 7 && page < period?.length / 7 && (
          <IconButton>
            <ChevronRight style={{ color: 'gray' }} onClick={() => setPage(page + 1)} />
          </IconButton>
          )}
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <FormControl className="w-100" variant="outlined" size="small">
            <InputLabel id="time-period">Thời gian</InputLabel>
            <Select
              labelId="time-period"
              id="time-period"
              value={period}
              onChange={e => onPeriodTimeSelect(e.target.value)}
              label="Thời gian"
            >
              {
                PERIOD.map(item => <MenuItem value={item.value}>{item.title}</MenuItem>)
              }
            </Select>
          </FormControl>
        </Grid> */}
      </Grid>
      <Chart type="line" data={graphData} options={getOptions()} />
    </div>
  )
}

CustomerOrder.propTypes = {
  data: PropTypes.shape(),
  classes: PropTypes.shape(),
  // onPeriodChange: PropTypes.func,
}

export default withStyles(theme => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: 'white',
  },
}))(CustomerOrder)
