import React from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink } from 'react-router-dom'
import {
  Grid,
  Typography,
  InputLabel,
} from '@material-ui/core'
import { formatPrice } from 'utils/formatter'
import moment from 'moment'
import { diffTime } from 'utils/service'
import AppConfig from 'config'

const CustomerInfo = ({
  classes,
  values,
}) => {
  const {
    status,
    customer,
    depositAmount,
    depositWith,
  } = values

  return (
    <>
      <Typography className={classes.subTitle}>
        {['lock', 'reject-lock'].includes(values.status) ? 'Người khoá:' : 'Thông tin khách hàng:'}
      </Typography>
      <Grid container spacing={3}>
        {/* Đặt cọc */}
        <Grid item lg={12} md={12}>
          <InputLabel className={classes.itemTitle}>Đặt cọc:</InputLabel>
          {
            depositWith === '2home'
              ? (
                <div dangerouslySetInnerHTML={{
                  __html:
                  `<i style="font-size: 0.9rem">Khách hàng <b>đã có khoản đặt cọc ${formatPrice(depositAmount)} VND.</b> 
                  Nếu yêu cầu bổ sung tiền cọc, bạn vui lòng liên hệ với khách hàng bằng số điện thoại đã gửi trong "Hướng dẫn nhận phòng". 
                  Nên lưu lại các bằng chứng và giao dịch (nếu có)</i>`,
                }}
                />
              )
              : depositWith === 'host'
                ? (
                  <div dangerouslySetInnerHTML={{
                    __html:
                  `<i style="font-size: 0.9rem">Khách hàng sẽ đặt cọc <b>${formatPrice(depositAmount)} VND </b> trực tiếp khi nhận phòng.</i>`,
                  }}
                  />
                )
                : (
                  <div dangerouslySetInnerHTML={{
                    __html:
                  '<i style="font-size: 0.9rem">Không cọc</i>',
                  }}
                  />
                )
          }
        </Grid>
      </Grid>
      <br />
      {/* Customer and Receiver information */}
      {['lock', 'reject-lock'].includes(values.status)
        ? (
          <Grid container spacing={3} className="mb-3">
            <Grid item lg={6} md={6}>
              <InputLabel className={classes.itemTitle}>Tài khoản hệ thống:</InputLabel>
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                <RouterLink to={`/customer/${customer?.id}/update`} target="_blank">{`${customer?.name} (${customer?.code})`}</RouterLink>
              </Typography>
            </Grid>
          </Grid>
        )
        : (
          <Grid container spacing={3}>
            {/* Người đặt phòng */}
            <Grid item lg={6} md={6}>
              <InputLabel className={classes.itemTitle}>Khách hàng:</InputLabel>
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                {`${customer?.name} (${customer?.code})`}
              </Typography>
            </Grid>
            {/* Người nhận phòng */}
            <Grid item lg={6} md={6}>
              <InputLabel className={classes.itemTitle}>Người nhận phòng:</InputLabel>
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                {values.receiverName || values.customerName}
              </Typography>
            </Grid>
            {/* Số điện thoại */}
            <Grid item lg={6} md={6}>
              <InputLabel className={classes.itemTitle}>Số điện thoại:</InputLabel>
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                {(['paid'].includes(status)
                && (diffTime(moment().valueOf(), values.checkout + 24 * 60 * 60 * 1000, 'minute') >= 0
                || diffTime(values.checkin - 24 * 60 * 60 * 1000, moment().valueOf(), 'minute') >= 0)) || status === 'using'
                  ? <div>{values.receiverPhone || values.customerPhone}</div>
                  : (
                    <>
                      <div>*******</div>
                      <small>
                        <i>
                          (Số điện thoại và email của khách hàng chỉ được hiển thị từ 24 giờ trước giờ nhận phòng - đến 24 giờ sau giờ trả phòng.
                          Nếu có các vấn đề cần hỗ trợ trước đó, bạn vui lòng liên hệ 2home.)
                        </i>
                      </small>
                    </>
                  )
                }
              </Typography>
            </Grid>
            {/* Địa chỉ email */}
            <Grid item lg={6} md={6}>
              <InputLabel className={classes.itemTitle}>Địa chỉ email:</InputLabel>
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                {(['paid'].includes(status)
                && (diffTime(moment().valueOf(), values.checkout + 24 * 60 * 60 * 1000, 'minute') >= 0
                || diffTime(values.checkin - 24 * 60 * 60 * 1000, moment().valueOf(), 'minute') >= 0)) || status === 'using'
                  ? <div>{values.receiverEmail || values.customerEmail}</div>
                  : (
                    <>
                      <div>*******</div>
                      <small>
                        <i>
                          (Số điện thoại và email của khách hàng chỉ được hiển thị từ 24 giờ trước giờ nhận phòng - đến 24 giờ sau giờ trả phòng.
                          Nếu có các vấn đề cần hỗ trợ trước đó, bạn vui lòng liên hệ 2home.)
                        </i>
                      </small>
                    </>
                  )}
              </Typography>
            </Grid>
            {/* Giấy tờ tuỳ thân */}
            <Grid item lg={12} md={12}>
              <InputLabel className={classes.itemTitle}>Giấy tờ tuỳ thân:</InputLabel>
              {
                values.cardIdOpt === '2home' && diffTime(moment().valueOf(), values.checkout + 24 * 60 * 60 * 1000, 'minute') >= 0
                  ? (
                    <>
                      <div
                        style={{ fontSize: '0.9rem', marginBottom: 15 }}
                        dangerouslySetInnerHTML={{
                          __html: '<b>Đã có</b> ', // (được ẩn: sau 24 giờ khách hàng trả phòng nếu không xảy ra khiếu nại, hoặc khi kết thúc khiếu nại)
                        }}
                      />
                      <img src={`${AppConfig.BASE_IMG_URL}/${values.frontIDImage}`} alt="card-id" style={{ maxWidth: 300, borderRadius: 6 }} />
                    </>
                  )
                  : <Typography variant="body1">Khách tự xác minh khi nhận phòng</Typography>
              }
            </Grid>
          </Grid>
        )
      }
    </>
  )
}

CustomerInfo.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
}

export default CustomerInfo
