import axios from 'axios'
import { COUPON_TABLE_LIST } from 'config'
import * as ENDPOINT from './url'

export const find = async (query) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_COUPON({ ...query, page: query.page || 1, limit: query.limit || COUPON_TABLE_LIST }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const findOne = async (id) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_COUPON_BY_ID(id))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const create = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.CREATE_COUPON, data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const update = async (data) => {
  try {
    const resp = await axios.patch(ENDPOINT.UPDATE_COUPON(data?.id), data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const fetchHistory = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.GET_COUPON_HISTORY, data)
    return resp.data
  } catch (e) {
    throw e
  }
}
