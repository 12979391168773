import { connect } from 'react-redux'
import { ReservationList as ReservationPage } from 'views'
import { getReservationData } from 'redux/selector/reservation'
import { getUserData } from 'redux/selector/user'
import {
  find,
  pay,
  confirm,
  reject,
  undo,
} from 'redux/action/reservation'

const mapDispatchToProps = dispatch => ({
  find: query => dispatch(find(query)),
  pay: (id, paymentType) => dispatch(pay(id, paymentType)),
  reject: (id, data) => dispatch(reject(id, data)),
  confirm: id => dispatch(confirm(id)),
  undo: id => dispatch(undo(id)),
})

export default connect(state => ({ ...getReservationData(state), user: getUserData(state)?.data }), mapDispatchToProps)(ReservationPage)
