import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    padding: theme.spacing(1),
  },
  backdrop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 9999,
  },
}))

const BackgroundBackdrop = ({ loading, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {loading && <div className={classes.backdrop}><CircularProgress color="primary" /></div>}
      <div style={{ opacity: loading ? 0.7 : 1 }}>{children}</div>
    </div>
  )
}

BackgroundBackdrop.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.shape(),
}

export default BackgroundBackdrop
