import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'
import { CustomersToolbar, CustomersTable } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}))

const UserList = (props) => {
  const {
    customer, find, location, history,
  } = props
  const classes = useStyles()

  const updateUrl = (query) => {
    if (query.code) {
      // eslint-disable-next-line no-param-reassign
      delete query.code
    }
    const url = `${location.pathname}?${applyFiltersToUrl(query)}`
    history.push(url, { replace: false })
  }

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    find({ ...query, active: query.active || 1 })
    updateUrl({ ...query, active: query.active || 1 })
    return () => {}
    // eslint-disable-next-line
  }, [])

  const applyFilter = (fltData) => {
    const query = { ...customer.query, ...fltData }
    find(query)
    updateUrl(query)
  }

  const changePage = (page) => {
    const query = { ...customer.query, page }
    updateUrl(query)
    find(query)
  }

  const changeRowsPerPage = (limit) => {
    const query = { ...customer.query, page: 1, limit }
    updateUrl(query)
    find(query)
  }

  const sort = (sortBy, sortDirection) => {
    const query = { ...customer.query, sortBy, sortDirection }
    updateUrl(query)
    find(query)
  }

  const update = (id) => {
    history.push(`/customer/${id}/update`)
  }

  return (
    <div className={classes.root}>
      <CustomersToolbar
        applyFilter={applyFilter}
        location={location}
      />
      <div className={classes.content}>
        <CustomersTable
          location={location}
          loading={customer.loading}
          customers={customer.data}
          meta={customer.meta}
          defaultSort={
            customer.query
              ? {
                sortBy: customer.query.sortBy,
                sortDirection: customer.query.sortDirection,
              }
              : {}
          }
          onPageChange={changePage}
          onRowsPerPageChange={changeRowsPerPage}
          onSortChange={sort}
          find={find}
          update={update}
        />
      </div>
      <RouterLink to="/customer/create">
        <Fab color="primary" aria-label="add" className={classes.fab}>
          <AddIcon />
        </Fab>
      </RouterLink>
    </div>
  )
}

UserList.propTypes = {
  customer: PropTypes.arrayOf(PropTypes.shape()),
  find: PropTypes.func,
  history: PropTypes.shape(),
  location: PropTypes.shape(),
}

export default UserList
