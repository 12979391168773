import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

const AutoCompleteCreatable = ({
  className,
  suggestions,
  renderOption,
  onChange,
  onSelect,
  label,
  shrink,
  placeholder,
  defaultValue,
  // leftIcon,
}) => {
  const [value, setValue] = React.useState(null) // {value, title}

  const onOptionSelected = (newValue) => {
    onSelect(newValue)
  }

  const onSearchInputChange = debounce((searchTxt) => {
    onChange(searchTxt)
  }, 800)

  return (
    <Autocomplete
      className={className}
      value={value || defaultValue}
      onChange={(event, newValue) => {
        if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            value: null,
            title: newValue.inputValue,
          })
          onOptionSelected({
            value: null,
            title: newValue.inputValue,
          })
        } else {
          setValue(newValue)
          onOptionSelected(newValue)
        }
      }}
      filterOptions={(options, params) => {
        const filtered = options.filter(opt => opt.title.indexOf(params.inputValue) !== -1)
        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            title: `Thêm ${label?.toLowerCase()} "${params.inputValue}"`,
          })
        }
        return filtered
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={suggestions}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.title
        }
        // Regular option
        return option.title
      }}
      renderOption={renderOption}
      freeSolo
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeholder}
          label={label}
          variant="outlined"
          size="small"
          onChange={e => onSearchInputChange(e.target.value)}
          InputLabelProps={{
            shrink,
          }}
        />
      )}
    />
  )
}

AutoCompleteCreatable.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.shape(),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.shape()),
  renderOption: PropTypes.func,
  leftIcon: PropTypes.element,
  shrink: PropTypes.bool,
}

export default AutoCompleteCreatable
