import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  withStyles,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'
import { EnhancedEncryption, FiberManualRecord } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DatePicker from 'components/DatePicker2'
import CheckinIcon from 'assets/icons/icon-date-in.png'
import CheckoutIcon from 'assets/icons/icon-date-out.png'
import * as roomApi from 'provider/room'
import * as orderApi from 'provider/reservation'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import { Table as LockTable } from 'components'
import { useTheme } from '@material-ui/styles'
import Toolbar from './Toolbar'
import Table from './Table'
import reservationListStyle from './styled'

const ReservationList = ({
  classes,
  reservation,
  location,
  history,
  find,
  pay,
  reject,
  confirm,
  undo,
  refund,
  addCheckout,
  resetOrderActive,
  resetOrderInactive,
  socket,
  user,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })
  const [openLockDialog, setOpenLockDialog] = useState(false)
  const [allRooms, setAllRooms] = useState([])
  const [sltRoom, setSltRoom] = useState(null)
  const [checkin, setCheckin] = useState(null)
  const [checkout, setCheckout] = useState(null)
  const [duplicateLockOrder, setDuplicateLockOrder] = useState([])

  // Fetch room
  const fetchRoom = async (query) => {
    try {
      const resp = await roomApi.find({
        ...query,
        owner: user?.id,
        status: JSON.stringify(['active']),
        limit: 10000000,
      })
      setAllRooms(
        resp.data.map(item => ({
          id: item.id,
          name: item.name,
          code: item.code,
          bookingType: item.bookingType,
          address: item.address,
          hasService: item.hasService,
          host: item.hostId,
          reservations: item.reservations,
        })),
      )
    } catch (e) {
      // console.log(e)
    }
  }

  const updateUrl = (query) => {
    if (query.code) {
      // eslint-disable-next-line no-param-reassign
      delete query.code
    }
    const url = `${location.pathname}?${applyFiltersToUrl(query)}`
    history.push(url, { replace: false })
    find({
      ...query,
      active: query.active || 0,
    })
  }

  useEffect(() => {
    fetchRoom()
  }, [])

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    try {
      const { status } = query
      find({ ...query, status, active: query.active || 0 })
      // updateUrl({ ...query, status, active: query.active || 0 })
    } catch (e) {
      delete query.status
      find({ ...query, active: query.active || 0 })
      // updateUrl({ ...query, active: query.active || 0 })
    }
    return () => {}
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setSltRoom(null)
    setCheckin(null)
    setCheckout(null)
    setDuplicateLockOrder([])
    if (openLockDialog) {
      fetchRoom()
    }
    return () => {
    }
  }, [openLockDialog])

  useEffect(() => {
    if (!sltRoom) {
      setSltRoom(null)
    }
    return () => {
    }
  }, [sltRoom])

  useEffect(() => {
    if (checkin && checkout && sltRoom) {
      setDuplicateLockOrder(sltRoom?.reservations?.filter(r => (r.checkin < checkin && checkin < r.checkout) || (r.checkin < checkout && checkout < r.checkout) || (checkin < r.checkin && r.checkout < checkout)))
    }
    return () => {

    }
  }, [checkin, checkout, sltRoom])

  const applyFilter = (fltData) => {
    const query = { ...reservation.query, ...fltData }
    updateUrl(query)
    find(query)
  }

  const changePage = (page) => {
    const query = { ...reservation.query, page }
    updateUrl(query)
    find(query)
  }

  const changeRowsPerPage = (limit) => {
    const query = { ...reservation.query, page: 1, limit }
    updateUrl(query)
    find(query)
  }

  const sort = (sortBy, sortDirection) => {
    const query = { ...reservation.query, sortBy, sortDirection }
    updateUrl(query)
    find(query)
  }

  const lockRoom = async () => {
    if (!sltRoom || !checkin || !checkout) {
      enqueueSnackbar('Không thể khoá phòng, cần đầy đủ thông tin (phòng, thời gian khoá)', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      return
    }
    try {
      await roomApi.lock(sltRoom.id, checkin, checkout)
      enqueueSnackbar('Khóa phòng thành công', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      setSltRoom(null)
      setCheckin(null)
      setCheckout(null)
    } catch (e) {
      // console.log(e.response)
      enqueueSnackbar(`Không thể khoá phòng, ${e.response?.data}.`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    setOpenLockDialog(false)
  }

  const renderStatus = (status) => {
    switch (status) {
      case 'processing-pay-extra':
      case 'confirmed-pay-extra':
      case 'processing-refund-in-update':
      case 'waiting-refund-in-update':
      case 'host-confirm-refund-update':
      case 'add-checkout-processing':
      case 'paid':
        return <Typography variant="body1">Đã thanh toán</Typography>
      case 'lock':
        return <Typography variant="body1">Đã khoá</Typography>
      default:
        return null
    }
  }

  const rejectLockingOrder = async (id) => {
    // Huỷ đơn
    try {
      await orderApi.reject(id)
      setDuplicateLockOrder([...duplicateLockOrder].filter(o => o.id !== id))
    } catch (e) {
      enqueueSnackbar('Không thể huỷ khoá phòng, đã có lỗi xảy ra!')
    }
  }

  const renderAction = (status, origin) => {
    switch (status) {
      case 'lock':
        return (<Button variant="outlined" onClick={() => rejectLockingOrder(origin.id)}>Xoá</Button>)
      case 'processing-pay-extra':
      case 'confirmed-pay-extra':
      case 'processing-refund-in-update':
      case 'waiting-refund-in-update':
      case 'host-confirm-refund-update':
      case 'add-checkout-processing':
      case 'paid':
      default:
        return null
    }
  }

  return (
    <div className={classes.root}>
      {/* Locking room */}
      <Dialog open={openLockDialog} onClose={() => setOpenLockDialog(false)} scroll="body" PaperProps={{ style: { overflowY: 'unset', maxWidth: '100%' } }}>
        <DialogTitle disableTypography>
          <h4>Khoá phòng tạm thời</h4>
        </DialogTitle>
        <DialogContent style={{ minWidth: '80vw', minHeight: 80, overflowY: 'hidden' }}>
          <Grid container>
            {/* Chọn phòng khoá */}
            <Grid container item md={6}>
              <Grid container item xs={12} spacing={3}>
                <Grid item lg={12} md={12}>
                  Lưu ý: Không thể khoá phòng nếu có đơn đã thanh toán trong khoảng thời gian khoá hoặc thời gian khoá trùng với đơn khoá phòng khác.
                  Sau khi khoá phòng, các đơn chưa thanh toán sẽ bị huỷ.
                </Grid>
              </Grid>
              {/* Phòng/chủ nhà */}
              <Grid container item xs={12} spacing={3}>
                {/* Phòng */}
                <Grid item lg={12} md={12}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    fullWidth
                    size="small"
                  >
                    <Autocomplete
                      value={sltRoom}
                      options={allRooms}
                      getOptionLabel={opt => `${opt.name} (${opt.code})`}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Phòng"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Chọn phòng ..."
                          size="small"
                        />
                      )}
                      onChange={(e, sltItem) => setSltRoom(sltItem)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {/* Thời gian khoá */}
              <Grid container item xs={12} spacing={3} style={{ marginTop: 20 }}>
                <Grid item lg={6} md={6}>
                  <DatePicker
                    type="datetime"
                    fullWidth
                    icon={<img src={CheckinIcon} style={{ width: 20, marginRight: 10 }} alt="checkin-icon" />}
                    label="Từ ngày/giờ"
                    value={checkin}
                    onSelect={value => setCheckin(value)}
                    size="small"
                    picked={sltRoom?.reservations?.map(r => ({ start: r.checkin, end: r.checkout }))}
                  />
                </Grid>
                <Grid item lg={6} md={6}>
                  <DatePicker
                    type="datetime"
                    fullWidth
                    icon={<img src={CheckoutIcon} style={{ width: 20, marginRight: 10 }} alt="checkout-icon" />}
                    label="Đến ngày/giờ"
                    value={checkout}
                    onSelect={value => setCheckout(value)}
                    size="small"
                    min={checkin}
                    picked={sltRoom?.reservations?.map(r => ({ start: r.checkin, end: r.checkout }))}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Thông tin lịch trùng */}
            <Grid container item md={6}>
              <Grid item md={12}>
                <Typography variant="h6">Lịch trùng</Typography>
              </Grid>
              <Grid item md={12}>
                <LockTable
                  className="w-100"
                  data={duplicateLockOrder}
                  columns={[
                    {
                      Head: 'Mã',
                      Cell: (value, origin) => (
                        <div className="d-flex align-items-center">
                          {moment.duration(moment().diff(moment(origin.updatedAt))).asMinutes() > 15 && origin.status === 'processing'
                            ? <FiberManualRecord fontSize="small" style={{ color: '#ffa854', marginRight: 8 }} />
                            : null}
                          <RouterLink to={`/reservation/${value}/view`} className={classes.codeCell}>
                            <Typography variant="body1">{value}</Typography>
                            <Typography variant="body2">
                              {moment(origin.createdAt).format('DD/MM/YYYY')}
                            </Typography>
                          </RouterLink>
                        </div>
                      ),
                      accessor: 'code',
                      sortable: false,
                    },
                    {
                      Head: 'Thời gian',
                      Cell: (value, origin) => (
                        <div className="text-left">
                          <Typography variant="body1" className="d-flex">
                            <div>
                              <div>{moment(origin.checkin).format('HH:mm')}</div>
                              <div>{moment(origin.checkin).format('DD/MM')}</div>
                            </div>
                            <div style={{ margin: 'auto 20px' }}>-</div>
                            <div>
                              <div>{moment(origin.checkout).format('HH:mm')}</div>
                              <div>{moment(origin.checkout).format('DD/MM')}</div>
                            </div>
                          </Typography>
                        </div>
                      ),
                      accessor: 'checkin',
                      sortable: false,
                    },
                    {
                      Head: 'Trạng thái',
                      Cell: value => renderStatus(value),
                      accessor: 'status',
                      sortable: false,
                    },
                    {
                      Head: '',
                      Cell: (value, origin) => renderAction(value, origin),
                      accessor: 'status',
                    },
                  ]}
                  total={duplicateLockOrder?.length}
                  showPaginate={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={lockRoom} color="primary" disabled={duplicateLockOrder?.length || !checkin || !checkout || !sltRoom}>
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      {/* Filter options */}
      <Toolbar
        isDesktop={isDesktop}
        classes={classes}
        location={location}
        applyFilter={applyFilter}
        socket={socket}
        resetOrderActive={resetOrderActive}
        resetOrderInactive={resetOrderInactive}
      />
      {/* Reservation table */}
      <div className={classes.content}>
        <Table
          isDesktop={isDesktop}
          classes={classes}
          loading={reservation.loading}
          reservations={reservation.data}
          meta={reservation.meta}
          defaultSort={
            reservation.query
              ? {
                sortBy: reservation.query.sortBy,
                sortDirection: reservation.query.sortDirection,
              }
              : {}
          }
          onPageChange={changePage}
          onRowsPerPageChange={changeRowsPerPage}
          onSortChange={sort}
          applyFilter={applyFilter}
          pay={pay}
          confirm={confirm}
          reject={reject}
          undo={undo}
          refund={refund}
          addCheckout={addCheckout}
          edit={id => history.push(`/reservation/${id}/update`)}
          view={id => history.push(`/reservation/${id}/view`)}
        />
      </div>
      {/* Create/Lock button */}
      <Fab color="primary" aria-label="add" className={classes.lockBtn} onClick={() => setOpenLockDialog(true)}>
        <EnhancedEncryption />
      </Fab>
    </div>
  )
}

ReservationList.propTypes = {
  classes: PropTypes.shape(),
  reservation: PropTypes.arrayOf(PropTypes.shape()),
  find: PropTypes.func,
  pay: PropTypes.func,
  reject: PropTypes.func,
  confirm: PropTypes.func,
  undo: PropTypes.func,
  refund: PropTypes.func,
  addCheckout: PropTypes.func,
  history: PropTypes.shape(),
  location: PropTypes.shape(),
  socket: PropTypes.shape(),
  resetOrderActive: PropTypes.func,
  resetOrderInactive: PropTypes.func,
  user: PropTypes.shape(),
}

export default withStyles(reservationListStyle)(ReservationList)
