import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DropzoneDialog } from 'material-ui-dropzone'
import { Button } from '@material-ui/core'
import { CancelRounded } from '@material-ui/icons'
import Config from 'config'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { shiftArray } from 'utils/service'

const Upload = ({
  readOnly,
  highlight,
  files, // [{isLink: true, file: 'image url'}, {isLink: false, file: 'input image obj'}]
  multiple,
  max,
  imageWidth,
  imageHeight,
  onChange,
  onItemClick,
  title,
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [sltFiles, setSltFiles] = useState([])
  const [previewUrlFiles, setPreviewUrlFiles] = useState([])
  const [previewObjFiles, setPreviewObjFiles] = useState([])
  const [count, setCount] = useState(1)

  useEffect(() => {
    setPreviewUrlFiles(files.filter(item => item.isLink).map(item => item.file))
    setSltFiles(files.filter(item => !item.isLink).map(item => item.file))
    return () => {
    }
  }, [files])

  useEffect(() => {
    setPreviewObjFiles([])
    if (!sltFiles || !sltFiles.length) {
      return () => {}
    }
    sltFiles.forEach((item) => {
      if (!item) return
      const reader = new FileReader()
      reader.onload = (e) => {
        setPreviewObjFiles(prev => ([...prev, e.target.result]))
      }
      reader.readAsDataURL(item)
    })
    return () => {
    }
  }, [sltFiles])

  useEffect(() => {
    setCount(multiple ? max : 1)
    return () => {
    }
  }, [multiple])

  const openUploadDialog = () => {
    setOpenDialog(true)
  }

  const onFilesSubmit = (inputFiles) => {
    if (multiple) {
      onChange([...previewUrlFiles.map(file => ({ isLink: true, file })), ...inputFiles.map(file => ({ isLink: false, file }))])
    } else {
      onChange(inputFiles.map(file => ({ isLink: false, file })))
    }
    setOpenDialog(false)
  }

  const onRemove = (type, idx) => {
    switch (type) {
      case 'obj':
        const remainObjFiles = [...sltFiles.slice(0, idx), ...sltFiles.slice(idx + 1, sltFiles.length)]
        setSltFiles(remainObjFiles)
        onChange([...previewUrlFiles.map(file => ({ isLink: true, file })), ...remainObjFiles.map(file => ({ isLink: false, file }))])
        break
      case 'url':
        const remainUrlFiles = [...previewUrlFiles.slice(0, idx), ...previewUrlFiles.slice(idx + 1, previewUrlFiles.length)]
        setPreviewUrlFiles(remainUrlFiles)
        onChange([...remainUrlFiles.map(file => ({ isLink: true, file })), ...sltFiles.map(file => ({ isLink: false, file }))])
        break
      default:
    }
  }

  const onDragEnd = (result) => {
    console.log('on drag', result)
    if (!result || !result.destination || !result.source) return
    // Swap images in array
    const {
      destination: {
        index: dstIndex,
      },
      source: {
        index: srcIndex,
      },
    } = result
    shiftArray(previewUrlFiles, srcIndex, dstIndex)
    // TODO: Cần xử lý cho cả trường hợp có input files
    onChange([...previewUrlFiles.map(file => ({ isLink: true, file }))])
  }

  return (
    <>
      <DropzoneDialog
        filesLimit={count}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={inputFiles => onFilesSubmit(inputFiles)}
        previewGridClasses={{ container: 'm-0 w-100', item: 'm-1 p-0' }}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {dropProvider => (
            <div
              ref={dropProvider.innerRef}
              {...dropProvider.droppableProps}
              className="d-flex mb-2 w-100"
              style={{ overflowY: 'auto' }}
            >
              {previewUrlFiles.map((item, idx) => (
                <Draggable key={item?.file} draggableId={`draggable${idx + 1}`} index={idx}>
                  {dragProvider => (
                    <div
                      ref={dragProvider.innerRef}
                      {...dragProvider.draggableProps}
                      {...dragProvider.dragHandleProps}
                      style={{
                        backgroundColor: 'white',
                        position: 'relative',
                        margin: 'auto 5px',
                        ...dragProvider.draggableProps.style,
                      }}
                    >
                      <img
                        src={`${Config.BASE_IMG_URL}/${item}`}
                        width={imageWidth}
                        style={{
                          borderRadius: 5,
                          border: (highlight && item === highlight) ? 'solid 3px #432400' : 'solid 1px #d5d5d5',
                          width: max > 1 ? ([...previewUrlFiles, ...previewObjFiles].length > 1 ? 160 : '100%') : imageWidth,
                          height: max > 1 ? ([...previewUrlFiles, ...previewObjFiles].length > 1 ? 120 : 'auto') : imageHeight,
                          objectFit: 'contain',
                        }}
                        alt="file"
                        onClick={() => onItemClick([{ isLink: true, file: item }])}
                        role="presentation"
                      />
                      {readOnly
                        ? null
                        : (
                          <CancelRounded
                            className="position-absolute"
                            style={{
                              color: 'red',
                              fontSize: '1.6rem',
                              cursor: 'pointer',
                              right: 0,
                              backgroundColor: 'white',
                              borderRadius: '50%',
                            }}
                            onClick={() => onRemove('url', idx)}
                          />
                        )}
                    </div>
                  )}
                </Draggable>
              ))}
              {previewObjFiles.map((item, idx) => (
                <Draggable key={item.file} draggableId={`draggable${previewUrlFiles.length + idx + 1}`} index={previewUrlFiles.length + idx}>
                  {dragProvider => (
                    <div
                      ref={dragProvider.innerRef}
                      {...dragProvider.draggableProps}
                      {...dragProvider.dragHandleProps}
                      style={{
                        backgroundColor: 'white',
                        position: 'relative',
                        margin: 'auto 5px',
                        ...dragProvider.draggableProps.style,
                      }}
                    >
                      <img
                        src={item}
                        width={imageWidth}
                        style={{
                          borderRadius: 5,
                          border: (highlight && item === highlight) ? 'solid 3px #432400' : 'solid 1px #d5d5d5',
                          width: max > 1 ? ([...previewUrlFiles, ...previewObjFiles].length > 1 ? 160 : '100%') : imageWidth,
                          height: max > 1 ? ([...previewUrlFiles, ...previewObjFiles].length > 1 ? 120 : 'auto') : imageHeight,
                          objectFit: 'contain',
                        }}
                        alt="file"
                        onClick={() => onItemClick([{ isLink: false, file: sltFiles[idx] }])}
                        role="presentation"
                      />
                      <CancelRounded
                        className="position-absolute"
                        style={{
                          color: 'red',
                          fontSize: '1.6rem',
                          cursor: 'pointer',
                          right: 0,
                          backgroundColor: 'white',
                          borderRadius: '50%',
                        }}
                        onClick={() => onRemove('obj', idx)}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {dropProvider.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {readOnly ? null : <Button color="primary" onClick={openUploadDialog}>{title || 'Tải lên'}</Button>}
    </>
  )
}

Upload.defaultProps = {
  readOnly: false,
  files: [],
  multiple: true,
  max: 20,
  imageWidth: '100%',
  imageHeight: 'auto',
  highlight: '',
  onItemClick: () => {},
  title: '',
}

Upload.propTypes = {
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onItemClick: PropTypes.func,
  max: PropTypes.number,
  multiple: PropTypes.bool,
  highlight: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.string),
  imageWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
}

export default Upload
