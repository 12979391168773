import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SearchTextInput } from 'components'
import {
  Grid,
  Typography,
  Button,
  Badge,
} from '@material-ui/core'
import { getFiltersFromUrl } from 'utils/url'
import { Search } from '@material-ui/icons'
import Filter from './Filters'

export const BOOKING_STATUS = [{
  value: 'processing',
  label: 'Chờ xác nhận',
  active: 0,
}, {
  value: 'confirmed',
  label: 'Chờ thanh toán',
  active: 0,
}, {
  value: 'paid',
  label: 'Đã thanh toán',
  active: 0,
}, {
  value: 'using',
  label: 'Đang sử dụng',
  active: 0,
}, {
  value: 'reject-by-host',
  label: 'Chủ nhà huỷ đơn',
  active: 1,
}, {
  value: 'reject',
  label: 'Huỷ đơn không thanh toán',
  active: 1,
}, {
  value: 'reject-refund',
  label: 'Huỷ có hoàn tiền',
  active: 1,
}, {
  value: 'reject-norefund',
  label: 'Huỷ không hoàn tiền',
  active: 1,
}, {
  value: 'reject-processing',
  label: 'Huỷ chưa hoàn tiền',
  active: 2,
}, {
  value: 'reject-waiting',
  label: 'Huỷ chờ cung cấp thông tin',
  active: 2,
}, {
  value: 'completed',
  label: 'Hoàn thành',
  active: 1,
}, {
  value: 'expired',
  label: 'Hết hạn',
  active: 3,
}, {
  value: 'lock',
  label: 'Khoá phòng',
  active: 4,
}, {
  value: 'waiting-refund-in-update',
  label: 'Xác nhận chỉnh sửa chờ hoàn tiền ngân hàng',
  active: 2,
}, {
  value: 'processing-refund-in-update',
  label: 'Chờ xác nhận chỉnh sửa trước khi hoàn tiền',
  active: 2,
}, {
  value: 'confirmed-pay-extra',
  label: 'Xác nhận chỉnh sửa chưa thanh toán',
  active: 0,
}, {
  value: 'processing-pay-extra',
  label: 'Chờ xác nhận chỉnh sửa trước khi thanh toán thêm',
  active: 0,
}, {
  value: 'add-checkout-processing',
  label: 'Đặt ở thêm chờ xác nhận',
  active: 0,
}]

const ReservationsToolbar = ({
  // isDesktop,
  classes,
  location,
  applyFilter,
  socket,
  // resetOrderActive,
  // resetOrderInactive,
}) => {
  const [fltData, setFltData] = useState({ active: 0 })
  const {
    order: {
      active: newActiveOrder,
      // inactive: newInactiveOrder,
    },
  } = socket || { order: {} }

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    if (!query.active) {
      query.active = 0
    } else {
      // eslint-disable-next-line no-restricted-globals
      query.active = !isNaN(query.active) ? Number(query.active) : 0
    }
    setFltData(query || {})
    return () => {}
  }, [location.search])

  const filter = (data) => {
    setFltData(data)
    const {
      active,
      searchTxt,
      status,
      startdate,
      enddate,
      room,
      customer,
      hasService,
    } = data
    applyFilter({
      active,
      searchTxt: searchTxt || undefined,
      status: status && status.length ? JSON.stringify(status) : undefined,
      startdate: startdate || undefined,
      enddate: enddate || undefined,
      room: room || undefined,
      customer: customer || undefined,
      hasService,
    })
  }

  const onSearch = (textStr) => {
    const query = getFiltersFromUrl(location.search)
    filter({ ...query, searchTxt: textStr })
  }

  const groupByStatus = (data) => {
    filter(data)
  }

  return (
    <>
      {/* Title */}
      <Grid container spacing={1}>
        {/* Title */}
        <Grid item xs={9}>
          <Typography variant="h3" gutterBottom>Danh sách đơn đặt phòng</Typography>
        </Grid>
        {/* Search */}
        <Grid item xs={3} style={{ marginLeft: 'auto' }} />
      </Grid>
      {/* Filter */}
      <Grid container className={classes.filter}>
        {/* Customer */}
        {/* <Filter.Customer
          value={fltData.customer}
          onApply={customer => filter({ ...fltData, customer })}
        /> */}
        {/* Room */}
        <Filter.Room
          value={fltData.room}
          onApply={room => filter({ ...fltData, room })}
        />
        {/* Status */}
        <Filter.Status
          // eslint-disable-next-line no-restricted-globals
          data={BOOKING_STATUS.filter(item => isNaN(fltData.active) || fltData.active === -1 ? true : item.active === Number(fltData.active))}
          value={fltData.status}
          onApply={status => filter({ ...fltData, status })}
        />
        {/* Service */}
        <Filter.Service
          value={fltData.hasService}
          onApply={service => filter({ ...fltData, hasService: service })}
        />
        {/* Checkin/Checkout */}
        <Filter.BookingDate
          startLabel="Nhận phòng"
          endLabel="Trả phòng"
          startdate={Number(fltData.startdate)}
          enddate={Number(fltData.enddate)}
          onApply={({ startdate, enddate }) => filter({ ...fltData, startdate, enddate })}
        />
        {/* Tìm kiếm đơn */}
        <SearchTextInput
          fullWidth
          className={`${classes.searchInput} ml-auto`}
          placeholder="Tìm kiếm (theo mã, theo phòng, ...)"
          initValue={getFiltersFromUrl(location.search)?.searchTxt}
          debounceTime={800}
          onChange={str => onSearch(str)}
          leftIcon={<Search />}
          size="small"
          style={{ marginBottom: 0, marginTop: 0 }}
        />
      </Grid>
      {/* Active/Inactive */}
      <Grid container spacing={1} style={{}}>
        {/* Đang hoạt động */}
        <Grid item md={2} xs={6}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 0 ? 'contained' : 'default'}`}
            color={`${fltData.active === 0 ? 'primary' : 'default'}`}
            onClick={() => {
              groupByStatus({ ...fltData, active: 0 })
              // resetOrderActive()
            }}
          >
            <span className="mr-3">Hoạt động</span>
            {newActiveOrder ? <Badge badgeContent={newActiveOrder} color="secondary" /> : null}
          </Button>
        </Grid>
        {/* Chưa hoàn tiền */}
        {/* <Grid item md={2} xs={4}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 2 ? 'contained' : 'default'}`}
            color={`${fltData.active === 2 ? 'primary' : 'default'}`}
            onClick={() => {
              groupByStatus({ ...fltData, active: 2 })
              // resetOrderInactive()
            }}
          >
            <span className="mr-3">Chưa hoàn tiền</span>
          </Button>
        </Grid> */}
        {/* Chưa hoàn cọc */}
        <Grid item md={2} xs={6}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 3 ? 'contained' : 'default'}`}
            color={`${fltData.active === 3 ? 'primary' : 'default'}`}
            onClick={() => {
              groupByStatus({ ...fltData, active: 3 })
              // resetOrderInactive()
            }}
          >
            <span className="mr-3">Chưa hoàn cọc</span>
          </Button>
        </Grid>
        {/* Hoàn thành */}
        <Grid item md={2} xs={6}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 1 ? 'contained' : 'default'}`}
            color={`${fltData.active === 1 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: 1 })}
          >
            Hoàn thành
          </Button>
        </Grid>
        {/* Khoá phòng */}
        <Grid item md={2} xs={6}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 4 ? 'contained' : 'default'}`}
            color={`${fltData.active === 4 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: 4 })}
          >
            Khoá phòng
          </Button>
        </Grid>
        {/* Tất cả đơn */}
        <Grid item md={2} xs={12}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === -1 ? 'contained' : 'default'}`}
            color={`${fltData.active === -1 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: -1 })}
          >
            Tất cả
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

ReservationsToolbar.propTypes = {
  classes: PropTypes.shape(),
  applyFilter: PropTypes.func,
  location: PropTypes.shape(),
  socket: PropTypes.shape(),
  // resetOrderActive: PropTypes.func,
  // resetOrderInactive: PropTypes.func,
}

export default ReservationsToolbar
