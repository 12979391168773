import moment from 'moment'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from 'components/DateRange'

const Date = ({ onApply, startdate, enddate }) => {
  const [startDate, setStartdate] = useState(startdate)
  const [endDate, setEnddate] = useState(enddate)

  useEffect(() => {
    if (startdate) {
      setStartdate(startdate)
    }
  }, [startdate])

  useEffect(() => {
    if (enddate) {
      setEnddate(enddate)
    }
  }, [enddate])

  const onDateChange = (start, end) => {
    setStartdate(start)
    setEnddate(end)
    onApply(start, end)
  }

  return (
    <DateRangePicker
      type="date-range"
      startdate={startDate}
      enddate={endDate}
      startLabel={`${startDate ? moment(startDate).format('DD/MM/YYYY') : 'Từ ngày'}`}
      endLabel={`${endDate ? moment(endDate).format('DD/MM/YYYY') : 'Đến ngày'}`}
      onSelect={onDateChange}
    />
  )
}

Date.propTypes = {
  onApply: PropTypes.func,
  startdate: PropTypes.number,
  enddate: PropTypes.number,
}

export default Date
