import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme, withStyles } from '@material-ui/styles'
import {
  Grid,
  Typography,
  Popover,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
} from '@material-ui/core'
import { Search, Toc } from '@material-ui/icons'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import * as reportApi from 'provider/report'
import { useSnackbar } from 'notistack'
import TimeRange from 'components/TimeRange'
import moment from 'moment'
import { SearchTextInput } from 'components'
import GeneralInfo from './GeneralInfo'
import Orders from './List'

const OrderReport = ({
  classes,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })
  const [filter, setFilter] = useState({
    startdate: moment().startOf('day').valueOf(),
    enddate: moment().endOf('day').valueOf(),
    sortBy: undefined,
    sortDirection: undefined,
    searchTxt: undefined,
  })
  const [info, setInfo] = useState({})
  const [list, setList] = useState([])
  const [meta, setMeta] = useState({
    page: 1,
    total: 0,
    limit: 10,
  })
  const [sltColumns, setSltColumns] = useState({
    'Mã đơn': true,
    // 'Đặt phòng': false,
    'Doanh thu phòng': true,
    // 'Doanh thu phòng (2home)': false,
    'Doanh thu phòng (Chủ nhà)': true,
    'Doanh thu net (Chủ nhà)': true,
    // 'Dịch vụ': false,
    // 'Phí huỷ': true,
    'Phạt cọc': true,
    '2home setup': true,
    'Chi hộ phát sinh': true,
    '2home thuê phòng': true,
    'Giảm giá': true,
    'Tiền cọc': true,
  })

  useEffect(() => {
    setSltColumns({
      'Mã đơn': true,
      // 'Đặt phòng': false,
      'Doanh thu phòng': true,
      // 'Doanh thu phòng (2home)': false,
      'Doanh thu phòng (Chủ nhà)': true,
      'Doanh thu net (Chủ nhà)': false,
      // 'Dịch vụ': false,
      // 'Phí huỷ': true,
      'Phạt cọc': false,
      '2home setup': false,
      'Chi hộ phát sinh': false,
      '2home thuê phòng': false,
      'Giảm giá': false,
      'Tiền cọc': false,
    })
  }, [isDesktop])

  const fetchOrderReport = async (query) => {
    try {
      const resp = await reportApi.fetchOrderList({ ...query })
      const {
        data,
        ...rest
      } = resp
      setInfo(rest)
      setMeta(rest)
      setList(JSON.parse(JSON.stringify(data)))
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin doanh thu (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  useEffect(() => {
    fetchOrderReport({ ...filter })
    return () => {
    }
  }, [])

  const onSearch = (searchData) => {
    setFilter({
      ...filter,
      ...searchData,
    })
    fetchOrderReport({
      ...filter,
      ...searchData,
      ...meta,
      page: 1,
    })
  }

  const onSortChange = (sortBy, sortDirection) => {
    setFilter({
      ...filter,
      sortBy,
      sortDirection,
    })
    fetchOrderReport({
      ...filter,
      sortBy,
      sortDirection,
    })
  }

  const onPageChange = (page) => {
    setMeta({
      ...meta,
      page,
    })
    fetchOrderReport({
      ...filter,
      ...meta,
      page,
    })
  }

  const onRowsPerPageChange = (limit) => {
    setMeta({
      ...meta,
      limit,
    })
    fetchOrderReport({
      ...filter,
      ...meta,
      limit,
    })
  }

  return (
    <div className={classes.root}>
      {/* Date filter and info */}
      <Grid container spacing={1}>
        {/* Time range */}
        <Grid item md={5} xs={12}>
          <div className="border p-2 h-100 w-100" style={{ backgroundColor: 'white' }}>
            <Typography variant="h4" className="mb-3">Thống kê đơn đặt phòng </Typography>
            <TimeRange
              isMobile={!isDesktop}
              value={{ startDate: filter.startdate, endDate: filter.enddate }}
              onApply={searchDate => onSearch({ ...filter, startdate: searchDate.startDate, enddate: searchDate.endDate })}
              maxDate={moment().valueOf()}
            />
          </div>
        </Grid>
        {/* Information */}
        <Grid item md={7} xs={12}>
          <div className="border p-2 h-100 w-100" style={{ backgroundColor: 'white' }}>
            <GeneralInfo data={info} />
          </div>
        </Grid>
      </Grid>
      {/* Table columns */}
      <Grid container spacing={1} className="mt-2">
        <Grid item md={3} xs={56} className="mt-auto">
          <PopupState variant="popover" popupId="customer-filter">
            {popupState => (
              <div>
                <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
                  <Toc />
                  Cột danh sách
                </Button>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Box p={2} style={{ width: 600 }}>
                    <Grid container>
                      {
                        Object.keys(sltColumns).map(item => (
                          <Grid item md={4} xs={4}>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={sltColumns[item]}
                                  onChange={() => {
                                    const clone = { ...sltColumns }
                                    clone[item] = !clone[item]
                                    setSltColumns(clone)
                                  }}
                                  name={item}
                                />
                              )}
                              label={item}
                            />
                          </Grid>
                        ))
                      }
                    </Grid>
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </Grid>
        <Grid item md={4} xs={7} className="ml-auto">
          <SearchTextInput
            fullWidth
            className={classes.searchInput}
            placeholder="Tìm kiếm theo mã đặt phòng"
            debounceTime={800}
            onChange={searchTxt => onSearch({ ...filter, searchTxt })}
            leftIcon={<Search />}
            size="small"
            style={{ marginBottom: 0 }}
          />
        </Grid>
      </Grid>
      {/* Order table */}
      <Grid container className="mt-4">
        <Grid item md={12} xs={12}>
          <Orders
            setting={sltColumns}
            data={list}
            meta={meta}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Grid>
      </Grid>
    </div>
  )
}

OrderReport.propTypes = {
  classes: PropTypes.shape(),
}

export default withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
}))(OrderReport)
