import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import RichEditor from 'components/RichEditor'

const Description = ({
  classes,
  errors,
  touched,
  values,
  // handleChange,
  setFieldValue,
}) => (
  <>
    <Typography className={classes.subTitle}>Mô tả căn hộ:</Typography>
    <Grid container item lg={12} md={12} xs={12}>
      <RichEditor
        style={{ backgroundColor: 'white' }}
        label="Viết nội dung mô tả cho căn hộ ..."
        name="description"
        fullWidth
        value={values.description}
        onChange={value => setFieldValue('description', value)}
        error={errors.description && touched.description}
        helperText={errors.description && touched.description && errors.description}
        placeholder="Nhập nội dung mô tả căn hộ"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
  </>
)

Description.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  // handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default Description
