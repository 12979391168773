import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getFiltersFromUrl } from 'utils/url'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { SearchTextInput } from 'components'
import {
  Grid, Typography, Button,
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import Filter from './Filters'

const PRICE_MIN = 0
const PRICE_MAX = 5000000

const useStyles = makeStyles(theme => ({
  root: {},
  filter: {
    '& > div': {
      margin: theme.spacing(1),
    },
  },
}))

const ServiceToolbar = (props) => {
  const {
    className, location, applyFilter, ...rest
  } = props
  const classes = useStyles()
  const [fltData, setFltData] = useState({})

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    if (!query.active) {
      query.active = 1
    } else {
      // eslint-disable-next-line no-restricted-globals
      query.active = !isNaN(query.active) ? Number(query.active) : 1
    }
    setFltData(query || {})
    return () => {}
  }, [])

  const filter = (data) => {
    setFltData(data)
    const {
      active,
      searchTxt,
      fromPrice,
      toPrice,
    } = data
    applyFilter({
      active,
      searchTxt,
      fromPrice: fromPrice ? parseInt(fromPrice.toString().replace(/,/g, ''), 0) : PRICE_MIN,
      toPrice: toPrice ? parseInt(toPrice.toString().replace(/,/g, ''), 0) : PRICE_MAX,
    })
  }

  const onSearch = (data) => {
    filter(data)
  }

  const groupByStatus = (data) => {
    filter(data)
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={1}>
        <Typography variant="h3" gutterBottom>Danh sách dịch vụ</Typography>
      </Grid>
      <Grid container spacing={1} className={classes.filter}>
        <Filter.Price
          min={fltData.fromPrice || PRICE_MIN}
          max={fltData.toPrice || PRICE_MAX}
          onApply={(fromPrice, toPrice) => filter({ ...fltData, fromPrice, toPrice })}
        />
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={2} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 1 ? 'contained' : 'default'}`}
            color={`${fltData.active === 1 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: 1 })}
          >
            Hoạt động
          </Button>
        </Grid>
        <Grid item lg={2} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 0 ? 'contained' : 'default'}`}
            color={`${fltData.active === 0 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: 0 })}
          >
            Đã xoá
          </Button>
        </Grid>
        <Grid item lg={3} md={4} style={{ marginLeft: 'auto' }}>
          <SearchTextInput
            size="small"
            fullWidth
            className={classes.searchInput}
            placeholder="Tìm kiếm (theo mã, tên, địa chỉ, ...)"
            initValue={fltData.searchTxt}
            debounceTime={800}
            onChange={str => onSearch({ ...fltData, searchTxt: str })}
            leftIcon={<Search />}
            style={{ margin: 0 }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

ServiceToolbar.propTypes = {
  className: PropTypes.string,
  applyFilter: PropTypes.func,
  location: PropTypes.shape(),
}

export default ServiceToolbar
