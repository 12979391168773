import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core'

const Description = ({
  classes,
  values,
}) => (
  <>
    {['lock', 'reject-lock'].includes(values.status)
      ? null
      : (
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} style={{ marginTop: 15 }}>
            <InputLabel className={classes.itemTitle}>Ghi chú:</InputLabel>
            <Typography variant="body1">
              {values.note || <i>Không có ghi chú</i>}
            </Typography>
          </Grid>
        </Grid>
      )
    }
  </>
)

Description.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
}

export default Description
