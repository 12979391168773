import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import {
  Grid,
  Popover,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Toc } from '@material-ui/icons'
import * as invoiceApi from 'provider/invoice'
import { useSnackbar } from 'notistack'
import moment from 'moment'
// import GeneralInfo from './GeneralInfo'
import { DatePicker } from 'components'
import { max } from 'underscore'
import WarningDialog from 'views/Dialog/WarningDialog'
import { diffTime } from 'utils/service'
import Invoices from './List'
import ConfirmDialog from './ConfirmDialog'
import OrderInvoiceDialog from './OrderInvoiceDialog'

const START_PERIOD = 1659312000000

const InvoiceList = ({
  classes,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [schedules, setSchedules] = useState([])
  const [sltSchedule, setSltSchedule] = useState(-1)
  const [sltInvoice, setSltInvoice] = useState(null)
  const [list, setList] = useState([])
  const [meta, setMeta] = useState({
    page: 1,
    total: 0,
    limit: 10,
  })
  const [sltColumns, setSltColumns] = useState({
    'Kỳ đối soát': true,
    'Số đơn': true,
    'Doanh thu phòng': true,
    'Doanh thu host': true,
    'Chi hộ': true,
    'Doanh thu net chủ nhà': true,
    'Tiền cọc': true,
    'Tình trạng': true,
    'Mã bút toán': true,
  })
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [invoicePeriod, setInvoicePeriod] = useState(null)
  const [showWarningDialog, setShowWarningDialog] = useState(false)
  const [warningTitle, setWarningTitle] = useState('')
  const [warningContent, setWarningContent] = useState('')
  const [showInvoiceDetailDialog, setShowInvoiceDetailDialog] = useState('')

  const getLastInvoicePeriod = () => {
    if (!schedules.length) return START_PERIOD
    const lastPoint = max(schedules?.map(item => item.enddate))
    return moment(lastPoint).add(1, 'day').startOf('day').valueOf()
  }

  const fetchInvoiceSchedule = async () => {
    try {
      const resp = await invoiceApi.schedule()
      const {
        data,
      } = resp
      setSchedules(data)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin doanh thu (code: ${e.response ? e.response.data : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const fetchInvoices = async (query) => {
    try {
      const resp = await invoiceApi.find({ ...query })
      const {
        data,
        ...rest
      } = resp
      setList(data)
      setMeta(rest)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin thanh toán (code: ${e.response ? e.response.data : 'Unknown'}).`, { variant: 'error' })
    }
  }

  useEffect(() => {
    fetchInvoiceSchedule()
    fetchInvoices({ ...meta })
    return () => {
    }
  }, [])

  useEffect(() => {
    fetchInvoices({ ...meta, schedule: sltSchedule !== -1 ? sltSchedule : undefined })
  }, [sltSchedule])

  const onSortChange = (sortBy, sortDirection) => {
    fetchInvoices({
      ...meta,
      schedule: sltSchedule !== -1 ? sltSchedule : undefined,
      sortBy,
      sortDirection,
    })
  }

  const onPageChange = (page) => {
    setMeta({
      ...meta,
      page,
    })
    fetchInvoices({
      ...meta,
      schedule: sltSchedule !== -1 ? sltSchedule : undefined,
      page,
    })
  }

  const onRowsPerPageChange = (limit) => {
    setMeta({
      ...meta,
      limit,
    })
    fetchInvoices({
      ...meta,
      schedule: sltSchedule !== -1 ? sltSchedule : undefined,
      limit,
    })
  }

  const selectInvoiceSchedule = async (schedule) => {
    setSltSchedule(schedule)
  }

  const onCreateInvoice = () => {
    setShowConfirmDialog(true)
  }

  const createInvoice = async () => {
    if (diffTime(getLastInvoicePeriod(), moment(invoicePeriod).endOf('day').valueOf(), 'day') < 7) {
      enqueueSnackbar('Yêu cầu thanh toán cần được tạo với khoảng thời gian tối thiểu 7 ngày', { variant: 'error' })
      return
    }
    try {
      await invoiceApi.create({
        startdate: getLastInvoicePeriod(),
        enddate: moment(invoicePeriod).endOf('day').valueOf(),
      })
      setInvoicePeriod(null)
      fetchInvoiceSchedule()
      fetchInvoices({ ...meta })
      setShowWarningDialog(false)
      setWarningTitle('')
      setWarningContent('')
      enqueueSnackbar('Yêu cầu thanh toán thành công.', { variant: 'success' })
    } catch (e) {
      console.log(e.response)
      setShowWarningDialog(false)
      setWarningTitle('')
      setWarningContent('')
      enqueueSnackbar(`Máy chủ lỗi, không gửi được yêu cầu thanh toán (code: ${e.response ? e.response.data : 'Unknown'}).`, { variant: 'error' })
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.data : 'Unknown'}). `, { variant: 'error' })
    }
  }

  const onConfirm = () => {
    setWarningTitle('Gửi yêu cầu thanh toán')
    setWarningContent(`Xác nhận gửi yêu cầu thanh toán từ ngày ${moment(getLastInvoicePeriod()).format('DD/MM/YYYY')} tới ngày ${moment(invoicePeriod).format('DD/MM/YYYY')} tới 2home?`)
    setShowWarningDialog(true)
    setShowConfirmDialog(false)
  }

  const onShowInvoiceDetail = (invoice) => {
    setSltInvoice(invoice)
    setShowInvoiceDetailDialog(true)
  }

  return (
    <>
      <WarningDialog
        visible={showWarningDialog}
        title={warningTitle}
        content={warningContent}
        onClose={() => setShowWarningDialog(false)}
        onConfirm={createInvoice}
      />
      <ConfirmDialog
        startdate={getLastInvoicePeriod()}
        enddate={invoicePeriod ? moment(invoicePeriod).endOf('day').valueOf() : null}
        visible={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={onConfirm}
      />
      <OrderInvoiceDialog
        data={sltInvoice?.ordersData}
        visible={showInvoiceDetailDialog}
        onClose={() => {
          setSltInvoice(null)
          setShowInvoiceDetailDialog(false)
        }}
      />
      <div className={classes.root}>
        <Grid container spacing={1}>
          {/* Time range */}
          <Grid item md={5} xs={12}>
            <div className="border p-2 h-100 w-100" style={{ backgroundColor: 'white' }}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                style={{ backgroundColor: 'white' }}
                size="small"
              >
                <InputLabel style={{ backgroundColor: 'white', padding: '0px 5px' }} id="invoice-period">
                  Chọn kỳ thanh toán
                </InputLabel>
                <Select
                  labelId="invoice-period"
                  id="invoice-period"
                  value={sltSchedule}
                  onChange={e => selectInvoiceSchedule(e.target.value)}
                >
                  <MenuItem value={-1}>Tất cả</MenuItem>
                  {schedules.map(item => (
                    <MenuItem value={item.id}>
                      {`${moment(item.startdate).format('DD/MM/YYYY')} - ${moment(item.enddate).format('DD/MM/YYYY')}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>
          {/* Information */}
          <Grid item md={7} xs={12}>
            <div className="border p-2 h-100 w-100" style={{ backgroundColor: 'white' }}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <DatePicker
                    minDate={getLastInvoicePeriod()}
                    maxDate={moment().subtract(1, 'day').valueOf()}
                    size="small"
                    fullWidth
                    name="birthday"
                    label="Chọn khoảng thời gian của kỳ thanh toán"
                    inputValue={invoicePeriod ? `${moment(getLastInvoicePeriod()).format('DD/MM/YYYY')} - ${moment(invoicePeriod).format('DD/MM/YYYY')}` : ''}
                    value={invoicePeriod}
                    onChange={value => setInvoicePeriod(value)}
                    invalidDateMessage={null}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button className="m-auto" variant="outlined" onClick={onCreateInvoice} disabled={!invoicePeriod}>Tạo yêu cầu</Button>
                </Grid>
              </Grid>
              <div className="mt-2" dangerouslySetInnerHTML={{ __html: `Yêu cầu thanh toán gần nhất: <b>${moment(getLastInvoicePeriod()).subtract(1, 'day').format('DD/MM/YYYY')}</b>` }} />
              {invoicePeriod ? <div dangerouslySetInnerHTML={{ __html: `Yêu cầu thanh toán sẽ được tạo cho khoảng thời gian từ ngày <b>${moment(getLastInvoicePeriod()).format('DD/MM/YYYY')}</b> tới ngày <b>${moment(invoicePeriod).format('DD/MM/YYYY')}</b>` }} /> : null}
            </div>
          </Grid>
        </Grid>
        {/* Table columns */}
        <Grid container spacing={1} className="mt-4">
          <PopupState variant="popover" popupId="customer-filter">
            {popupState => (
              <div>
                <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
                  <Toc />
                Cột thông tin danh sách
                </Button>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Box p={2} style={{ width: 600 }}>
                    <Grid container>
                      {
                      Object.keys(sltColumns).map(item => (
                        <Grid item md={4}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={sltColumns[item]}
                                onChange={() => {
                                  const clone = { ...sltColumns }
                                  clone[item] = !clone[item]
                                  setSltColumns(clone)
                                }}
                                name={item}
                              />
                          )}
                            label={item}
                          />
                        </Grid>
                      ))
                    }
                    </Grid>
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </Grid>
        {/* List */}
        <Grid container className="mt-4">
          <Grid item md={12} xs={12}>
            <Invoices
              setting={sltColumns}
              data={list}
              meta={meta}
              onSortChange={onSortChange}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              onInvoiceSelect={onShowInvoiceDetail}
            />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

InvoiceList.propTypes = {
  classes: PropTypes.shape(),
}

export default withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}))(InvoiceList)
