import React, { useState } from 'react'
import {
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List, ListItem, ListItemAvatar, ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import PropTypes from 'prop-types'
import { Table } from 'components'
import { Restore } from '@material-ui/icons'
import * as customerApi from 'provider/customer'
import { getFiltersFromUrl } from 'utils/url'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  ellipsisCell: {
    maxWidth: 150,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  amountCell: {
    minWidth: 150,
  },
  statusCell: {
    minWidth: 150,
  },
  codeCell: {
    color: '#3f51b5',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  serviceThumbnail: {
    width: 70,
    height: 70,
    borderRadius: 0,
    marginRight: theme.spacing(2),
  },
}))

const UsersTable = (props) => {
  const {
    loading,
    customers,
    meta,
    find,
    location,
    defaultSort,
    onSortChange,
    onPageChange,
    onRowsPerPageChange,
  } = props
  const classes = useStyles()
  const [sltCustomer, setSltCustomer] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const handlePageChange = (page) => {
    onPageChange(page)
  }

  const handleRowsPerPageChange = (limit) => {
    onRowsPerPageChange(limit)
  }

  const handleSortChange = (sortBy, sortDirection) => {
    onSortChange(sortBy, sortDirection)
  }

  const handleOpenConfirmDialog = async (customer) => {
    setOpenConfirmDialog(true)
    setSltCustomer(customer)
  }

  const confirmActive = async () => {
    await customerApi.update({ id: sltCustomer.id, status: sltCustomer.status === 2 ? 1 : 2 })
    const query = getFiltersFromUrl(location.search)
    find(query)
    setOpenConfirmDialog(false)
    setSltCustomer(null)
  }

  const renderCustomerStatus = (status) => {
    switch (status) {
      case 0:
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#ead180',
            }}
            label="Chờ kích hoạt"
          />
        )
      case 1:
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#00e676',
            }}
            label="Đang hoạt động"
          />
        )
      case 2:
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#9a9a9a',
            }}
            label="Dừng hoạt động"
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <Dialog
        open={openConfirmDialog}
        onClose={() => {
          setOpenConfirmDialog(false)
          setSltCustomer(null)
        }}
      >
        <DialogTitle disableTypography>
          {sltCustomer && <h3>{`${sltCustomer.status !== 2 ? 'Tạm dừng' : 'Khôi phục'} hoạt động khách hàng`}</h3>}
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80 }}>
          {sltCustomer && <div dangerouslySetInnerHTML={{ __html: `Xác nhận ${sltCustomer.status !== 2 ? 'tạm dừng' : 'khôi phục'} tài khoản khách hàng <b>${sltCustomer.name}</b>?` }} />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmDialog(false)
              setSltCustomer(null)
            }}
            color="default"
          >
            Thoát
          </Button>
          <Button
            onClick={confirmActive}
            color="primary"
          >
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      <Table
        data={customers}
        columns={[
          {
            Head: 'Tên',
            Cell: (value, origin) => (
              <div className={classes.nameContainer}>
                <List className={classes.root}>
                  <ListItem className="p-0">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.serviceThumbnail}
                        src={origin.image}
                      />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography variant="body1">8872 - BONNIE APARTMENT</Typography>
                      <Typography variant="body2">Name</Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </div>
            ),
            accessor: 'order',
            sortable: true,
          },
          {
            Head: 'Số tiền',
            Cell: (value, origin) => (
              <div className={classes.nameContainer}>100,000đ</div>
            ),
            accessor: 'amount',
            sortable: true,
          },
          {
            Head: 'TT với 2home',
            Cell: (value, origin) => (
              <>
                <Typography variant="body1">Đã thanh toán</Typography>
                <Typography variant="body2">2020/01/08</Typography>
              </>
            ),
            accessor: '2home',
            sortable: false,
          },
          {
            Head: 'TT với host',
            Cell: (value, origin) => (
              <>
                <Typography variant="body1">Chưa thanh toán</Typography>
                <Typography variant="body2">N/A</Typography>
              </>
            ),
            accessor: 'host',
            sortable: true,
          },
          {
            Head: 'Tình trạng đặt phòng',
            Cell: (value, origin) => (
              <>
                <Typography variant="body1">Chưa hoàn thành</Typography>
              </>
            ),
            accessor: 'status',
            sortable: true,
          },
          {
            Head: 'Ngày tạo',
            Cell: (value, origin) => (
              <>
                <Typography variant="body1">2020/01/08</Typography>
                <Typography variant="body2">23:27:35</Typography>
              </>
            ),
            accessor: 'createAt',
            sortable: false,
          },
          {
            Head: '',
            Cell: (value, origin) => {
              if (origin.status === 2) {
                return (
                  <Tooltip title="Khôi phục tài khoản" aria-label="Khôi phục tài khoản">
                    <IconButton
                      aria-label="edit"
                      className={classes.margin}
                      onClick={() => handleOpenConfirmDialog(origin)}
                    >
                      <Restore />
                    </IconButton>
                  </Tooltip>
                )
              }
              return (
                <>
                  {/* <Tooltip title="Chỉnh sửa" aria-label="Chỉnh sửa">
                    <IconButton
                      aria-label="edit"
                      className={classes.margin}
                      onClick={() => update(value)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Xoá" aria-label="Xoá">
                    <IconButton
                      aria-label="delete"
                      className={classes.margin}
                      onClick={() => handleOpenConfirmDialog(origin)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip> */}
                </>
              )
            },
            accessor: 'id',
          },
        ]}
        loading={loading}
        page={meta.page}
        limit={meta.limit}
        total={meta.total}
        defaultSort={defaultSort}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  )
}

UsersTable.propTypes = {
  loading: PropTypes.bool,
  customers: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape(),
  find: PropTypes.func,
  defaultSort: PropTypes.shape(),
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  location: PropTypes.shape(),
}

export default UsersTable
