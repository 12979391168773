import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { TextField, CircularProgress, Typography } from '@material-ui/core'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'

const useStyles = makeStyles({
  root: {
    marginTop: -16,
  },
  addressInput: {
    backgroundColor: 'white',
  },
  suggestionContainer: {
    position: 'absolute',
    zIndex: 99999,
  },
  suggestionItem: {
    padding: '5px 10px',
    backgroundColor: 'white',
    cursor: 'pointer',
  },
  activeItem: {
    backgroundColor: '#d5d5d5',
  },
})

const GooglePlaceInput = (props) => {
  const {
    onSelect,
    value,
    ...rest
  } = props
  const classes = useStyles()
  const [address, setAddress] = useState(value)
  const [error, setError] = useState('')

  useEffect(() => {
    setAddress(value)
    return () => {
    }
  }, [value])

  const handleChange = (addr) => {
    setAddress(addr)
  }

  const handleSelect = (addr) => {
    setAddress(addr)
    geocodeByAddress(addr)
      .then(results => getLatLng(results[0]))
      .then((geocode) => {
        onSelect({ address: addr, ...geocode })
      })
      .catch((e) => {
        setError(`Không thể lấy được thông tin toạ độ của địa chỉ, mã lỗi: ${e.status}`)
      })
  }

  const handleError = (status, clearSuggestions) => {
    setError(`Không thể kết nối tới Google Map api, mã lỗi: ${status}`)
    clearSuggestions()
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      onError={handleError}
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
      }) => (
        <div style={{ position: 'relative' }}>
          <TextField
            variant="outlined"
            fullWidth
            className={classes.addressInput}
            {...getInputProps({
              placeholder: 'Nhập địa chỉ ...',
            })}
            {...rest}
          />
          {error && <Typography variant="body2" style={{ color: 'red' }}>{error}</Typography>}
          <div className={classes.suggestionContainer}>
            {loading && <CircularProgress />}
            {
                suggestions.map((suggestion) => {
                  const clx = suggestion.active
                    ? `${classes.suggestionItem} ${classes.activeItem}`
                    : `${classes.suggestionItem}`
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className: clx,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })
              }
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

GooglePlaceInput.propTypes = {
  onSelect: PropTypes.func,
  value: PropTypes.string,
}

export default GooglePlaceInput
