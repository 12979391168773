import axios from 'axios'
import { ROOM_TABLE_LIST } from 'config'
import * as ENDPOINT from './url'

export const find = async (query) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_ROOM({ ...query, page: query.page || 1, limit: query.limit || ROOM_TABLE_LIST }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const findOne = async (id) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_ROOM_BY_ID(id))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const create = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.CREATE_ROOM, data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const update = async (data) => {
  try {
    const resp = await axios.patch(ENDPOINT.UPDATE_ROOM, data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const remove = async (ids) => {
  try {
    const resp = await axios.delete(ENDPOINT.DELETE_ROOM, {
      data: { ids },
    })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const restore = async (ids) => {
  try {
    const resp = await axios.post(ENDPOINT.RESTORE_ROOM, { ids })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const lock = async (id, checkin, checkout) => {
  try {
    const resp = await axios.post(ENDPOINT.LOCK_ROOM, { id, checkin, checkout })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const updateStatus = async (id, status) => {
  try {
    const resp = await axios.post(ENDPOINT.UPDATE_ROOM_STATUS(id), { status })
    return resp.data
  } catch (e) {
    throw e
  }
}
