import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}))

const HostList = (props) => {
  const {
    find, location, history,
  } = props
  const classes = useStyles()

  const updateUrl = (query) => {
    const url = `${location.pathname}?${applyFiltersToUrl(query)}`
    history.push(url, { replace: false })
  }

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    find({ ...query, active: query.active || 1 })
    updateUrl({ ...query, active: query.active || 1 })
    return () => {}
    // eslint-disable-next-line
  }, [])


  return (
    <div className={classes.root}>
      <Typography variant="h2" gutterBottom>Khóa phòng</Typography>
      <div className={classes.content}>
        <div
          style={{
            width: 375, maxWidth: '100%', display: 'inline-block', marginBottom: 30,
          }}
        >
          <div style={{
            display: 'flex', position: 'relative', cursor: 'pointer', width: '100%', borderRadius: 3, paddingBottom: '70%', backgroundImage: "url('https://via.placeholder.com/375')", backgroundSize: 'cover', backgroundPosition: '50% 50%',
          }}
          />
          <div style={{
            display: 'flex', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
          }}
          >
            <div>R5 - Mã phòng R75</div>
          </div>
          <div style={{ display: 'flex', fontWeight: 700, fontSize: 22 }}>
            <div style={{
              cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
            }}
            >
           The Gavani 5 - Times City
            </div>
          </div>
          <div style={{
            textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
          }}
          >
            <span>Thời gian khóa: 08/2020 - 09/2020</span>
          </div>
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>458 Phố Minh Khai, Khu đô thị Times City, Vĩnh Tuy, Hai Bà Trưng, Hà Nội, Việt Nam</div>
        </div>
      </div>

    </div>
  )
}

HostList.propTypes = {
  find: PropTypes.func,
  history: PropTypes.shape(),
  location: PropTypes.shape(),
}

export default HostList
