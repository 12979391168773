/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  TextField,
  Checkbox,
  Link,
  FormHelperText,
} from '@material-ui/core';
import { SnackbarProvider, useSnackbar } from 'notistack';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import Backdrop from '../../components/Backdrop';
import { register } from '../../provider/host';
import AlertOverlay from '../../components/AlertOverlay';

const DialogContainer = ({
  open,
  children,
  onClose,
}) => {
  return (
    <Dialog onClose={onClose} open={open} fullWidth={true}>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'Vui lòng nhập họ và tên' },
    length: {
      maximum: 255,
    },
  },
  phone: {
    presence: { allowEmpty: false, message: 'Vui lòng nhập SĐT'},
    length: {
      maximum: 255,
    },
  },
  email: {
    presence: { allowEmpty: false, message: 'Vui lòng nhập email'},
    email: { message: 'Vui lòng nhập một email hợp lệ' },
    length: {
      maximum: 100,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'Vui lòng nhập password'},
    length: {
      minimum: 4,
      maximum: 128,
    },
  },
  password_confirmation: {
    presence: { allowEmpty: false, message: 'Vui lòng nhập lại password'},
    equality: { attribute: 'password', message: () => 'Password không khớp'},
  },
  policy: {
    presence: { allowEmpty: false, message: 'Bạn cần đồng ý với điều khoản' },
    checked: { message: 'Bạn cần đồng ý với điều khoản' },
  },
};

const useStyles = makeStyles(theme => ({
  form: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  policyCheckbox: {
    marginLeft: '-14px',
  },
  signUpButton: {
    margin: theme.spacing(2, 0),
  },
}));

const SignUp = ({
  onSuccess,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema, {
      fullMessages: false,
    });

    setFormState(form => ({
      ...form,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState(form => ({
      ...form,
      values: {
        ...form.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...form.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    const { values } = formState;

    setIsSubmitting(true);

    try {
      await register({ ...values });

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      console.log(e);

      if (typeof e?.response?.data?.message === 'string') {
        enqueueSnackbar(e.response.data.message, { variant: 'error' });
      } else {
        enqueueSnackbar('Có lỗi xảy ra, vui lòng thử lại!',
          { variant: 'error' });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const hasError = field =>
    (!!(formState.touched[field] && formState.errors[field]));

  return (
    <Backdrop loading={isSubmitting}>
      <form
        className={classes.form}
        onSubmit={handleSignUp}
      >
        <Typography
          className={classes.title}
          variant="h2"
        >
          Đăng ký trở thành chủ nhà
        </Typography>

        <Typography
          color="textSecondary"
          gutterBottom
        >
          Sử dụng email của bạn để đăng ký.
        </Typography>

        <TextField
          className={classes.textField}
          error={hasError('name')}
          fullWidth
          helperText={
            hasError('name') ? formState.errors.name[0] : null
          }
          label="Họ và tên"
          name="name"
          onChange={handleChange}
          type="text"
          value={formState.values.name || ''}
          variant="outlined"
        />

        <TextField
          className={classes.textField}
          error={hasError('phone')}
          fullWidth
          helperText={
            hasError('phone') ? formState.errors.phone[0] : null
          }
          label="Số điện thoại"
          name="phone"
          onChange={handleChange}
          type="text"
          value={formState.values.phone || ''}
          variant="outlined"
        />

        <TextField
          className={classes.textField}
          error={hasError('address')}
          fullWidth
          helperText={
            hasError('address') ? formState.errors.address[0] : null
          }
          label="Địa chỉ thường chú"
          name="address"
          onChange={handleChange}
          type="text"
          value={formState.values.address || ''}
          variant="outlined"
        />

        <TextField
          className={classes.textField}
          error={hasError('email')}
          fullWidth
          helperText={
            hasError('email') ? formState.errors.email[0] : null
          }
          label="Email"
          name="email"
          onChange={handleChange}
          type="text"
          value={formState.values.email || ''}
          variant="outlined"
        />

        <TextField
          className={classes.textField}
          error={hasError('password')}
          fullWidth
          helperText={
            hasError('password') ? formState.errors.password[0] : null
          }
          label="Password"
          name="password"
          onChange={handleChange}
          type="password"
          value={formState.values.password || ''}
          variant="outlined"
        />

        <TextField
          className={classes.textField}
          error={hasError('password_confirmation')}
          fullWidth
          helperText={
            hasError('password_confirmation')
              ? formState.errors.password_confirmation[0]
              : null
          }
          label="Nhập lại password"
          name="password_confirmation"
          onChange={handleChange}
          type="password"
          value={formState.values.password_confirmation || ''}
          variant="outlined"
        />

        <div className={classes.policy}>
          <Checkbox
            checked={formState.values.policy || false}
            className={classes.policyCheckbox}
            color="primary"
            name="policy"
            onChange={handleChange}
          />

          <Typography
            className={classes.policyText}
            color="textSecondary"
            variant="body1"
          >
            Tôi đã đọc và đồng ý với
            {' '}
            <Link
              color="primary"
              component={RouterLink}
              to="#"
              underline="always"
              variant="h6"
            >
              các điều khoản
            </Link>
          </Typography>
        </div>

        {hasError('policy') && (
          <FormHelperText error>
            {formState.errors.policy[0]}
          </FormHelperText>
        )}

        <Button
          className={classes.signUpButton}
          color="primary"
          disabled={!formState.isValid}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Đăng ký
        </Button>

        <Typography
          color="textSecondary"
          variant="body1"
        >
          Đã có tài khoản?
          {' '}
          <Link
            component={RouterLink}
            to="/sign-in"
            variant="h6"
          >
            Đăng nhập
          </Link>
        </Typography>
      </form>
    </Backdrop>
  );
};

const RegisterPopup = ({ isOpen, onClose }) => {
  const [isDone, setIsDone] = useState(false);

  return (
    <SnackbarProvider
      autoHideDuration={3000}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <AlertOverlay
        open={isDone}
        onClose={() => setIsDone(false)}
      >
        Đăng ký thành công, vui lòng kiểm tra email để xác nhận!
      </AlertOverlay>

      <DialogContainer
        open={isOpen && !isDone}
        onClose={onClose}
      >
        <SignUp
          onSuccess={() => setIsDone(true)}
        />
      </DialogContainer>
    </SnackbarProvider>
  );
};

export default RegisterPopup;
