import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Apartment, Close, Remove } from '@material-ui/icons'
import { SearchSelectionInput } from 'components'
import * as roomApi from 'provider/room'
import { Grid, Typography } from '@material-ui/core'

const District = ({ value, onApply }) => {
  const [sltRoom, setSltRoom] = useState({})
  const [roomSuggestion, setRoomSuggestion] = useState([])
  const [allRooms, setAllRooms] = useState([])
  const [rooms, setRooms] = useState([])

  const fetchRoom = async (ids) => {
    const resp = await roomApi.find({ ids })
    setRooms(
      resp.data.map(opt => ({
        id: opt.id,
        title: `${opt.name} (${opt.code})`,
        subTitle: `${opt.address}`,
      })),
    )
  }

  const fetchAllRoom = async () => {
    const resp = await roomApi.find({})
    setAllRooms(
      resp.data.map(opt => ({
        id: opt.id,
        title: `${opt.name} (${opt.code})`,
        subTitle: `${opt.address}`,
      })),
    )
  }

  useEffect(() => {
    fetchAllRoom()
    return () => {
    }
  }, [])

  useEffect(() => {
    try {
      if (!value) {
        setRooms([])
        return () => {}
      }
      const ids = value
      if (ids && ids.length) {
        fetchRoom(ids)
      }
    } catch (e) {
      setRooms([])
      onApply(undefined)
    }
    return () => {}
  }, [value])

  const onRoomChange = async (str) => {
    const suggestions = allRooms.filter(r => r.title?.toLowerCase().includes(str?.toLowerCase()))
    setRoomSuggestion(suggestions)
  }

  const onRoomSelect = async (item) => {
    if (!item) return
    setSltRoom(item)
    if (!rooms.find(c => item && c.id === item.id)) {
      setRooms([...rooms].concat(item))
    }
  }

  const renderRoomOption = (option) => {
    const { title, subTitle } = option
    return (
      <div>
        <div style={{ fontSize: 16 }}>
          <strong>{title}</strong>
        </div>
        <div>
          <small>{subTitle}</small>
        </div>
      </div>
    )
  }

  return (
    <PopupState variant="popover" popupId="customer-filter">
      {popupState => (
        <div>
          <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
            <Apartment />
            {rooms.filter(c => value && value.includes(c.id)).length
              ? rooms.filter(c => value && value.includes(c.id)).map(c => c.title).join(', ')
              : 'Khu vực'}
            {value && value.length
              ? (
                <Close onClick={(e) => {
                  e.stopPropagation()
                  onApply(undefined)
                }}
                />
              )
              : null}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 400 }}>
              <SearchSelectionInput
                label="Khu vực"
                placeholder="Nhập thông tin khu vực"
                value={sltRoom.title}
                renderOption={renderRoomOption}
                suggestions={roomSuggestion}
                onChange={str => onRoomChange(str)}
                onSelect={onRoomSelect}
              />
              {/* Selected rooms */}
              <div>
                {rooms.length ? <Typography>Danh sách khu vực tìm kiếm:</Typography> : ''}
                {
                  rooms.map((c, i) => (
                    <Grid container>
                      <Typography variant="body1" style={{ fontWeight: 500 }}>{`${i + 1}. ${c.title}`}</Typography>
                      <Remove style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setRooms([...rooms].filter(item => item.id !== c.id))} />
                    </Grid>
                  ))
                }
              </div>
              <Button
                onClick={() => {
                  onApply(rooms && rooms.length ? JSON.stringify(rooms.map(c => c.id)) : undefined)
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

District.propTypes = {
  onApply: PropTypes.func,
  value: PropTypes.string,
}

export default District
