import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  FormHelperText,
  Typography,
  Divider,
} from '@material-ui/core'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import DatePicker from 'components/DatePicker'
import { create, update, findOne } from 'provider/customer'
import { getMembership } from 'provider/common'
import moment from 'moment'
import BackgroundBackdrop from 'components/Backdrop'
import { useSnackbar } from 'notistack'
import {
  Edit, LockOpen, AccountBox, AccountBalanceWallet,
} from '@material-ui/icons'
import { formatPrice } from 'utils/formatter'

const STATUS = [
  {
    value: 0,
    label: 'Chờ kích hoạt',
  },
  {
    value: 1,
    label: 'Đang hoạt động',
  },
  {
    value: 2,
    label: 'Dừng hoạt động',
  },
]

const GENDER = [
  {
    value: 'male',
    label: 'Nam',
  },
  {
    value: 'female',
    label: 'Nữ',
  },
]

const validateSchema = action => Yup.object().shape({
  password:
      action === 'update'
        ? Yup.string()
        : Yup.string().required('* Mật khẩu không được để trống'),
  name: Yup.string().required('* Họ tên không được để trống'),
  email: Yup.string().email().required('* Địa chỉ email không được để trống'),
  phone: Yup.string(),
  birthday: Yup.number(),
  address: Yup.string(),
  gender: Yup.string(),
  point: Yup.number(),
  rankPoint: Yup.number(),
  amount: Yup.number(),
  membership: Yup.string(),
  status: Yup.number(),
})

const initCustomer = {
  password: '1',
  name: 'Khách Hàng',
  email: 'khachhang@2home.com',
  phone: '0987654321',
  birthday: moment().valueOf(),
  gender: 'male',
  point: 0,
  rankPoint: 0,
  amount: 0,
  membership: '',
  status: 1,
  address: 'Hà Nội - không covid',
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 10,
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: 3,
  },
  title: {
    margin: 10,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '900',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  formControl: {
    backgroundColor: 'white',
  },
}))

const CustomerForm = (props) => {
  const {
    history,
    id,
    action,
    onUpdate,
  } = props
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [customer, setCustomer] = useState(initCustomer)
  const [membership, setMembership] = useState([])
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  // Fetch membership type
  const fetchMembership = async () => {
    try {
      const resp = await getMembership()
      setMembership(
        resp.data.map(item => ({ value: item.id, label: item.name })),
      )
    } catch (e) {
      console.log(e)
    }
  }

  // Fetch user data if in updating mode
  const fetchCustomer = async () => {
    setLoading(true)
    try {
      const resp = await findOne(id)
      setCustomer(resp)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  useEffect(() => {
    fetchMembership()
    if (action === 'update') {
      fetchCustomer()
    }
  }, [])

  const onSubmit = async (data) => {
    try {
      let resp = null
      switch (action) {
        case 'update':
          resp = await update(data)
          setCustomer(resp)
          onUpdate('success')
          break
        default:
          resp = await create(data)
          history.push(`/customer/${resp.id}/update`)
          onUpdate('info')
      }
    } catch (e) {
      onUpdate('error')
    }
  }

  return (
    <BackgroundBackdrop loading={loading}>
      <Typography variant="h4" className={classes.title}>
        {action === 'update' ? 'Thông tin khách hàng' : 'Thêm khách hàng'}
      </Typography>
      <Formik
        initialValues={customer}
        enableReinitialize
        validationSchema={validateSchema(action)}
        onSubmit={onSubmit}
      >
        {({
          values, touched, errors, handleChange, setFieldValue,
        }) => (
          <Form className={classes.root}>
            {/* Account and Password */}
            <Typography className={classes.subTitle} style={{ marginTop: 0 }}>
              <LockOpen />
              Thông tin đăng nhập
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        label="Hòm thư"
                        variant="outlined"
                        name="email"
                        fullWidth
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email && touched.email}
                        helperText={errors.email && touched.email && errors.email}
                        className={classes.formControl}
                        size="small"
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Email:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.email}
                        </Typography>
                      </>
                    )
                }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        label="Mật khẩu"
                        variant="outlined"
                        type="password"
                        name="password"
                        disabled={action === 'update'}
                        fullWidth
                        value={values.password || '*******'}
                        onChange={handleChange}
                        error={errors.password && touched.password}
                        helperText={
                          errors.password && touched.password && errors.password
                        }
                      />
                    )
                    : null
                }
              </Grid>
            </Grid>
            <br />
            <Divider />
            {/* Firstname and lastname */}
            <Typography className={classes.subTitle}>
              <AccountBox />
              Thông tin cá nhân
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        label="Họ tên"
                        variant="outlined"
                        name="name"
                        fullWidth
                        value={values.name}
                        onChange={handleChange}
                        error={errors.name && touched.name}
                        helperText={errors.name && touched.name && errors.name}
                        className={classes.formControl}
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Họ tên:</InputLabel>
                        <Typography variant="body1" style={{ fontWeight: 500 }}>{values.name}</Typography>
                      </>
                    )
                }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        label="Số điện thoại"
                        variant="outlined"
                        name="phone"
                        fullWidth
                        value={values.phone}
                        onChange={handleChange}
                        error={errors.phone && touched.phone}
                        helperText={errors.phone && touched.phone && errors.phone}
                        className={classes.formControl}
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Số điện thoại:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.phone
                            ? values.phone
                            : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                        </Typography>
                      </>
                    )
                }
              </Grid>
            </Grid>
            <br />
            {/* Birthday and Address */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <DatePicker
                        size="small"
                        fullWidth
                        name="birthday"
                        label="Ngày sinh"
                        value={values.birthday}
                        onChange={value => setFieldValue('birthday', value)}
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Ngày sinh:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.birthday
                            ? moment(values.birthday).format('DD/MM/YYYY')
                            : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                        </Typography>
                      </>
                    )
                }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        label="Địa chỉ"
                        variant="outlined"
                        name="address"
                        fullWidth
                        value={values.address}
                        onChange={handleChange}
                        className={classes.formControl}
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Địa chỉ:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.address}
                        </Typography>
                      </>
                    )
                }
              </Grid>
            </Grid>
            <br />
            {/* Gender */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <>
                        <FormControl
                          size="small"
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth
                          error={errors.gender && touched.gender}
                        >
                          <InputLabel
                            style={{ backgroundColor: 'white', padding: '0px 5px' }}
                            id="customer-gender"
                          >
                          Giới tính
                          </InputLabel>
                          <Select
                            labelId="customer-gender"
                            id="customer-gender-label"
                            name="gender"
                            value={values.gender}
                            onChange={handleChange}
                          >
                            {GENDER.map(item => (
                              <MenuItem value={item.value}>{item.label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormHelperText style={{ color: 'red' }}>
                          {errors.gender && touched.gender && errors.gender}
                        </FormHelperText>
                      </>
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Giới tính:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.gender
                            ? values.gender === 'male' ? 'Nam' : 'Nữ'
                            : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                        </Typography>
                      </>
                    )
                }
              </Grid>
            </Grid>
            <br />
            <Divider />
            <Typography className={classes.subTitle}>
              <AccountBalanceWallet />
              Tài khoản 2home
            </Typography>
            {/* Point and Amount */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        label="Điểm tích luỹ"
                        variant="outlined"
                        name="point"
                        fullWidth
                        value={values.point}
                        onChange={handleChange}
                        className={classes.formControl}
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Điểm tích luỹ:</InputLabel>
                        <Typography variant="body1" style={{ fontWeight: 500 }}>{`${values.point} điểm`}</Typography>
                      </>
                    )
                }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        label="Tài khoản thanh toán"
                        variant="outlined"
                        name="amount"
                        fullWidth
                        value={values.amount}
                        onChange={handleChange}
                        className={classes.formControl}
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Tài khoản thanh toán:</InputLabel>
                        <Typography variant="body1" style={{ fontWeight: 500 }}>{`${formatPrice(values.amount)} VND`}</Typography>
                      </>
                    )
                }
              </Grid>
            </Grid>
            <br />
            {/* Membership and rankpoint */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        label="Điểm thành viên"
                        variant="outlined"
                        name="rankPoint"
                        fullWidth
                        value={values.rankPoint}
                        onChange={handleChange}
                        className={classes.formControl}
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Điểm thành viên:</InputLabel>
                        <Typography variant="body1" style={{ fontWeight: 500 }}>{`${values.rankPoint} điểm`}</Typography>
                      </>
                    )
                }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <>
                        <FormControl
                          size="small"
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth
                          error={errors.membership && touched.membership}
                        >
                          <InputLabel
                            style={{ backgroundColor: 'white', padding: '0px 5px' }}
                            id="customer-membership"
                          >
                          Thành viên
                          </InputLabel>
                          <Select
                            labelId="customer-membership"
                            id="customer-membership-label"
                            name="membership"
                            value={values.membership}
                            onChange={handleChange}
                          >
                            {membership.map(item => (
                              <MenuItem value={item.value}>{item.label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormHelperText style={{ color: 'red' }}>
                          {errors.membership && touched.membership && errors.membership}
                        </FormHelperText>
                      </>
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Thành viên:</InputLabel>
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                          {
                            membership && values.membership
                              ? membership.find(item => item.value === values.membership)
                                ? membership.find(item => item.value === values.membership).label
                                : ''
                              : 'Hạng thường'
                          }
                        </Typography>
                      </>
                    )
                }
              </Grid>
            </Grid>
            <br />
            {/* Gender and status */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <FormControl
                        size="small"
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                        error={errors.status && touched.status}
                      >
                        <InputLabel
                          style={{ backgroundColor: 'white', padding: '0px 5px' }}
                          id="customer-status"
                        >
                        Trạng thái tài khoản
                        </InputLabel>
                        <Select
                          labelId="customer-status"
                          id="customer-status"
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                          helperText={errors.status && touched.status && errors.status}
                        >
                          {STATUS.map(item => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText style={{ color: 'red' }}>
                          {errors.status && touched.status && errors.status}
                        </FormHelperText>
                      </FormControl>
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Trạng thái tài khoản:</InputLabel>
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                          {
                            values.status
                              ? STATUS.find(item => item.value === values.status)
                                ? STATUS.find(item => item.value === values.status).label
                                : ''
                              : 'Không xác định'
                          }
                        </Typography>
                      </>
                    )
                }
              </Grid>
            </Grid>
            {/* Create button */}
            <br />
            <Divider />
            <br />
            {
              action === 'update'
                ? isEdit
                  ? (
                    <>
                      <Button type="submit" variant="contained" color="primary">
                        Cập nhật
                      </Button>
                      <Button variant="contained" onClick={() => setIsEdit(false)} style={{ marginLeft: 20 }}>
                        Thoát
                      </Button>
                    </>
                  )
                  : (
                    <Button variant="contained" color="primary" onClick={() => setIsEdit(true)}>
                      <Edit />
                      Chỉnh sửa
                    </Button>
                  )
                : (
                  <Button type="submit" variant="contained" color="primary">
                  Tạo tài khoản
                  </Button>
                )
            }
          </Form>
        )}
      </Formik>
    </BackgroundBackdrop>
  )
}

CustomerForm.propTypes = {
  history: PropTypes.shape(),
  id: PropTypes.string,
  action: PropTypes.string,
  onUpdate: PropTypes.func,
}

export default CustomerForm
