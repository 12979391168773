import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { getFiltersFromUrl } from 'utils/url'
import { makeStyles } from '@material-ui/styles'
import { SearchTextInput } from 'components'
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import Filter from './Filters'

// 0: Pending | 1: Active | 2: Inactive
const STATUS = [
  {
    value: 0,
    label: 'Chờ kích hoạt',
    active: 1,
  },
  {
    value: 1,
    label: 'Đang hoạt động',
    active: 1,
  },
  {
    value: 2,
    label: 'Dừng hoạt động',
    active: 0,
  },
]

const useStyles = makeStyles(theme => ({
  root: {},
  filter: {
    '& > div': {
      margin: theme.spacing(1),
    },
  },
}))

const UsersToolbar = (props) => {
  const {
    className, location, applyFilter, ...rest
  } = props
  const classes = useStyles()
  const [fltData, setFltData] = useState({})

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    if (!query.active) {
      query.active = 1
    } else {
      // eslint-disable-next-line no-restricted-globals
      query.active = !isNaN(query.active) ? Number(query.active) : 1
    }
    setFltData(query || {})
    return () => {}
  }, [])

  const filter = (data) => {
    setFltData(data)
    const {
      active,
      searchTxt,
      status,
      membership,
      startdate,
      enddate,
    } = data
    applyFilter({
      active,
      searchTxt,
      status,
      membership,
      startdate,
      enddate,
    })
  }

  const onSearch = async (data) => {
    filter(data)
  }

  const groupByStatus = (data) => {
    filter(data)
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={1}>
        <Typography variant="h3" gutterBottom>Quản lý thanh toán</Typography>
      </Grid>
      {/* <Grid container spacing={1} className={classes.filter}>
        Membership
        <Filter.Membership
          value={fltData.membership}
          onApply={membership => filter({ ...fltData, membership })}
        />
        Status
        <Filter.Status
          data={STATUS.filter(item => item.active === (fltData.active || 0))}
          value={fltData.status}
          onApply={status => filter({ ...fltData, status })}
        />
        Start-End
        <Filter.Date
          startdate={fltData.startdate}
          enddate={fltData.enddate}
          onApply={(startdate, enddate) => filter({ ...fltData, startdate, enddate })}
        />
      </Grid> */}
      <Grid container spacing={1}>
        <Grid item lg={2} style={{ marginTop: 'auto', color: 'rgb(188 171 137)', fontWeight: 600 }}>
          {/* <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 1 ? 'contained' : 'default'}`}
            color={`${fltData.active === 1 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: 1 })}
          >
            Hoạt động
          </Button> */}
          Lịch sử thanh toán
        </Grid>
        {/* <Grid item lg={2} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 0 ? 'contained' : 'default'}`}
            color={`${fltData.active === 0 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: 0 })}
          >
            Dừng hoạt động
          </Button>
        </Grid> */}
        <Grid item lg={3} md={4} style={{ marginLeft: 'auto' }}>
          <SearchTextInput
            size="small"
            fullWidth
            className={classes.searchInput}
            placeholder="Tìm kiếm (theo mã, tên, địa chỉ, ...)"
            initValue={fltData.searchTxt}
            debounceTime={800}
            onChange={str => onSearch({ ...fltData, searchTxt: str })}
            leftIcon={<Search />}
            style={{ marginBottom: 0 }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

UsersToolbar.propTypes = {
  className: PropTypes.string,
  applyFilter: PropTypes.func,
  location: PropTypes.shape(),
}

export default UsersToolbar
