import React from 'react'
import PropTypes from 'prop-types'
import CustomDateRangePicker from './DateRangePicker'

const DatePicker = ({ type, ...props }) => {
  switch (type) {
    case 'datetime-range':
      return null
    case 'date-range':
    default:
      return <CustomDateRangePicker {...props} />
  }
}

DatePicker.propTypes = {
  type: PropTypes.oneOf(['date-range', 'datetime-range']),
}

DatePicker.defaultProps = {
  type: 'date-range',
}

export default DatePicker
