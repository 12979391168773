import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import {
  Close, Remove, People,
} from '@material-ui/icons'
import { SearchSelectionInput } from 'components'
import * as commonApi from 'provider/common'
import * as customerApi from 'provider/customer'
import { Typography, Grid } from '@material-ui/core'

const Customer = ({
  values,
  touched,
  errors,
  // handleChange,
  setFieldValue,
}) => {
  const [customerSuggestion, setCustomerSuggestion] = useState([])
  const [sltCustomer, setSltCustomer] = useState({})
  const [customers, setCustomers] = useState(null)

  const fetchCustomer = async (ids) => {
    const resp = await customerApi.find({ ids })
    setCustomers(
      resp.data.map(opt => ({
        id: opt.id,
        title: `${opt.name} (${opt.code})`,
        subTitle: `${opt.email} - ${opt.phone}`,
      })),
    )
  }

  useEffect(() => {
    try {
      if (!values.customers) return () => {}
      if (!values.customers?.length) {
        setCustomers([])
        return () => {}
      }
      const ids = values.customers
      if (ids && ids.length) {
        fetchCustomer(JSON.stringify(ids))
      }
    } catch (e) {
      setCustomers([])
      setFieldValue('customers', [])
    }
    return () => {}
  }, [values.customers])

  const onCustomerChange = async (str) => {
    if (!str) return
    const suggestions = await commonApi.getCustomerSuggestion(str)
    setCustomerSuggestion(
      suggestions.map(opt => ({
        id: opt.id,
        title: `${opt.display} (${opt.code})`,
        subTitle: `${opt.email} - ${opt.phone}`,
      })),
    )
  }

  const onCustomerSelect = async (item) => {
    if (!item) return
    setSltCustomer(item)
    if (!customers?.find(c => item && c.id === item.id)) {
      setCustomers(customers ? [...customers].concat(item) : [item])
    }
  }

  const renderCustomerOption = (option) => {
    const { title, subTitle } = option
    return (
      <div>
        <div style={{ fontSize: 16 }}>
          <strong>{title}</strong>
        </div>
        <div>
          <small>{subTitle}</small>
        </div>
      </div>
    )
  }

  return (
    <PopupState variant="popover" popupId="customer-filter">
      {popupState => (
        <div className="w-100">
          <Button className="w-100" variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
            <People className="mr-2" />
            {customers
              ? customers.filter(c => values && (values.customers || []).includes(c.id)).length
                ? `Áp dụng cho ${customers.filter(c => values && (values.customers || []).includes(c.id)).length} khách hàng`
                : 'Áp dụng toàn bộ khách hàng'
              : 'Chọn khách hàng'}
            {values && (values || []).length
              ? (
                <Close onClick={(e) => {
                  e.stopPropagation()
                  setFieldValue('customers', [])
                }}
                />
              )
              : null}
          </Button>
          {errors.customers && touched.customers ? <div style={{ color: 'red' }}>{errors.customers}</div> : null}
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 400 }}>
              <SearchSelectionInput
                label="Khách hàng"
                placeholder="Nhập thông tin khách hàng"
                value={sltCustomer.title}
                renderOption={renderCustomerOption}
                suggestions={customerSuggestion}
                onChange={str => onCustomerChange(str)}
                onSelect={onCustomerSelect}
              />
              {/* Selected customers */}
              <div>
                {customers?.length ? <Typography>Danh sách khách hàng áp dụng:</Typography> : ''}
                {
                  customers?.map((c, i) => (
                    <Grid container>
                      <Typography variant="body1" style={{ fontWeight: 500 }}>{`${i + 1}. ${c.title}`}</Typography>
                      <Remove style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setCustomers([...customers].filter(item => item.id !== c.id))} />
                    </Grid>
                  ))
                }
              </div>
              <Button
                onClick={() => {
                  setFieldValue('customers', [])
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Tất cả khách hàng
              </Button>
              <Button
                onClick={() => {
                  setFieldValue('customers', customers?.length ? customers.map(c => c.id) : [])
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

Customer.propTypes = {
  values: PropTypes.shape(),
  setFieldValue: PropTypes.func,
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
}

export default Customer
