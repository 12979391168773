import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { formatPrice } from 'utils/formatter'
import { Table } from 'components'
import { DATETIME_FORMAT } from 'config'

const CouponHistoryTable = ({
  // classes,
  loading,
  coupon,
  meta,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const handlePageChange = (page) => {
    onPageChange(page)
  }

  const handleRowsPerPageChange = (limit) => {
    onRowsPerPageChange(limit)
  }

  const renderValue = (type, value) => {
    switch (type) {
      case 'money':
        return `${formatPrice(value)} VND`
      case 'percent':
        return `${value}%`
      default:
        return ''
    }
  }

  return (
    <Table
      data={coupon || []}
      columns={[
        {
          Head: 'Mã khuyến mại',
          Cell: value => value?.code || 'Không xác định',
          accessor: 'coupon',
          sortable: false,
        },
        {
          Head: 'Giá trị',
          Cell: value => renderValue(value?.valueType, value?.value),
          accessor: 'coupon',
          sortable: false,
        },
        {
          Head: 'Mô tả',
          Cell: value => <div dangerouslySetInnerHTML={{ __html: value?.description }} />,
          accessor: 'coupon',
          sortable: false,
        },
        {
          Head: 'Đơn đã dùng',
          Cell: value => value ? value?.code : '',
          accessor: 'reservation',
          sortable: false,
        },
        {
          Head: 'Khách hàng',
          Cell: value => value ? value?.name : '',
          accessor: 'customer',
          sortable: false,
        },
        {
          Head: 'Thời điểm sử dụng',
          Cell: value => value ? moment(value).format(DATETIME_FORMAT) : '',
          accessor: 'createdAt',
          sortable: false,
        },
      ]}
      loading={loading}
      page={meta?.page}
      limit={meta?.limit}
      total={meta?.total}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  )
}

CouponHistoryTable.propTypes = {
  // classes: PropTypes.shape(),
  loading: PropTypes.bool,
  coupon: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape(),
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
}

export default CouponHistoryTable
