import axios from 'axios'
import * as ENDPOINT from './url'

export const schedule = async () => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_SCHEDULE())
    return resp.data
  } catch (e) {
    throw e
  }
}

export const find = async ({ schedule: scheduleId, page, limit }) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_INVOICE({ schedule: scheduleId, page, limit }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const create = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.CREATE_INVOICE, data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const confirm = async (id, data) => {
  try {
    const resp = await axios.post(ENDPOINT.CONFIRM_INVOICE(id), data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const check = async (startdate, enddate) => {
  try {
    const resp = await axios.get(ENDPOINT.CHECK_INVOICE({ startdate, enddate }))
    return resp.data
  } catch (e) {
    throw e
  }
}
// export const remove = async (id) => {
//   try {
//     const resp = await axios.delete(ENDPOINT.INVOICE(id))
//     return resp.data
//   } catch (e) {
//     throw e
//   }
// }
