import axios from 'axios'
import * as ENDPOINT from './url'

export const getCity = async () => {
  try {
    const resp = await axios.get(ENDPOINT.GET_CITY)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getDistrict = async (city) => {
  try {
    const resp = await axios.get(ENDPOINT.GET_DISTRICT, { city })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const createDistrict = async (name) => {
  try {
    const resp = await axios.post(ENDPOINT.DISTRICT, { name })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getServiceType = async () => {
  try {
    const resp = await axios.get(ENDPOINT.GET_SERVICE_TYPE)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getServiceOption = async () => {
  try {
    const resp = await axios.get(ENDPOINT.GET_SERVICE_OPTION)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getServiceComponent = async () => {
  try {
    const resp = await axios.get(ENDPOINT.GET_SERVICE_COMPONENT)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getBuilding = async ({ district, name }) => {
  try {
    const resp = await axios.get(ENDPOINT.GET_BUILDING, {
      params: {
        district,
        name,
      },
    })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getRoomSuggestion = async (searchStr) => {
  try {
    const resp = await axios.get(ENDPOINT.GET_ROOM_SUGGESTION(searchStr))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getHostSuggestion = async (searchStr) => {
  try {
    const resp = await axios.get(ENDPOINT.GET_HOST_SUGGESTION(searchStr))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getCustomerSuggestion = async (searchStr) => {
  try {
    const resp = await axios.get(ENDPOINT.GET_CUSTOMER_SUGGESTION(searchStr))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getUserSuggestion = async (searchStr) => {
  try {
    const resp = await axios.get(ENDPOINT.GET_USER_SUGGESTION(searchStr))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getServiceSuggestion = async (searchStr) => {
  try {
    const resp = await axios.get(ENDPOINT.GET_SERVICE_SUGGESTION(searchStr))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getRole = async () => {
  try {
    const resp = await axios.get(ENDPOINT.GET_ROLE)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getMembership = async () => {
  try {
    const resp = await axios.get(ENDPOINT.GET_MEMBERSHIP)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getReservationSuggestion = async (searchStr) => {
  try {
    const resp = await axios.get(ENDPOINT.GET_RESERVATION_SUGGESTION(searchStr))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getPackage = async () => {
  try {
    const resp = await axios.get(ENDPOINT.GET_PACKAGE)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getRoomType = async () => {
  try {
    const resp = await axios.get(ENDPOINT.GET_ROOM_TYPE)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getBuildingSuggestion = async (searchStr) => {
  try {
    const resp = await axios.get(ENDPOINT.GET_BUILDING_SUGGESTION(searchStr))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const createBuilding = async (name, district) => {
  try {
    const resp = await axios.post(ENDPOINT.BUILDING, { name, district })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getAmenity = async () => {
  try {
    const resp = await axios.get(ENDPOINT.AMENITY)
    return resp.data
  } catch (e) {
    throw e
  }
}
