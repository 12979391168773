import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'

const General = ({
  classes,
  values,
}) => {
  const {
    status,
    room,
  } = values

  return (
    <>
      <Typography className={classes.subTitle}>
        {['lock', 'reject-lock'].includes(status) ? 'Thông tin khoá phòng:' : 'Thông tin phòng và dịch vụ:'}
      </Typography>
      {/* Room/Host and Service */}
      <Grid container>
        <Grid item lg={6} md={6} xs={12}>
          {/* Room */}
          <Grid item lg={12} md={12} xs={12}>
            <InputLabel className={classes.itemTitle}>Phòng:</InputLabel>
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              <RouterLink to={`/room/${room?.id}/update`} target="_blank">{`${room?.name} (${room?.code})`}</RouterLink>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

General.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
}

export default General
