import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'
import { ServiceToolbar, ServiceTable } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}))

const ServiceList = (props) => {
  const {
    service, location, history, find,
  } = props
  const classes = useStyles()

  const updateUrl = (query) => {
    const url = `${location.pathname}?${applyFiltersToUrl(query)}`
    history.push(url, { replace: false })
  }

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    find({ ...query, active: query.active || 1 })
    updateUrl({ ...query, active: query.active || 1 })
    return () => {}
    // eslint-disable-next-line
  }, [])

  const applyFilter = (fltData) => {
    const query = {
      ...service.query,
      ...fltData,
    }
    updateUrl(query)
    find(query)
  }

  const changePage = (page) => {
    const query = { ...service.query, page }
    updateUrl(query)
    find(query)
  }

  const changeRowsPerPage = (limit) => {
    const query = { ...service.query, page: 1, limit }
    updateUrl(query)
    find(query)
  }

  const sort = (sortBy, sortDirection) => {
    const query = { ...service.query, sortBy, sortDirection }
    updateUrl(query)
    find(query)
  }

  const update = (id) => {
    history.push(`/service/${id}/update`)
  }

  return (
    <div className={classes.root}>
      <ServiceToolbar location={location} applyFilter={applyFilter} />
      <div className={classes.content}>
        <ServiceTable
          find={find}
          update={update}
          loading={service.loading}
          services={service.data}
          meta={service.meta}
          defaultSort={
            service.query
              ? {
                sortBy: service.query.sortBy,
                sortDirection: service.query.sortDirection,
              }
              : {}
          }
          onPageChange={changePage}
          onRowsPerPageChange={changeRowsPerPage}
          onSortChange={sort}
          location={location}
        />
      </div>
      <RouterLink to="/service/create">
        <Fab color="primary" aria-label="add" className={classes.fab}>
          <AddIcon />
        </Fab>
      </RouterLink>
    </div>
  )
}

ServiceList.propTypes = {
  service: PropTypes.arrayOf(PropTypes.shape()),
  find: PropTypes.func,
  location: PropTypes.shape(),
  history: PropTypes.shape(),
}

export default ServiceList
