import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Button,
  FormHelperText,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
  Divider,
} from '@material-ui/core'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import DatePicker from 'components/DatePicker'
import {
  create, update, findOne, find,
} from 'provider/host'
import moment from 'moment'
import BackgroundBackdrop from 'components/Backdrop'
import { useSnackbar } from 'notistack'
import {
  Edit, AccountBox, LockOpen, CreditCard, Gavel,
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 10,
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: 3,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '900',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  formControl: {
    backgroundColor: 'white',
  },
  title: {
    margin: 10,
  },
  itemTitle: {
    fontSize: 12,
    marginBottom: 5,
  },
}))

const validateSchema = action => Yup.object().shape({
  password:
      action === 'update'
        ? Yup.string()
        : Yup.string().required('* Mật khẩu không được để trống'),
  name: Yup.string().required('* Họ tên không được để trống'),
  email: Yup.string()
    .email()
    .required('* Địa chỉ email không được để trống'),
  phone: Yup.string(),
  birthday: Yup.number(),
  address: Yup.string(),
  bankAccount: Yup.string(),
  bankName: Yup.string(),
  bankBranch: Yup.string(),
  policy: Yup.string(),
  policyDay: Yup.number(),
  role: Yup.string(),
  owner: Yup.string().nullable(),
})

const initHost = {
  account: '',
  password: '',
  name: '',
  email: '',
  phone: '',
  birthday: moment().valueOf(),
  address: '',
  bankAccountName: '',
  bankAccountNumber: '',
  bankName: '',
  bankBranch: '',
  policy: '',
  policyDay: 0,
  role: 'owner',
  owner: '',
}

const HostInfo = (props) => {
  const {
    history, id, action, onUpdate,
  } = props
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [host, setHost] = useState(initHost)
  const [hosts, setHosts] = useState([])
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const fetchHosts = async (role) => {
    if (role === 'manager' && !hosts.length) {
      try {
        const resp = await find({ limit: Number.MAX_SAFE_INTEGER })
        setHosts(
          resp.data.map(item => ({
            value: item.id,
            label: item.name,
          })),
        )
      } catch (e) {
        enqueueSnackbar(
          `Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`,
          { variant: 'error' },
        )
      }
    }
  }

  // Fetch user data if in updating mode
  const fetchHost = async () => {
    setLoading(true)
    try {
      const resp = await findOne(id)
      setHost({ ...resp, owner: resp.owner ? resp.owner.id : '' })
      if (resp.role === 'manager') {
        fetchHosts('manager')
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      enqueueSnackbar(
        `Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`,
        { variant: 'error' },
      )
    }
  }

  useEffect(() => {
    if (action === 'update') {
      fetchHost()
    }
  }, [])

  const onSubmit = async (data) => {
    try {
      let resp = null
      switch (action) {
        case 'update':
          resp = await update({ ...data, owner: data.owner || undefined })
          setHost(resp)
          onUpdate('success')
          break
        default:
          resp = await create({ ...data, owner: data.owner || undefined })
          history.push(`/user/${resp.id}/update`)
          onUpdate('info')
      }
      setIsEdit(false)
    } catch (e) {
      onUpdate('error')
    }
  }

  return (
    <BackgroundBackdrop loading={loading}>
      <Typography variant="h4" className={classes.title}>
        {action === 'update'
          ? 'Thông tin chủ nhà'
          : 'Thêm mới chủ nhà'}
      </Typography>
      <Formik
        initialValues={host}
        enableReinitialize
        validationSchema={validateSchema(action)}
        onSubmit={onSubmit}
      >
        {({
          values, touched, errors, handleChange, setFieldValue,
        }) => (
          <Form className={classes.root}>
            <Typography className={classes.subTitle} style={{ marginTop: 0 }}>
              <LockOpen />
              Thông tin đăng nhập:
            </Typography>
            {/* Email and Password */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        className={classes.formControl}
                        label="Email"
                        variant="outlined"
                        name="email"
                        fullWidth
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email && touched.email}
                        helperText={errors.email && touched.email && errors.email}
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Email:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.email}
                        </Typography>
                      </>
                    )
                }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        className={classes.formControl}
                        label="Mật khẩu"
                        variant="outlined"
                        type="password"
                        name="password"
                        disabled={action === 'update'}
                        fullWidth
                        value={values.password || '*******'}
                        onChange={handleChange}
                        error={errors.password && touched.password}
                        helperText={
                          errors.password && touched.password && errors.password
                        }
                      />
                    )
                    : null
                }
              </Grid>
            </Grid>
            <br />
            <Divider />
            <Typography className={classes.subTitle}>
              <AccountBox />
              Thông tin cá nhân:
            </Typography>
            {/* Name and phone */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        className={classes.formControl}
                        label="Họ tên"
                        variant="outlined"
                        name="name"
                        fullWidth
                        value={values.name}
                        onChange={handleChange}
                        error={errors.name && touched.name}
                        helperText={errors.name && touched.name && errors.name}
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Họ tên:</InputLabel>
                        <Typography variant="body1" style={{ fontWeight: 500 }}>{values.name}</Typography>
                      </>
                    )
                }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        className={classes.formControl}
                        label="Số điện thoại"
                        variant="outlined"
                        name="phone"
                        fullWidth
                        value={values.phone}
                        onChange={handleChange}
                        error={errors.phone && touched.phone}
                        helperText={errors.phone && touched.phone && errors.phone}
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Điện thoại:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.phone}
                        </Typography>
                      </>
                    )
                }
              </Grid>
            </Grid>
            <br />
            {/* Birthday and Address */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <DatePicker
                        size="small"
                        fullWidth
                        name="birthday"
                        label="Ngày sinh"
                        value={values.birthday}
                        onChange={value => setFieldValue('birthday', value)}
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Ngày sinh:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.birthday
                            ? moment(values.birthday).format('DD/MM/YYYY')
                            : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                        </Typography>
                      </>
                    )
                }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        className={classes.formControl}
                        label="Địa chỉ"
                        variant="outlined"
                        name="address"
                        fullWidth
                        value={values.address}
                        onChange={handleChange}
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Địa chỉ:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.address}
                        </Typography>
                      </>
                    )
                }
              </Grid>
            </Grid>
            <br />
            <Divider />
            <Typography className={classes.subTitle}>
              <CreditCard />
              Tài khoản ngân hàng:
            </Typography>
            {/* Bank information (owner, number) */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        className={classes.formControl}
                        label="Chủ tài khoản"
                        variant="outlined"
                        name="bankAccountName"
                        fullWidth
                        value={values.bankAccountName}
                        onChange={handleChange}
                        error={errors.bankAccountName && touched.bankAccountName}
                        helperText={
                          errors.bankAccountName
                          && touched.bankAccountName
                          && errors.bankAccountName
                        }
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Chủ tài khoản:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.bankAccountName
                            ? values.bankAccountName
                            : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                        </Typography>
                      </>
                    )
                }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        className={classes.formControl}
                        label="Số tài khoản"
                        variant="outlined"
                        name="bankAccountNumber"
                        fullWidth
                        value={values.bankAccountNumber}
                        onChange={handleChange}
                        error={errors.bankAccountNumber && touched.bankAccountNumber}
                        helperText={
                          errors.bankAccountNumber
                          && touched.bankAccountNumber
                          && errors.bankAccountNumber
                        }
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Số tài khoản:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.bankAccountNumber
                            ? values.bankAccountNumber
                            : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                        </Typography>
                      </>
                    )
                }
              </Grid>
            </Grid>
            <br />
            {/* Bank information (bank name, bank branch) */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        className={classes.formControl}
                        label="Tên ngân hàng"
                        variant="outlined"
                        name="bankName"
                        fullWidth
                        value={values.bankName}
                        onChange={handleChange}
                        error={errors.bankName && touched.bankName}
                        helperText={
                          errors.bankName && touched.bankName && errors.bankName
                        }
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Tên ngân hàng:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.bankName
                            ? values.bankName
                            : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                        </Typography>
                      </>
                    )
                }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        className={classes.formControl}
                        label="Chi nhánh "
                        variant="outlined"
                        name="bankBranch"
                        fullWidth
                        value={values.bankBranch}
                        onChange={handleChange}
                        error={errors.bankBranch && touched.bankBranch}
                        helperText={
                          errors.bankBranch && touched.bankBranch && errors.bankBranch
                        }
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Chi nhánh:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.bankBranch
                            ? values.bankBranch
                            : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                        </Typography>
                      </>
                    )
                }
              </Grid>
            </Grid>
            <br />
            <Divider />
            <Typography className={classes.subTitle}>
              <Gavel />
              Chính sách:
            </Typography>
            {/* Policy */}
            <Grid container spacing={2}>
              {
                isEdit
                  ? (
                    <>
                      <Grid item lg={6} md={6}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend" style={{ fontSize: 10 }}>
                          Chính sách huỷ
                          </FormLabel>
                          <RadioGroup
                            aria-label="policy"
                            name="policy"
                            value={values.policy}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="2home"
                              control={<Radio />}
                              label="Chính sách huỷ của 2home"
                            />
                            <FormControlLabel
                              value="owner"
                              control={<Radio />}
                              label="Chính sách huỷ riêng"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        {values.policy === '2home' ? (
                          <Link style={{ fontSize: 14, cursor: 'pointer' }}>
                          Thông tin về chính sách huỷ của 2home
                          </Link>
                        ) : (
                          <p style={{ fontSize: 14 }}>
                          Hoàn lại 100% tiền thuê căn hộ khi báo trước ít nhất
                            <input
                              style={{ width: 30, margin: 5 }}
                              name="policyDay"
                              value={values.policyDay}
                              onChange={handleChange}
                            />
                          ngày nhận phòng đã đặt. Không hoàn lại tiền nếu báo muộn hơn
                          số ngày này.
                          </p>
                        )}
                      </Grid>
                    </>
                  )
                  : (
                    <Grid item lg={12} md={12}>
                      <InputLabel className={classes.itemTitle}>Chính sách huỷ:</InputLabel>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 500 }}
                      >
                        {values.policy === '2home'
                          ? (
                            <Link style={{ cursor: 'pointer' }}>Chính sách huỷ của 2home</Link>
                          )
                          : `Hoàn lại 100% tiền thuê căn hộ khi báo trước ít nhất ${values.policyDay} ngày nhận phòng đã đặt. Không hoàn lại tiền nếu báo muộn hơn số ngày này.`
                        }
                      </Typography>
                    </Grid>
                  )
              }
            </Grid>
            <br />
            <Divider />
            <Typography className={classes.subTitle}>Quyền hạn:</Typography>
            {/* Role and owner */}
            <Grid container spacing={2}>
              {
                isEdit
                  ? (
                    <>
                      <Grid item lg={6} md={6}>
                        <FormControl
                          size="small"
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth
                          error={errors.role && touched.role}
                        >
                          <InputLabel
                            style={{ backgroundColor: 'white', padding: '0px 5px' }}
                            id="user-role"
                          >
                          Quyền
                          </InputLabel>
                          <Select
                            labelId="user-role"
                            id="user-role-label"
                            name="role"
                            value={values.role}
                            onChange={(e) => {
                              fetchHosts(e.target.value)
                              handleChange(e)
                            }}
                          >
                            {['owner', 'manager'].map(item => (
                              <MenuItem value={item}>
                                {item === 'owner' ? 'Chủ nhà' : 'Quản lý'}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormHelperText style={{ color: 'red' }}>
                          {errors.role && touched.role && errors.role}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        {values.role === 'owner' ? null : (
                          <>
                            <FormControl
                              size="small"
                              variant="outlined"
                              className={classes.formControl}
                              fullWidth
                              error={errors.owner && touched.owner}
                            >
                              <InputLabel
                                style={{ backgroundColor: 'white', padding: '0px 5px' }}
                                id="host-owner"
                              >
                              Chủ nhà
                              </InputLabel>
                              <Select
                                labelId="host-owner"
                                id="host-owner-label"
                                name="owner"
                                value={values.owner}
                                onChange={handleChange}
                              >
                                {hosts.map(({ value, label }) => (
                                  <MenuItem value={value}>{label}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormHelperText style={{ color: 'red' }}>
                              {errors.owner && touched.owner && errors.owner}
                            </FormHelperText>
                          </>
                        )}
                      </Grid>
                    </>
                  )
                  : (
                    <Grid item lg={12} md={12}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 500 }}
                      >
                        {values.role === 'owner' ? 'Chủ nhà' : 'Quản lý'}
                      </Typography>
                    </Grid>
                  )
              }
            </Grid>
            {/* Button */}
            <br />
            <Divider />
            <br />
            {
              action === 'update'
                ? isEdit
                  ? (
                    <>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                      Cập nhật
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setIsEdit(false)}
                        style={{ marginLeft: 20 }}
                      >
                      Thoát
                      </Button>
                    </>
                  )
                  : (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setIsEdit(true)}
                    >
                      <Edit style={{ fontSize: 16 }} />
                      Chỉnh sửa
                    </Button>
                  )
                : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                  Tạo tài khoản
                  </Button>
                )
            }
          </Form>
        )}
      </Formik>
    </BackgroundBackdrop>
  )
}

HostInfo.propTypes = {
  history: PropTypes.shape(),
  id: PropTypes.string,
  action: PropTypes.string,
  onUpdate: PropTypes.func,
}

export default HostInfo
