import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Grid, TextField, Button, Avatar, Typography, FormControl, FormControlLabel, Link, RadioGroup, Radio, Checkbox, Tooltip, Chip, Divider,
} from '@material-ui/core'
import * as hostApi from 'provider/host'
import uploadApi from 'provider/upload'
import { get } from 'provider/user'
import * as Yup from 'yup'
import moment from 'moment'
import { Formik, Form } from 'formik'
import { useSnackbar } from 'notistack'
import DatePicker from 'components/DatePicker'
import { Add, Delete, Help } from '@material-ui/icons'
import RichEditor from 'components/RichEditor'
import CurrencyInput from 'components/CurrencyInput'
import { formatPrice } from 'utils/formatter'
// import MediaUpload from '../../components/MediaUpload'
import Upload from 'components/Upload'
import AppConfig from 'config'
import { DepositRoom } from './components'

const depositTooltip = (
  <>
    <div>
      Tiền cọc là tiền do chủ nhà tự thu của khách nhằm đảm bảo khách không làm hư hại,
      làm mất, vi phạm quy định trong căn hộ. Khoản tiền này sẽ do chủ nhà hoàn trả lại toàn bộ cho khách nếu không
      có thiệt hại nào từ căn hộ và khách không vi phạm quy định của căn hộ. Ngược lại, nếu khách hàng gây thiệt hại
      tài sản hoặc vi phạm quy định của căn hộ, chủ nhà có thể giữ lại 1 phần hoặc toàn bộ số tiền cọc này của khách
      hàng tuỳ vào mức độ thiệt hại của căn hộ do 2 bên thoả thuận.
    </div>
    <div>
      Khách hàng là thành viên hạng Vàng và Kim Cương, hoặc có số dư 2home từ 3 triệu trở lên sẽ không phải chuyển tiền cọc,
      nhưng vẫn phải đền bù thiệt hại cho chủ nhà nếu khách hàng gây ra.
    </div>
  </>
)

const useStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(4) },
  avatar: {
    height: 100,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  infoLabel: {
    fontWeight: 'bold',
    color: 'black',
  },
  separator: {
    display: 'flex',
    width: '100%',
    margin: '15px auto 15px auto',
    borderBottom: '2px solid #dad6cf',
  },
}))

const validateSchema = action => Yup.object().shape({
  password:
      action === 'update'
        ? Yup.string()
        : Yup.string().required('* Mật khẩu không được để trống'),
  name: Yup.string().required('* Họ tên không được để trống'),
  email: Yup.string()
    .email()
    .required('* Địa chỉ email không được để trống'),
  phone: Yup.string(),
  birthday: Yup.number(),
  address: Yup.string(),
  bankAccount: Yup.string(),
  bankName: Yup.string(),
  bankBranch: Yup.string(),
  policy: Yup.string(),
  policyDay: Yup.number(),
  role: Yup.string(),
  owner: Yup.string().nullable(),
})

const initInfoHost = {
  account: '',
  password: '',
  name: '',
  email: '',
  phone: '',
  birthday: moment().valueOf(),
  address: '',
  bankAccountName: '',
  bankAccountNumber: '',
  bankName: '',
  bankBranch: '',
  policy: '',
  policyDay: 0,
  role: 'owner',
  owner: '',
  deposit: false,
  depositInfo: [],
}

const Account = ({
  // data: user,
  update,
}) => {
  const classes = useStyles()
  const [editInfoHost, setEditInfoHost] = useState(false)
  const [editInfoManager, setEditInfoManager] = useState(false)
  const [editPolicy, setEditPolicy] = useState(false)
  const [infoHost, setInfoHost] = useState(initInfoHost)
  const [depositInfo, setDepositInfo] = useState([])
  const { enqueueSnackbar } = useSnackbar()

  const fetchInfoHost = async () => {
    try {
      const resp = await get()
      setInfoHost(resp)
      setDepositInfo(resp?.depositInfo || [])
    } catch (e) {
      console.log(e)
    }
  }

  const onSubmit = async (data) => {
    try {
      const resp = await hostApi.update({ ...data, owner: data.owner || undefined })
      setInfoHost(resp)
      enqueueSnackbar('Cập nhật thông tin thành công', { variant: 'success' })
      setEditInfoHost(false)
      setEditInfoManager(false)
      setEditPolicy(false)
    } catch (e) {
      console.log(e)
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}). Không thể cập nhật thông tin phòng.`, { variant: 'error' })
    }
  }

  const onUploadAvatarSuccess = async (files) => {
    // update({ avatar: url[0] })
    try {
      const uploadToServer = await uploadApi.uploadImage(files.map(f => f.file), `Host/${infoHost.code}`)
      update({ id: infoHost.id, avatar: uploadToServer[0]?.shortUrl })
      setInfoHost({ ...infoHost, avatar: uploadToServer[0]?.shortUrl })
      enqueueSnackbar('Cập nhật thông tin thành công', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}). Không thể tải lên ảnh.`, { variant: 'error' })
    }
  }

  const onUpdateDepositInfo = (data, setFieldValue) => {
    setFieldValue('depositInfo', data)
  }

  const onAddDepositInfo = (setFieldValue) => {
    const clone = [...depositInfo]
    clone.push({
      amount: 0,
      description: '',
      price: '',
      rooms: [],
    })
    setDepositInfo(clone)
    setFieldValue('depositInfo', clone)
  }

  const onDeleteDepositInfo = (index, setFieldValue) => {
    const clone = [...depositInfo]
    const newDepositInfo = clone.filter((obj, i) => i !== index)
    setDepositInfo(newDepositInfo)
    setFieldValue('depositInfo', newDepositInfo)
  }

  useEffect(() => {
    // Fetch user data if in updating mode
    fetchInfoHost()
    // eslint-disable-next-line
  }, [])

  return (
    <Grid container className="mt-3 p-2">
      <Grid item xl={1} lg={2} md={2} xs={12} className="text-center">
        <Avatar className={`${classes.avatar} m-auto`} src={`${AppConfig.BASE_IMG_URL}/${infoHost.avatar}` || `${AppConfig.BASE_IMG_URL}/General/default-avatar.png`} />
        <Upload
          title="Tải lên ảnh"
          // files={infoHost.avatar || [{ isLink: true, file: 'General/default-avatar.png' }]}
          multiple={false}
          onChange={files => onUploadAvatarSuccess(files)}
        />
      </Grid>
      <Grid item xl={8} lg={8} md={8} xs={12}>
        {/* Thông tin chủ nhà */}
        {editInfoHost
          ? (
            <Formik
              initialValues={infoHost}
              enableReinitialize
              validationSchema={validateSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
              }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xs={12}>
                      <Typography variant="h4">THÔNG TIN CÁ NHÂN</Typography>
                    </Grid>
                    {/* Họ và tên */}
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography variant="body1">Họ và tên</Typography>
                      <TextField
                        id="outlined-secondary"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size="small"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        error={errors.name && touched.name}
                        helperText={errors.name && touched.name && errors.name}
                      />
                    </Grid>
                    {/* Ngày sinh */}
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography variant="body1">Ngày sinh</Typography>
                      <DatePicker
                        size="small"
                        fullWidth
                        name="birthday"
                        value={values.birthday}
                        onChange={value => setFieldValue('birthday', value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {/* Số điện thoại */}
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography variant="body1">Số điện thoại</Typography>
                      <TextField
                        id="outlined-secondary"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size="small"
                        name="phone"
                        onChange={handleChange}
                        value={values.phone}
                        error={errors.phone && touched.phone}
                        helperText={errors.phone && touched.phone && errors.phone}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {/* Địa chỉ */}
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography variant="body1">Địa chỉ</Typography>
                      <TextField
                        id="outlined-secondary"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size="small"
                        name="address"
                        onChange={handleChange}
                        value={values.address}
                        error={errors.address && touched.address}
                        helperText={errors.address && touched.address && errors.address}
                      />
                    </Grid>
                    {/* Email */}
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography variant="body1">Email</Typography>
                      <TextField
                        id="outlined-secondary"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size="small"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        error={errors.email && touched.email}
                        helperText={errors.email && touched.email && errors.email}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {/* Tên ngân hàng */}
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography variant="body1">Tên ngân hàng</Typography>
                      <TextField
                        id="outlined-secondary"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size="small"
                        name="bankName"
                        onChange={handleChange}
                        value={values.bankName}
                        error={errors.bankName && touched.bankName}
                        helperText={errors.bankName && touched.bankName && errors.bankName}
                      />
                    </Grid>
                    {/* Tên chi nhánh */}
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography variant="body1">Tên chi nhánh</Typography>
                      <TextField
                        id="outlined-secondary"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size="small"
                        name="bankBranch"
                        onChange={handleChange}
                        value={values.bankBranch}
                        error={errors.bankBranch && touched.bankBranch}
                        helperText={errors.bankBranch && touched.bankBranch && errors.bankBranch}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {/* Tài khoản ngân hàng */}
                    <Grid item lg={12} md={12} xs={12}>
                      <Typography variant="body1">Tài khoản ngân hàng</Typography>
                      <TextField
                        id="outlined-secondary"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size="small"
                        name="bankAccountName"
                        onChange={handleChange}
                        value={values.bankAccountName}
                        error={errors.bankAccountName && touched.bankAccountName}
                        helperText={errors.bankAccountName && touched.bankAccountName && errors.bankAccountName}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xs={12}>
                      <Typography variant="h4">THIẾT LẬP TÀI KHOẢN</Typography>
                    </Grid>
                    {/* Tài khoản và Mật khẩu */}
                    <Grid container item lg={6} md={6} xs={12}>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="body1">Tên đăng nhập</Typography>
                        <TextField
                          id="outlined-secondary"
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          size="small"
                          type="text"
                          name="account"
                          onChange={handleChange}
                          value={values.account}
                          error={errors.account && touched.account}
                          helperText={errors.account && touched.account && errors.account}
                        />
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="body1">Mật khẩu</Typography>
                        <TextField
                          id="outlined-secondary"
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          type="password"
                          size="small"
                          name="password"
                          onChange={handleChange}
                          value={values.password || '******'}
                          error={errors.password && touched.password}
                          helperText={errors.password && touched.password && errors.password}
                          disabled
                        />
                      </Grid>
                    </Grid>
                    {/* Ghi chú */}
                    <Grid container item lg={5} md={5} xs={12} className="ml-2">
                      <Typography variant="body2">
                        <div>Bạn có thể đăng nhập quản lý đơn bằng 2 cách:</div>
                        <ul>
                          <li>Cách 1: Email (chủ nhà) + mật khẩu</li>
                          <li>Cách 2: Tên đăng nhập + mật khẩu (trường hợp có nhiều nhân viên, bạn nên chia sẻ tên đăng nhập)</li>
                        </ul>
                      </Typography>
                      <Typography variant="body2">
                      Để chỉnh sửa các thông tin về chủ nhà, tài khoản thanh toán, mật khẩu,... bạn cần nhập mã OTP được gửi về số điện thoại 1 (chủ nhà) để tiếp tục.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        className="mt-3 mb-4 w-100"
                        style={{ backgroundColor: 'rgb(65 204 120)', color: '#ffffff' }}
                        onClick={() => setEditInfoHost(true)}
                      >
                      Cập nhật
                      </Button>
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <Button
                        variant="contained"
                        className="mt-3 mb-4 w-100"
                        style={{ backgroundColor: 'white' }}
                        onClick={() => setEditInfoHost(false)}
                      >
                      Thoát
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )
           }
            </Formik>
          ) : (
            <Grid container spacing={2}>
              {/* Username */}
              <Grid container item xs={12}>
                <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Họ tên</span></Grid>
                <Grid item xl={9} lg={8} md={7} xs={8}>
                  <span className={classes.infoLabel}>
                    {infoHost.name ? infoHost.name : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                  </span>
                </Grid>
              </Grid>
              {/* Email */}
              <Grid container item xs={12}>
                <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Email</span></Grid>
                <Grid item xl={9} lg={8} md={7} xs={8}>
                  <span className={classes.infoLabel}>
                    {infoHost.email ? infoHost.email : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                  </span>
                </Grid>
              </Grid>
              {/* Tài khoản */}
              <Grid container item xs={12}>
                <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Tài khoản</span></Grid>
                <Grid item xl={9} lg={8} md={7} xs={8}>
                  <span className={classes.infoLabel}>
                    {infoHost.account ? infoHost.account : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                  </span>
                </Grid>
              </Grid>
              {/* Password */}
              <Grid container item xs={12}>
                <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Mật khẩu</span></Grid>
                <Grid item xl={9} lg={8} md={7} xs={8}>
                  <span className={classes.infoLabel}>
                    {infoHost.password || '*******'}
                  </span>
                </Grid>
              </Grid>
              {/* Phone */}
              <Grid container item xs={12}>
                <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Số điện thoại</span></Grid>
                <Grid item xl={9} lg={8} md={7} xs={8}>
                  <span className={classes.infoLabel}>
                    {infoHost.phone ? infoHost.phone : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                  </span>
                </Grid>
              </Grid>
              {/* Birthday */}
              <Grid container item xs={12}>
                <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Ngày sinh</span></Grid>
                <Grid item xl={9} lg={8} md={7} xs={8}>
                  {infoHost.birthday
                    ? moment(infoHost.birthday).format('DD/MM/YYYY')
                    : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                </Grid>
              </Grid>
              {/* Address */}
              <Grid container item xs={12}>
                <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Địa chỉ</span></Grid>
                <Grid item xl={9} lg={8} md={7} xs={8}>
                  <span className={classes.infoLabel}>
                    {infoHost.address ? infoHost.address : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                  </span>
                </Grid>
              </Grid>
              {/* Số tài khoản */}
              <Grid container item xs={12}>
                <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Số tài khoản</span></Grid>
                <Grid item xl={9} lg={8} md={7} xs={8}>
                  <span className={classes.infoLabel}>
                    {infoHost.bankAccountNumber ? infoHost.bankAccountNumber : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                  </span>
                </Grid>
              </Grid>
              {/* Bank Account */}
              <Grid container item xs={12}>
                <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Chủ tài khoản</span></Grid>
                <Grid item xl={9} lg={8} md={7} xs={8}>
                  <span className={classes.infoLabel}>
                    {infoHost.bankAccountName ? infoHost.bankAccountName : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                  </span>
                </Grid>
              </Grid>
              {/* Bank Name */}
              <Grid container item xs={12}>
                <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Ngân hàng</span></Grid>
                <Grid item xl={9} lg={8} md={7} xs={8}>
                  <span className={classes.infoLabel}>
                    {infoHost.bankName ? infoHost.bankName : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                  </span>
                </Grid>
              </Grid>
              {/* Branch Name */}
              <Grid container item xs={12}>
                <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Chi nhánh</span></Grid>
                <Grid item xl={9} lg={8} md={7} xs={8}>
                  <span className={classes.infoLabel}>
                    {infoHost.bankBranch ? infoHost.bankBranch : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                  </span>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Button
                  variant="contained"
                  className="mb-4"
                  style={{ backgroundColor: '#432300', color: '#ffffff' }}
                  onClick={() => setEditInfoHost(true)}
                >
                Cập nhật thông tin
                </Button>
              </Grid>
            </Grid>
          )
        }
        {/* Thông tin quản lý */}
        {editInfoManager
          ? (
            <Formik
              initialValues={infoHost}
              enableReinitialize
          // validationSchema={validateSchema}
              onSubmit={onSubmit}
            >
              {({
                touched,
                errors,
                values,
                handleChange,
              }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xs={12}>
                      <Typography variant="h4">THÔNG TIN NGƯỜI QUẢN LÝ CĂN HỘ</Typography>
                    </Grid>
                    {/* Họ và tên */}
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography variant="body1">Họ và tên</Typography>
                      <TextField
                        id="outlined-secondary"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size="small"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        error={errors.name && touched.name}
                        helperText={errors.name && touched.name && errors.name}
                      />
                    </Grid>
                    {/* Email */}
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography variant="body1">Email (Nhận thông báo đơn đặt phòng)</Typography>
                      <TextField
                        id="outlined-secondary"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size="small"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        error={errors.email && touched.email}
                        helperText={errors.email && touched.email && errors.email}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography variant="body1">Số điện thoại</Typography>
                      <TextField
                        id="outlined-secondary"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size="small"
                        name="phone"
                        onChange={handleChange}
                        value={values.phone}
                        error={errors.phone && touched.phone}
                        helperText={errors.phone && touched.phone && errors.phone}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        className="mt-3 mb-4 w-100"
                        style={{ backgroundColor: 'rgb(65 204 120)', color: '#ffffff' }}
                        onClick={() => setEditInfoManager(true)}
                      >
                        Cập nhật
                      </Button>
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <Button
                        variant="contained"
                        className="mt-3 mb-4 w-100"
                        style={{ backgroundColor: 'white' }}
                        onClick={() => setEditInfoManager(false)}
                      >
                        Thoát
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          )
          : (
            <Grid container spacing={2}>
              {/* Name Manager */}
              <Grid container item xs={12}>
                <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Người quản lý</span></Grid>
                <Grid item xl={9} lg={8} md={7} xs={8}>
                  <span className={classes.infoLabel}>
                    {infoHost.name ? infoHost.name : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                  </span>
                </Grid>
              </Grid>
              {/* Email */}
              <Grid container item xs={12}>
                <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Email</span></Grid>
                <Grid item xl={9} lg={8} md={7} xs={8}>
                  <span className={classes.infoLabel}>
                    {infoHost.email ? infoHost.email : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                  </span>
                </Grid>
              </Grid>
              {/* Phone */}
              <Grid container item xs={12}>
                <Grid item xl={3} lg={4} md={5} xs={4}><span className={classes.infoLabel}>Phone</span></Grid>
                <Grid item xl={9} lg={8} md={7} xs={8}>
                  <span className={classes.infoLabel}>
                    {infoHost.phone ? infoHost.phone : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                  </span>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#432300', color: '#ffffff' }}
                  onClick={() => setEditInfoManager(true)}
                >
                Cập nhật thông tin
                </Button>
              </Grid>
            </Grid>
          )
        }
        <br/>
        {/* Thông tin chính sách */}
        <Typography variant='h3'>Chính sách</Typography>
        {editPolicy
          ? (
            <Formik
              initialValues={infoHost}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {({
                values,
                handleChange,
                setFieldValue,
              }) => (
                <Form>
                  <Grid container className={classes.infoLabel}>Chính sách hủy</Grid>
                  <FormControl component="fieldset" className="mb-2">
                    {/* Chính sách */}
                    <RadioGroup
                      aria-label="policy"
                      name="policy"
                      value={values.policy}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="2home"
                        control={<Radio />}
                        label={<Typography variant="body1">Sử dụng chính sách hủy/chỉnh sửa 2home</Typography>}
                      />
                      <div className="mb-1" style={{fontSize: '90%'}}>
                        Hoàn lại 100% số tiền thuê căn hộ nếu hủy trước từ 2 ngày (48 tiếng) đến 4 ngày so với thời gian nhận phòng đã đặt.
                      </div>
                      <div className="mb-1" style={{fontSize: '90%'}}>
                        Không được hoàn nếu hủy trong vòng 48 tiếng trước thời gian nhận phòng đã đặt hoặc khách hàng không tới nhận phòng.
                      </div>
                      <FormControlLabel
                        value="owner"
                        control={<Radio />}
                        label={<Typography variant="body1">Sử dụng chính sách hủy/chỉnh sửa riêng của chủ nhà</Typography>}
                      />
                      <div>
                        <span style={{fontSize: '90%'}}>Hoàn lại 100% tiền thuê căn hộ khi báo trước</span>
                        <TextField
                          id="day"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            style: {
                              paddingTop: 5,
                              paddingBottom: 5,
                            },
                          }}
                          variant="outlined"
                          size="small"
                          style={{ width: 50, marginLeft: 5, marginRight: 5 }}
                          name="policyDay"
                          onChange={handleChange}
                          value={values.policyDay}
                        />
                        <span style={{fontSize: '90%'}}>ngày nhận phòng đã đặt. Không hoàn lại tiền nếu báo muộn hơn số ngày này</span>
                      </div>
                      <div style={{fontSize: '90%'}}>
                        <div><b>Lưu ý:</b></div>
                        <ul>
                          <li>Điền 0 nếu LUÔN HOÀN TIỀN khi khách huỷ đơn</li>
                          <li>Điền -1 nếu KHÔNG HOÀN TIỀN khi khách huỷ đơn</li>
                        </ul>
                      </div>
                    </RadioGroup>
                    {/* Lưu ý */}
                    <Grid container style={{fontSize: '90%'}}>
                      <h6><b>Lưu ý về chỉnh sửa</b></h6>
                      <div>
                        Khi khách hàng yêu cầu chỉnh sửa các đơn đã thanh toán: tùy thời điểm chỉnh sửa, áp dụng theo chính sách hủy,
                        khách hàng có thể được giữ lại 100% - 0% số tiền đã thanh toán. Khách hàng cần thanh toán thêm hoặc được hoàn
                        tiền tùy vào chênh lệch giữa giá trị đơn mới và số dư khả dụng từ đơn cũ.
                      </div>
                      <div>
                        Nếu đơn vẫn ở trạng thái
                        {' '}
                        <Chip
                          size="small"
                          style={{
                            color: 'white',
                            fontSize: 12,
                            fontWeight: '900',
                            backgroundColor: '#ffa854',
                          }}
                          label="Chờ xác nhận chỉnh sửa"
                        />
                        {' '}
                        hoặc
                        {' '}
                        <Chip
                          size="small"
                          style={{
                            color: 'white',
                            fontSize: 12,
                            fontWeight: '900',
                            backgroundColor: '#ff1744',
                          }}
                          label="Chờ thanh toán chỉnh sửa"
                        />
                        {' '}
                        thì đơn đặt phòng cũ (khung giờ cũ) vẫn có hiệu lực.
                      </div>
                      <div>
                        Chỉ khi khách hàng thanh toán thêm thành công hoặc được hoàn tiền thành công,
                        đơn chuyển trạng thái
                        {' '}
                        <Chip
                          size="small"
                          style={{
                            color: 'white',
                            fontSize: 12,
                            fontWeight: '900',
                            backgroundColor: '#00b0ff',
                          }}
                          label="Đã thanh toán chỉnh sửa"
                        />
                        {' '}
                        thì đơn đặt phòng mới (khung giờ mới) mới được ghi nhận.
                      </div>
                    </Grid>
                  </FormControl>
                  {/* Đặt cọc */}
                  <Grid container className={classes.infoLabel}>
                    <div>Chính sách cọc</div>
                  </Grid>
                  <Grid container lg={12} md={12} xs={12} className="mt-2 mb-3">
                    <Checkbox
                      checked={values.deposit}
                      onChange={e => setFieldValue('deposit', e.target.checked)}
                      style={{ padding: 0 }}
                    />
                    <Typography variant="h6" style={{ marginLeft: 10 }}>
                      Có thể thu tiền cọc từ khách hàng?
                      <Tooltip title={depositTooltip}><Help fontSize="small" /></Tooltip>
                    </Typography>
                  </Grid>
                  {
                    values.deposit
                      ? (
                        <>
                          {
                            depositInfo.map((info, index) => (
                              <Grid container lg={12} md={12} xs={12} className="px-2 py-3 border mb-3">
                                <Grid container item lg={12} md={12} xs={12}>
                                  {/* Số tiền thu cọc */}
                                  <Grid item lg={3} md={3} xs={12} className="mb-3">
                                    <CurrencyInput
                                      size="small"
                                      label="Số tiền"
                                      variant="outlined"
                                      fullWidth
                                      value={info.amount}
                                      onChange={(v) => {
                                        const clone = [...depositInfo]
                                        clone[index].amount = v
                                        onUpdateDepositInfo(clone, setFieldValue)
                                      }}
                                      placeholder="Nhập số tiền"
                                    />
                                  </Grid>
                                  {/* Mô tả cho lý do đặt cọc */}
                                  <Grid item lg={9} md={9} xs={12} className="mb-3">
                                    <TextField
                                      size="small"
                                      label="Thu cọc trong các trường hợp"
                                      variant="outlined"
                                      fullWidth
                                      value={info.description}
                                      onChange={(e) => {
                                        const clone = [...depositInfo]
                                        clone[index].description = e.target.value
                                        onUpdateDepositInfo(clone, setFieldValue)
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      placeholder="Số tiền cọc có thể thu, khách thuê qua đêm, khách ở dài ngày,..."
                                    />
                                  </Grid>
                                </Grid>
                                {/* Mô tả chi tiết phạt cọc */}
                                <Grid item lg={12} md={12} xs={12} className="mb-3">
                                  <RichEditor
                                    style={{ backgroundColor: 'white' }}
                                    label="Bảng giá quy định phạt các vi phạm"
                                    fullWidth
                                    value={info.price}
                                    onChange={(value) => {
                                      const clone = [...depositInfo]
                                      clone[index].price = value
                                      onUpdateDepositInfo(clone, setFieldValue)
                                    }}
                                    placeholder="Ví dụ: Hút thuốc trong căn hộ: 100.000VND, ..."
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                {/* Phòng áp dụng */}
                                <Grid item lg={12} md={12} xs={12} className="mb-3">
                                  <DepositRoom
                                    rooms={infoHost?.rooms?.filter(r => !depositInfo?.filter((item, i) => i !== index)?.map(item => item.rooms)?.flat(1)?.includes(r.id))}
                                    defaultValue={info.rooms}
                                    onApplyRoom={(sltRooms) => {
                                      const clone = [...depositInfo]
                                      clone[index].rooms = sltRooms
                                      onUpdateDepositInfo(clone, setFieldValue)
                                    }}
                                  />
                                </Grid>
                                {/* Remove button */}
                                <Grid container item lg={12} md={12} xs={12}>
                                  <Button className="ml-auto" variant="outlined" onClick={() => onDeleteDepositInfo(index, setFieldValue)}>
                                    <Delete />
                                    Xoá điều kiện
                                  </Button>
                                </Grid>
                              </Grid>
                            ))
                          }
                          <Grid item lg={12} md={12} className="mt-2">
                            <Button variant="contained" onClick={() => onAddDepositInfo(setFieldValue)}>
                              <Add />
                              Thêm điều kiện cọc
                            </Button>
                          </Grid>
                        </>
                      )
                      : null
                  }
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        className="mt-3 mb-4 w-100"
                        style={{ backgroundColor: 'rgb(65 204 120)', color: '#ffffff' }}
                        onClick={() => setEditPolicy(true)}
                      >
                      Cập nhật
                      </Button>
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <Button
                        variant="contained"
                        className="mt-3 mb-4 w-100"
                        onClick={() => {
                          setEditPolicy(false)
                        }}
                      >
                      Thoát
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          )
          : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={classes.infoLabel}>Chính sách hủy</div>
                {infoHost?.policy === '2home'
                  ? (
                    <div><Link style={{ cursor: 'pointer', color: '#bcaa89' }}>Chính sách hủy phòng của 2home</Link></div>
                  )
                  : (
                    <div>
                      <Link style={{ cursor: 'pointer', color: '#bcaa89' }}>Sử dụng chính sách hủy phòng của chủ nhà</Link>
                      <div>
                        {infoHost.policyDay > 0
                          ? `Hoàn lại 100% tiền thuê căn hộ khi báo trước ít nhất ${infoHost.policyDay} ngày nhận phòng đã đặt. Không hoàn lại tiền nếu báo muộn hơn số ngày này.`
                          : infoHost.policyDay === 0
                            ? 'Luôn hoàn tiền khi khách báo huỷ đơn đặt phòng'
                            : 'Không hoàn tiền khi khách báo huỷ đơn đặt phòng'}
                      </div>
                    </div>
                  )
                }
              </Grid>
              <br />
              <Grid item xs={12}>
                <Grid container className={classes.infoLabel}>
                  <div>Đặt cọc</div>
                </Grid>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {infoHost?.deposit
                    ? 'Thu tiền cọc từ khách hàng khi nhận phòng'
                    : 'Không thu tiền cọc từ khách hàng khi nhận phòng'
                  }
                </Typography>
                {
                  infoHost?.deposit
                    ? infoHost?.depositInfo?.map((info, index) => (
                      <Grid item xs={12}>
                        <Typography>{`${index + 1}. Điều kiện cọc ${index + 1}:`}</Typography>
                        <Grid item lg={12} md={12}>
                          <Typography>{` - Tiền cọc: ${formatPrice(info.amount)} VND`}</Typography>
                          <Typography>{` - Điều kiện phạt: ${info.description}`}</Typography>
                          <Typography>{' - Chi tiết phạt:'}</Typography>
                          <Typography variant="body2" className="pl-4" style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: info.price }} />
                          <Typography>{` - Áp dụng cho phòng: ${infoHost?.rooms?.filter(r => info.rooms.includes(r.id))?.map(r => `${r.name} (${r.code})`)?.join(', ')}`}</Typography>
                        </Grid>
                      </Grid>
                    ))
                    : null
                }
              </Grid>
              <br />
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  className="mb-5"
                  style={{ backgroundColor: '#432300', color: '#ffffff' }}
                  onClick={() => setEditPolicy(true)}
                >
                Thay đổi chính sách
                </Button>
              </Grid>
            </Grid>
          )
        }
      </Grid>
    </Grid>
  )
}

Account.propTypes = {
  // data: PropTypes.shape(),
  update: PropTypes.func,
}

export default Account
