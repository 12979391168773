import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import CustomerInfo from './CustomerInfo'
import ReservationsList from './ReservationsList'
import PrepaidPackageList from './PrepaidPackageList'

const useStyles = makeStyles(() => ({
  titleAlign: {
    marginTop: 10,
    marginBottom: 10,
  },
}))

const CustomerForm = (props) => {
  const { history, id, action } = props
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const onCustomerInfoUpdate = (type) => {
    switch (type) {
      case 'success':
        enqueueSnackbar('Cập nhật thông tin khách hàng thành công!', { variant: type })
        break
      case 'error':
        enqueueSnackbar('Cập nhật thông tin khách hàng không thành công!', { variant: type })
        break
      case 'info':
        enqueueSnackbar('Thêm mới khách hàng thành công!', { variant: type })
        break
      default:
    }
  }

  return (
    <Grid container>
      {/*  Customer info */}
      <Grid item lg={4} md={12} style={{ padding: 5 }}>
        <CustomerInfo
          action={action}
          history={history}
          id={id}
          onUpdate={onCustomerInfoUpdate}
        />
      </Grid>
      {/* Reservation and Prepaid package */}
      <Grid item lg={8} md={12} style={{ padding: 5 }}>
        <Typography variant="h4" className={classes.titleAlign}>
          Đơn đặt phòng
        </Typography>
        <ReservationsList customer={id} />
        <br />
        <Typography variant="h4" className={classes.titleAlign}>
          Gói đã mua
        </Typography>
        <PrepaidPackageList customer={id} />
      </Grid>
    </Grid>
  )
}

CustomerForm.propTypes = {
  history: PropTypes.shape(),
  id: PropTypes.string,
  action: PropTypes.string,
}

export default CustomerForm
