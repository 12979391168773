import axios from 'axios'
import { RESERVATION_TABLE_LIST } from 'config'
import * as ENDPOINT from './url'

export const find = async (query) => {
  try {
    const resp = await axios.get(
      ENDPOINT.FETCH_RESERVATION({
        ...query,
        page: query.page || 1,
        limit: query.limit || RESERVATION_TABLE_LIST,
      }),
    )
    return resp.data
  } catch (e) {
    throw e
  }
}

export const findOne = async (id) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_RESERVATION_BY_ID(id))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const update = async (id, data) => {
  try {
    const resp = await axios.patch(ENDPOINT.UPDATE_RESERVATION(id), { ...data })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const create = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.CREATE_RESERVATION, data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const recalculate = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.RECALCULATE_RESERVATION, data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const updateStatus = async (id, data) => {
  try {
    const resp = await axios.post(ENDPOINT.UPDATE_RESERVATION_STATUS(id), data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const pay = async (id, paymentType) => {
  try {
    const resp = await axios.post(ENDPOINT.PAY_RESERVATION(id), {
      paymentType,
    })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const reject = async (id, data) => {
  try {
    const resp = await axios.post(ENDPOINT.REJECT_RESERVATION(id), data || {})
    return resp.data
  } catch (e) {
    throw e
  }
}

export const confirm = async (id) => {
  try {
    const resp = await axios.post(ENDPOINT.CONFIRM_RESERVATION(id))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const undo = async (id) => {
  try {
    const resp = await axios.post(ENDPOINT.UNDO_RESERVATION(id))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const warning = async (id, mode, data) => {
  try {
    const resp = await axios.get(ENDPOINT.WARNING(id, mode), {
      params: data || {},
    })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const punishDeposit = async (id, info) => {
  try {
    const resp = await axios.post(ENDPOINT.PUNISH_DEPOSIT_RESERVATION(id), info)
    return resp.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const agreePunishDeposit = async (id, isAgree) => {
  try {
    const resp = await axios.post(ENDPOINT.AGREE_PUNISH_DEPOSIT(id), { isAgree })
    return resp.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const releaseDeposit = async (id) => {
  try {
    const resp = await axios.post(ENDPOINT.RELEASE_DEPOSIT(id), {})
    return resp.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const rejectCheckout = async (id) => {
  try {
    const resp = await axios.post(ENDPOINT.REJECT_CHECKOUT(id), {})
    return resp.data
  } catch (e) {
    throw e
  }
}

export const confirmCheckoutPoint = async (id) => {
  try {
    const resp = await axios.post(ENDPOINT.CONFIRM_CHECKOUT_POINT(id), {})
    return resp.data
  } catch (e) {
    throw e
  }
}
