import moment from 'moment'

export const copy = (code, cb) => {
  const aux = document.createElement('input')
  aux.setAttribute('value', code)
  document.body.appendChild(aux)
  aux.select()
  document.execCommand('copy')
  document.body.removeChild(aux)
  cb()
}

export const shiftArray = (arr, src, dst) => {
  if (dst >= arr.length) {
    let k = dst - arr.length + 1
    // eslint-disable-next-line no-plusplus
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(dst, 0, arr.splice(src, 1)[0])
  return arr // for testing
}

export const getCalculatedArray = (orderPrice) => {
  if (!orderPrice) return []
  let total = 0
  const arr = []
  const {
    byHour,
    hour,
    day,
    night,
    addGuestFee,
    cleaningFee,
    discountWeek,
    discountMonth,
  } = orderPrice
  if (night && night.fee) {
    arr.push({
      title: 'Ưu đãi đêm',
      price: night.fee,
    })
    total += night.fee
  } else if (byHour && hour) {
    // Tinh theo gio
    const {
      firstHour, firstFee,
      extraHour, extraFee,
      nightFee, special,
    } = hour
    if (firstHour && firstFee) {
      arr.push({
        title: `${firstHour} giờ đầu${special ? ` (${special})` : ''}`,
        price: firstFee,
      })
      total += firstFee
    }
    if (extraHour && extraFee) {
      arr.push({ title: `Thêm ${extraHour} giờ`, price: extraFee })
      total += extraFee
    }
    if (nightFee) {
      arr.push({ title: 'Giờ buổi tối', price: nightFee })
      total += nightFee
    }
  } else if (day) {
    // Tinh theo ngay
    const {
      normalDays,
      normalDayFee,
      extraBeforeHour,
      extraBeforeFee,
      extraAfterHour,
      extraAfterFee,
      weekendDays,
      specialDays,
    } = day
    // Ngày thường
    if (normalDays && normalDayFee) {
      arr.push({
        title: `${normalDays} ngày thường`,
        price: normalDayFee,
      })
      total += normalDayFee
    }
    // Checkin sớm ngày thường
    if (extraBeforeHour && extraBeforeFee) {
      const note = []
      note.push({
        title: 'Mẹo: Bạn nên chọn giờ nhận phòng muộn hơn để không bị tính phí thêm giờ',
      })
      arr.push({
        title: `Thêm ${extraBeforeHour} giờ trước (ngày thường) *`,
        price: extraBeforeFee,
        note,
      })
      total += extraBeforeFee
    }
    // Checkout muộn ngày thường
    if (extraAfterHour && extraAfterFee) {
      const note = []
      note.push({
        title: 'Mẹo: Bạn nên chọn giờ trả phòng sớm hơn để không bị tính phí thêm giờ',
      })
      arr.push({
        title: `Thêm ${extraAfterHour} giờ sau (ngày thường) *`,
        price: extraAfterFee,
        note,
      })
      total += extraAfterFee
    }
    // Ngày cuối tuần
    if (weekendDays && Object.keys(weekendDays).length > 0) {
      Object.keys(weekendDays).sort().forEach((x) => {
        if (weekendDays[x].dayBaseFee) {
          arr.push({
            title: moment(x, 'DD-MM').format('dddd, L')?.charAt(0)?.toUpperCase() + moment(x, 'DD-MM').format('dddd, L')?.slice(1),
            price: weekendDays[x].dayBaseFee,
          })
          total += weekendDays[x].dayBaseFee
        }
      })
    }
    // Checkin sớm ngày cuối tuần
    if (weekendDays && Object.keys(weekendDays).length > 0) {
      Object.keys(weekendDays).sort().forEach((x) => {
        if (weekendDays[x].extraBeforeFee) {
          arr.push({
            title: `Thêm ${weekendDays[x].extraBeforeHour} giờ trước (${moment(x, 'DD-MM').format('dddd, L')})`,
            price: weekendDays[x].extraBeforeFee,
            note: [{ title: 'Mẹo: Bạn nên chọn giờ nhận phòng muộn hơn để không bị tính phí thêm giờ' }],
          })
        }
        total += weekendDays[x].extraBeforeFee
      })
    }
    // Checkout muộn ngày cuối tuần
    if (weekendDays && Object.keys(weekendDays).length > 0) {
      Object.keys(weekendDays).sort().forEach((x) => {
        if (weekendDays[x].extraAfterFee) {
          arr.push({
            title: `Thêm ${weekendDays[x].extraAfterHour} giờ sau (${moment(x, 'DD-MM').format('dddd, L')})`,
            price: weekendDays[x].extraAfterFee,
            note: [{ title: 'Mẹo: Bạn nên chọn giờ trả phòng sớm hơn để không bị tính phí thêm giờ' }],
          })
          total += weekendDays[x].extraAfterFee
        }
      })
    }
    // Ngày đặc biệt
    if (specialDays && Object.keys(specialDays).length > 0) {
      Object.keys(specialDays).forEach((x) => {
        if (specialDays[x].dayBaseFee) {
          arr.push({
            title: moment(x, 'DD-MM').format('L'),
            price: specialDays[x].dayBaseFee,
          })
          total += specialDays[x].dayBaseFee
        }
      })
    }
    // Checkin sớm ngày đặc biệt
    if (specialDays && Object.keys(specialDays).length > 0) {
      Object.keys(specialDays).sort().forEach((x) => {
        if (specialDays[x].extraBeforeFee) {
          arr.push({
            title: `Thêm ${specialDays[x].extraBeforeHour} giờ trước (${moment(x, 'DD-MM').format('dddd, L')})`,
            price: specialDays[x].extraBeforeFee,
            note: [{ title: 'Mẹo: Bạn nên chọn giờ nhận phòng muộn hơn để không bị tính phí thêm giờ' }],
          })
          total += specialDays[x].extraBeforeFee
        }
      })
    }
    // Checkout muộn ngày đặc biệt
    if (specialDays && Object.keys(specialDays).length > 0) {
      Object.keys(specialDays).sort().forEach((x) => {
        if (specialDays[x].extraAfterFee) {
          arr.push({
            title: `Thêm ${specialDays[x].extraAfterHour} giờ sau (${moment(x, 'DD-MM').format('dddd, L')})`,
            price: specialDays[x].extraAfterFee,
            note: [{ title: 'Mẹo: Bạn nên chọn giờ trả phòng sớm hơn để không bị tính phí thêm giờ' }],
          })
          total += specialDays[x].extraAfterFee
        }
      })
    }
  }
  if (addGuestFee && addGuestFee.addGuestNumber && addGuestFee.total) {
    arr.push({
      title: `Thêm ${addGuestFee.addGuestNumber} người ${addGuestFee.days > 1 ? `(x${addGuestFee.days} ngày)` : ''}`,
      price: addGuestFee.total,
    })
    total += addGuestFee.total
  }
  if (cleaningFee) {
    arr.push({
      title: 'Dọn phòng',
      price: cleaningFee,
    })
    total += cleaningFee
  }
  if (discountWeek) {
    arr.push({
      title: `Giảm giá tuần (${discountWeek}%)`,
      price: (total * discountWeek / 100) * (-1),
    })
  }
  if (discountMonth) {
    arr.push({
      title: `Giảm giá tháng (${discountMonth}%)`,
      price: (total * discountMonth / 100) * (-1),
    })
  }
  return arr
}

export const diffTime = (sourceTime, targetTime, unit) => {
  let starttime = moment(sourceTime).set({ millisecond: 0 }).valueOf()
  let endtime = moment(targetTime).set({ millisecond: 0 }).valueOf()
  if (unit !== 'second') {
    starttime = moment(sourceTime).set({ second: 0, millisecond: 0 }).valueOf()
    endtime = moment(targetTime).set({ second: 0, millisecond: 0 }).valueOf()
  }
  switch (unit) {
    case 'second':
      return Math.round((endtime - starttime) / 1000)
    case 'minute':
      return Math.round((endtime - starttime) / (60 * 1000))
    case 'hour':
      return Math.round((endtime - starttime) / (60 * 60 * 1000))
    case 'day':
      return Math.round((endtime - starttime) / (24 * 60 * 60 * 1000))
    default:
      return 0
  }
}

export const validateJson = (str) => {
  if (!str) {
    return false
  }
  return /^[\],:{}\s]*$/.test(str.replace(/\\["\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))
}

export const getPaidCheckoutPoint = order => (order?.addCheckoutPoint || []).filter(item => item.status === 'paid')

export const getLastCheckout = (order) => {
  if (!order) {
    return null
  }
  let minDate = order.checkout
  const checkoutPoints = getPaidCheckoutPoint()
  if (checkoutPoints.length) {
    minDate = checkoutPoints[checkoutPoints.length - 1].checkout
  }
  return minDate
}
