import {
  Chip,
  Typography,
} from '@material-ui/core'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { formatPrice } from 'utils/formatter'
import { Table } from 'components'

const CouponTable = ({
  // classes,
  loading,
  coupon,
  meta,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const handlePageChange = (page) => {
    onPageChange(page)
  }

  const handleRowsPerPageChange = (limit) => {
    onRowsPerPageChange(limit)
  }

  const renderType = (status) => {
    switch (status) {
      case 'date_or_time':
        return 'Số lần hoặc thời gian'
      case 'date_range':
        return 'Thời gian'
      default: // time_limit
        return 'Số lần'
    }
  }

  const renderValue = (type, value) => {
    switch (type) {
      case 'money':
        return `${formatPrice(value)} VND`
      case 'percent':
        return `${value}%`
      default:
        return ''
    }
  }

  const renderApply = (customers, rooms, services, useFor) => {
    const components = []
    if (customers && customers.length) {
      components.push(<Typography variant="body2">{`${customers.length} khách hàng`}</Typography>)
    } else {
      components.push(<Typography variant="body2">Toàn bộ khách hàng</Typography>)
    }
    if (useFor === 'room') {
      if (rooms && rooms.length) {
        components.push(<Typography variant="body2">{`${rooms.length} phòng`}</Typography>)
      } else {
        components.push(<Typography variant="body2">Toàn bộ phòng</Typography>)
      }
    }
    if (useFor === 'service') {
      if (services && services.length) {
        components.push(<Typography variant="body2">{`${services.length} phòng`}</Typography>)
      } else {
        components.push(<Typography variant="body2">Toàn bộ dịch vụ</Typography>)
      }
    }
    return components
  }

  const renderStatus = (status) => {
    const content = {
      used: 'Đã sử dụng',
      valid: 'Còn hiệu lực',
      expired: 'Hết hạn',
    }
    const color = {
      used: '#ffa854',
      valid: '#00e676',
      expired: '#ff1744',
    }
    return (
      <Chip
        size="small"
        style={{
          color: 'white',
          fontSize: 12,
          fontWeight: '900',
          backgroundColor: color[status],
        }}
        label={content[status]}
      />
    )
  }

  return (
    <Table
      data={coupon || []}
      columns={[
        {
          Head: 'Mã khuyến mại',
          Cell: value => value || 'Không xác định',
          accessor: 'code',
          sortable: false,
        },
        {
          Head: 'Loại',
          Cell: value => renderType(value),
          accessor: 'type',
          sortable: false,
        },
        {
          Head: 'Giá trị',
          Cell: (value, origin) => renderValue(origin.valueType, value),
          accessor: 'value',
          sortable: false,
        },
        {
          Head: 'Hạn sử dụng',
          Cell: value => value ? moment(value).format('DD/MM/YYYY') : 'Không giới hạn',
          accessor: 'enddate',
          sortable: false,
        },
        {
          Head: 'Mô tả',
          Cell: value => <div dangerouslySetInnerHTML={{ __html: value }} />,
          accessor: 'description',
          sortable: false,
        },
        {
          Head: 'Áp dụng',
          Cell: (value, origin) => renderApply(origin.customers, origin.rooms, origin.services, value),
          accessor: 'useFor',
          sortable: false,
        },
        {
          Head: 'Trạng thái',
          Cell: value => renderStatus(value),
          accessor: 'status',
          sortable: false,
        },
      ]}
      loading={loading}
      page={meta?.page}
      limit={meta?.limit}
      total={meta?.total}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  )
}

CouponTable.propTypes = {
  // classes: PropTypes.shape(),
  loading: PropTypes.bool,
  coupon: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape(),
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
}

export default CouponTable
