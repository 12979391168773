import moment from 'moment'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import {
  useMediaQuery,
  Divider,
  Grid,
  Typography,
  Tooltip,
  Chip,
  Button,
  IconButton,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
// import FullCalendar from '@fullcalendar/react'
// import dayGridPlugin from '@fullcalendar/daygrid'
import * as orderApi from 'provider/reservation'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'
import {
  ArrowDownward,
  ArrowRight,
  Delete,
  Done,
  Refresh,
  Undo,
} from '@material-ui/icons'
import { Table } from 'components'
import { formatPrice } from 'utils/formatter'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import AppConfig from 'config'
import { last } from 'underscore'
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from 'react-calendar-timeline'
import Filters from './Filters'
import RejectDialog from './Dialog/Reject'
import ConfirmDialog from './Dialog/Confirm'
import InstructionDialog from './Dialog/Instruction'
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))

const Dashboard = ({
  history,
  location,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })
  const [mode, setMode] = useState('calendar')
  const [fltData, setFltData] = useState({ active: 0 })
  const [sltOrder, setSltOrder] = useState(null)
  const [orders, setOrders] = useState([])
  const [paidOrders, setPaidOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState({ page: 1, limit: 10, total: 0 })
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [showOrderInstruction, setShowOrderInstruction] = useState(false)

  const fetchOrders = async (data) => {
    const query = getFiltersFromUrl(location.search)
    try {
      setLoading(true)
      const resp = await orderApi.find({
        ...query,
        page: 1,
        limit: 5,
        active: 0,
        ...(data || {}),
      })
      setLoading(false)
      setFltData({
        ...query,
        ...(data || {}),
      })
      const {
        data: orderData,
        ...rest
      } = resp
      setOrders(orderData || [])
      setMeta(rest)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const fetchPaidOrders = async () => {
    try {
      setLoading(true)
      const resp = await orderApi.find({
        page: 1,
        limit: 1000,
        active: 0,
      })
      setLoading(false)
      setPaidOrders(resp?.data || [])
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handlePageChange = (page) => {
    fetchOrders({ page })
  }

  const handleRowsPerPageChange = (limit) => {
    fetchOrders({ limit })
  }

  const updateUrl = (query) => {
    const url = `${location.pathname}?${applyFiltersToUrl(query)}`
    history.push(url, { replace: false })
  }

  useEffect(() => {
    try {
      fetchOrders()
      fetchPaidOrders()
    } catch (e) {
      fetchOrders({})
    }
  }, [])

  const renderRoomType = (type) => {
    switch (type) {
      case 'request':
        return 'Yêu cầu xác nhận'
      case 'instant':
        return 'Đặt nhanh'
      default:
        return ''
    }
  }

  const getPrice = (order) => {
    let total = order.totalPrice
    if (order.addCheckoutPoint && order.addCheckoutPoint.length) {
      order.addCheckoutPoint.forEach((o) => {
        total += o.value
      })
    }
    return total
  }

  const getCheckout = (order) => {
    let time = order.checkout
    if (order.addCheckoutPoint && order.addCheckoutPoint.length) {
      time = order.addCheckoutPoint[order.addCheckoutPoint.length - 1].checkout
    }
    return time
  }

  const renderStatus = (status, origin) => {
    const {
      refundRoom,
      refundService,
      paymentHistory,
      addCheckoutPoint,
    } = origin
    const lastCheckoutPoint = last(addCheckoutPoint || [])
    switch (status) {
      case 'reject':
      case 'reject-by-host':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: '0.7rem',
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Huỷ"
          />
        )
      case 'reject-waiting':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: '0.7rem',
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Chờ cung cấp thông tin"
          />
        )
      case 'reject-norefund':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: '0.7rem',
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Huỷ không hoàn tiền"
          />
        )
      case 'reject-refund':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: '0.7rem',
              fontWeight: '900',
              backgroundColor: '#35a211',
            }}
            label="Huỷ hoàn tiền"
          />
        )
      case 'reject-processing':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: '0.7rem',
              fontWeight: '900',
              backgroundColor: '#ffc107',
            }}
            label="Huỷ chờ hoàn tiền"
          />
        )
      case 'processing':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: '0.7rem',
              fontWeight: '900',
              backgroundColor: '#ffa854',
            }}
            label="Chờ xác nhận"
          />
        )
      case 'processing-pay-extra':
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: '0.7rem',
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
              &nbsp;
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: '0.7rem',
                fontWeight: '900',
                backgroundColor: '#ffa854',
              }}
              label="Chờ xác nhận sau chỉnh sửa"
            />
          </>
        )
      case 'confirmed-pay-extra':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: '0.7rem',
              fontWeight: '900',
              backgroundColor: '#ff1744',
            }}
            label="Chờ thanh toán chỉnh sửa"
          />
        )
      case 'processing-refund-in-update':
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: '0.7rem',
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
              &nbsp;
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: '0.7rem',
                fontWeight: '900',
                backgroundColor: '#52c41a',
              }}
              label={refundRoom + refundService > 0 ? 'Chờ hoàn tiền chỉnh sửa' : 'Chờ xác nhận'}
            />
          </>
        )
      case 'waiting-refund-in-update':
      case 'host-confirm-refund-update':
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: '0.7rem',
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
              &nbsp;
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: '0.7rem',
                fontWeight: '900',
                backgroundColor: '#52c41a',
              }}
              label="Chờ hoàn tiền chỉnh sửa"
            />
          </>
        )
      case 'add-checkout-processing':
        if (lastCheckoutPoint?.checkout < moment().valueOf()) {
          return (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#3e51b5',
              }}
              label="Hoàn thành"
            />
          )
        }
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: '0.7rem',
                fontWeight: '900',
                backgroundColor: '#00e676',
              }}
              label="Đang sử dụng"
            />
            {lastCheckoutPoint?.status === 'processing' && (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#41CC78',
              }}
              label="Chờ xác nhận ở thêm"
            />
            )}
            {lastCheckoutPoint?.status === 'confirmed' && (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#41CC78',
              }}
              label="Chờ thanh toán ở thêm"
            />
            )}
          </>
        )
      case 'confirmed':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: '0.7rem',
              fontWeight: '900',
              backgroundColor: '#ff1744',
            }}
            label="Chờ thanh toán"
          />
        )
      case 'paid':
        if (paymentHistory?.length > 1 && refundRoom + refundService <= 0) {
          return (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: '0.7rem',
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán chỉnh sửa"
            />
          )
        }
        if (paymentHistory?.length > 1 && refundRoom + refundService > 0) {
          return (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: '0.7rem',
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã hoàn tiền chỉnh sửa"
            />
          )
        }
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: '0.7rem',
              fontWeight: '900',
              backgroundColor: '#00b0ff',
            }}
            label="Đã thanh toán"
          />
        )
      case 'using':
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: '0.7rem',
                fontWeight: '900',
                backgroundColor: origin.addCheckoutPoint && origin.addCheckoutPoint.length ? '#0052cc' : '#00e676',
              }}
              label={origin.addCheckoutPoint && origin.addCheckoutPoint.length ? 'Đang ở thêm' : 'Đang sử dụng'}
            />
            &nbsp;
            {
              addCheckoutPoint && addCheckoutPoint.length
                ? (
                  <Chip
                    size="small"
                    style={{
                      color: 'white',
                      fontSize: '0.7rem',
                      fontWeight: '900',
                      backgroundColor: '#0052cc',
                    }}
                    label="Đã thanh toán chỉnh sửa"
                  />
                )
                : null
            }
          </>
        )
      case 'completed':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: '0.7rem',
              fontWeight: '900',
              backgroundColor: '#3e51b5',
            }}
            label="Hoàn thành"
          />
        )
      case 'lock':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: '0.7rem',
              fontWeight: '900',
              backgroundColor: '#9e9e9e',
            }}
            label="Khoá phòng"
          />
        )
      case 'reject-lock':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: '0.7rem',
              fontWeight: '900',
              backgroundColor: '#9e9e9e',
            }}
            label="Huỷ khoá phòng"
          />
        )
      case 'expired':
      default:
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: '0.7rem',
              fontWeight: '900',
              backgroundColor: '#9e9e9e',
            }}
            label="Hết hạn"
          />
        )
    }
  }

  const filter = (data) => {
    const query = getFiltersFromUrl(location.search)
    setFltData(data)
    const {
      startdate,
      enddate,
      room,
    } = data
    fetchOrders({
      startdate: startdate || undefined,
      enddate: enddate || undefined,
      room: room || undefined,
    })
    updateUrl({
      ...query,
      startdate: startdate || undefined,
      enddate: enddate || undefined,
      room: room || undefined,
    })
  }

  const onRefresh = () => {
    const query = getFiltersFromUrl(location.search)
    try {
      fetchOrders(query)
    } catch (e) {
      fetchOrders({})
    }
  }

  const openConfirmOrderDialog = (order) => {
    setShowConfirmDialog(true)
    setSltOrder(order)
  }

  const closeConfirmOrderDialog = () => {
    setShowConfirmDialog(false)
    setSltOrder(null)
  }

  const confirmOrder = async () => {
    try {
      setLoading(true)
      await orderApi.confirm(sltOrder?.id)
      setLoading(false)
      closeConfirmOrderDialog()
      fetchOrders()
    } catch (e) {
      console.log(e)
    }
  }

  const openRejectOrderDialog = (order) => {
    setShowRejectDialog(true)
    setSltOrder(order)
  }

  const closeRejectOrderDialog = () => {
    setShowRejectDialog(false)
    setSltOrder(null)
  }

  const confirmRejectOrder = async () => {
    try {
      setLoading(true)
      await orderApi.reject(sltOrder?.id)
      setLoading(false)
      closeRejectOrderDialog()
      fetchOrders()
    } catch (e) {
      console.log(e)
    }
  }

  const renderAction = (status, order) => {
    switch (status) {
      case 'processing':
        return (
          <>
            {/* Xác nhận */}
            <Tooltip title="Xác nhận đơn đặt phòng">
              <IconButton className={classes.margin} onClick={() => openConfirmOrderDialog(order)}>
                <Done />
              </IconButton>
            </Tooltip>
            <Tooltip title="Huỷ đơn đặt phòng">
              <IconButton className={classes.margin} onClick={() => openRejectOrderDialog(order)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      case 'processing-pay-extra':
        return (
          <>
            <Tooltip title="Không đồng ý thay đổi đơn đặt phòng">
              <IconButton className={classes.margin} onClick={() => openConfirmOrderDialog(order)}>
                <Undo />
              </IconButton>
            </Tooltip>
            <Tooltip title="Xác nhận đơn đặt phòng">
              <IconButton className={classes.margin} onClick={() => openConfirmOrderDialog(order)}>
                <Done />
              </IconButton>
            </Tooltip>
          </>
        )
      case 'processing-refund-in-update':
      case 'waiting-refund-in-update':
      case 'confirmed-pay-extra':
        return (
          <>
            <Tooltip title="Không đồng ý thay đổi đơn đặt phòng">
              <IconButton className={classes.margin} onClick={() => openConfirmOrderDialog(order)}>
                <Undo />
              </IconButton>
            </Tooltip>
          </>
        )
      case 'confirmed':
      case 'paid':
        return (
          <Tooltip title="Huỷ đơn đặt phòng">
            <IconButton className={classes.margin} onClick={() => openRejectOrderDialog(order)}>
              <Delete />
            </IconButton>
          </Tooltip>
        )
      default:
        return null
    }
  }

  const getUpdatedOrderStatus = (status, origin) => {
    const {
      refundRoom,
      refundService,
      paymentHistory,
    } = origin
    switch (status) {
      case 'confirmed-pay-extra':
      case 'procesing-pay-extra':
        return (<Typography variant="body2"><i>Đơn cũ hiện vẫn có hiệu lực!</i></Typography>)
      case 'paid':
        if (paymentHistory?.length > 1 && refundRoom + refundService <= 0) {
          return (<Typography variant="body2"><i>Đơn đã chỉnh sửa thành công, kiểm tra lại!</i></Typography>)
        }
        if (paymentHistory?.length > 1 && refundRoom + refundService > 0) {
          return (<Typography variant="body2"><i>Đơn đã chỉnh sửa thành công, kiểm tra lại!</i></Typography>)
        }
        return null
      default:
        return null
    }
  }

  const changeMode = (value) => {
    setMode(value)
  }

  const convertOrderCalendar = () => {
    const convertOrders = []
    orders.forEach((o) => {
      convertOrders.push({
        id: o.id,
        group: o.room.id,
        title: o.code,
        start_time: o.checkin,
        end_time: o.checkout,
      })
    })
    return convertOrders
  }

  const convertRoomCalendar = () => {
    const convertRooms = []
    orders.forEach((o) => {
      if (!convertRooms.find(r => r.id === o.room.id)) {
        convertRooms.push({
          id: o.room.id,
          title: `(${o.room.code}) ${o.room.name}`,
        })
      }
    })
    return convertRooms
  }

  return (
    <>
      <RejectDialog
        open={showRejectDialog}
        order={sltOrder}
        onConfirm={confirmRejectOrder}
        onClose={closeRejectOrderDialog}
      />
      <ConfirmDialog
        open={showConfirmDialog}
        order={sltOrder}
        onConfirm={confirmOrder}
        onClose={closeConfirmOrderDialog}
      />
      <InstructionDialog
        open={showOrderInstruction}
        text={sltOrder?.room?.help}
        onClose={() => {
          setSltOrder(null)
          setShowOrderInstruction(false)
        }}
      />
      <div className={classes.root}>
        {/* Bộ lọc */}
        <Grid container spacing={2}>
          {/* Phòng */}
          <Grid item lg={3} md={3} xs={12}>
            <Filters.Room
              value={fltData.room}
              onApply={value => filter({ ...fltData, room: value })}
            />
          </Grid>
          {/* Checkin - Checkout */}
          <Grid item lg={5} md={5} xs={12}>
            <Filters.BookingDate
              startLabel="Nhận phòng"
              endLabel="Trả phòng"
              startdate={Number(fltData.startdate)}
              enddate={Number(fltData.enddate)}
              onApply={({ startdate, enddate }) => filter({ ...fltData, startdate, enddate })}
            />
          </Grid>
          {/* Thông tin lưu ý */}
          <Grid item lg={4} md={4} xs={12}>
            <Typography variant="body2">
              Bạn có khách từ nguồn khác? Vui lòng kiểm tra khung giờ để tránh khách thanh toán trùng lịch.
            </Typography>
            <Typography variant="body2">
              - Nếu có đơn 2home trạng thái CHỜ THANH TOÁN, bạn cần ấn HỦY.
            </Typography>
            <Typography variant="body2">
              - Nếu có đơn 2home trạng thái CHỜ THANH TOÁN SAU CHỈNH SỬA, bạn cần ấn HỦY YÊU CẦU CHỈNH SỬA
            </Typography>
          </Grid>
        </Grid>
        {/* Chế độ xem (Lịch/Danh sách) */}
        <Grid container spacing={2}>
          <Grid item lg={2} md={2} xs={6}>
            <Button
              variant={mode === 'calendar' ? 'outlined' : 'default'}
              fullWidth
              style={mode === 'calendar' ? { backgroundColor: 'white' } : {}}
              onClick={() => changeMode('calendar')}
            >
              Lịch
            </Button>
          </Grid>
          <Grid item lg={2} md={2} xs={6}>
            <Button
              variant={mode === 'list' ? 'outlined' : 'default'}
              fullWidth
              style={mode === 'list' ? { backgroundColor: 'white' } : {}}
              onClick={() => changeMode('list')}
            >
              Danh sách
            </Button>
          </Grid>
        </Grid>
        {/* Danh sách đơn đặt phòng */}
        <Grid container className="mt-4">
          {/* Danh sách đơn */}
          {
            mode === 'list'
              ? (
                <>
                  <Grid item lg={8} sm={8} xl={9} xs={12} className="pr-2">
                    <Grid container item lg={12} className="p-3" style={{ backgroundColor: 'white' }}>
                      <div className="d-flex mb-3 w-100">
                        <Typography variant="h4">
                          <span>{`Đơn đặt phòng mới (${orders.length} đơn)`}</span>
                        </Typography>
                        <Typography variant="h4" className="ml-auto" style={{ color: '#432300' }}>
                          <Refresh onClick={onRefresh} style={{ cursor: 'pointer' }} />
                        </Typography>
                      </div>
                      <Table
                        className="w-100"
                        data={orders}
                        columns={[
                          {
                            Head: 'Mã',
                            Cell: (value, origin) => (
                              <div className="d-flex align-items-center">
                                {
                          moment.duration(moment().diff(moment(origin.updatedAt))).asMinutes() > 15 && origin.status === 'processing'
                            ? <FiberManualRecordIcon fontSize="small" style={{ color: '#ffa854', marginRight: 8 }} />
                            : null
                        }
                                {
                      ['processing-refund-in-update', 'waiting-refund-in-update', 'host-confirm-refund-update', 'processing-pay-extra', 'confirmed-pay-extra'].includes(origin.status)
                        ? <FiberManualRecordIcon fontSize="small" style={{ color: 'red', marginRight: 8 }} />
                        : null
                      }
                                <RouterLink to={`/reservation/${value}/view`} target="_blank" className={classes.codeCell}>
                                  <Typography variant="body1">{value}</Typography>
                                  <Typography variant="body2">
                                    {moment(origin.createdAt).format('DD/MM/YYYY')}
                                  </Typography>
                                </RouterLink>
                              </div>
                            ),
                            accessor: 'code',
                            sortable: false,
                          },
                          {
                            Head: 'Phòng',
                            Cell: (value, origin) => (
                              <>
                                <a href={`${AppConfig.endpoint.customer}/phong-can-ho/${origin.room.code}`} target="_blank" rel="noopener noreferrer">
                                  <Tooltip title={value.name} aria-label={value.name}>
                                    <Typography variant="body1" className="text-truncate">{value.name}</Typography>
                                  </Tooltip>
                                </a>
                                <Typography variant="body2">
                                  {`Mã: ${value.code}`}
                                </Typography>
                                <Typography variant="body2">
                                  {renderRoomType(value.bookingType)}
                                </Typography>
                                <Typography variant="body2">
                                  <a
                                    type="button"
                                    onKeyDown={() => {}}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    {`${
                                      origin.services && origin.services.length
                                        ? `${origin.services.length} dịch vụ`
                                        : ''
                                    }`}
                                  </a>
                                </Typography>
                                {getUpdatedOrderStatus(origin.status, origin)}
                              </>
                            ),
                            accessor: 'room',
                            sortable: false,
                          },
                          {
                            Head: 'Tổng tiền',
                            Cell: (value, origin) => (
                              <>
                                <Typography variant="body1">
                                  {`${formatPrice(getPrice(origin))}`}
                                </Typography>
                                {
                            origin.depositWith === '2home'
                              ? (
                                <Typography variant="body2">
                                  {`Đặt cọc: ${formatPrice(origin.depositAmount)}`}
                                </Typography>
                              )
                              : <Typography variant="body2">&nbsp;</Typography>
                          }
                                {
                            origin.extraFund
                              ? (
                                <div>
                                  <ArrowDownward className="mx-auto" fontSize="small" />
                                  <Typography variant="body1" className="text-left">
                                    {
                                      origin.extraFund > 0
                                        ? (<Typography variant="body2">Thanh toán thêm:</Typography>)
                                        : (<Typography variant="body2">Hoàn tiền:</Typography>)
                                    }
                                    <div>{`${formatPrice(origin.extraFund)}`}</div>
                                  </Typography>
                                </div>
                              )
                              : null
                          }
                              </>
                            ),
                            accessor: 'totalPrice',
                            sortable: true,
                          },
                          {
                            Head: 'Thời gian',
                            Cell: (value, origin) => (
                              <div className="text-left">
                                {
                            origin.prevState
                              ? (
                                <div className="text-center">
                                  <Typography variant="body1" className="d-flex">
                                    <div style={{ width: '40%' }}>
                                      <div>{moment(origin.prevState.checkin).format('HH:mm')}</div>
                                      <div>{moment(origin.prevState.checkin).format('DD/MM')}</div>
                                    </div>
                                    <div style={{ margin: 'auto 20px' }}>-</div>
                                    <div style={{ width: '40%' }}>
                                      <div>{moment(getCheckout(origin.prevState)).format('HH:mm')}</div>
                                      <div>{moment(getCheckout(origin.prevState)).format('DD/MM')}</div>
                                    </div>
                                  </Typography>
                                  <ArrowDownward className="mx-auto" fontSize="small" />
                                </div>
                              )
                              : null
                          }
                                <Typography variant="body1" className="d-flex text-center">
                                  <div style={{ width: '40%' }}>
                                    <div>{moment(origin.checkin).format('HH:mm')}</div>
                                    <div>{moment(origin.checkin).format('DD/MM')}</div>
                                  </div>
                                  <div style={{ margin: 'auto 20px' }}>-</div>
                                  <div style={{ width: '40%' }}>
                                    <div>{moment(getCheckout(origin)).format('HH:mm')}</div>
                                    <div>{moment(getCheckout(origin)).format('DD/MM')}</div>
                                  </div>
                                </Typography>
                              </div>
                            ),
                            accessor: 'checkin',
                            sortable: false,
                          },
                          {
                            Head: 'Trạng thái',
                            Cell: (value, origin) => renderStatus(value, origin),
                            accessor: 'status',
                            sortable: false,
                          },
                          {
                            Head: '',
                            Cell: (value, origin) => renderAction(value, origin),
                            accessor: 'status',
                          },
                        ]}
                        loading={loading}
                        page={meta?.page}
                        limit={meta?.limit}
                        total={meta?.total}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                      />
                      <RouterLink to="/reservations" className="mt-2 ml-auto">
                        <Button color="primary" size="small" variant="text">
                          Xem tất cả
                          <ArrowRight />
                        </Button>
                      </RouterLink>
                    </Grid>
                  </Grid>
                  {/* Đơn chuẩn bị nhận phòng */}
                  <Grid item lg={4} sm={4} xl={3} xs={12} className="pl-2">
                    <Grid container item lg={12} className="p-3" style={{ backgroundColor: '#eaeaea' }}>
                      <Typography variant="h4" className="mb-3">Khách sắp nhận phòng</Typography>
                      <Divider className="w-100" style={{ backgroundColor: '#d1d1d1' }} />
                      {
              paidOrders.map(order => (
                <>
                  <Grid container item xs={12} className="my-3">
                    <Grid item xs={8}>
                      <Typography variant="h5">{order?.room?.name}</Typography>
                      <Typography variant="body1">
                        <span>{`Mã: ${order?.room?.code}`}</span>
                        {/* <span>{`Số nhà: ${order?.room?.roomNumber}`}</span> */}
                      </Typography>
                      {/* <Typography variant="body1">
                        Hướng dẫn cập nhật lần cuối
                      </Typography> */}
                      <Button
                        size="small"
                        className="px-0"
                        style={{ textTransform: 'none' }}
                        onClick={() => {
                          setSltOrder(order)
                          setShowOrderInstruction(true)
                        }}
                      >
                        Xem hướng dẫn
                      </Button>
                    </Grid>
                    <Grid item xs={4} className="text-right">
                      <Typography variant="h5">{moment(order.checkin).format('HH:mm')}</Typography>
                      <Typography variant="body1">{moment(order.checkin).format('DD/MM/YYYY')}</Typography>
                    </Grid>
                  </Grid>
                  <Divider className="w-100" style={{ backgroundColor: '#d1d1d1' }} />
                </>
              ))
            }
                    </Grid>
                  </Grid>
                </>
              )
              : (
                <Grid container item xs={12}>
                  {
                    convertRoomCalendar().length
                      ? (
                        <Timeline
                          className="w-100 border"
                          itemsSorted
                          itemTouchSendsClick={false}
                          lineHeight={60}
                          stackItems
                          showCursorLine
                          sidebarWidth={isDesktop ? 200 : 100}
                          canMove={false}
                          canResize={false}
                          groups={convertRoomCalendar()}
                          items={convertOrderCalendar()}
                          defaultTimeStart={moment().subtract(2, 'day').startOf('day').toDate()}
                          defaultTimeEnd={moment().add(2, 'day').endOf('day').toDate()}
                        >
                          <TimelineHeaders className="sticky">
                            <SidebarHeader>
                              {({ getRootProps }) => <div {...getRootProps()} />}
                            </SidebarHeader>
                            <DateHeader
                              unit="primaryHeader"
                            />
                            <DateHeader labelFormat={(data, unit) => {
                              if (unit === 'hour') {
                                return moment(data[0]).format('H:mm')
                              }
                              if (unit === 'day') {
                                return moment(data[0]).format('DD')
                              }
                              if (unit === 'month') {
                                return moment(data[0]).format('MMM')
                              }
                              return moment(data[0]).format('DD/MM')
                            }}
                            />
                          </TimelineHeaders>
                        </Timeline>
                      )
                      : null
                  }
                </Grid>
              )
            }
        </Grid>
      </div>
    </>
  )
}

Dashboard.propTypes = {
  history: PropTypes.shape(),
  location: PropTypes.shape(),
}

export default Dashboard
