import React from 'react'
import { connect } from 'react-redux'
import { getSocketData } from 'redux/selector/socket'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Divider, Drawer, Badge, IconButton, Typography,
} from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
// import PeopleIcon from '@material-ui/icons/People'
import Apartment from '@material-ui/icons/Apartment'
import AssignmentInd from '@material-ui/icons/AssignmentInd'
import ReceiptIcon from '@material-ui/icons/Receipt'
// import VpnKeyIcon from '@material-ui/icons/VpnKey'
import PostAddIcon from '@material-ui/icons/PostAdd'
import Redeem from '@material-ui/icons/Redeem'
import InputIcon from '@material-ui/icons/Input'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
// import { Spa } from '@material-ui/icons'
import { SOCKET_ACTION } from 'redux/type'
import {
  Profile,
  SidebarNav,
  // UpgradePlan
} from './components'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 235,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      // height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

const Sidebar = (props) => {
  const {
    open,
    variant,
    onClose,
    className,
    user,
    anchor,
    socket,
    resetSocket,
    signout,
    ...rest
  } = props

  const classes = useStyles()

  const pages = [
    {
      title: 'Bảng điều khiển',
      href: '/dashboard',
      icon: <DashboardIcon />,
      isVisible: true,
    },
    {
      title: 'Profile',
      href: '/profile',
      icon: <AssignmentInd />,
      isVisible: true,
    },
    {
      title: 'Phòng',
      href: '/room',
      icon: <Apartment />,
      isVisible: true,
    },
    {
      title: 'Đơn đặt phòng',
      href: '/reservations',
      icon: socket?.sidebar?.order
        ? (
          <Badge badgeContent={socket?.sidebar?.order} color="primary">
            <ReceiptIcon />
          </Badge>
        )
        : <ReceiptIcon />,
      isVisible: true,
      onClick: () => resetSocket(SOCKET_ACTION.RESET_ORDER_SIDEBAR),
    },
    // {
    //   title: 'Quản lý thanh toán',
    //   href: '/customer',
    //   icon: <AccountBalanceWalletIcon />,
    //   isVisible: true,
    // },
    {
      title: 'Mã khuyến mại',
      href: '/coupon',
      icon: <Redeem />,
      isVisible: true,
    },
    {
      title: 'Báo cáo phòng',
      href: '/report',
      icon: <PostAddIcon />,
      isVisible: true,
      subs: [{
        title: 'Đơn đặt phòng',
        href: '/report-order',
        icon: <PostAddIcon />,
      }, {
        title: 'Phòng',
        href: '/report-room',
        icon: <PostAddIcon />,
      },
      ],
    },
    {
      title: 'Đối soát',
      href: '/invoice',
      icon: <AccountBalanceWalletIcon />,
      isVisible: true,
    },
    {
      title: '',
      href: '#',
      icon: (
        <IconButton
          className="p-0"
          color="inherit"
          onClick={signout}
        >
          <InputIcon />
          <Typography variant="body1" className="ml-2">Đăng xuất</Typography>
        </IconButton>
      ),
      isVisible: true,
    },
  ]

  return (
    <Drawer
      anchor={anchor}
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile user={user} />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
        {/* <UpgradePlan /> */}
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  socket: PropTypes.shape(),
  user: PropTypes.shape(),
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  anchor: PropTypes.string,
  resetSocket: PropTypes.func,
  signout: PropTypes.func,
}

export default connect(getSocketData, false)(Sidebar)
