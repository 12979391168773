import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Avatar, Typography } from '@material-ui/core'
import AppConfig from 'config'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}))

const Profile = (props) => {
  const classes = useStyles()
  const { className, user, ...rest } = props

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={`${AppConfig.BASE_IMG_URL}/${user.avatar}`}
        to="/account"
      />
      <Typography
        className={classes.name}
        variant="h4"
      >
        {user.name}
      </Typography>
      <Typography variant="body2">{user.account}</Typography>
      <Typography variant="body2">{user.email}</Typography>
    </div>
  )
}

Profile.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape(),
}

export default Profile
