import React, { useState, useEffect } from 'react'
import { Chip, IconButton } from '@material-ui/core'
import { Table } from 'components'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import { formatPrice } from 'utils/formatter'
import * as prepaidPackageApi from 'provider/prepaidHistory'
import { Delete, Done, SwapHoriz } from '@material-ui/icons'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
  },
  actionMarginBtn: {
    margin: theme.spacing(1),
  },
}))

const PrepaidPackageList = (props) => {
  const classes = useStyles()
  const { customer } = props
  const { enqueueSnackbar } = useSnackbar()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [total, setTotal] = useState(0)
  const [packages, setPackages] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchPrepaidPackages = async (p, l) => {
    if (customer) {
      setLoading(true)
      try {
        const resp = await prepaidPackageApi.find({ customer, page: p || 1, limit: l || 5 })
        setTotal(resp.total)
        setPackages(resp.data)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
      }
    }
  }

  useEffect(() => {
    fetchPrepaidPackages()
    return () => {
    }
  }, [customer])

  const handlePageChange = async (p) => {
    setPage(p)
    await fetchPrepaidPackages(p, limit)
  }

  const handleRowsPerPageChange = async (l) => {
    setLimit(l)
    setPage(1)
    await fetchPrepaidPackages(1, l)
  }

  const renderStatus = (status) => {
    const content = {
      pending: 'Chờ thanh toán',
      paid: 'Đã thanh toán',
      reject: 'Đã huỷ',
    }
    const color = {
      pending: '#ffa854',
      paid: '#00e676',
      reject: '#ff1744',
    }
    return (
      <Chip
        size="small"
        style={{
          color: 'white',
          fontSize: 12,
          fontWeight: '900',
          backgroundColor: color[status],
        }}
        label={content[status]}
      />
    )
  }

  const paid = async (id) => {
    try {
      const resp = await prepaidPackageApi.update(id, { status: 'paid' })
      let clone = [...packages]
      clone = clone.map(item => (item.id === id ? resp : item))
      setPackages(clone)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const reject = async (id) => {
    try {
      const resp = await prepaidPackageApi.update(id, { status: 'reject' })
      let clone = [...packages]
      clone = clone.map(item => (item.id === id ? resp : item))
      setPackages(clone)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  return (
    <Table
      className={classes.root}
      data={packages}
      columns={[
        {
          Head: 'Mã giao dịch',
          Cell: value => value || 'Không xác định',
          accessor: 'code',
          sortable: false,
        },
        {
          Head: 'Tên gói',
          Cell: value => value ? value.name : null,
          accessor: 'package',
          sortable: false,
        },
        {
          Head: 'Số tiền',
          Cell: value => value ? `${formatPrice(value.value)} VND` : null,
          accessor: 'package',
          sortable: false,
        },
        {
          Head: 'Ngày mua',
          Cell: value => moment(value).format('DD/MM/YYYY'),
          accessor: 'createdAt',
          sortable: false,
        },
        {
          Head: 'Trạng thái',
          Cell: value => renderStatus(value),
          accessor: 'status',
          sortable: false,
        },
        {
          Head: '',
          Cell: (value, origin) => {
            if (origin.status === 'pending') {
              return (
                <>
                  <IconButton
                    aria-label="edit"
                    className={classes.actionMarginBtn}
                    onClick={() => paid(value)}
                  >
                    <Done />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    className={classes.actionMarginBtn}
                    onClick={() => reject(value)}
                  >
                    <Delete />
                  </IconButton>
                </>
              )
            }
            if (origin.status === 'paid') {
              return (
                <IconButton
                  aria-label="paid"
                  className={classes.actionMarginBtn}
                  onClick={() => {}}
                >
                  <SwapHoriz />
                </IconButton>
              )
            }
            return null
          },
          accessor: 'id',
        },
      ]}
      loading={loading}
      page={page}
      limit={limit}
      total={total}
      // defaultSort={defaultSort}
      // onSortChange={handleSortChange}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  )
}

PrepaidPackageList.propTypes = {
  customer: PropTypes.string,
}

export default PrepaidPackageList
