import Customer from './Customer'
import Date from './Date'
import PrepaidPackage from './Package'
import Status from './Status'
import BookingDate from './BookingDate'

export default {
  Customer,
  Date,
  PrepaidPackage,
  Status,
  BookingDate,
}
