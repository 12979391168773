import React, { useState, useEffect } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  TableContainer,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Pagination } from '@material-ui/lab'

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
  },
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050,
  },
  avatar: {
    width: 80,
    height: 80,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    justifyContent: 'flex-end',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

const options = [5, 10, 25, 50]

const EnhancedTable = ({
  className,
  data,
  columns,
  loading,
  page,
  limit,
  total,
  defaultSort,
  onSortChange,
  onPageChange,
  onRowsPerPageChange,
  showPaginate,
}) => {
  const classes = useStyles()
  const [sortBy, setSortBy] = useState(null)
  const [sortDirection, setSortDirection] = useState(null)

  useEffect(() => {
    if (defaultSort) {
      const { sortBy: sort, sortDirection: direction } = defaultSort
      setSortBy(sort)
      setSortDirection(direction)
    }
    return () => {}
  }, [defaultSort])

  const handlePageChange = (e, p) => {
    onPageChange(p)
  }

  const handleRowsPerPageChange = (e) => {
    onRowsPerPageChange(e.target.value)
  }

  const handleSortChange = (id) => {
    if (!sortBy || sortBy !== id) {
      setSortBy(id)
      setSortDirection('asc')
      onSortChange(id, 'asc')
    } else {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
      onSortChange(id, sortDirection === 'asc' ? 'desc' : 'asc')
    }
  }

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <TableContainer style={{ maxHeight: '60vh' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns?.filter(column => !column.hidden)?.map((column, index) => (
                      <TableCell
                        key={column.id || index}
                        align={column.align ? 'right' : 'left'}
                        padding={column.disablePadding ? 'none' : 'default'}
                        sortDirection={sortBy === column.accessor ? sortDirection : false}
                      >
                        {column.sortable ? (
                          <TableSortLabel
                            active={sortBy === column.accessor}
                            direction={sortBy === column.accessor ? sortDirection : 'asc'}
                            onClick={() => handleSortChange(column.accessor)}
                          >
                            {column.Head}
                            {sortBy === column.accessor ? (
                              <span className={classes.visuallyHidden}>
                                {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          column.Head
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell
                        colspan={columns.length}
                        style={{ textAlign: 'center' }}
                      >
                        <CircularProgress size={25} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.map((row, index) => (
                      <TableRow key={row.id || index} hover>
                        {columns?.filter(column => !column.hidden)?.map(column => (
                          <TableCell className={column.className}>
                            {column.Cell ? (
                              column.Cell(row[column.accessor], row, index)
                            ) : (
                              <Typography variant="body1">
                                {row[column.accessor]}
                              </Typography>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </PerfectScrollbar>
      </CardContent>
      {/* Pagination */}
      {showPaginate && total
        ? (
          <CardActions className={classes.actions}>
            <FormControl
              size="small"
              variant="outlined"
              style={{
                margin: 5,
                minWidth: 80,
              }}
            >
              <Select value={limit} onChange={handleRowsPerPageChange}>
                {options.map(item => <MenuItem value={item}>{item}</MenuItem>)}
              </Select>
            </FormControl>
            <Pagination
              count={Math.ceil(total / limit)}
              page={page}
              rowsPerPage={limit}
              onChange={handlePageChange}
            />
            {/* <TablePagination
              component="div"
              count={total}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page - 1}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            /> */}
          </CardActions>
        )
        : null}
      {!data?.length && <Typography variant="body1" style={{ textAlign: 'center', marginTop: 15 }}><i>Không có dữ liệu</i></Typography>}
    </Card>
  )
}

EnhancedTable.defaultProps = {
  showPaginate: true,
}

EnhancedTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.shape()),
  page: PropTypes.number,
  limit: PropTypes.number,
  total: PropTypes.number,
  loading: PropTypes.bool,
  defaultSort: PropTypes.shape(),
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  showPaginate: PropTypes.bool,
}

export default EnhancedTable
