import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import {
  ArrowRightAlt, AttachMoney,
} from '@material-ui/icons'
import { Grid, Typography } from '@material-ui/core'
import CurrencyInput from 'components/CurrencyInput'
import { formatPrice } from 'utils/formatter'

const Date = ({ onApply, min, max }) => {
  const [minPrice, setMinPrice] = useState(min)
  const [maxPrice, setMaxPrice] = useState(max)

  useEffect(() => {
    setMinPrice(min)
    setMaxPrice(max)
  }, [min, max])

  return (
    <PopupState variant="popover" popupId="customer-filter">
      {popupState => (
        <div>
          <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
            <AttachMoney />
            <Typography variant="body1" style={{ fontWeight: 500, marginRight: 30 }}>Giá phòng: </Typography>
            <Typography variant="body1" style={{ fontWeight: 500 }}>{`${formatPrice(min)}VND`}</Typography>
            <ArrowRightAlt style={{ margin: 'auto 20px' }} />
            <Typography variant="body1" style={{ fontWeight: 500 }}>{`${formatPrice(max)}VND`}</Typography>
            {/* {min || max
              ? (
                <Close onClick={(e) => {
                  e.stopPropagation()
                  onApply(undefined)
                }}
                />
              )
              : null} */}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 400 }}>
              <Grid container>
                <Grid item lg={5} md={5}>
                  <CurrencyInput
                    label="Từ"
                    variant="outlined"
                    fullWidth
                    value={minPrice}
                    onChange={e => setMinPrice(e.target.value)}
                    style={{ backgroundColor: 'white' }}
                    size="small"
                  />
                </Grid>
                <Grid item lg={2} md={2}>
                  <ArrowRightAlt style={{ margin: 'auto 20px' }} />
                </Grid>
                <Grid item lg={5} md={5}>
                  <CurrencyInput
                    label="Đến"
                    variant="outlined"
                    fullWidth
                    value={maxPrice}
                    onChange={e => setMaxPrice(e.target.value)}
                    style={{ backgroundColor: 'white' }}
                    size="small"
                    min={minPrice}
                  />
                </Grid>
              </Grid>
              <Button
                onClick={() => {
                  const from = minPrice ? parseInt(minPrice.toString().replace(/,/g, ''), 0) : min
                  const to = maxPrice ? parseInt(maxPrice.toString().replace(/,/g, ''), 0) : max
                  onApply(from, to)
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

Date.propTypes = {
  onApply: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
}

export default Date
