import moment from 'moment'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Radio,
  Checkbox,
  InputAdornment,
  FormHelperText,
  InputLabel,
  Tooltip,
} from '@material-ui/core'
import CurrencyInput from 'components/CurrencyInput'
import { formatPrice } from 'utils/formatter'
import { Help } from '@material-ui/icons'


const SELECT_TIME = Array.from(Array(24).keys()).map(item => item < 10 ? `0${item}:00` : `${item}:00`)
const FIXTIME_TOOLTIP = 'Đây là căn hộ tính giá ngày theo khung giờ cố định. Ví dụ, khung giờ qua đêm 14:00-12:00. Khách hàng thuê ngoài khung giờ này cần trả thêm phí vào sớm/ ra muộn hoặc tính là 2 ngày, tùy theo cách thiết lập giá của căn hộ'
const FLEXTIME_TOOLTIP = 'Đây là căn hộ tính giá ngày theo số giờ ở. Ví dụ, bạn điền “Cho thuê theo giờ tối đa 9 tiếng”. Vậy khách hàng thuê từ 10 - 24 tiếng tính giá 1 ngày (được khách hàng yêu thích)'
const INDAY_TOOLTIP = 'Cho phép khách hàng được hưởng mức giá 1 ngày khi thuê trong khung giờ này. Thuê ngoài khung giờ này tính như thuê qua đêm + giờ ở thêm ( giá từ 1,5 - 2 ngày)'
const EXTRATIMEDAY_TOOLTIP = (
  <div>
    <div>Ví dụ: Khung giờ qua đêm: 14h - 12h. Vào sớm/ ra muộn tối đa: 1 giờ.</div>
    <div>* Khách hàng có thể thuê từ 13h (vào sớm 1 tiếng) - 13h (ra muộn 1 tiếng). Tổng thanh toán = giá 1 ngày + giá 1 tiếng vào sớm + giá 1 tiếng ra muộn.</div>
    <div>* Nếu vào sớm hoặc ra muộn từ 2 tiếng trở lên: Tính giá 2 ngày.</div>
  </div>
)
const MAXHOUR_TOOLTIP = 'Đây là tổng số tiếng khách hàng có thể thuê theo giờ (hưởng giá và cách tính thuê giờ). Ví dụ, bạn điền “Thuê theo giờ tối đa: 7”, nếu tổng thời gian khách đặt là 8 tiếng, đơn của khách được coi là đang thuê theo ngày và áp dụng theo cách tính giá ngày'
const CLEAN_TOOLTIP = 'Phí dọn dẹp được tính cho các đơn thuê qua đêm từ 12 tiếng và chỉ tính 1 lần/đơn'

const NormalPriceInfo = ({
  classes,
  errors,
  touched,
  values,
  handleChange,
  setFieldValue,
}) => {
  const [rate, setRate] = useState(1.25)

  useEffect(() => {
    setRate(1 / ((100 - values.commission) / 100))
  }, [values.commission])

  const on2homeFeeChanged = (has2homeFee) => {
    setFieldValue('has2homeFee', has2homeFee)
    if (has2homeFee) {
      setFieldValue('dayBaseFee', values.dayBaseFee / rate)
      setFieldValue('dayHourExtraBaseFee', values.dayHourExtraBaseFee / rate)
      setFieldValue('hourFirstBaseFee', values.hourFirstBaseFee / rate)
      setFieldValue('hourExtraBaseFee', values.hourExtraBaseFee / rate)
      setFieldValue('overnightFee', values.overnightFee / rate)
      setFieldValue('addGuestBaseFee', values.addGuestBaseFee / rate)
      setFieldValue('dayRangeFee', values.dayRangeFee / rate)
    } else {
      setFieldValue('dayBaseFee', values.dayBaseFee * rate)
      setFieldValue('dayHourExtraBaseFee', values.dayHourExtraBaseFee * rate)
      setFieldValue('hourFirstBaseFee', values.hourFirstBaseFee * rate)
      setFieldValue('hourExtraBaseFee', values.hourExtraBaseFee * rate)
      setFieldValue('overnightFee', values.overnightFee * rate)
      setFieldValue('addGuestBaseFee', values.addGuestBaseFee * rate)
      setFieldValue('dayRangeFee', values.dayRangeFee * rate)
    }
  }

  return (
    <>
      <div className={classes.subTitle}>Giá phòng:</div>
      {/* Thiết lập */}
      <Grid container item lg={8} md={8} xs={12} className={`${classes.rowAlign} ${classes.priceBox}`}>
        <Grid item lg={3} md={3} xs={12}>
          Thiết lập
        </Grid>
        <Grid container item lg={9} md={9} xs={12}>
          <div className="d-flex align-items-center">
            <Radio
              name="has2homeFee"
              checked={values.has2homeFee}
              onChange={() => on2homeFeeChanged(true)}
              size="small"
            />
            <div style={{ whiteSpace: 'normal' }}>
              <span>Phí dưới đây đã bao gồm</span>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{
                  backgroundColor: 'white',
                  verticalAlign: 'middle',
                  margin: 'auto 10px',
                }}
                size="small"
              >
                <Select
                  name="commission"
                  value={values.commission}
                  onChange={(e) => {
                    if (e.target.value === 30) {
                      setFieldValue('commission', 30)
                      setFieldValue('has2homeFee', true)
                    } else {
                      handleChange(e)
                    }
                  }}
                >
                  {[15, 20, 30].map(item => (
                    <MenuItem value={item}>{`${item}%`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <span>phí 2home</span>
            </div>
          </div>
          <div className="d-flex align-items-center mt-2">
            <Radio
              name="has2homeFee"
              checked={!values.has2homeFee}
              onChange={() => on2homeFeeChanged(false)}
              size="small"
              disabled={values.commission === 30}
            />
            <div style={{ whiteSpace: 'normal' }}>
              <span>Phí dưới đây chưa bao gồm</span>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{
                  backgroundColor: 'white',
                  verticalAlign: 'middle',
                  margin: 'auto 10px',
                }}
                size="small"
                disabled={values.commission === 30}
              >
                <Select
                  name="commission"
                  value={values.commission}
                  onChange={(e) => {
                    if (e.target.value === 30) {
                      setFieldValue('commission', 30)
                      setFieldValue('has2homeFee', true)
                    } else {
                      handleChange(e)
                    }
                  }}
                >
                  {[15, 20, 30].map(item => (
                    <MenuItem value={item}>{`${item}%`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <span>phí 2home</span>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* Cách tính giá */}
      <Grid container item lg={8} md={8} xs={12} className={`${classes.rowAlign} ${classes.priceBox}`}>
        <Grid item lg={3} md={3} xs={12}>
          Cách tính giá
        </Grid>
        <Grid container item lg={9} md={9} xs={12}>
          <div className="d-flex align-items-center">
            <Radio
              size="small"
              name="checkinMode"
              checked={values.isFlexible}
              onChange={() => setFieldValue('isFlexible', true)}
            />
            <div className="my-auto" style={{ whiteSpace: 'normal' }}>
              <span className="mr-2">Giờ linh hoạt (Phí ngày theo số giờ ở)</span>
              <Tooltip title={FLEXTIME_TOOLTIP}>
                <Help color="secondary" style={{ fontSize: 16 }} />
              </Tooltip>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Radio
              size="small"
              name="checkinMode"
              checked={!values.isFlexible}
              onChange={() => setFieldValue('isFlexible', false)}
            />
            <div className="my-auto" style={{ whiteSpace: 'normal' }}>
              <span className="mr-2">Giờ quy định (Phí ngày theo khung giờ cố định)</span>
              <Tooltip title={FIXTIME_TOOLTIP}>
                <Help color="secondary" style={{ fontSize: 16 }} />
              </Tooltip>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        {/* Phí theo ngày */}
        <Grid container item lg={4} md={4} xs={12} className="pr-1">
          <Grid container item lg={12} md={12} xs={12} className={`${classes.rowAlign} ${classes.priceBox}`}>
            {/* Title */}
            <Grid item lg={12} md={12} xs={12} className="mb-2">
              <Typography variant="h6">
                THUÊ THEO NGÀY
              </Typography>
            </Grid>
            {
              !values.isFlexible
                ? (
                  <Grid item lg={12} md={12} xs={12} className="mb-2">
                    <Grid item lg={12} md={12} xs={12} className="mb-auto">
                      <Typography>Qua đêm</Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} xs={12} spacing={2} className="mt-2">
                      {/* Thời điểm checkin */}
                      <Grid item lg={6} md={6} xs={6}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth
                          style={{ backgroundColor: 'white' }}
                          error={errors.checkin && touched.checkin}
                          size="small"
                        >
                          <InputLabel style={{ backgroundColor: 'white', padding: '0px 5px' }} id="checkin-time">
                            Từ
                          </InputLabel>
                          <Select
                            labelId="checkin-time"
                            id="checkin-time-label"
                            name="checkin"
                            value={`${values.checkin ? `${values.checkin.replace(':00', '')}:00` : ''}`}
                            onChange={handleChange}
                          >
                            {SELECT_TIME.map(item => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Thời điểm checkout */}
                      <Grid item lg={6} md={6} xs={6}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth
                          style={{ backgroundColor: 'white' }}
                          error={errors.checkout && touched.checkout}
                          size="small"
                        >
                          <InputLabel style={{ backgroundColor: 'white', padding: '0px 5px' }} id="checkout-time">
                            Đến
                          </InputLabel>
                          <Select
                            labelId="checkout-time"
                            id="checkout-time-label"
                            name="checkout"
                            value={`${values.checkout ? `${values.checkout.replace(':00', '')}:00` : ''}`}
                            onChange={handleChange}
                          >
                            {SELECT_TIME.map(item => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )
                : null
            }
            {/* Phí cho 1 ngày */}
            <Grid container item lg={12} md={12} xs={12} className="mb-2">
              <Grid item lg={6} md={6} xs={12}>
                <Typography>Giá theo ngày</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <CurrencyInput
                  style={{ backgroundColor: 'white' }}
                  variant="outlined"
                  name="dayBaseFee"
                  fullWidth
                  value={values.dayBaseFee / (values.has2homeFee ? 1 : rate)}
                  onChange={(value) => {
                    if (!value) {
                      setFieldValue('dayBaseFee', '')
                      return
                    }
                    const dayBaseFee = value * (values.has2homeFee ? 1 : rate)
                    setFieldValue('dayBaseFee', dayBaseFee)
                    if (value && values.hourExtraBaseFee && values.isFlexible) {
                      setFieldValue('dayHourExtraMax', Math.floor(dayBaseFee / values.hourExtraBaseFee))
                    }
                    if (value && values.hourExtraBaseFee && values.hourFirstBaseFee && values.hourFirstAmount) {
                      setFieldValue('hourExtraMax', Math.floor((dayBaseFee - values.hourFirstBaseFee) / values.hourExtraBaseFee) + Number(values.hourFirstAmount))
                    }
                  }}
                  error={errors.dayBaseFee && touched.dayBaseFee}
                  helperText={errors.dayBaseFee}
                  size="small"
                  placeholder="Nhập số tiền"
                />
                {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(values.dayBaseFee)} VND`}</Typography> : null}
              </Grid>
            </Grid>
            {/* Phí ở thêm 1 giờ */}
            <Grid container item lg={12} md={12} xs={12} className="mb-2">
              <Grid item lg={6} md={6} xs={12}>
                <Typography variant="body1">
                  <span>Giá 1 giờ vào sớm /ra muộn</span>
                  <Tooltip title={EXTRATIMEDAY_TOOLTIP}>
                    <Help color="secondary" style={{ fontSize: 14 }} />
                  </Tooltip>
                </Typography>
                <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>(Chỉ áp dụng cho khung giờ qua đêm)</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <CurrencyInput
                  style={{ backgroundColor: 'white' }}
                  variant="outlined"
                  name="dayHourExtraBaseFee"
                  fullWidth
                  value={values.dayHourExtraBaseFee / (values.has2homeFee ? 1 : rate)}
                  onChange={(value) => {
                    if (!value) {
                      setFieldValue('dayHourExtraBaseFee', '')
                      return
                    }
                    const dayHourExtraBaseFee = value * (values.has2homeFee ? 1 : rate)
                    setFieldValue('dayHourExtraBaseFee', dayHourExtraBaseFee)
                    if (value && values.dayBaseFee) {
                      setFieldValue('dayHourExtraMax', Math.floor(values.dayBaseFee / dayHourExtraBaseFee))
                    }
                  }}
                  error={errors.dayHourExtraBaseFee && touched.dayHourExtraBaseFee}
                  helperText={errors.dayHourExtraBaseFee}
                  size="small"
                  placeholder="Nhập số tiền"
                />
                {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(values.dayHourExtraBaseFee)} VND`}</Typography> : null}
              </Grid>
            </Grid>
            {/* Số giờ tối đa cho việc ở thêm theo ngày */}
            <Grid container item lg={12} md={12} xs={12} className="mb-2">
              <Grid item lg={6} md={6} xs={12}>
                <Typography>Vào sớm/ra muộn tối đa</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  type="number"
                  style={{ backgroundColor: 'white', textAlign: 'right' }}
                  variant="outlined"
                  name="dayHourExtraMax"
                  fullWidth
                  value={values.dayHourExtraMax}
                  onChange={handleChange}
                  error={errors.dayHourExtraMax && touched.dayHourExtraMax}
                  helperText={errors.dayHourExtraMax}
                  size="small"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">Giờ</InputAdornment>,
                  }}
                  placeholder="Nhập số giờ"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Phí theo giờ ? */}
        <Grid container item lg={4} md={4} xs={12} className="pl-1">
          <Grid container item lg={12} md={12} xs={12} className={`${classes.rowAlign} ${classes.priceBox}`}>
            {/* Có tính phí theo giờ? */}
            <Grid item lg={12} md={12} xs={12} className="mb-4">
              <Typography variant="h6">
                <Checkbox
                  checked={values.hasRentByHour}
                  onChange={e => setFieldValue('hasRentByHour', e.target.checked)}
                  style={{ padding: 0 }}
                />
                <span className="ml-2 mt-1">CÓ CHO THUÊ THEO GIỜ</span>
              </Typography>
            </Grid>
            {/* Phí theo giờ */}
            {values.hasRentByHour ? (
              <>
                {/* Phí giờ đầu */}
                <Grid container item lg={12} md={12} xs={12} className="mb-2">
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography>Giá giờ đầu</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <CurrencyInput
                      style={{ backgroundColor: 'white' }}
                      variant="outlined"
                      name="hourFirstBaseFee"
                      fullWidth
                      value={values.hourFirstBaseFee / (values.has2homeFee ? 1 : rate)}
                      onChange={(value) => {
                        if (!value) {
                          setFieldValue('hourFirstBaseFee', '')
                          return
                        }
                        const hourFirstBaseFee = value * (values.has2homeFee ? 1 : rate)
                        setFieldValue('hourFirstBaseFee', hourFirstBaseFee)
                        if (value && values.hourFirstBaseFee && values.dayBaseFee && values.hourFirstAmount) {
                          setFieldValue('hourExtraMax', Math.floor((values.dayBaseFee - hourFirstBaseFee) / values.hourExtraBaseFee) + Number(values.hourFirstAmount))
                        }
                      }}
                      error={errors.hourFirstBaseFee && touched.hourFirstBaseFee}
                      helperText={errors.hourFirstBaseFee}
                      size="small"
                      placeholder="Nhập số tiền"
                    />
                    {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(values.hourFirstBaseFee)} VND`}</Typography> : null}
                  </Grid>
                </Grid>
                {/* Số giờ đầu */}
                <Grid container item lg={12} md={12} xs={12} className="mb-2">
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography>Số giờ đầu</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      style={{ backgroundColor: 'white' }}
                      type="number"
                      variant="outlined"
                      name="hourFirstAmount"
                      fullWidth
                      value={values.hourFirstAmount}
                      onChange={(e) => {
                        const { value } = e.target
                        if (values.hourFirstBaseFee && values.hourExtraBaseFee && value) {
                          setFieldValue('hourExtraMax', Math.floor((values.dayBaseFee - values.hourFirstBaseFee) / values.hourExtraBaseFee) + Number(value))
                        }
                        handleChange(e)
                      }}
                      error={errors.hourFirstAmount && touched.hourFirstAmount}
                      helperText={errors.hourFirstAmount}
                      size="small"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">Giờ</InputAdornment>,
                      }}
                      placeholder="Nhập số giờ"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
                {/* Phí ở thêm 1 giờ */}
                <Grid container item lg={12} md={12} xs={12} className="mb-2">
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography>Giá 1 giờ tiếp theo</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <CurrencyInput
                      style={{ backgroundColor: 'white' }}
                      variant="outlined"
                      name="hourExtraBaseFee"
                      fullWidth
                      value={values.hourExtraBaseFee / (values.has2homeFee ? 1 : rate)}
                      onChange={(value) => {
                        if (!value) {
                          setFieldValue('hourExtraBaseFee', '')
                          return
                        }
                        const hourExtraBaseFee = value * (values.has2homeFee ? 1 : rate)
                        setFieldValue('hourExtraBaseFee', hourExtraBaseFee)
                        if (value && values.hourFirstBaseFee && values.dayBaseFee && values.hourFirstAmount) {
                          setFieldValue('hourExtraMax', Math.floor((values.dayBaseFee - values.hourFirstBaseFee) / hourExtraBaseFee) + Number(values.hourFirstAmount))
                        }
                      }}
                      error={errors.hourExtraBaseFee && touched.hourExtraBaseFee}
                      helperText={errors.hourExtraBaseFee}
                      size="small"
                      placeholder="Nhập số tiền"
                    />
                    {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(values.hourExtraBaseFee)} VND`}</Typography> : null}
                  </Grid>
                </Grid>
                {/* Số giờ tối đa */}
                <Grid container item lg={12} md={12} xs={12} className="mb-2">
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography>
                      <span>Cho thuê theo giờ tối đa</span>
                      <Tooltip title={MAXHOUR_TOOLTIP}>
                        <Help color="secondary" style={{ fontSize: 14 }} />
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      type="number"
                      style={{ backgroundColor: 'white' }}
                      variant="outlined"
                      name="hourExtraMax"
                      fullWidth
                      value={values.hourExtraMax}
                      onChange={handleChange}
                      error={errors.hourExtraMax && touched.hourExtraMax}
                      helperText={errors.hourExtraMax}
                      size="small"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">Giờ</InputAdornment>,
                      }}
                      placeholder="Nhập số giờ"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      {/* Phí dọn dẹp, phí thêm người */}
      <Grid container item lg={8} md={8} xs={12} className={`${classes.rowAlign}`}>
        {/* Phí dọn dẹp */}
        <Grid item lg={4} md={4} xs={12} className={`${classes.priceBox}`}>
          <Grid container item lg={12} md={12} xs={12} style={{ marginLeft: '-10px', marginBottom: 10 }}>
            <Checkbox
              checked={values.hasCleaningFee}
              onChange={e => setFieldValue('hasCleaningFee', e.target.checked)}
            />
            <Typography variant="h6" style={{ margin: 'auto 5px' }}>
              <span>Phí dọn dẹp</span>
              <Tooltip title={CLEAN_TOOLTIP}>
                <Help color="secondary" style={{ fontSize: 14 }} />
              </Tooltip>
            </Typography>
          </Grid>
          {values.hasCleaningFee ? (
            <Grid item lg={12} md={12} xs={12} style={{ marginRight: 10 }}>
              <CurrencyInput
                style={{ backgroundColor: 'white' }}
                label="Phí dọn dẹp"
                variant="outlined"
                name="cleaningFee"
                fullWidth
                value={values.cleaningFee}
                onChange={value => setFieldValue('cleaningFee', value)}
                error={errors.cleaningFee && touched.cleaningFee}
                helperText={errors.cleaningFee}
                size="small"
                placeholder="Nhập số tiền"
              />
            </Grid>
          ) : null}
        </Grid>
        {/* Phí thêm người */}
        <Grid container item lg={8} md={8} xs={12} className={`${classes.priceBox}`} style={{ paddingLeft: 5 }}>
          <Grid container item lg={12} md={12} xs={12} style={{ marginLeft: 10, marginBottom: 20, marginTop: 10 }}>
            <Typography variant="h6">Phí thêm người</Typography>
          </Grid>
          <Grid item container lg={12} md={12} xs={12} spacing={2}>
            {/* Số người bắt đầu tính */}
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                type="number"
                style={{ backgroundColor: 'white' }}
                label="Bắt đầu từ người thứ?"
                variant="outlined"
                name="addGuestFeeStartAt"
                fullWidth
                value={values.addGuestFeeStartAt}
                onChange={handleChange}
                error={errors.addGuestFeeStartAt && touched.addGuestFeeStartAt}
                helperText={errors.addGuestFeeStartAt}
                size="small"
                placeholder="Nhập số người"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {/* Phí ở thêm 1 người */}
            <Grid item lg={6} md={6} xs={12}>
              <CurrencyInput
                style={{ backgroundColor: 'white' }}
                label="Phí ở thêm cho 1 người"
                variant="outlined"
                name="addGuestBaseFee"
                fullWidth
                value={values.addGuestBaseFee / (values.has2homeFee ? 1 : rate)}
                onChange={(value) => {
                  const addGuestBaseFee = value ? value * (values.has2homeFee ? 1 : rate) : 0
                  setFieldValue('addGuestBaseFee', addGuestBaseFee)
                }}
                error={errors.addGuestBaseFee && touched.addGuestBaseFee}
                helperText={errors.addGuestBaseFee}
                size="small"
                placeholder="Nhập số tiền"
              />
              {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(values.addGuestBaseFee)} VND`}</Typography> : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Phí ưu đãi đêm ? */}
      <Grid container item lg={8} md={8} xs={12} className={`${classes.rowAlign} ${classes.priceBox}`}>
        {/* Có ưu đãi đêm? */}
        <Grid container item lg={12} md={12} xs={12} style={{ marginBottom: 15 }}>
          <Checkbox
            checked={values.hasNightPromote}
            onChange={e => setFieldValue('hasNightPromote', e.target.checked)}
            style={{ padding: 0 }}
          />
          <Typography variant="h6" style={{ marginLeft: 10 }}>Phòng có giá ưu đãi đêm</Typography>
        </Grid>
        {/* Phí */}
        {values.hasNightPromote ? (
          <Grid container item lg={12} md={12} xs={12} spacing={2}>
            {/* Phí ưu đãi */}
            <Grid item lg={4} md={4} xs={12}>
              <CurrencyInput
                style={{ backgroundColor: 'white' }}
                label="Giá ưu đãi đêm"
                variant="outlined"
                name="overnightFee"
                fullWidth
                value={values.overnightFee / (values.has2homeFee ? 1 : rate)}
                onChange={(value) => {
                  if (!value) {
                    setFieldValue('overnightFee', '')
                    return
                  }
                  const overnightFee = value * (values.has2homeFee ? 1 : rate)
                  setFieldValue('overnightFee', overnightFee)
                }}
                error={errors.overnightFee && touched.overnightFee}
                helperText={errors.overnightFee}
                size="small"
                placeholder="Nhập số tiền"
              />
              {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(values.overnightFee)} VND`}</Typography> : null}
            </Grid>
            {/* Thời điểm bắt đầu đêm */}
            <Grid item lg={4} md={4} xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                style={{ backgroundColor: 'white' }}
                error={errors.nightStartAt && touched.nightStartAt}
                size="small"
              >
                <InputLabel style={{ backgroundColor: 'white', padding: '0px 5px' }} id="night-start-at">
                  Từ
                </InputLabel>
                <Select
                  labelId="night-start-at"
                  id="night-start-at-label"
                  name="nightStartAt"
                  value={values.nightStartAt}
                  onChange={handleChange}
                >
                  {SELECT_TIME.map(item => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Thời điểm kết thúc đêm */}
            <Grid item lg={4} md={4} xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                style={{ backgroundColor: 'white' }}
                error={errors.nightEndAt && touched.nightEndAt}
                size="small"
              >
                <InputLabel style={{ backgroundColor: 'white', padding: '0px 5px' }} id="night-end-at">
                  Đến
                </InputLabel>
                <Select
                  labelId="night-end-at"
                  id="night-end-at-label"
                  name="nightEndAt"
                  value={values.nightEndAt}
                  onChange={handleChange}
                >
                  {SELECT_TIME.map(item => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {/* Phí trong ngày ? */}
      {
        !values.isFlexible && values.hasRentByHour
          ? (
            <Grid item lg={8} md={8} xs={12} className={`${classes.rowAlign} ${classes.priceBox}`}>
              <Grid container item lg={12} md={12} xs={12} style={{ marginBottom: 15 }}>
                <Checkbox
                  checked={values.applyTimeRange}
                  onChange={e => setFieldValue('applyTimeRange', e.target.checked)}
                  className="mb-auto p-0"
                />
                <Typography className="ml-1 mb-auto">
                  <Typography variant="h6" style={{ marginLeft: 10 }}>
                    <span>Trong ngày</span>
                    <Tooltip title={INDAY_TOOLTIP}>
                      <Help color="secondary" style={{ fontSize: 14 }} />
                    </Tooltip>
                  </Typography>
                </Typography>
              </Grid>
              {
                values.applyTimeRange
                  ? (
                    <Grid container item lg={12} md={12} xs={12} spacing={2}>
                      <Grid item lg={4} md={4} xs={12}>
                        <CurrencyInput
                          style={{ backgroundColor: 'white' }}
                          label="Giá trong ngày"
                          variant="outlined"
                          name="dayRangeFee"
                          fullWidth
                          value={values.dayRangeFee / (values.has2homeFee ? 1 : rate)}
                          onChange={(value) => {
                            if (!value) {
                              setFieldValue('dayRangeFee', '')
                              return
                            }
                            const dayRangeFee = value * (values.has2homeFee ? 1 : rate)
                            setFieldValue('dayRangeFee', dayRangeFee)
                          }}
                          error={errors.dayRangeFee && touched.dayRangeFee}
                          helperText={errors.dayRangeFee}
                          size="small"
                          placeholder="Nhập số tiền"
                        />
                        {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(values.dayRangeFee)} VND`}</Typography> : null}
                      </Grid>
                      {/* Thời điểm checkin */}
                      <Grid item lg={4} md={4} xs={12}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth
                          style={{ backgroundColor: 'white' }}
                          error={errors.startTimeRange && touched.startTimeRange}
                          size="small"
                        >
                          <InputLabel style={{ backgroundColor: 'white', padding: '0px 5px' }} id="start-time-range">
                            Từ
                          </InputLabel>
                          <Select
                            labelId="start-time-range"
                            id="start-time-range-label"
                            name="startTimeRange"
                            value={values.startTimeRange}
                            onChange={handleChange}
                          >
                            {SELECT_TIME.map(item => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Thời điểm checkout */}
                      <Grid item lg={4} md={4} xs={12}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth
                          style={{ backgroundColor: 'white' }}
                          error={errors.startTimeRange && touched.startTimeRange}
                          size="small"
                        >
                          <InputLabel style={{ backgroundColor: 'white', padding: '0px 5px' }} id="start-time-range">
                            Đến
                          </InputLabel>
                          <Select
                            labelId="start-time-range"
                            id="start-time-range-label"
                            name="endTimeRange"
                            value={values.endTimeRange}
                            onChange={handleChange}
                          >
                            {SELECT_TIME.map(item => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <FormHelperText style={{ color: 'red', whiteSpace: 'pre-wrap' }}>
                        {values.startTimeRange
                        && values.endTimeRange
                        && moment(`${moment().format('DD/MM/YYYY')} ${values.endTimeRange}`, 'DD/MM/YYYY HH:mm').diff(moment(`${moment().format('DD/MM/YYYY')} ${values.startTimeRange}`, 'DD/MM/YYYY HH:mm'), 'hour') < values.dayHourExtraMax
                          ? '* Thời gian áp dụng giá trong ngày phải lớn hơn hoặc bằng số tiếng ở tối đa theo giờ'
                          : ''}
                      </FormHelperText>
                    </Grid>
                  )
                  : null
              }
            </Grid>
          )
          : null
      }
    </>
  )
}

NormalPriceInfo.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default NormalPriceInfo
