import { connect } from 'react-redux'
import { RoomList as RoomPage } from 'views'
import { getRoomData } from 'redux/selector/room'
import { getUserData } from 'redux/selector/user'
import { find } from 'redux/action/room'

const mapDispatchToProps = dispatch => ({
  find: query => dispatch(find(query)),
})

export default connect(state => ({ ...getRoomData(state), user: getUserData(state) }), mapDispatchToProps)(RoomPage)
