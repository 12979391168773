import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Grid,
  Typography,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
} from '@material-ui/core'
import {
  findOne,
  recalculate,
} from 'provider/reservation'
import { getCalculatedArray } from 'utils/service'
import BackgroundBackdrop from 'components/Backdrop'
import { useSnackbar } from 'notistack'
import uploadApi from 'provider/upload'
import * as orderApi from 'provider/reservation'
import Upload from 'components/Upload'
import CurrencyInput from 'components/CurrencyInput'
import { Add } from '@material-ui/icons'
import { formatPrice } from 'utils/formatter'
import moment from 'moment'
import { last } from 'underscore'
import AppConfig from 'config'
import Instruction from './Instruction'
import reservationFormStyle from './styled'
import General from './General'
import BookingInfo from './BookingInfo'
import CustomerInfo from './CustomerInfo'
import PriceInfo from './PriceInfo'
import Description from './Description'
import History from './History'
import { useTheme } from '@material-ui/styles'

export const BOOKING_STATUS = [
  {
    value: 'processing',
    label: 'Đang xử lý',
  },
  {
    value: 'confirmed',
    label: 'Đã xác nhận',
  },
  {
    value: 'paid',
    label: 'Đã thanh toán',
  },
  {
    value: 'reject',
    label: 'Đã huỷ',
  },
  // {
  //   value: 'completed',
  //   label: 'Hoàn thành',
  // },
]

const ReservationForm = ({
  classes,
  id,
  action,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })
  const [reservation, setReservation] = useState(null)
  const [roomPrice, setRoomPrice] = useState([])
  const [couponData, setCouponData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [rejectNote, setRejectNote] = useState(undefined)
  const [rejectImage, setRejectImage] = useState([])
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openDepositDialog, setOpenDepositDialog] = useState(false)
  const [hasDepositPunish, setHasDepositPunish] = useState(true)
  const [openUndoDialog, setOpenUndoDialog] = useState(false)
  const [punishDepositInfo, setPunishDepositInfo] = useState({
    amount: 0,
    description: '',
    images: [], // {isLink, file}
  })
  const [aggreeWithCustomer, setAgreeWithCustomer] = useState('1')

  const onRoomPriceRecalculate = async (room, checkin, checkout, guest, limitRoom, hasService) => {
    if (false && room && checkin && checkout && guest) {
      try {
        const resp = await recalculate({
          roomid: room,
          guest,
          starttime: checkin,
          endtime: checkout,
          limitRoom,
          hasService,
        })
        setRoomPrice(getCalculatedArray(resp))
      } catch (e) {
        console.log(e)
      }
    }
  }

  // Fetch reservation if in updating mode
  const fetchReservation = async () => {
    if (!id) return
    setLoading(true)
    setReservation(null)
    try {
      const resp = await findOne(id)
      setReservation({
        ...resp,
        room: resp.room,
        services: resp.services,
        customer: resp.customer ? resp.customer : '',
        coupon: resp.coupon ? resp.coupon : '',
        note: resp.note || undefined,
      })
      setCouponData(resp.coupon)
      setRoomPrice(getCalculatedArray(resp.roomPriceInfo?.data))
      setLoading(false)
    } catch (e) {
      // console.log(e)
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchReservation()
  }, [id])

  const renderStatus = () => {
    const {
      refundRoom,
      refundService,
      addCheckoutPoint,
      depositAmount,
      depositWith,
      depositStatus,
      checkout,
    } = reservation
    const lastCheckoutPoint = last(addCheckoutPoint || [])
    switch (reservation.status) {
      case 'reject':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#999999',
            }}
            label="Huỷ"
          />
        )
      case 'lock':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#999999',
            }}
            label="Khoá phòng"
          />
        )
      case 'reject-waiting':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#999999',
            }}
            label="Chờ cung cấp thông tin"
          />
        )
      case 'reject-norefund':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#999999',
            }}
            label="Huỷ không hoàn tiền"
          />
        )
      case 'reject-refund':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#35a211',
            }}
            label="Huỷ hoàn tiền"
          />
        )
      case 'reject-processing':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#ffc107',
            }}
            label="Huỷ chờ hoàn tiền"
          />
        )
      case 'processing':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#ffa854',
            }}
            label="Chờ xác nhận"
          />
        )
      case 'confirmed':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#ff1744',
            }}
            label="Chờ thanh toán"
          />
        )
      case 'paid':
        // if (refundRoom + refundService <= 0) {
        //   return (
        //     <Chip
        //       classes={{
        //         label: classes.status,
        //       }}
        //       style={{
        //         backgroundColor: '#00b0ff',
        //       }}
        //       label="Đã thanh toán chỉnh sửa"
        //     />
        //   )
        // }
        // if (refundRoom + refundService > 0) {
        //   return (
        //     <Chip
        //       classes={{
        //         label: classes.status,
        //       }}
        //       style={{
        //         backgroundColor: '#00b0ff',
        //       }}
        //       label="Đã hoàn tiền chỉnh sửa"
        //     />
        //   )
        // }
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#00b0ff',
            }}
            label="Đã thanh toán"
          />
        )
      case 'using':
        return (
          <>
            <Chip
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: addCheckoutPoint?.filter(item => item.status === 'paid')?.length ? '#0052cc' : '#00e676',
              }}
              label={addCheckoutPoint?.filter(item => item.status === 'paid')?.length ? 'Đang ở thêm' : 'Đang sử dụng'}
            />
            &nbsp;
            {
              addCheckoutPoint?.filter(item => item.status === 'paid')?.length
                ? (
                  <Chip
                    classes={{
                      label: classes.status,
                    }}
                    style={{
                      backgroundColor: '#0052cc',
                    }}
                    label="Đã thanh toán chỉnh sửa"
                  />
                )
                : null
            }
          </>
        )
      case 'completed':
        return depositWith === '2home' && depositAmount > 0 && checkout < moment().valueOf()
        && !['none', 'release'].includes(depositStatus)
          ? (
            <>
              {/* Yêu cầu phạt cọc */}
              {
                depositStatus === 'hold'
                  ? (
                    <Chip
                      size="small"
                      style={{
                        color: 'white',
                        fontSize: 12,
                        fontWeight: '900',
                        backgroundColor: '#3e51b5',
                      }}
                      label="Chờ xác nhận hoàn cọc"
                    />
                  )
                  : null
              }
              {/* Đồng ý yêu cầu phạt cọc */}
              {
                depositStatus === 'customer-complain'
                  ? (
                    <Chip
                      size="small"
                      style={{
                        color: 'white',
                        fontSize: 12,
                        fontWeight: '900',
                        backgroundColor: '#3e51b5',
                      }}
                      label="Khách gửi đề xuất phạt cọc"
                    />
                  )
                  : null
              }
              {
              ['host-complain'].includes(depositStatus)
                ? (
                  <Chip
                    size="small"
                    style={{
                      color: 'white',
                      fontSize: 12,
                      fontWeight: '900',
                      backgroundColor: '#3e51b5',
                    }}
                    label="Đã gửi yêu cầu phạt cọc"
                  />
                )
                : null
            }
              {
              ['2home-handle'].includes(depositStatus)
                ? (
                  <Chip
                    size="small"
                    style={{
                      color: 'white',
                      fontSize: 12,
                      fontWeight: '900',
                      backgroundColor: '#3e51b5',
                    }}
                    label="2home đang xử lý cọc"
                  />
                )
                : null
            }
              {
              ['waiting-release'].includes(depositStatus)
                ? (
                  <Chip
                    size="small"
                    style={{
                      color: 'white',
                      fontSize: 12,
                      fontWeight: '900',
                      backgroundColor: '#3e51b5',
                    }}
                    label="2home đang xử lý cọc"
                  />
                )
                : null
            }
              {
              ['processing-release'].includes(depositStatus)
                ? (
                  <Chip
                    size="small"
                    style={{
                      color: 'white',
                      fontSize: 12,
                      fontWeight: '900',
                      backgroundColor: '#3e51b5',
                    }}
                    label="2home đang xử lý cọc"
                  />
                )
                : null
            }
            </>
          )
          : (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#3e51b5',
              }}
              label="Hoàn thành"
            />
          )
      case 'expired':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#9e9e9e',
            }}
            label="Hết hạn"
          />
        )
      case 'processing-pay-extra':
        return (
          <>
            <Chip
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
            &nbsp;
            <Chip
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: '#ffa854',
              }}
              label="Chờ xác nhận sau chỉnh sửa"
            />
          </>
        )
      case 'confirmed-pay-extra':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#ff1744',
            }}
            label="Chờ thanh toán chỉnh sửa"
          />
        )
      case 'processing-refund-in-update':
        return (
          <>
            <Chip
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
            &nbsp;
            <Chip
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: '#52c41a',
              }}
              label={refundRoom + refundService > 0 ? 'Chờ hoàn tiền chỉnh sửa' : 'Chờ xác nhận'}
            />
          </>
        )
      case 'waiting-refund-in-update':
      case 'host-confirm-refund-update':
        return (
          <>
            <Chip
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
            &nbsp;
            <Chip
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: '#52c41a',
              }}
              label="Chờ hoàn tiền chỉnh sửa"
            />
          </>
        )
      case 'add-checkout-processing':
        if (lastCheckoutPoint?.checkout < moment().valueOf()) {
          return (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#3e51b5',
              }}
              label="Hoàn thành"
            />
          )
        }
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#00e676',
              }}
              label="Đang sử dụng"
            />
            {lastCheckoutPoint?.status === 'processing' && (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#41CC78',
              }}
              label="Chờ xác nhận ở thêm"
            />
            )}
            {lastCheckoutPoint?.status === 'confirmed' && (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#41CC78',
              }}
              label="Chờ thanh toán ở thêm"
            />
            )}
          </>
        )
      default:
        return null
    }
  }

  const renderTitle = () => {
    switch (action) {
      case 'update':
        return `Cập nhật thông tin đơn đặt phòng ${id}`
      case 'view':
        return `Thông tin đơn đặt phòng ${id}`
      default:
        return 'Thêm mới đơn đặt phòng'
    }
  }

  const getActions = () => {
    const {
      refundRoom,
      refundService,
      checkout,
      status,
      depositStatus,
      depositAmount,
      depositWith,
    } = reservation
    switch (status) {
      case 'processing':
        return (
          <div className="d-flex justify-content-end">
            <Button
              className="mx-1 w-100"
              color="secondary"
              variant="contained"
              onClick={() => setOpenConfirmDialog(true)}
            >
              Xác nhận
            </Button>
            <Button
              className="mx-1 w-100"
              style={{ backgroundColor: 'red', color: 'white' }}
              variant="contained"
              onClick={() => setOpenRejectDialog(true)}
            >
              Huỷ đơn
            </Button>
          </div>
        )
      case 'paid':
      case 'lock':
        return (
          <div className="d-flex justify-content-end">
            <Button
              className="mx-1 w-100"
              style={{ backgroundColor: 'red', color: 'white' }}
              variant="contained"
              onClick={() => setOpenRejectDialog(true)}
            >
              Huỷ đơn
            </Button>
          </div>
        )
      case 'processing-pay-extra': // Chờ xác nhận đơn
        return (
          <div className="d-flex justify-content-end">
            <Button
              className="mx-1 w-100"
              color="secondary"
              variant="contained"
              onClick={() => setOpenConfirmDialog(true)}
            >
              Xác nhận
            </Button>
            <Button
              className="mx-1 w-100"
              style={{ backgroundColor: 'red', color: 'white' }}
              variant="contained"
              onClick={() => setOpenUndoDialog(true)}
            >
              Hủy thay đổi
            </Button>
          </div>
        )
      case 'processing-refund-in-update':
        if (refundRoom + refundService > 0) {
          return (
            <Button
              className="mx-1 w-100"
              style={{ backgroundColor: 'red', color: 'white' }}
              variant="contained"
              onClick={() => setOpenUndoDialog(true)}
            >
              Hủy thay đổi
            </Button>
          )
        }
        return (
          <div className="d-flex justify-content-end">
            <Button
              className="mx-1 w-100"
              color="secondary"
              variant="contained"
              onClick={() => setOpenConfirmDialog(true)}
            >
              Xác nhận
            </Button>
            <Button
              className="mx-1 w-100"
              style={{ backgroundColor: 'red', color: 'white' }}
              variant="contained"
              onClick={() => setOpenUndoDialog(true)}
            >
              Hủy thay đổi
            </Button>
          </div>
        )
      case 'confirmed-pay-extra': // Đơn chỉnh sửa đã được xác nhận, chờ thanh toán thêm
        return (
          <div className="d-flex justify-content-end">
            <Button
              className="mx-1 w-100"
              style={{ backgroundColor: 'red', color: 'white' }}
              variant="contained"
              onClick={() => setOpenUndoDialog(true)}
            >
              Huỷ thay đổi
            </Button>
          </div>
        )
      case 'confirmed':
        return (
          <div className="d-flex justify-content-end">
            <Button className="mx-1 w-100" style={{ backgroundColor: 'red', color: 'white' }} variant="contained" onClick={() => setOpenRejectDialog(true)}>
              Huỷ đơn
            </Button>
          </div>
        )
      case 'completed':
        return (
          <div className="d-flex justify-content-end">
            {
              depositWith === '2home' && depositAmount > 0 && checkout < moment().valueOf() && !['none', 'release'].includes(depositStatus)
                ? (
                  <>
                    {/* Yêu cầu phạt cọc */}
                    {depositStatus === 'hold' ? (
                      <>
                        <Button
                          className="mx-1 w-100"
                          variant="contained"
                          onClick={() => setOpenDepositDialog(true)}
                          style={{ backgroundColor: '#cc41c4', color: 'white' }}
                        >
                        Yêu cầu phạt cọc
                        </Button>
                        <Button
                          className="mx-1 w-100"
                          variant="contained"
                          onClick={() => {
                            setHasDepositPunish(false)
                            setOpenDepositDialog(true)
                          }}
                          style={{ backgroundColor: 'white', color: 'black' }}
                        >
                          Hoàn cọc 100%
                        </Button>
                      </>
                    ) : null}
                    {/* Đồng ý yêu cầu phạt cọc */}
                    {depositStatus === 'customer-complain' ? (
                      <Button
                        className="mx-1 w-100"
                        variant="contained"
                        onClick={() => setOpenDepositDialog(true)}
                        style={{ backgroundColor: '#cc41c4', color: 'white' }}
                      >
                        Xác nhận yêu cầu phạt cọc
                      </Button>
                    ) : null}
                    {depositStatus === 'host-complain' ? (
                      <Button
                        className="mx-1 w-100"
                        variant="text"
                        disabled
                        style={{ backgroundColor: '#d5d5d5', color: 'black' }}
                      >
                        Đã gửi yêu cầu phạt cọc
                      </Button>
                    ) : null}
                    {depositStatus === '2home-handle' ? (
                      <Button
                        className="mx-1 w-100"
                        variant="text"
                        disabled
                        style={{ backgroundColor: '#d5d5d5', color: 'black' }}
                      >
                        2home đang xử lý
                      </Button>
                    ) : null}
                  </>
                )
                : null
            }
          </div>
        )
      case 'reject-waiting':
      case 'reject-processing':
      case 'reject':
      case 'reject-by-host':
      case 'reject-refund':
      case 'reject-norefund':
      default:
        return null
    }
  }

  useEffect(() => {
    if (!reservation) {
      return () => {}
    }
    return () => {
    }
  }, [openRejectDialog])

  const confirmReservation = async () => {
    try {
      await orderApi.confirm(reservation.id)
      enqueueSnackbar('Xác nhận đơn đặt phòng thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (error) {
      enqueueSnackbar('Xác nhận đơn đặt phòng không thành công.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    setOpenConfirmDialog(false)
    fetchReservation()
  }

  const undoReservation = async () => {
    try {
      await orderApi.undo(reservation.id)
      enqueueSnackbar('Huỷ thay đổi đơn đặt phòng thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (e) {
      enqueueSnackbar('Huỷ thay đổi đơn đặt phòng không thành công.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    setOpenUndoDialog(false)
    fetchReservation()
  }

  const rejectReservation = async () => {
    if (reservation.status === 'lock') {
      // Huỷ đơn
      await orderApi.reject(reservation.id, '', {})
      setOpenRejectDialog(false)
      fetchReservation()
      return
    }
    // Upload image and add to reject apis
    let rejectImageUrls = []
    if (rejectImage.length) {
      rejectImageUrls = await uploadApi.uploadImage(rejectImage.filter(file => !file.isLink).map(item => item.file), `Customer/${reservation?.customer?.code}/Reservation/${reservation?.code}`)
    }
    // Huỷ đơn
    await orderApi.reject(reservation.id, {
      rejectNote,
      rejectImage: rejectImageUrls.map(item => item.shortUrl),
    })
    setRejectNote(undefined)
    setRejectImage([])
    setOpenRejectDialog(false)
    fetchReservation()
  }

  const confirmPunishDeposit = async () => {
    // console.log(reservation, punishDepositInfo)
    switch (reservation?.depositStatus) {
      case 'hold':
        try {
          if (hasDepositPunish) {
            await orderApi.punishDeposit(reservation.id, punishDepositInfo)
            enqueueSnackbar('Phạt cọc cho đơn đặt phòng thành công.')
          } else {
            await orderApi.releaseDeposit(reservation.id)
            enqueueSnackbar('Hoàn cọc cho đơn đặt phòng thành công.')
          }
        } catch (e) {
          enqueueSnackbar('Xác nhận không thành công.')
        }
        break

      case 'customer-complain':
        try {
          await orderApi.agreePunishDeposit(reservation.id, aggreeWithCustomer === '1')
          enqueueSnackbar('Xác nhận phạt cọc cho đơn đặt phòng thành công.')
        } catch (e) {
          enqueueSnackbar('Xác nhận phạt cọc cho đơn đặt phòng không thành công.')
        }
        break

      default:
        break
    }
    setOpenDepositDialog(false)
    setHasDepositPunish(true)
    fetchReservation()
  }

  const getPunishDepositInfoFromHost = (order) => {
    const {
      depositPunishInfo,
    } = order
    const amount = depositPunishInfo?.find(d => d.from === 'host')?.amount || 0
    const description = depositPunishInfo?.find(d => d.from === 'host')?.description || ''
    return `Bạn đã đưa ra số tiền phạt trước đó là ${formatPrice(amount)}VND với lý do "${description}"".`
  }

  const getPunishDepositInfoFromCustomer = (order) => {
    const {
      depositPunishInfo,
    } = order
    const amount = depositPunishInfo?.find(d => d.from === 'customer')?.amount || 0
    const description = depositPunishInfo?.find(d => d.from === 'customer')?.description || ''
    return `Khách hàng không đồng ý với mức phạt trên và đề xuất mức phạt mới: ${formatPrice(amount)}VND với lý do "${description}".`
  }

  if (!reservation) return null

  return (
    <BackgroundBackdrop loading={loading}>
      {/* Reject dialog */}
      <Dialog
        open={openRejectDialog}
        onClose={() => {
          setOpenRejectDialog(false)
        }}
      >
        <DialogTitle disableTypography>
          <h4>Huỷ đơn đặt phòng</h4>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80 }}>
          {
            reservation?.status === 'lock'
              ? (
                <Grid container spacing={3}>
                  <Typography variant="body1">
                    {`Xác nhận huỷ đơn khoá phòng ${reservation?.code}`}
                  </Typography>
                </Grid>
              )
              : (
                <>
                  <Typography variant="body1">
                    {'Xác nhận huỷ đơn đặt phòng '}
                    <b>{reservation?.code}</b>
                  </Typography>
                  {
                    reservation.status === 'paid'
                      ? (
                        <Typography variant="body1">
                          Bạn đang ấn huỷ đơn đã thanh toán, số tiền phòng sẽ được hoàn lại 100% cho khách hàng
                        </Typography>
                      )
                      : null
                  }
                  <Grid container className="mt-4">
                    <TextField
                      label="Lý do huỷ"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      value={rejectNote}
                      onChange={e => setRejectNote(e.target.value)}
                      className={classes.formControl}
                    />
                  </Grid>
                </>
              )
            }
        </DialogContent>
        <DialogActions>
          <Button onClick={rejectReservation} color="primary">
            Huỷ đơn
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirm dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle disableTypography>
          <h3>Xác nhận yêu cầu đặt phòng</h3>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80, paddingTop: 0 }}>
          <Typography variant="body1" className="mb-2">
            Xác nhận phòng này vẫn còn trống, khách hàng có thể thanh toán để đặt phòng!
          </Typography>
          <Typography variant="body2" className="border p-2">
            Lưu ý: Nếu khách hàng 2home chưa thanh toán, bạn có khách từ nguồn khác,
            vui lòng khoá lịch này hoặc huỷ đơn (chờ thanh toán) này để tránh khách hàng 2home thanh toán trùng lịch.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmReservation} color="primary">
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      {/* Undo dialog */}
      <Dialog
        open={openUndoDialog}
        onClose={() => setOpenUndoDialog(false)}
      >
        <DialogTitle disableTypography>
          <h4>Huỷ thay đổi đơn đặt phòng</h4>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80, paddingTop: 0 }}>
          <div className="mb-2">
            Không đồng ý thay đổi đơn đặt phòng
            {' '}
            <b>{reservation?.code}</b>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={undoReservation} color="primary">
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      {/* Phạt cọc */}
      <Dialog
        open={openDepositDialog}
        onClose={() => {
          setHasDepositPunish(true)
          setOpenDepositDialog(false)
        }}
        PaperProps={{ style: { overflowY: 'unset', maxWidth: '100%' } }}
      >
        <DialogTitle disableTypography>
          <h4>Yêu cầu phạt cọc</h4>
        </DialogTitle>
        <DialogContent style={{ minWidth: '60vw', minHeight: 80, overflowY: 'hidden' }}>
          {
            reservation?.depositStatus === 'hold'
              ? hasDepositPunish
                ? (
                  <>
                    {/* Thông tin phạt cọc */}
                    <Grid container spacing={3}>
                      <Grid item md={4}>
                        <Typography variant="body1" className="mb-2">Số tiền phạt yêu cầu</Typography>
                        <CurrencyInput
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={null}
                          onChange={v => setPunishDepositInfo({ ...punishDepositInfo, amount: v })}
                          placeholder="Nhập số tiền"
                        />
                      </Grid>
                      <Grid item md={8}>
                        <Typography variant="body1">Lý do phạt</Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={3}
                          value={null}
                          onChange={e => setPunishDepositInfo({ ...punishDepositInfo, description: e.target.value })}
                          className={classes.formControl}
                        />
                      </Grid>
                    </Grid>
                    {/* Ảnh chứng minh */}
                    <Grid container spacing={3}>
                      <Grid item md={4}>
                        <Typography variant="body1">Tải lên ảnh bằng chứng</Typography>
                        <Upload
                          files={[]}
                          multiple
                          onChange={files => setPunishDepositInfo({ ...punishDepositInfo, images: [...files] })}
                          uploadComponent={(
                            <div className="w-100 border d-flex" style={{ height: 120, borderStyle: 'dashed !important' }}>
                              <Add fontSize="large" className="m-auto" />
                            </div>
                        )}
                        />
                      </Grid>
                      <Grid item md={8} className="mt-2">
                        <Typography variant="body1">Ảnh bằng chứng</Typography>
                        <Upload
                          files={punishDepositInfo.images}
                          onChange={files => setPunishDepositInfo({ ...punishDepositInfo, images: [...files] })}
                          multiple
                          readOnly
                        />
                      </Grid>
                    </Grid>
                    {/* Mô tả */}
                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <Typography variant="body1">
                      Nếu khách hàng đồng ý với số tiền bạn đưa ra hoặc không phản hồi sau 24 giờ, chung tôi sẽ thông báo và chuyển số tiền này vào tài khoản của bạn.
                        </Typography>
                        <Typography variant="body1">
                      Nếu khách hàng không đồng ý và đưa ra một số tiền đề xuất khác (trong vòng 24 giờ).
                          <ul className="ml-4">
                            <li>Bạn đồng ý với số tiền khách hàng đề xuất, số tiền này sẽ được chuyển vào tài khoản của bạn.</li>
                            <li>Bạn không đồng ý với đề xuất, 2home sẽ can thiệp giải quyết với các bên (thời hạn giải quyết 5 ngày).</li>
                          </ul>
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )
                : (
                  <Grid container spacing={3}>
                    <Grid item md={12}>
                      <Typography variant="body1">Xác nhận hoàn 100% tiền cọc cho khách hàng</Typography>
                    </Grid>
                  </Grid>
                )
              : reservation?.depositStatus === 'customer-complain'
                ? (
                  <>
                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <Typography variant="body1">
                          {getPunishDepositInfoFromHost(reservation)}
                        </Typography>
                      </Grid>
                      {
                        reservation?.depositPunishInfo?.find(d => d.from === 'host')?.images?.map(image => (
                          <img src={`${AppConfig.BASE_IMG_URL}/${image}`} width={120} alt="host-prove" />
                        ))
                      }
                      <Grid item md={12}>
                        <Typography variant="body1">
                          {getPunishDepositInfoFromCustomer(reservation)}
                        </Typography>
                      </Grid>
                      {
                        reservation?.depositPunishInfo?.find(d => d.from === 'customer')?.images?.map(image => (
                          <img src={`${AppConfig.BASE_IMG_URL}/${image}`} width={120} alt="customer-prove" />
                        ))
                      }
                      <Grid item md={12}>
                        <RadioGroup name="isAgree" value={aggreeWithCustomer} onChange={e => setAgreeWithCustomer(e.target.value)}>
                          <FormControlLabel value="1" control={<Radio />} label="Tôi đồng ý số tiền mà khách hàng đề xuất" />
                          <FormControlLabel value="2" control={<Radio />} label="Tôi không đồng ý, cần có sự can thiệp của 2home" />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </>
                )
                : null
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmPunishDeposit} color="primary">
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      {/* Thông tin đơn đặt phòng */}
      <Grid container spacing={1}>
        {/* Thông tin đặt phòng */}
        <Grid item xs={12} md={7}>
          <Typography variant="h3">
            <span>{renderTitle()}</span>
            <span className={isDesktop ? "float-right" : "0"}>{renderStatus()}</span>
          </Typography>
          <br />
          {/* Thông tin phòng và dịch vụ */}
          <General
            classes={classes}
            values={reservation}
          />
          <br />
          {/* Thông tin đặt phòng (checkin, checkout, guest) */}
          <BookingInfo
            classes={classes}
            values={reservation}
            onRoomPriceRecalculate={onRoomPriceRecalculate}
          />
          <br />
          {/* Thông tin khách hàng */}
          <CustomerInfo
            classes={classes}
            values={reservation}
          />
          {/* Thông tin thêm */}
          <Description
            classes={classes}
            values={reservation}
          />
        </Grid>
        {/* Chi tiết giá và thanh toán/hoàn tiền */}
        <Grid item xs={12} md={5}>
          <Typography variant="h3">Chi tiết:</Typography>
          <br />
          {/* Thông tin giá */}
          <PriceInfo
            values={reservation}
            classes={classes}
            roomPrice={roomPrice}
            coupon={couponData}
          />
          {/* Hướng dẫn nhận phòng */}
          {['paid', 'using'].includes(reservation.status)
            ? (
              <Instruction
                values={reservation}
                classes={classes}
              />
            )
            : null}
          {!isDesktop ? <History data={reservation.log} classes={classes} /> : null}
          <br/>
          {/* Hành động */}
          {getActions()}
          {
            reservation?.depositPunishInfo?.length
              ? (
                <>
                  <Typography className={classes.subTitle}>Thông tin phạt cọc:</Typography>
                  {
                  reservation.depositPunishInfo.map((d) => {
                    switch (d.from) {
                      case 'host':
                        return (
                          <>
                            <p>{`Chủ nhà yêu cầu phạt một khoản ${formatPrice(d.amount)} VND trừ vào tiền đặt cọc của bạn với lý do "${d.description}".`}</p>
                            {
                              d.images?.map(image => (<img src={`${AppConfig.BASE_DESKTOP_IMG_URL}/${image}`} width={120} alt="host-prove" />))
                            }
                          </>
                        )
                      case 'customer':
                        return (
                          <>
                            <p>{`Bạn yêu cầu phạt ${formatPrice(d.amount)} VND trừ vào tiền đặt cọc của bạn với lý do "${d.description}".`}</p>
                            {
                              d.images?.map(image => (<img src={`${AppConfig.BASE_DESKTOP_IMG_URL}/${image}`} width={120} alt="customer-prove" />))
                            }
                          </>
                        )
                      case '2home':
                        return (
                          <>
                            <p>{`2home quyết định số tiền phạt là ${formatPrice(d.amount)} VND.`}</p>
                            {
                                d.images?.map(image => (<img src={`${AppConfig.BASE_DESKTOP_IMG_URL}/${image}`} width={120} alt="2home-decision" />))
                              }
                          </>
                        )
                      default:
                        return null
                    }
                  })
                }
                </>
              )
              : null
          }
          <br />
          {/* Lịch sử giao dịch */}
          {isDesktop ? <History data={reservation.log} classes={classes} /> : null}
        </Grid>
      </Grid>
    </BackgroundBackdrop>
  )
}

ReservationForm.propTypes = {
  classes: PropTypes.shape(),
  id: PropTypes.string,
  action: PropTypes.string,
}

export default withStyles(reservationFormStyle)(ReservationForm)
