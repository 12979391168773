import moment from 'moment'
import React, { useState, useEffect, useRef } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import './custom.css'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { Button } from '@material-ui/core'
import { Close, AccessTime } from '@material-ui/icons'

const CustomDateTimePicker = ({
  icon,
  value,
  label,
  onSelect,
  format,
  picked, // Mảng các thời gian đã được chọn trước đó, không thể chọn các ngày và thời gian đã được chọn này [{start, end}]
  backward,
  min,
  max,
  ...rest
}) => {
  const [datetime, setDateTime] = useState(null)
  const [open, setOpen] = useState(false)
  const [excludeTimes, setExcludeTimes] = useState([])
  const calendarRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setOpen(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (value) {
      if (min && value < min) {
        setDateTime(min)
      } else {
        setDateTime(value)
      }
    }
  }, [value])

  useEffect(() => {
    if (!datetime) {
      return () => {
      }
    }
    const times = []
    const startDate = moment(datetime).set({
      hour: 0, minute: 0, second: 0, millisecond: 0,
    }).valueOf()
    const endDate = moment(datetime).set({ hour: 23, minute: 59, second: 59 }).valueOf()
    let count = startDate
    while (count <= endDate) {
      if (!backward && count <= moment().valueOf()) {
        times.push(moment(count).toDate())
      }
      if (min && count < min) {
        times.push(moment(count).toDate())
      }
      if (max && count > max) {
        times.push(moment(count).toDate())
      }
      count += 30 * 60 * 1000
    }
    picked.forEach((item) => {
      if (item.end < moment().valueOf()) return
      count = startDate
      while (count <= endDate) {
        if (count >= item.start && count <= item.end) {
          times.push(moment(count).toDate())
        }
        count += 30 * 60 * 1000
      }
    })
    setExcludeTimes(times)
    return () => {
    }
  }, [picked, datetime])

  return (
    <>
      {/* Date */}
      <Button
        variant="outlined"
        style={{ backgroundColor: 'white', textTransform: 'none', width: '100%' }}
        onClick={() => setOpen(true)}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}>
          {icon || <AccessTime />}
          {datetime
            ? <span className="ml-2">{moment(datetime).format(format || 'DD/MM/YYYY HH:mm')}</span>
            : <span className="ml-2" style={{ color: 'gray' }}>{label}</span>}
        </div>
        {datetime
          ? (
            <Close onClick={(e) => {
              e.stopPropagation()
              setDateTime(null)
              onSelect(null)
            }}
            />
          )
          : null
        }
      </Button>
      {/* Calendar */}
      <div ref={calendarRef}>
        <DatePicker
          inline={open}
          fixedHeight
          locale="vi"
          showPopperArrow
          selected={datetime ? moment(datetime).toDate() : null}
          timeFormat="HH:mm"
          dateFormat="MMMM d, yyyy h:mm aa"
          onChange={(sltDate) => {
            setDateTime(moment((new Date(sltDate)).getTime()).set({ second: 0, millisecond: 0 }).valueOf())
            onSelect(moment((new Date(sltDate)).getTime()).set({ second: 0, millisecond: 0 }).valueOf())
          }}
          excludeTimes={excludeTimes}
          showTimeSelect
          customInput={<div />}
          minDate={min ? moment(min).toDate() : (!backward ? moment().toDate() : null)}
          maxDate={max ? moment(max).toDate() : null}
          calendarClassName="react-datepicker-2home"
          {...rest}
        />
      </div>
    </>
  )
}

CustomDateTimePicker.defaultProps = {
  backward: false,
  picked: [], // Test: 17:00 11/4 - 23:00 11/4
}

CustomDateTimePicker.propTypes = {
  onSelect: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.shape(),
  value: PropTypes.number,
  format: PropTypes.string,
  picked: PropTypes.arrayOf(PropTypes.shape()),
  backward: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
}

export default CustomDateTimePicker
