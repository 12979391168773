import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
// import { Field } from 'formik'
// import MediaUpload from 'components/MediaUpload'
import Upload from 'components/Upload'

const Media = ({
  classes,
  errors,
  touched,
  values,
  handleChange,
  setFieldValue,
}) => (
  <>
    <Typography className={classes.subTitle}>Ảnh đại diện:</Typography>
    <div style={{ marginBottom: 10 }}>
      <Upload
        files={values.thumbnail}
        multiple={false}
        onChange={files => setFieldValue('thumbnail', files)}
      />
    </div>
    <Typography className={classes.subTitle}>Danh sách ảnh và video:</Typography>
    <div style={{ marginBottom: 10 }}>
      <Upload
        files={values.images}
        multiple
        onChange={files => setFieldValue('images', files)}
        onItemClick={files => setFieldValue('thumbnail', files)}
        highlight={values.thumbnail ? values.thumbnail[0]?.file : null}
      />
    </div>
    <Grid lg={12} md={12} xs={12}>
      <TextField
        style={{ backgroundColor: 'white' }}
        label="Đường dẫn video"
        variant="outlined"
        name="video"
        fullWidth
        value={values.video}
        onChange={handleChange}
        error={errors.video && touched.video}
        helperText={errors.video && touched.video && errors.video}
        size="small"
        placeholder="Nhập đường link video giới thiệu phòng"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
  </>
)

Media.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default Media
