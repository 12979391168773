import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme, withStyles } from '@material-ui/styles'
import {
  Grid,
  Typography,
  Popover,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
} from '@material-ui/core'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Toc } from '@material-ui/icons'
import * as reportApi from 'provider/report'
import { useSnackbar } from 'notistack'
import TimeRange from 'components/TimeRange'
import moment from 'moment'
import GeneralInfo from './GeneralInfo'
import Rooms from './List'

const RoomReport = ({
  classes,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })
  const [filter, setFilter] = useState({
    startdate: null, // moment().startOf('day').valueOf(),
    enddate: null, // moment().endOf('day').valueOf(),
  })
  const [info, setInfo] = useState({})
  const [list, setList] = useState([])
  const [meta, setMeta] = useState({
    page: 1,
    total: 0,
    limit: 10,
  })
  const [sltColumns, setSltColumns] = useState({
    Phòng: true,
    'Đơn hoàn thành': true,
    'Đơn huỷ': true,
    'Số khách': true,
    'Doanh thu phòng': true,
    // 'Doanh thu dịch vụ': true,
    'Trung bình đặt phòng': true,
  })

  const fetchRoomReport = async (query) => {
    try {
      const resp = await reportApi.fetchRoomList({ ...query })
      const {
        data,
        ...rest
      } = resp
      setInfo(rest)
      setMeta(rest)
      setList(JSON.parse(JSON.stringify(data)))
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin doanh thu (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  useEffect(() => {
    fetchRoomReport({ ...filter })
    return () => {
    }
  }, [])

  useEffect(() => {
    setSltColumns({
      Phòng: true,
      'Đơn hoàn thành': true,
      'Đơn huỷ': isDesktop,
      'Số khách': isDesktop,
      'Doanh thu phòng': true,
      // 'Doanh thu dịch vụ': true,
      'Trung bình đặt phòng': isDesktop,
    })
  }, [isDesktop])

  const onSearch = (date) => {
    const {
      startDate: startdate,
      endDate: enddate,
    } = date
    setFilter({
      ...filter,
      startdate,
      enddate,
    })
    fetchRoomReport({
      startdate,
      enddate,
      ...meta,
      page: 1,
    })
  }

  const onSortChange = (sortBy, sortDirection) => {
    fetchRoomReport({
      ...filter,
      sortBy,
      sortDirection,
    })
  }

  const onPageChange = (page) => {
    setMeta({
      ...meta,
      page,
    })
    fetchRoomReport({
      ...filter,
      ...meta,
      page,
    })
  }

  const onRowsPerPageChange = (limit) => {
    setMeta({
      ...meta,
      limit,
    })
    fetchRoomReport({
      ...filter,
      ...meta,
      limit,
    })
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {/* Time range */}
        <Grid item md={5} xs={12}>
          <div className="border p-2 h-100 w-100" style={{ backgroundColor: 'white' }}>
            <Typography variant="h4" className="mb-3">Thống kê phòng: </Typography>
            <TimeRange
              startdate={filter.startdate}
              enddate={filter.enddate}
              onApply={onSearch}
              maxDate={moment().valueOf()}
            />
          </div>
        </Grid>
        {/* Information */}
        <Grid item md={7} xs={12}>
          <div className="border p-2 h-100 w-100" style={{ backgroundColor: 'white' }}>
            <GeneralInfo data={info} />
          </div>
        </Grid>
      </Grid>
      {/* Table columns */}
      <Grid container spacing={1} className="mt-4">
        <PopupState variant="popover" popupId="customer-filter">
          {popupState => (
            <div>
              <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
                <Toc />
                Cột thông tin danh sách
              </Button>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Box p={2} style={{ width: 600 }}>
                  <Grid container>
                    {
                      Object.keys(sltColumns).map(item => (
                        <Grid item md={4}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={sltColumns[item]}
                                onChange={() => {
                                  const clone = { ...sltColumns }
                                  clone[item] = !clone[item]
                                  setSltColumns(clone)
                                }
                            }
                                name={item}
                              />
                          )}
                            label={item}
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Box>
              </Popover>
            </div>
          )}
        </PopupState>
      </Grid>
      <Grid container className="mt-4">
        <Grid item md={12} xs={12}>
          <Rooms
            setting={sltColumns}
            data={list}
            meta={meta}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Grid>
      </Grid>
    </div>
  )
}

RoomReport.propTypes = {
  classes: PropTypes.shape(),
}

export default withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
}))(RoomReport)
