
import numeral from 'numeral'

require('numeral/locales')

export const formatPrice = (price, locale = 'vi') => {
  numeral.locale(locale)
  return numeral(price).format('0,0')
}

export const percentFormat = (price, locale = 'vi') => {
  numeral.locale(locale)
  return `${numeral(price).format('0,0.0')}%`
}

export const nonAccentVietnamese = (inputStr) => {
  let str = inputStr.toLowerCase()
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '')
  str = str.replace(/\u02C6|\u0306|\u031B/g, '')
  return str
}

export const convertTime = (minutes) => {
  const d = Math.floor(minutes / 1440) // 60*24
  const h = Math.floor((minutes - (d * 1440)) / 60)
  const m = Math.round(minutes % 60)

  return (`${d > 0 ? `${d} ngày ` : ''}${h > 0 ? `${h} giờ ` : ''}${m > 0 ? `${m} phút` : ''}`)
}
