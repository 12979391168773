import { connect } from 'react-redux'
import { Coupon as CouponPage } from 'views'
import { getUserData } from 'redux/selector/user'
import { find } from 'redux/action/coupon'

const mapDispatchToProps = dispatch => ({
  // update: data => dispatch(update(data)),
  find: query => dispatch(find(query)),
})

export default connect(getUserData, mapDispatchToProps)(CouponPage)
