import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  InputLabel,
  withStyles,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { formatPrice } from 'utils/formatter'
import DatePicker from 'components/DatePicker2'
import {
  recalculate,
} from 'provider/reservation'
import Upload from 'components/Upload'
import uploadApi from 'provider/upload'
import { getCalculatedArray, getPaidCheckoutPoint, getLastCheckout } from 'utils/service'
import * as roomApi from 'provider/room'
import styled from './styled'

const AddCheckoutPointDialog = ({
  classes,
  reservation,
  open,
  onClose,
  onConfirm,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [price, setPrice] = useState(null)
  const [amount, setAmount] = useState(0)
  const [checkout, setCheckout] = useState(null)
  const [payment, setPayment] = useState('2home') // 2home, point, bank-transfer, host
  const [images, setImages] = useState([])
  const [note, setNote] = useState(undefined)
  const [bankName, setBankName] = useState(undefined)
  const [room, setRoom] = useState(null)
  // const [bankAccount, setBankAccount] = useState(undefined)
  // const [bankUser, setBankUser] = useState(undefined)

  const fetchRoom = async () => {
    const resp = await roomApi.findOne(reservation?.room?.id)
    setRoom(resp)
  }

  useEffect(() => {
    setPrice(null)
    setCheckout(null)
    setPayment('2home')
    setImages([])
    setNote(undefined)
    setBankName(undefined)
    if (open) {
      fetchRoom()
    }
    return () => {
    }
  }, [open])

  const getTotal = (roomPrice) => {
    let total = 0
    roomPrice.forEach((item) => {
      total += Number(item.price)
    })
    return total
  }

  const addCheckout = async () => {
    // Call api to upload images before adding new checkout point
    try {
      const uploadImages = await uploadApi.uploadImage(images.filter(file => !file.isLink).map(item => item.file), `Customer/${reservation?.customer?.code}/Reservation/${reservation?.code}`)
      onConfirm({
        checkout,
        payment,
        value: price,
        images: uploadImages.map(item => item.shortUrl),
        bankName,
        // bankAccount,
        // bankUser,
      })
    } catch (e) {
      enqueueSnackbar('Tải ảnh lên không thành công, làm ơn thử lại.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
  }

  const onImageSelect = (files) => {
    setImages(files)
  }

  const recalculatePrice = async (value) => {
    setCheckout(value)
    // Recalculate order price with new checkout point
    const {
      room: {
        id: roomid,
      },
      guest,
      checkin: starttime,
      roomPrice,
      services,
    } = reservation
    try {
      const resp = await recalculate({
        roomid,
        starttime,
        endtime: value,
        guest,
        hasService: services.length,
      })
      const newPrice = getTotal(getCalculatedArray(resp))
      setPrice(newPrice - (roomPrice + getPaidCheckoutPoint(reservation)?.map(item => item.value).reduce((a, b) => a + b, 0)))
    } catch (e) {
      console.log(e)
    }
  }

  const getMinCheckout = () => {
    if (!reservation) {
      return null
    }
    const minDate = getLastCheckout(reservation)?.checkout || reservation.checkout
    return minDate
  }

  useEffect(() => {
    setAmount(moment(checkout).diff(moment(getMinCheckout()), 'hour'))
    return () => {
    }
  }, [checkout])

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { maxWidth: 800, overflowY: 'unset' } }} scroll="body">
      <DialogTitle disableTypography>
        <h3>Đặt ở thêm</h3>
      </DialogTitle>
      <DialogContent style={{ minWidth: 800, minHeight: 80, paddingTop: 0 }}>
        <Grid item xs={6} className="mb-2">
          <InputLabel>Giờ checkout mới: </InputLabel>
          <DatePicker
            type="datetime"
            fullWidth
            label="Chọn giờ checkout mới"
            value={getMinCheckout()}
            onSelect={value => recalculatePrice(value)}
            size="small"
            min={getMinCheckout()}
            picked={room?.reservations?.map(r => ({ start: r.checkin, end: r.checkout }))}
          />
        </Grid>
        {amount
          ? (
            <>
              <Grid item x={12} className="my-3">
                <InputLabel>Số giờ ở thêm: </InputLabel>
                <Typography variant="h4">{`${amount} tiếng`}</Typography>
              </Grid>
              <Grid item x={12} className="my-3">
                <InputLabel>Số tiền phải thanh toán thêm: </InputLabel>
                <Typography variant="h4">{`${formatPrice(price) || 0} VND`}</Typography>
              </Grid>
              <Grid item x={12} className="mt-2">
                <InputLabel>Phương thức thanh toán: </InputLabel>
                {/* 3 phương thức: TK 2home, Chuyển khoản, Host thu hộ */}
                <Grid container spacing={3} className="mb-2">
                  {/* Tài khoản 2home */}
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color={payment === '2home' ? 'primary' : 'default'}
                      onClick={() => setPayment('2home')}
                    >
                      Tài khoản 2home
                    </Button>
                  </Grid>
                  {/* Điểm 2home */}
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color={payment === 'point' ? 'primary' : 'default'}
                      onClick={() => setPayment('point')}
                    >
                      Điểm 2home
                    </Button>
                  </Grid>
                  {/* Chuyển khoản ngân hàng */}
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color={payment === 'bank-transfer' ? 'primary' : 'default'}
                      onClick={() => setPayment('bank-transfer')}
                    >
                      Chuyển khoản
                    </Button>
                  </Grid>
                  {/* Chủ nhà thu hộ */}
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color={payment === 'host' ? 'primary' : 'default'}
                      onClick={() => setPayment('host')}
                    >
                      Chủ nhà thu hộ
                    </Button>
                  </Grid>
                </Grid>
                {/* Ảnh xác minh và các thông tin thêm */}
                {
                  ['bank-transfer'].includes(payment)
                    ? (
                      <>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            {/* <TextField
                              className="mb-2"
                              label="Chủ tài khoản"
                              variant="outlined"
                              fullWidth
                              value={bankUser}
                              onChange={e => setBankUser(e.target.value)}
                            />
                            <TextField
                              className="my-2"
                              label="Số tài khoản"
                              variant="outlined"
                              fullWidth
                              value={bankAccount}
                              onChange={e => setBankAccount(e.target.value)}
                            /> */}
                            <TextField
                              className="mt-2"
                              label="Ngân hàng"
                              variant="outlined"
                              fullWidth
                              value={bankName}
                              onChange={e => setBankName(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'center' }}>
                            <Upload files={images} multiple onChange={files => onImageSelect(files)} />
                          </Grid>
                        </Grid>
                        <Grid container className="mt-2">
                          <TextField
                            label="Ghi chú"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            value={note}
                            onChange={e => setNote(e.target.value)}
                            className={classes.formControl}
                          />
                        </Grid>
                      </>
                    )
                    : (
                      <>
                        <Grid container>
                          <Upload files={images} multiple onChange={files => onImageSelect(files)} />
                        </Grid>
                        <Grid container className="mt-2">
                          <TextField
                            label="Ghi chú"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            value={note}
                            onChange={e => setNote(e.target.value)}
                            className={classes.formControl}
                          />
                        </Grid>
                      </>
                    )
                }
              </Grid>
            </>
          )
          : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={addCheckout} color="primary">
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddCheckoutPointDialog.propTypes = {
  classes: PropTypes.shape(),
  reservation: PropTypes.shape(),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default withStyles(styled)(AddCheckoutPointDialog)
