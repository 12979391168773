import moment from 'moment'
import React, { useState, useEffect } from 'react'
import {
  Typography,
  Chip,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { useSnackbar } from 'notistack'
import { NavLink as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { formatPrice } from 'utils/formatter'
import { Table } from 'components'
import {
  Delete,
  Done,
  Undo,
  ArrowDownward,
  Remove,
  Add,
  AssignmentTurnedIn,
} from '@material-ui/icons'
import AppConfig from 'config'
import uploadApi from 'provider/upload'
import CurrencyInput from 'components/CurrencyInput'
import Upload from 'components/Upload'
import * as orderApi from 'provider/reservation'
import { last } from 'underscore'
import AddCheckoutPointDialog from '../Dialog/AddCheckoutPoint'

const REJECT_AS_HOST = 'Khi thực hiện huỷ đơn cho khách thay cho chủ nhà, khách sẽ luôn được hoàn tiền.'

const ReservationsTable = ({
  isDesktop,
  classes,
  loading,
  reservations,
  meta,
  applyFilter,
  // confirm,
  reject,
  // edit,
  // view,
  // undo,
  addCheckout,
  defaultSort,
  onSortChange,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [services, setServices] = useState([])
  const [sltReservation, setSltReservation] = useState(undefined)
  const [openServiceDialog, setOpenServiceDialog] = useState(false)
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [rejectNote, setRejectNote] = useState(undefined)
  const [rejectImage, setRejectImage] = useState([])
  const [warningMessage, setWarningMessage] = useState('')
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openUndoDialog, setOpenUndoDialog] = useState(false)
  const [openAddTimeDialog, setOpenAddTimeDialog] = useState(false)
  const [openDepositDialog, setOpenDepositDialog] = useState(false)
  const [punishDepositInfo, setPunishDepositInfo] = useState({
    amount: 0,
    description: '',
    images: [], // {isLink, file}
  })
  const [aggreeWithCustomer, setAgreeWithCustomer] = useState('1')
  const [hasDepositPunish, setHasDepositPunish] = useState(true)

  const handlePageChange = (page) => {
    onPageChange(page)
  }

  const handleRowsPerPageChange = (limit) => {
    onRowsPerPageChange(limit)
  }

  const handleSortChange = (sortBy, sortDirection) => {
    onSortChange(sortBy, sortDirection)
  }

  const renderRoomType = (type) => {
    switch (type) {
      case 'request':
        return 'Yêu cầu xác nhận'
      case 'instant':
        return 'Đặt nhanh'
      default:
        return ''
    }
  }

  const renderStatus = (status, origin) => {
    const {
      checkin,
      checkout,
      refundRoom,
      refundService,
      paymentHistory,
      addCheckoutPoint,
      depositStatus,
      depositWith,
      depositAmount,
    } = origin
    const lastCheckoutPoint = last(addCheckoutPoint || [])
    switch (status) {
      case 'reject':
      case 'reject-by-host':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Huỷ"
          />
        )
      case 'reject-waiting':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Chờ cung cấp thông tin"
          />
        )
      case 'reject-norefund':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Huỷ không hoàn tiền"
          />
        )
      case 'reject-refund':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#35a211',
            }}
            label="Huỷ hoàn tiền"
          />
        )
      case 'reject-processing':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#ffc107',
            }}
            label="Huỷ chờ hoàn tiền"
          />
        )
      case 'processing':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#ffa854',
            }}
            label="Chờ xác nhận"
          />
        )
      case 'processing-pay-extra':
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
              &nbsp;
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#ffa854',
              }}
              label="Chờ xác nhận sau chỉnh sửa"
            />
          </>
        )
      case 'confirmed-pay-extra':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#ff1744',
            }}
            label="Chờ thanh toán chỉnh sửa"
          />
        )
      case 'processing-refund-in-update':
        if (moment().valueOf() > checkout) {
          return (
            <>
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#52c41a',
                }}
                label={refundRoom + refundService > 0 ? 'Chờ hoàn tiền chỉnh sửa' : 'Chờ xác nhận'}
              />
            </>
          )
        }
        if (moment().valueOf() >= checkin) {
          return (
            <>
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: origin.addCheckoutPoint && origin.addCheckoutPoint.length ? '#0052cc' : '#00e676',
                }}
                label={origin.addCheckoutPoint && origin.addCheckoutPoint.length ? 'Đang ở thêm' : 'Đang sử dụng'}
              />
                &nbsp;
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#52c41a',
                }}
                label={refundRoom + refundService > 0 ? 'Chờ hoàn tiền chỉnh sửa' : 'Chờ xác nhận'}
              />
            </>
          )
        }
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
                &nbsp;
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#52c41a',
              }}
              label={refundRoom + refundService > 0 ? 'Chờ hoàn tiền chỉnh sửa' : 'Chờ xác nhận'}
            />
          </>
        )
      case 'waiting-refund-in-update':
      case 'host-confirm-refund-update':
        if (moment().valueOf() > checkout) {
          return (
            <>
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#52c41a',
                }}
                label="Chờ hoàn tiền chỉnh sửa"
              />
            </>
          )
        }
        if (moment().valueOf() >= checkin) {
          return (
            <>
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: origin.addCheckoutPoint && origin.addCheckoutPoint.length ? '#0052cc' : '#00e676',
                }}
                label={origin.addCheckoutPoint && origin.addCheckoutPoint.length ? 'Đang ở thêm' : 'Đang sử dụng'}
              />
                &nbsp;
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#52c41a',
                }}
                label="Chờ hoàn tiền chỉnh sửa"
              />
            </>
          )
        }
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
                &nbsp;
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#52c41a',
              }}
              label="Chờ hoàn tiền chỉnh sửa"
            />
          </>
        )
      case 'add-checkout-processing':
        if (lastCheckoutPoint?.checkout < moment().valueOf()) {
          return (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#3e51b5',
              }}
              label="Hoàn thành"
            />
          )
        }
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#00e676',
              }}
              label="Đang sử dụng"
            />
            {lastCheckoutPoint?.status === 'processing' && (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#41CC78',
              }}
              label="Chờ xác nhận ở thêm"
            />
            )}
            {lastCheckoutPoint?.status === 'confirmed' && (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#41CC78',
              }}
              label="Chờ thanh toán ở thêm"
            />
            )}
          </>
        )
      case 'confirmed':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#ff1744',
            }}
            label="Chờ thanh toán"
          />
        )
      case 'paid':
        if (paymentHistory?.length > 1 && refundRoom + refundService <= 0) {
          return (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán chỉnh sửa"
            />
          )
        }
        if (paymentHistory?.length > 1 && refundRoom + refundService > 0) {
          return (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã hoàn tiền chỉnh sửa"
            />
          )
        }
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#00b0ff',
            }}
            label="Đã thanh toán"
          />
        )
      case 'using':
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: origin.addCheckoutPoint?.filter(item => item.status === 'paid')?.length ? '#0052cc' : '#00e676',
              }}
              label={origin.addCheckoutPoint?.filter(item => item.status === 'paid')?.length ? 'Đang ở thêm' : 'Đang sử dụng'}
            />
            &nbsp;
            {
              origin.addCheckoutPoint?.filter(item => item.status === 'paid')?.length
                ? (
                  <Chip
                    size="small"
                    style={{
                      color: 'white',
                      fontSize: 12,
                      fontWeight: '900',
                      backgroundColor: '#0052cc',
                    }}
                    label="Đã thanh toán chỉnh sửa"
                  />
                )
                : null
            }
          </>
        )
      case 'completed':
        return depositWith === '2home' && depositAmount > 0 && checkout < moment().valueOf() && !['none', 'release'].includes(depositStatus)
          ? (
            <>
              {/* Yêu cầu phạt cọc */}
              {
                depositStatus === 'hold'
                  ? (
                    <Chip
                      size="small"
                      style={{
                        color: 'white',
                        fontSize: 12,
                        fontWeight: '900',
                        backgroundColor: '#3e51b5',
                      }}
                      label="Chờ xác nhận hoàn cọc"
                    />
                  )
                  : null
              }
              {/* Đồng ý yêu cầu phạt cọc */}
              {
                depositStatus === 'customer-complain'
                  ? (
                    <Chip
                      size="small"
                      style={{
                        color: 'white',
                        fontSize: 12,
                        fontWeight: '900',
                        backgroundColor: '#3e51b5',
                      }}
                      label="Khách gửi đề xuất phạt cọc"
                    />
                  )
                  : null
              }
              {
                ['host-complain'].includes(depositStatus)
                  ? (
                    <Chip
                      size="small"
                      style={{
                        color: 'white',
                        fontSize: 12,
                        fontWeight: '900',
                        backgroundColor: '#3e51b5',
                      }}
                      label="Đã gửi yêu cầu phạt cọc"
                    />
                  )
                  : null
              }
              {
              ['2home-handle'].includes(depositStatus)
                ? (
                  <Chip
                    size="small"
                    style={{
                      color: 'white',
                      fontSize: 12,
                      fontWeight: '900',
                      backgroundColor: '#3e51b5',
                    }}
                    label="2home đang xử lý cọc"
                  />
                )
                : null
            }
              {
              ['waiting-release'].includes(depositStatus)
                ? (
                  <Chip
                    size="small"
                    style={{
                      color: 'white',
                      fontSize: 12,
                      fontWeight: '900',
                      backgroundColor: '#3e51b5',
                    }}
                    label="2home đang xử lý cọc"
                  />
                )
                : null
            }
              {
              ['processing-release'].includes(depositStatus)
                ? (
                  <Chip
                    size="small"
                    style={{
                      color: 'white',
                      fontSize: 12,
                      fontWeight: '900',
                      backgroundColor: '#3e51b5',
                    }}
                    label="2home đang xử lý cọc"
                  />
                )
                : null
            }
            </>
          )
          : (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#3e51b5',
              }}
              label="Hoàn thành"
            />
          )
      case 'lock':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#9e9e9e',
            }}
            label="Khoá phòng"
          />
        )
      case 'reject-lock':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#9e9e9e',
            }}
            label="Huỷ khoá phòng"
          />
        )
      case 'expired':
      default:
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#9e9e9e',
            }}
            label="Hết hạn"
          />
        )
    }
  }

  const rejectReservation = async () => {
    try {
      if (sltReservation.status === 'lock') {
      // Huỷ đơn
        reject(sltReservation.id, '', {})
        applyFilter({})
        setOpenRejectDialog(false)
        setSltReservation(undefined)
        enqueueSnackbar('Huỷ khoá phòng thành công.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
        return
      }
      // Upload image and add to reject apis
      let rejectImageUrls = []
      if (rejectImage.length) {
        rejectImageUrls = await uploadApi.uploadImage(rejectImage.filter(file => !file.isLink).map(item => item.file), `Customer/${sltReservation?.customer?.code}/Reservation/${sltReservation?.code}`)
      }
      // Huỷ đơn
      reject(sltReservation.id, {
        rejectNote,
        rejectImage: rejectImageUrls.map(item => item.shortUrl),
      })
      enqueueSnackbar('Huỷ đơn đặt phòng thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      applyFilter({})
    } catch (e) {
      enqueueSnackbar('Huỷ đơn đặt phòng không thành công.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    setSltReservation(undefined)
    setRejectNote(undefined)
    setRejectImage([])
    setOpenRejectDialog(false)
  }

  const confirmReservation = async () => {
    const {
      addCheckoutPoint,
    } = sltReservation || {}
    const lastCheckoutPoint = addCheckoutPoint && addCheckoutPoint.length ? addCheckoutPoint[addCheckoutPoint.length - 1] : null
    try {
      switch (sltReservation.status) {
        case 'add-checkout-processing':
          if (lastCheckoutPoint?.status === 'processing') {
            await orderApi.confirmCheckoutPoint(sltReservation.id)
          }
          applyFilter({ })
          break

        default:
          await orderApi.confirm(sltReservation.id)
          // await confirm(sltReservation.id)
          applyFilter({ })
          break
      }
      enqueueSnackbar('Xác nhận đơn đặt phòng thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (error) {
      if (error?.response?.data?.code === 'TIME_02') {
        enqueueSnackbar('Xác nhận đơn đặt phòng không thành công, trùng thời gian khoá phòng.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      } else {
        enqueueSnackbar('Xác nhận đơn đặt phòng không thành công, đã có lỗi xảy ra.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      }
    }
    setOpenConfirmDialog(false)
    setSltReservation(undefined)
  }

  const addTimeReservation = async (data) => {
    setOpenAddTimeDialog(false)
    // Call api to add more checkout point
    const {
      id,
    } = sltReservation
    try {
      await addCheckout(id, data)
      setSltReservation(null)
      enqueueSnackbar('Xác nhận ở thêm thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      applyFilter({ })
    } catch (e) {
      enqueueSnackbar('Xác nhận ở thêm không thành công', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
  }

  const undoReservation = async () => {
    try {
      switch (sltReservation.status) {
        case 'add-checkout-processing':
          await orderApi.rejectCheckout(sltReservation.id)
          break

        default:
          await orderApi.undo(sltReservation.id)
          break
      }
      applyFilter({})
      enqueueSnackbar('Huỷ thay đổi đơn đặt phòng thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      setOpenUndoDialog(false)
      setSltReservation(undefined)
    } catch (error) {
      enqueueSnackbar('Huỷ thay đổi đơn đặt phòng không thành công.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
  }

  const renderAction = (status, order) => {
    const {
      refundRoom,
      refundService,
      checkin,
      checkout,
      depositStatus,
      depositAmount,
      depositWith,
      prevState,
    } = order
    const {
      checkin: prevCheckin,
      checkout: prevCheckout,
    } = prevState || {}
    const {
      addCheckoutPoint,
    } = order || {}
    const lastCheckoutPoint = addCheckoutPoint && addCheckoutPoint.length ? addCheckoutPoint[addCheckoutPoint.length - 1] : null
    switch (status) {
      case 'processing':
        return (
          <>
            {/* Xác nhận */}
            <Tooltip title="Xác nhận đơn đặt phòng">
              <IconButton
                className={classes.margin}
                onClick={() => {
                  setSltReservation(order)
                  setOpenConfirmDialog(true)
                }}
              >
                <Done />
              </IconButton>
            </Tooltip>
            <Tooltip title="Huỷ đơn đặt phòng">
              <IconButton
                className={classes.margin}
                onClick={() => {
                  setSltReservation(order)
                  setOpenRejectDialog(true)
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      case 'processing-pay-extra':
        if ((prevCheckin && checkin !== prevCheckin) || (prevCheckout && checkout !== prevCheckout)) {
          return (
            <>
              <Tooltip title="Xác nhận đơn đặt phòng">
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setSltReservation(order)
                    setOpenConfirmDialog(true)
                  }}
                >
                  <Done />
                </IconButton>
              </Tooltip>
              <Tooltip title="Không đồng ý thay đổi đơn đặt phòng">
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setSltReservation(order)
                    setOpenUndoDialog(true)
                  }}
                >
                  <Undo />
                </IconButton>
              </Tooltip>
            </>
          )
        }
        return null
      case 'processing-refund-in-update':
        if ((prevCheckin && checkin !== prevCheckin) || (prevCheckout && checkout !== prevCheckout)) {
          if (refundRoom + refundService > 0) {
            return (
              <>
                <Tooltip title="Không đồng ý thay đổi đơn đặt phòng">
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      setSltReservation(order)
                      setOpenUndoDialog(true)
                    }}
                  >
                    <Undo />
                  </IconButton>
                </Tooltip>
              </>
            )
          }
          return (
            <>
              <Tooltip title="Không đồng ý thay đổi đơn đặt phòng">
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setSltReservation(order)
                    setOpenUndoDialog(true)
                  }}
                >
                  <Undo />
                </IconButton>
              </Tooltip>
              <Tooltip title="Xác nhận đơn đặt phòng">
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setSltReservation(order)
                    setOpenConfirmDialog(true)
                  }}
                >
                  <Done />
                </IconButton>
              </Tooltip>
            </>
          )
        }
        return null
      // case 'waiting-refund-in-update':
      case 'confirmed-pay-extra':
        if ((prevCheckin && checkin !== prevCheckin) || (prevCheckout && checkout !== prevCheckout)) {
          return (
            <>
              <Tooltip title="Không đồng ý thay đổi đơn đặt phòng">
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setSltReservation(order)
                    setOpenUndoDialog(true)
                  }}
                >
                  <Undo />
                </IconButton>
              </Tooltip>
            </>
          )
        }
        return null
      case 'confirmed':
      case 'paid':
        return (
          <Tooltip title="Huỷ đơn đặt phòng">
            <IconButton
              className={classes.margin}
              onClick={() => {
                setSltReservation(order)
                setOpenRejectDialog(true)
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )
      case 'completed':
        return (
          depositWith === '2home' && depositAmount > 0 && checkout < moment().valueOf()
          && !['none', 'release', '2home-handle', 'host-complain', 'waiting-release', 'processing-release'].includes(depositStatus)
            ? (
              <>
                {/* TODO: Yêu cầu phạt cọc */}
                {
                  depositStatus === 'hold'
                    ? (
                      <>
                        <Tooltip title="Phạt cọc">
                          <IconButton
                            className={classes.margin}
                            onClick={() => {
                              setSltReservation(order)
                              setOpenDepositDialog(true)
                            }}
                          >
                            <Remove />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Hoàn 100% tiền cọc">
                          <IconButton
                            className={classes.margin}
                            onClick={() => {
                              setSltReservation(order)
                              setHasDepositPunish(false)
                              setOpenDepositDialog(true)
                            }}
                          >
                            <AssignmentTurnedIn />
                          </IconButton>
                        </Tooltip>
                      </>
                    )
                    : null
                }
                {/* TODO: Đồng ý yêu cầu phạt cọc */}
                {
                  depositStatus === 'customer-complain'
                    ? (
                      <Tooltip title="Đồng ý tiền phạt cọc khách đề xuất">
                        <IconButton
                          className={classes.margin}
                          onClick={() => {
                            setSltReservation(order)
                            setOpenDepositDialog(true)
                          }}
                        >
                          <Done />
                        </IconButton>
                      </Tooltip>
                    )
                    : null
                }
              </>
            )
            : null
        )
      case 'add-checkout-processing':
        if (last(addCheckoutPoint)?.checkout < moment().valueOf()) {
          return null
        }
        if (lastCheckoutPoint?.status === 'processing') {
          return (
            <>
              {/* Xác nhận */}
              <Tooltip title="Xác nhận ở thêm" aria-label="Xác nhận ở thêm">
                <IconButton
                  aria-label="confirm"
                  className={classes.margin}
                  onClick={() => {
                    setOpenConfirmDialog(true)
                    setSltReservation(order)
                  }}
                >
                  <Done />
                </IconButton>
              </Tooltip>
              {/* Xác nhận */}
              <Tooltip title="Không đồng ý đặt thêm giờ" aria-label="Không đồng ý đặt thêm giờ">
                <IconButton
                  aria-label="confirm"
                  className={classes.margin}
                  onClick={() => {
                    setOpenUndoDialog(true)
                    setSltReservation(order)
                  }}
                >
                  <Undo />
                </IconButton>
              </Tooltip>
            </>
          )
        }
        return null
      default:
        return null
    }
  }

  const getWarning = async () => {
    if (!sltReservation) return
    setWarningMessage(REJECT_AS_HOST)
  }

  const getPrevPrice = (order) => {
    if (!order || !order.prevState) return 0
    const {
      prevState,
    } = order
    let total = (prevState?.roomPrice || 0) + (prevState?.guestPrice || 0)
    if (!prevState?.applyCoupon && prevState?.coupon?.creator === 'host') {
      switch (prevState?.coupon?.valueType) {
        case 'money':
          total -= prevState?.coupon?.value
          break
        case 'percent':
          total -= ((100 - prevState?.coupon?.value) * total) / 100
          break
        default:
      }
    }
    if (prevState?.setupFee) {
      total += prevState?.setupFee
    }
    return total
  }

  const getPrice = (order) => {
    let total = order.roomPrice + order.guestPrice
    let checkoutPoints = order?.addCheckoutPoint?.filter(item => item.status !== 'reject')
    if (moment().valueOf() > last(checkoutPoints)?.checkout) {
      checkoutPoints = order?.addCheckoutPoint?.filter(item => item.status === 'paid')
    }
    if (checkoutPoints && checkoutPoints.length) {
      checkoutPoints.forEach((o) => {
        total += o.value
      })
    }
    if (!order?.applyCoupon && order?.coupon?.creator === 'host') {
      switch (order?.coupon?.valueType) {
        case 'money':
          total -= order?.coupon?.value
          break
        case 'percent':
          total -= ((100 - order?.coupon?.value) * total) / 100
          break
        default:
      }
    }
    if (order?.setupFee) {
      total += order?.setupFee
    }
    return total
  }

  const getCheckout = (order) => {
    let time = order.checkout
    let lastPaidCheckout = last(order?.addCheckoutPoint?.filter(item => item.status !== 'reject'))
    if (moment().valueOf() > lastPaidCheckout?.checkout) {
      lastPaidCheckout = last(order?.addCheckoutPoint?.filter(item => item.status === 'paid'))
    }
    if (lastPaidCheckout) {
      time = lastPaidCheckout.checkout
    }
    return time
  }

  const confirmPunishDeposit = async () => {
    console.log(sltReservation, punishDepositInfo)
    switch (sltReservation?.depositStatus) {
      case 'hold':
        try {
          if (hasDepositPunish) {
            if (punishDepositInfo.images?.length) {
              const punishImageUrls = await uploadApi.uploadImage(punishDepositInfo.images.filter(file => !file.isLink).map(item => item.file), `Customer/${sltReservation?.customer?.code}/Reservation/${sltReservation?.code}`)
              punishDepositInfo.images = punishImageUrls?.map(item => item.shortUrl)
            }
            await orderApi.punishDeposit(sltReservation.id, punishDepositInfo)
            enqueueSnackbar('Phạt cọc cho đơn đặt phòng thành công.')
            applyFilter({})
          } else {
            await orderApi.releaseDeposit(sltReservation.id)
            enqueueSnackbar('Hoàn cọc cho đơn đặt phòng thành công.')
          }
        } catch (e) {
          enqueueSnackbar('Xác nhận không thành công.')
        }
        break

      case 'customer-complain':
        try {
          await orderApi.agreePunishDeposit(sltReservation.id, aggreeWithCustomer === '1')
          enqueueSnackbar('Xác nhận phạt cọc cho đơn đặt phòng thành công.')
          applyFilter({})
        } catch (e) {
          enqueueSnackbar('Xác nhận phạt cọc cho đơn đặt phòng không thành công.')
        }
        break

      default:
        break
    }
    setHasDepositPunish(true)
    setOpenDepositDialog(false)
  }

  const getPunishDepositInfoFromHost = (order) => {
    const {
      depositPunishInfo,
    } = order
    const amount = depositPunishInfo?.find(d => d.from === 'host')?.amount || 0
    const description = depositPunishInfo?.find(d => d.from === 'host')?.description || ''
    return `Bạn đã đưa ra số tiền phạt trước đó là ${formatPrice(amount)}VND với lý do ${description}.`
  }

  const getPunishDepositInfoFromCustomer = (order) => {
    const {
      depositPunishInfo,
    } = order
    const amount = depositPunishInfo?.find(d => d.from === 'customer')?.amount || 0
    const description = depositPunishInfo?.find(d => d.from === 'customer')?.description || ''
    return `Khách hàng không đồng ý với mức phạt trên và đề xuất mức phạt mới: ${formatPrice(amount)}VND với lý do ${description}.`
  }

  const getConfirmOrderHeader = () => {
    if (!sltReservation) return null
    const {
      status,
      addCheckoutPoint,
    } = sltReservation
    const lastCheckoutPoint = addCheckoutPoint && addCheckoutPoint.length ? addCheckoutPoint[addCheckoutPoint.length - 1] : null
    switch (status) {
      //! Trạng thái tương ứng với đơn mới đặt chưa thanh toán
      case 'processing':
        return (
          <h3>Xác nhận đơn đặt phòng</h3>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa được hoàn tiền với phòng cần xác nhận
      case 'processing-refund-in-update':
        return (
          <h3>Xác nhận chỉnh sửa đơn đặt phòng</h3>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa được hoàn tiền với phòng xác nhận ngay và hoàn tiền vào ngân hàng
      case 'waiting-refund-in-update':
        return (
          <h3>Xác nhận chỉnh sửa đơn đặt phòng</h3>
        )
      case 'host-confirm-refund-update':
        return (
          <h3>Chờ xác nhận hoàn tiền sau chỉnh sửa</h3>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa cần thanh toán thêm với phòng cần xác nhận
      case 'processing-pay-extra':
        return (
          <h3>Xác nhận chỉnh sửa đơn đặt phòng</h3>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa cần thanh toán thêm với phòng xác nhận ngay
      case 'confirmed-pay-extra':
        return (
          <h3>Xác nhận chỉnh sửa đơn đặt phòng</h3>
        )
      //! Trạng thái tương ứng với đơn đặt ở thêm, chờ xác nhận thanh toán qua tài khoản ngân hàng
      case 'add-checkout-processing':
        if (lastCheckoutPoint?.status === 'processing') {
          return (
            <h3>Xác nhận đặt thêm</h3>
          )
        }
        return null
      default:
        return null
    }
  }

  const getConfirmOrderContent = () => {
    if (!sltReservation) return null
    const {
      status,
      addCheckoutPoint,
      extraFund,
    } = sltReservation
    const lastCheckoutPoint = addCheckoutPoint && addCheckoutPoint.length ? addCheckoutPoint[addCheckoutPoint.length - 1] : null
    switch (status) {
      //! Trạng thái tương ứng với đơn mới đặt chưa thanh toán
      case 'processing':
        return (
          <div>
            <span>Xác nhận đơn đặt phòng </span>
            <b>{sltReservation?.code}</b>
          </div>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa được hoàn tiền với phòng cần xác nhận
      case 'processing-refund-in-update':
        return (
          <div>
            <div>
              <span>Xác nhận chỉnh sửa đơn đặt phòng </span>
              <b>{sltReservation?.code}</b>
            </div>
          </div>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa được hoàn tiền với phòng xác nhận ngay và hoàn tiền vào ngân hàng
      case 'waiting-refund-in-update':
      case 'host-confirm-refund-update':
        return (
          <div>
            <div>
              <span>Xác nhận chỉnh sửa đơn đặt phòng </span>
              <b>{sltReservation?.code}</b>
            </div>
          </div>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa cần thanh toán thêm với phòng cần xác nhận
      case 'processing-pay-extra':
        return (
          <div>
            <div>
              <span>Xác nhận chỉnh sửa đơn đặt phòng </span>
              <b>{sltReservation?.code}</b>
            </div>
            <span>Sau khi đơn đặt phòng được xác nhận, khách hàng sẽ phải thanh toán thêm số tiền </span>
            <span><b>{`${formatPrice(Math.abs(extraFund))} VND.`}</b></span>
          </div>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa cần thanh toán thêm với phòng xác nhận ngay
      case 'confirmed-pay-extra':
        return null
      //! Trạng thái tương ứng với đơn đặt ở thêm, chờ xác nhận thanh toán qua tài khoản ngân hàng
      case 'add-checkout-processing':
        if (lastCheckoutPoint?.status === 'processing') {
          return (
            <div>
              <div>
                <span>Xác nhận đặt thêm cho đơn đặt phòng </span>
                <b>{sltReservation?.code}</b>
              </div>
              <div>
                <span>Thời gian </span>
                <span><b>{`${lastCheckoutPoint?.amount} tiếng.`}</b></span>
              </div>
              <div>
                <span>Số tiền </span>
                <span><b>{`${formatPrice(lastCheckoutPoint?.value)} VND.`}</b></span>
              </div>
            </div>
          )
        }
        return null
      default:
        return null
    }
  }

  useEffect(() => {
    const reservation = reservations.find(item => item.code === sltReservation?.code)
    if (!reservation) {
      return () => {}
    }
    if (openRejectDialog) {
      switch (reservation?.status) {
        case 'paid':
          getWarning()
          break
        default:
      }
    }
    return () => {
    }
  }, [openRejectDialog])

  const showOnDesktop = () => [{
    Head: 'Mã',
    Cell: (value, origin) => (
      <div className="d-flex align-items-center">
        {moment.duration(moment().diff(moment(origin.updatedAt))).asMinutes() > 15 && origin.status === 'processing'
          ? <FiberManualRecordIcon fontSize="small" style={{ color: '#ffa854', marginRight: 8 }} />
          : null}
        <RouterLink to={`/reservation/${value}/view`} className={classes.codeCell}>
          <Typography variant="body1">{value}</Typography>
          <Typography variant="body2">
            {moment(origin.createdAt).format('DD/MM/YYYY')}
          </Typography>
        </RouterLink>
      </div>
    ),
    accessor: 'code',
    sortable: false,
  },
  {
    Head: 'Phòng',
    Cell: (value, origin) => (
      <>
        <a href={`${AppConfig.endpoint.customer}/phong-can-ho/${origin.room.code}`} target="_blank" rel="noopener noreferrer">
          <Tooltip title={value.name} aria-label={value.name}>
            <Typography variant="body1">{value.name}</Typography>
          </Tooltip>
        </a>
        <Typography variant="body2">
          <div>{`Mã: ${value.code}`}</div>
          <div>{renderRoomType(value.bookingType)}</div>
        </Typography>
      </>
    ),
    accessor: 'room',
    sortable: false,
  },
  {
    Head: 'Tổng tiền',
    Cell: (value, origin) => (
      <>
        <Typography variant="body1">
          {origin.prevState ? `${formatPrice(getPrevPrice(origin))}` : `${formatPrice(getPrice(origin))}`}
        </Typography>
        {
          origin.prevState
            ? origin.prevState.services && origin.prevState.services.length
              ? (
                <Typography variant="body2">
                  <a
                    onClick={() => {
                      setOpenServiceDialog(true)
                      setServices(origin.prevState.services)
                    }}
                    type="button"
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    {`${origin.prevState.services && origin.prevState.services.length ? `${origin.prevState.services.length} dịch vụ` : ''}`}
                  </a>
                </Typography>
              )
              : null
            : origin.services && origin.services.length
              ? (
                <Typography variant="body2">
                  <a
                    onClick={() => {
                      setOpenServiceDialog(true)
                      setServices(origin.services)
                    }}
                    type="button"
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    {`${origin.services && origin.services.length ? `${origin.services.length} dịch vụ` : ''}`}
                  </a>
                </Typography>
              )
              : null
        }
        {
          !origin?.applyCoupon && origin?.coupon?.creator === 'host'
            ? (
              <Typography variant="body2">
                {`Mã khuyến mãi (${origin?.coupon?.code}): ${origin?.coupon?.valueType === 'money' ? `${formatPrice(origin?.coupon?.value)}VND` : `${origin?.coupon?.value}%`}`}
              </Typography>
            )
            : null
        }
        {
          origin.depositWith === '2home'
            ? (
              <Typography variant="body2">
                {`Đặt cọc: ${formatPrice(origin.depositAmount)}`}
              </Typography>
            )
            : <Typography variant="body2">&nbsp;</Typography>
        }
        {
          origin.prevState
            ? (
              <div>
                <ArrowDownward className="mx-auto" fontSize="small" />
                <Typography variant="body1" className="text-left">
                  {`${formatPrice(getPrice(origin))}`}
                </Typography>
                {
                  origin.services && origin.services.length
                    ? (
                      <Typography variant="body2">
                        <a
                          onClick={() => {
                            setOpenServiceDialog(true)
                            setServices(origin.services)
                          }}
                          type="button"
                          onKeyDown={() => {}}
                          role="button"
                          tabIndex={0}
                        >
                          {`${origin.services && origin.services.length ? `${origin.services.length} dịch vụ` : ''}`}
                        </a>
                      </Typography>
                    )
                    : null
                }
              </div>
            )
            : null
        }
        {/* {
          origin.extraFund
            ? (
              <div>
                <ArrowDownward className="mx-auto" fontSize="small" />
                <Typography variant="body1" className="text-left">
                  {
                    origin.extraFund > 0
                      ? (<Typography variant="body2">Thanh toán thêm:</Typography>)
                      : (<Typography variant="body2">Hoàn tiền:</Typography>)
                  }
                  <div>{`${formatPrice(origin.extraFund)}`}</div>
                </Typography>
              </div>
            )
            : null
        } */}
      </>
    ),
    accessor: 'totalPrice',
    sortable: true,
  },
  {
    Head: 'Thời gian',
    Cell: (value, origin) => (
      <div className="text-left">
        {
          origin.prevState
            ? (
              <div className="text-center">
                <Typography variant="body1" className="d-flex">
                  <div style={{ width: '40%' }}>
                    <div>{moment(origin.prevState.checkin).format('HH:mm')}</div>
                    <div>{moment(origin.prevState.checkin).format('DD/MM')}</div>
                  </div>
                  <div style={{ margin: 'auto 20px' }}>-</div>
                  <div style={{ width: '40%' }}>
                    <div>{moment(getCheckout(origin.prevState)).format('HH:mm')}</div>
                    <div>{moment(getCheckout(origin.prevState)).format('DD/MM')}</div>
                  </div>
                </Typography>
                <ArrowDownward className="mx-auto" fontSize="small" />
              </div>
            )
            : null
        }
        <Typography variant="body1" className="d-flex text-center">
          <div style={{ width: '40%' }}>
            <div>{moment(origin.roomCheckin).format('HH:mm')}</div>
            <div>{moment(origin.roomCheckin).format('DD/MM')}</div>
          </div>
          <div style={{ margin: 'auto 20px' }}>-</div>
          <div style={{ width: '40%' }}>
            <div>{moment(getCheckout(origin)).format('HH:mm')}</div>
            <div>{moment(getCheckout(origin)).format('DD/MM')}</div>
          </div>
        </Typography>
        {/* {origin.useServiceAt
          ? (
            <Typography variant="body2" className="d-flex">
              <div style={{ width: '40%' }}>
                <div>{moment(origin.roomCheckin).format('HH:mm')}</div>
                <div>{moment(origin.roomCheckin).format('DD/MM/YYYY')}</div>
              </div>
              <div style={{ margin: 'auto 20px' }}>-</div>
              <div style={{ width: '40%' }}>
                <div>{moment(origin.useServiceAt).format('HH:mm')}</div>
                <div>{moment(origin.useServiceAt).format('DD/MM/YYYY')}</div>
              </div>
            </Typography>
          )
          : null} */}
      </div>
    ),
    accessor: 'checkin',
    sortable: false,
  },
  {
    Head: 'Trạng thái',
    Cell: (value, origin) => renderStatus(value, origin),
    accessor: 'status',
    sortable: false,
  },
  {
    Head: '',
    Cell: (value, origin) => renderAction(value, origin),
    accessor: 'status',
  }]

  const showOnMobile = () => [{
    Head: 'Mã - Căn hộ',
    Cell: (value, origin) => (
      <div style={{width: 120}}>
        <div className="d-flex align-items-center">
          {moment.duration(moment().diff(moment(origin.updatedAt))).asMinutes() > 15 && origin.status === 'processing'
            ? <FiberManualRecordIcon fontSize="small" style={{ color: '#ffa854', marginRight: 8 }} />
            : null}
          <RouterLink to={`/reservation/${value}/view`} className={classes.codeCell}>
            <Typography variant="body1"><b>{value}</b></Typography>
          </RouterLink>
        </div>
        <div>
          <a href={`${AppConfig.endpoint.customer}/phong-can-ho/${origin.room.code}`} target="_blank" rel="noopener noreferrer">
            <Tooltip title={origin.room.name} aria-label={origin.room.name}>
              <Typography variant="body1" className="text-truncate">{origin.room.name}</Typography>
            </Tooltip>
          </a>
          <Typography variant="body2">
            <div>{`Mã: ${origin.room.code}`}</div>
            {/* <div>{renderRoomType(origin.room.bookingType)}</div> */}
          </Typography>
          <Typography variant="body2">
            {moment(origin.createdAt).format('DD/MM/YYYY')}
          </Typography>
          {renderStatus(origin.status, origin)}
        </div>
      </div>
    ),
    accessor: 'code',
    sortable: false,
  },
  {
    Head: 'Tổng tiền',
    Cell: (value, origin) => (
      <>
        <Typography variant="body1">
          {origin.prevState ? `${formatPrice(getPrevPrice(origin))}` : `${formatPrice(getPrice(origin))}`}
        </Typography>
        {
          origin.prevState
            ? origin.prevState.services && origin.prevState.services.length
              ? (
                <Typography variant="body2">
                  <a
                    onClick={() => {
                      setOpenServiceDialog(true)
                      setServices(origin.prevState.services)
                    }}
                    type="button"
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    {`${origin.prevState.services && origin.prevState.services.length ? `${origin.prevState.services.length} dịch vụ` : ''}`}
                  </a>
                </Typography>
              )
              : null
            : origin.services && origin.services.length
              ? (
                <Typography variant="body2">
                  <a
                    onClick={() => {
                      setOpenServiceDialog(true)
                      setServices(origin.services)
                    }}
                    type="button"
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    {`${origin.services && origin.services.length ? `${origin.services.length} dịch vụ` : ''}`}
                  </a>
                </Typography>
              )
              : null
        }
        {
          !origin?.applyCoupon && origin?.coupon?.creator === 'host'
            ? (
              <Typography variant="body2">
                {`Mã khuyến mãi (${origin?.coupon?.code}): ${origin?.coupon?.valueType === 'money' ? `${formatPrice(origin?.coupon?.value)}VND` : `${origin?.coupon?.value}%`}`}
              </Typography>
            )
            : null
        }
        {
          origin.depositWith === '2home'
            ? (
              <Typography variant="body2">
                {`Đặt cọc: ${formatPrice(origin.depositAmount)}`}
              </Typography>
            )
            : <Typography variant="body2">&nbsp;</Typography>
        }
        {
          origin.prevState
            ? (
              <div>
                <ArrowDownward className="mx-auto" fontSize="small" />
                <Typography variant="body1" className="text-left">
                  {`${formatPrice(getPrice(origin))}`}
                </Typography>
                {
                  origin.services && origin.services.length
                    ? (
                      <Typography variant="body2">
                        <a
                          onClick={() => {
                            setOpenServiceDialog(true)
                            setServices(origin.services)
                          }}
                          type="button"
                          onKeyDown={() => {}}
                          role="button"
                          tabIndex={0}
                        >
                          {`${origin.services && origin.services.length ? `${origin.services.length} dịch vụ` : ''}`}
                        </a>
                      </Typography>
                    )
                    : null
                }
              </div>
            )
            : null
        }
        {/* {
          origin.extraFund
            ? (
              <div>
                <ArrowDownward className="mx-auto" fontSize="small" />
                <Typography variant="body1" className="text-left">
                  {
                    origin.extraFund > 0
                      ? (<Typography variant="body2">Thanh toán thêm:</Typography>)
                      : (<Typography variant="body2">Hoàn tiền:</Typography>)
                  }
                  <div>{`${formatPrice(origin.extraFund)}`}</div>
                </Typography>
              </div>
            )
            : null
        } */}
      </>
    ),
    accessor: 'totalPrice',
    sortable: true,
  },
  {
    Head: 'Thời gian',
    Cell: (value, origin) => (
      <div className="text-left">
        {
          origin.prevState
            ? (
              <div className="text-center">
                <Typography variant="body1" className="d-flex">
                  <div style={{ width: '40%' }}>
                    <div>{moment(origin.prevState.checkin).format('HH:mm')}</div>
                    <div>{moment(origin.prevState.checkin).format('DD/MM')}</div>
                  </div>
                  <div style={{ margin: 'auto 5px' }}>-</div>
                  <div style={{ width: '40%' }}>
                    <div>{moment(getCheckout(origin.prevState)).format('HH:mm')}</div>
                    <div>{moment(getCheckout(origin.prevState)).format('DD/MM')}</div>
                  </div>
                </Typography>
                <ArrowDownward className="mx-auto" fontSize="small" />
              </div>
            )
            : null
        }
        <Typography variant="body1" className="d-flex text-center">
          <div style={{ width: '40%' }}>
            <div>{moment(origin.roomCheckin).format('HH:mm')}</div>
            <div>{moment(origin.roomCheckin).format('DD/MM')}</div>
          </div>
          <div style={{ margin: 'auto 5px' }}>-</div>
          <div style={{ width: '40%' }}>
            <div>{moment(getCheckout(origin)).format('HH:mm')}</div>
            <div>{moment(getCheckout(origin)).format('DD/MM')}</div>
          </div>
        </Typography>
        {/* {origin.useServiceAt
          ? (
            <Typography variant="body2" className="d-flex">
              <div style={{ width: '40%' }}>
                <div>{moment(origin.roomCheckin).format('HH:mm')}</div>
                <div>{moment(origin.roomCheckin).format('DD/MM/YYYY')}</div>
              </div>
              <div style={{ margin: 'auto 20px' }}>-</div>
              <div style={{ width: '40%' }}>
                <div>{moment(origin.useServiceAt).format('HH:mm')}</div>
                <div>{moment(origin.useServiceAt).format('DD/MM/YYYY')}</div>
              </div>
            </Typography>
          )
          : null} */}
      </div>
    ),
    accessor: 'checkin',
    sortable: false,
  },
  {
    Head: '',
    Cell: (value, origin) => renderAction(value, origin),
    accessor: 'status',
  }]

  return (
    <>
      {/* Service dialog */}
      <Dialog
        open={openServiceDialog}
        onClose={() => setOpenServiceDialog(false)}
      >
        <DialogTitle disableTypography>
          <h4>Chi tiết dịch vụ</h4>
        </DialogTitle>
        <DialogContent style={{ width: 400 }}>
          <List>
            {services
              && services.map(service => (
                <ListItem key={service.id}>
                  <ListItemAvatar>
                    <Avatar className={classes.serviceThumbnail} src={`${AppConfig.BASE_IMG_URL}/${service.image}`} />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.serviceName}
                    primary={(
                      <RouterLink to="" target="_blank" className={classes.link}>
                        {service.name}
                      </RouterLink>
                    )}
                  />
                  {/* <ListItemText
                    className={classes.servicePrice}
                    primary={`${formatPrice(service.price)} `}
                  /> */}
                </ListItem>
              ))}
          </List>
        </DialogContent>
      </Dialog>
      {/* Reject dialog */}
      <Dialog
        open={openRejectDialog}
        onClose={() => {
          setOpenRejectDialog(false)
          setSltReservation(undefined)
          setWarningMessage('')
        }}
      >
        <DialogTitle disableTypography>
          <h4>Huỷ đơn đặt phòng</h4>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80 }}>
          {
            sltReservation?.status === 'lock'
              ? (
                <Grid container spacing={3}>
                  <Grid item>
                    {`Xác nhận huỷ đơn khoá phòng ${sltReservation?.code}`}
                  </Grid>
                </Grid>
              )
              : (
                <>
                  <Grid container spacing={3}>
                    <Grid item>
                      {'Xác nhận huỷ đơn đặt phòng '}
                      <b>{sltReservation?.code}</b>
                    </Grid>
                  </Grid>
                  {warningMessage
                    ? (
                      <Grid container>
                        {/* Warning */}
                        <p dangerouslySetInnerHTML={{ __html: warningMessage }} />
                      </Grid>
                    )
                    : null}
                  <Grid container>
                    <TextField
                      label="Lý do huỷ"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      value={rejectNote}
                      onChange={e => setRejectNote(e.target.value)}
                      className={classes.formControl}
                    />
                  </Grid>
                </>
              )
            }
        </DialogContent>
        <DialogActions>
          <Button onClick={rejectReservation} color="primary">
            Huỷ đơn
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirm dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle disableTypography>
          {getConfirmOrderHeader()}
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80, paddingTop: 0 }}>
          {getConfirmOrderContent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmReservation} color="primary">
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      {/* Undo dialog */}
      <Dialog
        open={openUndoDialog}
        onClose={() => {
          setOpenUndoDialog(false)
          setSltReservation(undefined)
        }}
      >
        <DialogTitle disableTypography>
          <h4>Huỷ thay đổi đơn đặt phòng</h4>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80, paddingTop: 0 }}>
          <div className="mb-2">
            Không đồng ý thay đổi đơn đặt phòng
            {' '}
            <b>{sltReservation?.code}</b>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={undoReservation} color="primary">
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      {/* Phạt cọc */}
      <Dialog
        open={openDepositDialog}
        onClose={() => {
          setOpenDepositDialog(false)
          setHasDepositPunish(true)
          setSltReservation(undefined)
        }}
        PaperProps={{ style: { overflowY: 'unset', maxWidth: '100%' } }}
      >
        <DialogTitle disableTypography>
          <h4>Yêu cầu phạt cọc</h4>
        </DialogTitle>
        <DialogContent style={{ minWidth: '60vw', minHeight: 80, overflowY: 'hidden' }}>
          {
            sltReservation?.depositStatus === 'hold'
              ? hasDepositPunish
                ? (
                  <>
                    {/* Thông tin phạt cọc */}
                    <Grid container spacing={3}>
                      <Grid item md={4}>
                        <Typography variant="body1" className="mb-2">Số tiền phạt yêu cầu</Typography>
                        <CurrencyInput
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={null}
                          onChange={v => setPunishDepositInfo({ ...punishDepositInfo, amount: v })}
                          placeholder="Nhập số tiền"
                        />
                      </Grid>
                      <Grid item md={8}>
                        <Typography variant="body1">Lý do phạt</Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={3}
                          value={null}
                          onChange={e => setPunishDepositInfo({ ...punishDepositInfo, description: e.target.value })}
                          className={classes.formControl}
                        />
                      </Grid>
                    </Grid>
                    {/* Ảnh chứng minh */}
                    <Grid container spacing={3}>
                      <Grid item md={4}>
                        <Typography variant="body1">Tải lên ảnh bằng chứng</Typography>
                        <Upload
                          files={[]}
                          multiple
                          onChange={files => setPunishDepositInfo({ ...punishDepositInfo, images: [...files] })}
                          uploadComponent={(
                            <div className="w-100 border d-flex" style={{ height: 120, borderStyle: 'dashed !important' }}>
                              <Add fontSize="large" className="m-auto" />
                            </div>
                        )}
                        />
                      </Grid>
                      <Grid item md={8} className="mt-2">
                        <Typography variant="body1">Ảnh bằng chứng</Typography>
                        <Upload
                          files={punishDepositInfo.images}
                          onChange={files => setPunishDepositInfo({ ...punishDepositInfo, images: [...files] })}
                          multiple
                          readOnly
                        />
                      </Grid>
                    </Grid>
                    {/* Mô tả */}
                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <Typography variant="body1">
                        Nếu khách hàng đồng ý với số tiền bạn đưa ra hoặc không phản hồi sau 24 giờ, chung tôi sẽ thông báo và chuyển số tiền này vào tài khoản của bạn.
                        </Typography>
                        <Typography variant="body1">
                        Nếu khách hàng không đồng ý và đưa ra một số tiền đề xuất khác (trong vòng 24 giờ).
                          <ul className="ml-4">
                            <li>Bạn đồng ý với số tiền khách hàng đề xuất, số tiền này sẽ được chuyển vào tài khoản của bạn.</li>
                            <li>Bạn không đồng ý với đề xuất, 2home sẽ can thiệp giải quyết với các bên (thời hạn giải quyết 5 ngày).</li>
                          </ul>
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )
                : (
                  <Grid container spacing={3}>
                    <Grid item md={12}>
                      <Typography variant="body1">Xác nhận hoàn 100% tiền cọc cho khách hàng</Typography>
                    </Grid>
                  </Grid>
                )
              : sltReservation?.depositStatus === 'customer-complain'
                ? (
                  <>
                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <Typography variant="body1">
                          {getPunishDepositInfoFromHost(sltReservation)}
                        </Typography>
                      </Grid>
                      {
                        sltReservation?.depositPunishInfo?.find(d => d.from === 'host')?.images?.map(image => (
                          <img src={`${AppConfig.BASE_IMG_URL}/${image}`} width={120} alt="host-prove" />
                        ))
                      }
                      <Grid item md={12}>
                        <Typography variant="body1">
                          {getPunishDepositInfoFromCustomer(sltReservation)}
                        </Typography>
                      </Grid>
                      {
                        sltReservation?.depositPunishInfo?.find(d => d.from === 'customer')?.images?.map(image => (
                          <img src={`${AppConfig.BASE_IMG_URL}/${image}`} width={120} alt="customer-prove" />
                        ))
                      }
                      <Grid item md={12}>
                        <RadioGroup name="isAgree" value={aggreeWithCustomer} onChange={e => setAgreeWithCustomer(e.target.value)}>
                          <FormControlLabel value="1" control={<Radio />} label="Tôi đồng ý số tiền mà khách hàng đề xuất" />
                          <FormControlLabel value="2" control={<Radio />} label="Tôi không đồng ý, cần có sự can thiệp của 2home" />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </>
                )
                : null
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmPunishDeposit} color="primary">
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      {/* Add time dialog */}
      <AddCheckoutPointDialog
        classes={classes}
        reservation={sltReservation}
        open={openAddTimeDialog}
        onClose={() => setOpenAddTimeDialog(false)}
        onConfirm={addTimeReservation}
      />
      {/* Reservation list */}
      <Table
        data={reservations}
        columns={isDesktop ? showOnDesktop() : showOnMobile()}
        loading={loading}
        page={meta.page}
        limit={meta.limit}
        total={meta.total}
        defaultSort={defaultSort}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  )
}

ReservationsTable.propTypes = {
  classes: PropTypes.shape(),
  loading: PropTypes.bool,
  reservations: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape(),
  defaultSort: PropTypes.shape(),
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  applyFilter: PropTypes.func,
  // confirm: PropTypes.func,
  reject: PropTypes.func,
  // undo: PropTypes.func,
  // view: PropTypes.func,
  addCheckout: PropTypes.func,
}

export default ReservationsTable
