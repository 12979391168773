import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/styles'
import { useMediaQuery, CircularProgress } from '@material-ui/core'
import { getUserData } from 'redux/selector/user'
import { getNotificationData } from 'redux/selector/notification'
import * as userAction from 'redux/action/user'
import * as notificationAction from 'redux/action/notification'
import * as socketAction from 'redux/action/socket'
import { SnackbarProvider } from 'notistack'
import { getSocketData } from 'redux/selector/socket'
import { Sidebar, Topbar, Footer } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
    '@media (max-width:1280px)': {
      paddingLeft: 240,
    },
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}))

const Main = ({
  children,
  user,
  notification,
  socket,
  getInfo,
  loading,
  signout,
  getNotification,
  updateNotification,
  resetSocket,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })
  const [openSidebar, setOpenSidebar] = useState(false)

  useEffect(() => {
    if (!user || !user.data || !user.data.id) {
      getInfo()
      getNotification()
    }
    return () => {}
    // eslint-disable-next-line
  }, [])

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  const shouldOpenSidebar = isDesktop ? true : openSidebar

  if (!user || !user.data || !user.data.id) return null

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar
        socket={socket}
        onSidebarOpen={handleSidebarOpen}
        signout={signout}
        notifications={notification.data}
        updateNotification={updateNotification}
        resetSocket={resetSocket}
      />
      <Sidebar
        user={user.data}
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
        anchor={isDesktop ? 'left' : 'right'}
        resetSocket={resetSocket}
        signout={signout}
      />
      {loading || !user.data.id ? (
        <CircularProgress />
      ) : (
        <main className={classes.content}>
          <SnackbarProvider
            autoHideDuration={2000}
            maxSnack={3}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
          >
            {children}
          </SnackbarProvider>
          <Footer />
        </main>
      )}
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node,
  notification: PropTypes.shape(),
  getNotification: PropTypes.func,
  updateNotification: PropTypes.func,
  user: PropTypes.shape(),
  data: PropTypes.shape(),
  socket: PropTypes.shape(),
  getInfo: PropTypes.func,
  signout: PropTypes.func,
  loading: PropTypes.bool,
  resetSocket: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  getInfo: () => dispatch(userAction.get()),
  signout: () => dispatch(userAction.signout()),
  getNotification: () => dispatch(notificationAction.find()),
  updateNotification: id => dispatch(notificationAction.update(id)),
  resetSocket: type => dispatch(socketAction.reset(type)),
})

export default connect(
  state => ({
    notification: getNotificationData(state),
    user: getUserData(state),
    ...getSocketData(state),
  }),
  mapDispatchToProps,
)(Main)
