import React from 'react'
import PropTypes from 'prop-types'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { debounce } from 'underscore'

const CurrencyInput = ({
  classes, onChange, value, ...props
}) => {
  const debounceFn = debounce((v) => {
    onChange(parseInt(v?.replace(/,/g, ''), 0))
  }, 800)

  const handleChange = (e) => {
    debounceFn(e.target.value)
  }

  return (
    <CurrencyTextField
      currencySymbol="VND"
      outputFormat="number"
      decimalPlaces={0}
      {...props}
      value={value || 0}
      onChange={handleChange}
    />
  )
}

CurrencyInput.propTypes = {
  classes: PropTypes.shape(),
  onChange: PropTypes.func,
  value: PropTypes.number,
}

export default CurrencyInput
