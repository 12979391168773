import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Switch, Redirect } from 'react-router-dom'
import { getSocketData } from 'redux/selector/socket'
import { setMessage } from 'redux/action/socket'
// import CustomerReportPage from 'container/CustomerReport'
import { RouteWithLayout } from './components'
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Null as NullLayout,
} from './layouts'
import SigninContainer from './container/Signin'
// import StaffContainer from './container/Staff'
import ReservationContainer from './container/Reservation'
import RoomContainer from './container/Room'
import CustomerContainer from './container/Customer'
import PrepaidHistoryContainer from './container/PrepaidHistory'
import AccountContainer from './container/Account'
import HostContainer from './container/Host'
import StaffFormContainer from './container/StaffForm'
import HostFormContainer from './container/HostForm'
import CustomerFormContainer from './container/CustomerForm'
import ReservationFormContainer from './container/ReservationForm'
import ReservationInfoContainer from './container/ReservationInfo'
import ReservationSetRoomContainer from './container/ReservationSetRoom'
import CouponContainer from './container/Coupon'
import RoomFormContainer from './container/RoomForm'
import ServiceFormContainer from './container/ServiceForm'
import ServiceContainer from './container/Service'
import CouponFormContainer from './container/CouponForm'
import ReportContainer from './container/Report'
import OrderReportContainer from './container/OrderReport'
import RoomReportContainer from './container/RoomReport'
import InvoiceContainer from './container/Invoice'

import {
  Dashboard as DashboardView,
  Settings as SettingsView,
  SignUp as SignUpView,
  NotFound as NotFoundView,
  LandingPage as LandingPageView,
} from './views';

const Routes = ({ setSocketMessage }) => {
  useEffect(() => {
    // Integrate socket
    window.isSocketEnabled = setInterval(() => {
      if (window.io && window.io.sails) {
        clearInterval(window.isSocketEnabled)
        window.io.sails.url = 'http://localhost:1337'
        const mySocket = window.io.sails.connect()
        mySocket.on('connect', () => {
          mySocket.on('message', (body) => {
            // Send messsage from server: sails.sockets.broadcast([], { greeting: 'Hola!' })
            setSocketMessage(body)
          })
        })
      }
    }, 500)
    return () => {
    }
  }, [])

  return (
    <Switch>
      <RouteWithLayout
        exact
        path="/"
        component={LandingPageView}
        layout={NullLayout}
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={AccountContainer}
        exact
        layout={MainLayout}
        path="/profile"
      />
      <RouteWithLayout
        component={StaffFormContainer}
        exact
        layout={MainLayout}
        path="/user/create"
      />
      <RouteWithLayout
        component={StaffFormContainer}
        exact
        layout={MainLayout}
        path="/user/:id/:action"
      />
      <RouteWithLayout
        component={ReportContainer}
        exact
        layout={MainLayout}
        path="/report"
      />
      <RouteWithLayout
        component={OrderReportContainer}
        exact
        layout={MainLayout}
        path="/report-order"
      />
      <RouteWithLayout
        component={RoomReportContainer}
        exact
        layout={MainLayout}
        path="/report-room"
      />
      <RouteWithLayout
        component={InvoiceContainer}
        exact
        layout={MainLayout}
        path="/invoice"
      />
      {/* <RouteWithLayout
        component={CustomerReportPage}
        exact
        layout={MainLayout}
        path="/report-customer"
      /> */}
      <RouteWithLayout
        component={StaffFormContainer}
        exact
        layout={MainLayout}
        path="/user/:id/:action"
      />
      <RouteWithLayout
        component={ReservationContainer}
        exact
        layout={MainLayout}
        path="/reservations"
      />
      <RouteWithLayout
        component={ReservationFormContainer}
        exact
        layout={MainLayout}
        path="/reservation/create"
      />
      <RouteWithLayout
        component={ReservationInfoContainer}
        exact
        layout={MainLayout}
        path="/reservation/info"
      />
      <RouteWithLayout
        component={ReservationSetRoomContainer}
        exact
        layout={MainLayout}
        path="/reservation/set-room"
      />
      <RouteWithLayout
        component={ReservationFormContainer}
        exact
        layout={MainLayout}
        path="/reservation/:id/:action"
      />
      <RouteWithLayout
        component={RoomContainer}
        exact
        layout={MainLayout}
        path="/room"
      />
      <RouteWithLayout
        component={RoomFormContainer}
        exact
        layout={MainLayout}
        path="/room/create"
      />
      <RouteWithLayout
        component={RoomFormContainer}
        exact
        layout={MainLayout}
        path="/room/:id/:action"
      />
      <RouteWithLayout
        component={ServiceContainer}
        exact
        layout={MainLayout}
        path="/service"
      />
      <RouteWithLayout
        component={ServiceFormContainer}
        exact
        layout={MainLayout}
        path="/service/create"
      />
      <RouteWithLayout
        component={ServiceFormContainer}
        exact
        layout={MainLayout}
        path="/service/:id/:action"
      />
      <RouteWithLayout
        component={CustomerContainer}
        exact
        layout={MainLayout}
        path="/customer"
      />
      <RouteWithLayout
        component={CustomerFormContainer}
        exact
        layout={MainLayout}
        path="/customer/create"
      />
      <RouteWithLayout
        component={CustomerFormContainer}
        exact
        layout={MainLayout}
        path="/customer/:id/:action"
      />
      <RouteWithLayout
        component={HostContainer}
        exact
        layout={MainLayout}
        path="/host"
      />
      <RouteWithLayout
        component={HostFormContainer}
        exact
        layout={MainLayout}
        path="/host/create"
      />
      <RouteWithLayout
        component={HostFormContainer}
        exact
        layout={MainLayout}
        path="/host/:id/:action"
      />
      <RouteWithLayout
        component={PrepaidHistoryContainer}
        exact
        layout={MainLayout}
        path="/prepaid-history"
      />
      <RouteWithLayout
        component={AccountContainer}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={CouponContainer}
        exact
        layout={MainLayout}
        path="/coupon"
      />
      <RouteWithLayout
        component={CouponFormContainer}
        exact
        layout={MainLayout}
        path="/coupon/create"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SigninContainer}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}

const mapDispatchToProps = dispatch => ({
  setSocketMessage: data => dispatch(setMessage(data)),
})

Routes.propTypes = {
  setSocketMessage: PropTypes.func,
}

export default connect(getSocketData, mapDispatchToProps)(Routes)
