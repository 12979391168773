import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
// import * as notifyApi from 'provider/notification'
import {
  // useTheme,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles'
import {
  List,
  Typography,
  Box,
  Popover,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
  Badge,
  // Avatar,
  // IconButton,
  // Tabs,
  // Tab,
} from '@material-ui/core'
import PopupState from 'material-ui-popup-state'
import { DoneAll } from '@material-ui/icons'

const notificationStyle = makeStyles(() => ({
  container: {
    padding: 0,
  },
  item: {
    backgroundColor: '#e9e9e9',
    borderBottom: 'solid 1px #d2d2d2',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#dddddd',
    },
  },
  seen: {
    backgroundColor: 'white',
  },
}))

const NotificationItem = ({
  items,
  onClick,
  update,
}) => {
  const classes = notificationStyle()

  return (
    <List className={classes.container}>
      {items.map(item => (
        <RouterLink to={item.link}>
          <ListItem
            className={item.status ? `${classes.item} ${classes.seen}` : `${classes.item}`}
            key={item.subjectId}
            onClick={() => {
              update(item.id)
              if (onClick) {
                onClick()
              }
            }}
          >
            <ListItemAvatar>
              {item.icon}
            </ListItemAvatar>
            <ListItemText
              primary={item.title}
              secondary={(
                <>
                  <div style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: item.description }} />
                  <Typography variant="body2" style={{ color: '#999999' }}>
                    {moment(item.createdAt).format('DD/MM/YYYY HH:mm')}
                  </Typography>
                </>
              )}
            />
          </ListItem>
        </RouterLink>
      ))}
    </List>
  )
}

NotificationItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  onClick: PropTypes.func,
  update: PropTypes.func,
}

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -10,
    top: 1,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge)

const NotificationList = ({
  icon,
  label,
  count,
  data,
  unseen,
  total,
  page,
  onFetchData,
  setAsRead,
  update,
  resetSocket,
}) => {
  const buttonRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    if (onFetchData) {
      onFetchData()
    }
  }, [])

  const loadMore = () => {
    if (onFetchData) {
      onFetchData()
    }
  }

  const openPopup = () => {
    setAnchorEl(buttonRef.current)
    if (count) {
      resetSocket()
    }
  }

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {popupState => (
        <div>
          {/* Notification icon */}
          <Button
            color="inherit"
            ref={buttonRef}
            onClick={() => openPopup(popupState)}
            className="px-3"
          >
            <div>
              <StyledBadge badgeContent={unseen + (count || 0)} color="error" fontSize={10}>
                {icon}
              </StyledBadge>
              <div className="ml-1 text-center text-capitalize">
                <small>{label}</small>
              </div>
            </div>
          </Button>
          {/* Notification list */}
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box style={{ width: 600, maxHeight: 'calc(100vh - 250px)', overflowY: 'auto' }}>
              <NotificationItem
                onNotificationClick={popupState.close}
                update={update}
                items={data || []}
              />
            </Box>
            <div className="d-flex">
              <Button className="m-2" onClick={() => setAsRead()}>
                <DoneAll className="mr-1" />
                <span>Đánh dấu đã đọc</span>
              </Button>
              {
                total === -1 || (total > page * 10)
                  ? (
                    <Button className="m-2 ml-auto" onClick={() => loadMore()}>Tải thêm</Button>
                  )
                  : null
              }
            </div>
          </Popover>
        </div>
      )
      }
    </PopupState>
  )
}

NotificationList.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  count: PropTypes.number,
  unseen: PropTypes.number,
  total: PropTypes.number,
  page: PropTypes.number,
  onFetchData: PropTypes.func,
  setAsRead: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape()),
  update: PropTypes.func,
  resetSocket: PropTypes.func,
}

export default NotificationList
