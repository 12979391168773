import { connect } from 'react-redux'
import { PrepaidHistoryList as PrepaidHistoryPage } from 'views'
import { getPrepaidHistoryData } from 'redux/selector/prepaidHistory'
import { find, update } from 'redux/action/prepaidHistory'

const mapDispatchToProps = dispatch => ({
  find: query => dispatch(find(query)),
  update: data => dispatch(update(data)),
})

export default connect(getPrepaidHistoryData, mapDispatchToProps)(PrepaidHistoryPage)
