import AppConfig from 'config'
import qs from 'query-string'

export const LOGIN = `${AppConfig.endpoint.api}/host/login`
export const GET_USER = `${AppConfig.endpoint.api}/host/get-info`
export const UPDATE_USER = id => `${AppConfig.endpoint.api}/host/${id}`
export const GET_CITY = `${AppConfig.endpoint.api}/city`
export const DISTRICT = `${AppConfig.endpoint.api}/district`
export const GET_DISTRICT = `${AppConfig.endpoint.api}/district`
export const BUILDING = `${AppConfig.endpoint.api}/building`
export const GET_BUILDING = `${AppConfig.endpoint.api}/building`
export const GET_ROOM_SUGGESTION = searchTxt => `${AppConfig.endpoint.api}/host/room/suggestion?text=${searchTxt}`
export const GET_BUILDING_SUGGESTION = searchTxt => `${AppConfig.endpoint.api}/building/suggestion?text=${searchTxt}`
export const GET_SERVICE_TYPE = `${AppConfig.endpoint.api}/service-type?limit=${Number.MAX_SAFE_INTEGER}`
export const GET_SERVICE_OPTION = `${AppConfig.endpoint.api}/service-option?limit=${Number.MAX_SAFE_INTEGER}`
export const GET_SERVICE_COMPONENT = `${AppConfig.endpoint.api}/service-component?limit=${Number.MAX_SAFE_INTEGER}`
export const AMENITY = `${AppConfig.endpoint.api}/amenity`

// Hạng thành viên
export const GET_MEMBERSHIP = `${AppConfig.endpoint.api}/membership`

// Gói trả trước
export const GET_PACKAGE = `${AppConfig.endpoint.api}/prepaid-package`

// Quyền
export const GET_ROLE = `${AppConfig.endpoint.api}/role`

// Nhân viên
export const FETCH_STAFF = query => `${AppConfig.endpoint.api}/user${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_STAFF_BY_ID = id => `${AppConfig.endpoint.api}/user/${id}`
export const GET_USER_SUGGESTION = searchTxt => `${AppConfig.endpoint.api}/user/suggestion?text=${searchTxt}`
export const CREATE_STAFF = `${AppConfig.endpoint.api}/user/create`
export const UPDATE_STAFF = id => `${AppConfig.endpoint.api}/user/${id}`
export const DELETE_STAFF = `${AppConfig.endpoint.api}/user`

// Đơn đặt phòng
export const UPDATE_RESERVATION = id => `${AppConfig.endpoint.api}/reservation/${id}`
export const FETCH_RESERVATION = query => `${AppConfig.endpoint.api}/host/reservation${
  query ? `?${qs.stringify(query)}` : ''
}`
export const FETCH_RESERVATION_BY_ID = id => `${AppConfig.endpoint.api}/host/reservation/${id}`
export const CREATE_RESERVATION = `${AppConfig.endpoint.api}/reservation`
export const RECALCULATE_RESERVATION = `${AppConfig.endpoint.api}/reservation/recalculate`
export const UPDATE_RESERVATION_STATUS = id => `${AppConfig.endpoint.api}/reservation/${id}/status`
export const GET_RESERVATION_SUGGESTION = searchTxt => `${AppConfig.endpoint.api}/reservation/suggestion?text=${searchTxt}`
export const PAY_RESERVATION = id => `${AppConfig.endpoint.api}/reservation/${id}/pay`
export const REJECT_RESERVATION = id => `${AppConfig.endpoint.api}/host/reservation/${id}/reject`
export const CONFIRM_RESERVATION = id => `${AppConfig.endpoint.api}/host/reservation/${id}/confirm`
export const UNDO_RESERVATION = id => `${AppConfig.endpoint.api}/host/reservation/${id}/undo`
export const WARNING = (id, mode) => `${AppConfig.endpoint.api}/reservation/${id}/warning?mode=${mode}`
export const PUNISH_DEPOSIT_RESERVATION = id => `${AppConfig.endpoint.api}/host/reservation/${id}/punish-deposit`
export const AGREE_PUNISH_DEPOSIT = id => `${AppConfig.endpoint.api}/host/reservation/${id}/agree-deposit`
export const RELEASE_DEPOSIT = id => `${AppConfig.endpoint.api}/host/reservation/${id}/release-deposit`
export const CONFIRM_CHECKOUT_POINT = id => `${AppConfig.endpoint.api}/host/reservation/${id}/confirm-checkout`
export const REJECT_CHECKOUT = id => `${AppConfig.endpoint.api}/host/reservation/${id}/reject-checkout`

// Phòng
export const FETCH_ROOM = query => `${AppConfig.endpoint.api}/host/room${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_ROOM_BY_ID = id => `${AppConfig.endpoint.api}/host/room/${id}`
export const GET_ROOM_TYPE = `${AppConfig.endpoint.api}/room-type`
export const CREATE_ROOM = `${AppConfig.endpoint.api}/host/room`
export const UPDATE_ROOM = `${AppConfig.endpoint.api}/host/room`
export const DELETE_ROOM = `${AppConfig.endpoint.api}/host/room`
export const RESTORE_ROOM = `${AppConfig.endpoint.api}/host/room/restore`
export const LOCK_ROOM = `${AppConfig.endpoint.api}/host/room/lock`
export const UPDATE_ROOM_STATUS = id => `${AppConfig.endpoint.api}/host/room/${id}/update-status`

// Dịch vụ
export const FETCH_SERVICE = query => `${AppConfig.endpoint.api}/service${query ? `?${qs.stringify(query)}` : ''}`
export const GET_SERVICE_SUGGESTION = searchTxt => `${AppConfig.endpoint.api}/service/suggestion?text=${searchTxt}`
export const FETCH_SERVICE_BY_ID = id => `${AppConfig.endpoint.api}/service/${id}`
export const CREATE_SERVICE = `${AppConfig.endpoint.api}/service`
export const UPDATE_SERVICE = `${AppConfig.endpoint.api}/service`
export const DELETE_SERVICE = `${AppConfig.endpoint.api}/service`

// Khách hàng
export const FETCH_CUSTOMER = query => `${AppConfig.endpoint.api}/customer${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_CUSTOMER_BY_ID = id => `${AppConfig.endpoint.api}/customer/${id}`
export const GET_CUSTOMER_SUGGESTION = searchTxt => `${AppConfig.endpoint.api}/customer/suggestion?text=${searchTxt}`
export const CREATE_CUSTOMER = `${AppConfig.endpoint.api}/customer`
export const UPDATE_CUSTOMER = id => `${AppConfig.endpoint.api}/customer/${id}`
export const DELETE_CUSTOMER = `${AppConfig.endpoint.api}/customer`

// Lịch sử mua gói
export const FETCH_PREPAID_HISTORY = query => `${AppConfig.endpoint.api}/prepaid-package-history${
  query ? `?${qs.stringify(query)}` : ''
}`
export const FETCH_PREPAID_HISTORY_BY_ID = id => `${AppConfig.endpoint.api}/prepaid-package-history/${id}`
export const UPDATE_PREPAID_HISTORY = id => `${AppConfig.endpoint.api}/prepaid-package-history/${id}`

// Chủ nhà
export const FETCH_HOST = query => `${AppConfig.endpoint.api}/host${query ? `?${qs.stringify(query)}` : ''}`
export const GET_HOST_SUGGESTION = searchTxt => `${AppConfig.endpoint.api}/host/suggestion?text=${searchTxt}`
export const FETCH_HOST_BY_ID = id => `${AppConfig.endpoint.api}/host/${id}`
export const CREATE_HOST = `${AppConfig.endpoint.api}/host`
export const UPDATE_HOST = id => `${AppConfig.endpoint.api}/host/${id}`
export const DELETE_HOST = `${AppConfig.endpoint.api}/host`
export const REGISTER_HOST = `${AppConfig.endpoint.api}/host/register`

// Phương thức thanh toán
export const FETCH_PAYMENT = query => `${AppConfig.endpoint.api}/payment-method${query ? `?${qs.stringify(query)}` : ''}`

// Thông báo
export const FETCH_NOTIFICATION = query => `${AppConfig.endpoint.api}/host/notification${query ? `?${qs.stringify(query)}` : ''}`
export const UPDATE_NOTIFICATION = id => `${AppConfig.endpoint.api}/host/notification/${id}/seen`
export const READ_NOTIFICATION = `${AppConfig.endpoint.api}/host/notification/read-all`

// Toà nhà
export const FETCH_BUILDING = query => `${AppConfig.endpoint.api}/building${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_BUILDING_BY_ID = id => `${AppConfig.endpoint.api}/building/${id}`

// Mã khuyến mãi
export const FETCH_COUPON = query => `${AppConfig.endpoint.api}/host/coupon${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_COUPON_BY_ID = id => `${AppConfig.endpoint.api}/host/coupon/${id}`
export const CREATE_COUPON = `${AppConfig.endpoint.api}/host/coupon`
export const UPDATE_COUPON = id => `${AppConfig.endpoint.api}/host/coupon/${id}`
export const GET_COUPON_HISTORY = `${AppConfig.endpoint.api}/host/coupon-history`

// Upload image
export const CLOUDINARY_SIGN_UPLOAD_IMAGE = () => `${AppConfig.endpoint.api}/upload/image/sign`
export const S3_SIGN_UPLOAD_IMAGE = () => `${AppConfig.endpoint.api}/s3/presigned-url`

// Báo cáo
export const FETCH_REVENUE_GRAPH = query => `${AppConfig.endpoint.api}/host/report/graph/revenue${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_ORDER_COUNT_GRAPH = query => `${AppConfig.endpoint.api}/host/report/graph/order-count${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_ROOM_COUNT_GRAPH = query => `${AppConfig.endpoint.api}/host/report/graph/room-count${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_HOST_COUNT_GRAPH = query => `${AppConfig.endpoint.api}/host/report/graph/host-count${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_CUSTOMER_COUNT_GRAPH = query => `${AppConfig.endpoint.api}/host/report/graph/customer-count${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_CUSTOMER_ORDER_GRAPH = query => `${AppConfig.endpoint.api}/host/report/graph/customer-order${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_ORDER_AVERAGE_GRAPH = query => `${AppConfig.endpoint.api}/host/report/graph/order-average${query ? `?${qs.stringify(query)}` : ''}`

export const FETCH_ORDER_LIST_REPORT = query => `${AppConfig.endpoint.api}/host/report/order-list${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_ROOM_LIST_REPORT = query => `${AppConfig.endpoint.api}/host/report/room-list${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_CUSTOMER_LIST_REPORT = query => `${AppConfig.endpoint.api}/host/report/customer-list${query ? `?${qs.stringify(query)}` : ''}`

// Đối soát
export const FETCH_SCHEDULE = () => `${AppConfig.endpoint.api}/host/invoice/schedule`
export const FETCH_INVOICE = query => `${AppConfig.endpoint.api}/host/invoice${query ? `?${qs.stringify(query)}` : ''}`
export const CREATE_INVOICE = `${AppConfig.endpoint.api}/host/invoice`
export const CONFIRM_INVOICE = id => `${AppConfig.endpoint.api}/host/invoice/${id}/confirm`
export const CHECK_INVOICE = query => `${AppConfig.endpoint.api}/host/invoice/check${query ? `?${qs.stringify(query)}` : ''}`
