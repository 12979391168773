import React from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />

const AlertOverlay = ({
  open, type, timelive, onClose, content, children
}) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    open={open}
    autoHideDuration={timelive}
    onClose={onClose}
  >
    <Alert onClose={onClose} severity={type}>
      {children || content}
    </Alert>
  </Snackbar>
)

AlertOverlay.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.oneOf(['success', 'info', 'error', 'warning']),
  timelive: PropTypes.number,
  onClose: PropTypes.func,
  content: PropTypes.string,
  children: PropTypes.node
}

AlertOverlay.defaultProps = {
  timelive: 6000,
}

export default AlertOverlay
