import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
} from '@material-ui/core'

const RejectPolicy = ({
  classes,
  values,
  // errors,
  // touched,
  // setFieldValue,
}) => (
  <>
    <Typography className={classes.subTitle}>
      Chính sách huỷ và đặt cọc:
    </Typography>
    <Grid container item lg={12} md={12}>
      <div className="mr-2">Chính sách huỷ: </div>
      {values?.policy === 'owner'
        ? (<div>Sử dụng chính sách huỷ và chỉnh sửa chủ nhà</div>)
        : (<div>Sử dụng chính sách huỷ và chỉnh sửa 2home</div>)}
    </Grid>
    <Grid container item lg={12} md={12}>
      <div className="mr-2">Chính sách đặt cọc: </div>
      <div>{values.deposit ? 'Căn hộ có yêu cầu đặt cọc (trong một vài trường hợp)' : 'Không yêu cầu'}</div>
    </Grid>
    <a href="/profile">Chi tiết</a>
  </>
)

RejectPolicy.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  // errors: PropTypes.shape(),
  // touched: PropTypes.shape(),
  // setFieldValue: PropTypes.func,
}

export default RejectPolicy
