import moment from 'moment'
import React, { useState, useEffect, useRef } from 'react'
import './custom.css'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { Button } from '@material-ui/core'
import { Close, AccessTime } from '@material-ui/icons'

const CustomDatePicker = (props) => {
  const {
    icon,
    value,
    label,
    onSelect,
    format,
    calendarStyle,
    fixed,
  } = props
  const [date, setDate] = useState(null)
  const [open, setOpen] = useState(false)
  const calendarRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setOpen(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (value) {
      setDate(value)
    }
  }, [value])

  return (
    <div>
      <Button
        variant="outlined"
        style={{ backgroundColor: 'white', textTransform: 'none' }}
        onClick={() => setOpen(true)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {icon || <AccessTime />}
          <span>{date ? moment(date).format(format || 'DD/MM/YYYY') : label}</span>
        </div>
        {date
          ? (
            <Close onClick={(e) => {
              e.stopPropagation()
              setDate(null)
              onSelect(null)
            }}
            />
          )
          : null
      }
      </Button>
      <div style={{ display: 'flex', position: 'absolute', zIndex: 999 }} ref={calendarRef}>
        {
          fixed
            ? (
              <DatePicker
                inline={open}
                fixedHeight
                locale="vi"
                showPopperArrow
                selected={date ? new Date(date) : null}
                dateFormat="MMMM d, yyyy h:mm aa"
                onChange={(sltDate) => {
                  setDate((new Date(sltDate)).getTime())
                  onSelect((new Date(sltDate)).getTime())
                }}
                customInput={<div />}
                calendarClassName={fixed ? 'react-datepicker-2home' : ''}
              />
            )
            : (
              <DatePicker
                inline={open}
                fixedHeight
                locale="vi"
                showPopperArrow
                selected={date ? new Date(date) : null}
                dateFormat="MMMM d, yyyy h:mm aa"
                onChange={(sltDate) => {
                  setDate((new Date(sltDate)).getTime())
                  onSelect((new Date(sltDate)).getTime())
                }}
                customInput={<div />}
                calendarContainer={({ className, children }) => <div className={className} style={calendarStyle}>{children}</div>}
              />
            )
        }
      </div>
    </div>
  )
}

CustomDatePicker.propTypes = {
  onSelect: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.shape(),
  value: PropTypes.number,
  format: PropTypes.string,
  calendarStyle: PropTypes.shape(),
  fixed: PropTypes.bool,
}

export default CustomDatePicker
