import React, { useState, useEffect, useRef } from 'react'
import './custom.css'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { Button } from '@material-ui/core'
import { ArrowRightAlt, Close } from '@material-ui/icons'
import CheckinIcon from 'assets/icons/icon-date-in.png'
import CheckoutIcon from 'assets/icons/icon-date-out.png'

const CustomDateRangePicker = (props) => {
  const {
    startdate,
    enddate,
    startLabel,
    endLabel,
    onSelect,
  } = props
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [open, setOpen] = useState(false)
  const calendarRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setOpen(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (startdate) {
      setStartDate(new Date(startdate))
    }
  }, [startdate])

  useEffect(() => {
    if (enddate) {
      setEndDate(new Date(enddate))
    }
  }, [enddate])

  return (
    <div className="twohome-daterange">
      <Button variant="outlined" style={{ backgroundColor: 'white', textTransform: 'none' }} onClick={() => setOpen(true)}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={CheckinIcon} style={{ width: 20, marginRight: 10 }} alt="checkin-icon" />
          <span>{startLabel}</span>
        </div>
        <ArrowRightAlt style={{ margin: 'auto 20px' }} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={CheckoutIcon} style={{ width: 20, marginRight: 10 }} alt="checkout-icon" />
          <span>{endLabel}</span>
        </div>
        {startdate || enddate
          ? (
            <Close onClick={(e) => {
              e.stopPropagation()
              onSelect(null, null)
            }}
            />
          )
          : null}
      </Button>
      <div style={{ display: 'flex', position: 'absolute', zIndex: 999 }} ref={calendarRef}>
        <DatePicker
          inline={open}
          fixedHeight
          locale="vi"
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          customInput={<div />}
          calendarContainer={({ className, children }) => <div className={`${className} checkin-time`}>{children}</div>}
        />
        <DatePicker
          inline={open}
          fixedHeight
          locale="vi"
          showPopperArrow={false}
          selected={endDate}
          onChange={(date) => {
            setEndDate(date)
            setOpen(false)
            onSelect((new Date(startDate)).getTime(), (new Date(date)).getTime())
          }}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          customInput={<div />}
          calendarContainer={({ className, children }) => <div className={`${className} checkout-time`}>{children}</div>}
        />
      </div>
    </div>
  )
}

CustomDateRangePicker.propTypes = {
  onSelect: PropTypes.func,
  startLabel: PropTypes.string,
  endLabel: PropTypes.string,
  startdate: PropTypes.number,
  enddate: PropTypes.number,
}

export default CustomDateRangePicker
