import {
  Typography,
  Avatar,
  Tooltip,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { formatPrice } from 'utils/formatter'
import { Table } from 'components'
import * as roomApi from 'provider/room'
import AppConfig from 'config'

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
  },
  avatar: {
    width: 80,
    height: 80,
  },
  ellipsisCell: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  codeCell: {
    width: 30,
    '& a': {
      color: '#3f51b5',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  avatarCell: {
    width: 80,
  },
  roomCell: {
    maxWidth: 200,
  },
}))

const RoomsTable = (props) => {
  const {
    owner, loading,
  } = props
  const classes = useStyles()
  const [rooms, setRooms] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [total, setTotal] = useState(0)

  const fetchRoom = async (query) => {
    try {
      const resp = await roomApi.find(query)
      setRooms(resp.data)
      setTotal(resp.total)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (owner) {
      fetchRoom({ owner, page, limit })
    }
    return () => { }
    // eslint-disable-next-line
  }, [owner])

  const changePage = (p) => {
    setPage(p)
    const query = { owner, page: p, limit }
    fetchRoom(query)
  }

  const changeRowsPerPage = (l) => {
    setLimit(l)
    const query = { owner, page: 1, limit: l }
    fetchRoom(query)
  }

  return (
    <Table
      className={classes.root}
      data={rooms}
      columns={[
        {
          Head: 'Mã',
          Cell: (value, origin) => (
            <RouterLink to={`/room/${origin.id}/update`}>
              <div>{value}</div>
            </RouterLink>
          ),
          accessor: 'code',
          className: `${classes.codeCell}`,
        },
        {
          Head: 'Ảnh',
          Cell: value => (
            <Avatar variant="square" src={value} className={classes.avatar} />
          ),
          accessor: 'image',
          sortable: false,
          className: `${classes.avatarCell}`,
        },
        {
          Head: 'Tên phòng',
          Cell: (value, origin) => (
            <>
              <a href={`${AppConfig.endpoint.customer}/phong-can-ho/${origin.id}`} target="_blank" rel="noopener noreferrer">
                <Tooltip title={value}>
                  <Typography variant="body1" className={classes.ellipsisCell}>
                    {value}
                  </Typography>
                </Tooltip>
              </a>
              <Typography variant="body2" className={classes.ellipsisCell}>
                {`Toà nhà: ${origin.building}`}
              </Typography>
              <Typography variant="body2" className={classes.ellipsisCell}>
                {`${origin.address}`}
              </Typography>
            </>
          ),
          accessor: 'name',
          sortable: false,
          className: `${classes.roomCell}`,
        },
        {
          Head: 'Giá theo giờ',
          Cell: (value, origin) => (
            <Typography variant="body1">
              {
                origin.hourFirstAmount * origin.hourFirstBaseFee
                  ? `${formatPrice(origin.hourFirstAmount * origin.hourFirstBaseFee)}VND/${origin.hourFirstAmount}h`
                  : '-'
              }
            </Typography>
          ),
          accessor: 'hourPrice',
        },
        {
          Head: 'Giá ngày',
          Cell: (value, origin) => <Typography variant="body1">{`${formatPrice(origin.dayBaseFee)}VND`}</Typography>,
          accessor: 'dayPrice',
        },
      ]}
      loading={loading}
      page={page}
      limit={limit}
      total={total}
      onPageChange={changePage}
      onRowsPerPageChange={changeRowsPerPage}
    />
  )
}

RoomsTable.propTypes = {
  owner: PropTypes.string,
  loading: PropTypes.bool,
}

export default RoomsTable
