import moment from 'moment'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  InputLabel,
  Chip,
} from '@material-ui/core'
import { DATETIME_FORMAT, TIME_FORMAT, DATE_FORMAT } from 'config'
import { formatPrice } from 'utils/formatter'
import { last } from 'underscore'

const BookingInfo = ({
  classes,
  values,
  onRoomPriceRecalculate,
}) => {
  const {
    status,
    checkin,
    checkout,
    guest,
    roomCheckin,
    roomCheckout,
    room,
    useServiceAt,
    services,
    addCheckoutPoint,
    roomPrice,
    guestPrice,
    limitRoom,
  } = values

  useEffect(() => {
    if (room && checkin && checkout && guest) {
      // const actualRoomCheckout = roomCheckout - ((checkout - checkin) >= 5 * 60 * 60 * 1000 ? 60 : 30) * 60 * 1000
      onRoomPriceRecalculate(room.id, checkin, checkout, guest, limitRoom, services.length > 0)
    }
    return () => {
    }
  }, [checkin, checkout, guest])

  const getCheckout = () => {
    let time = roomCheckout
    const lastPaidCheckout = last(addCheckoutPoint?.filter(item => item.status !== 'reject'))
    if (lastPaidCheckout) {
      time = lastPaidCheckout.checkout
    }
    return time
  }

  const getAddCheckoutPointStatus = (checkoutStatus) => {
    switch (checkoutStatus) {
      case 'processing':
        return 'Chờ xác nhận'
      case 'confirmed':
        return 'Chờ thanh toán'
      case 'paid':
        return 'Đã thanh toán'
      case 'reject':
        return 'Đã huỷ'
      default:
        return null
    }
  }

  return (
    <>
      <Typography className={classes.subTitle}>
        {['lock', 'reject-lock'].includes(status) ? 'Thời gian khoá phòng:' : 'Thông tin đặt phòng:'}
      </Typography>
      {/* Checkin/Checkout */}
      <Grid container>
        <Grid item lg={12} md={12} xs={12}>
          <InputLabel className={classes.itemTitle}>
            {['lock', 'reject-lock'].includes(status) ? 'Bắt đầu khoá - Kết thúc khoá' : 'Nhận phòng - Trả phòng'}
          </InputLabel>
        </Grid>
        {
          values?.prevState
            ? (
              <>
                <Grid item lg={5} md={5} xs={12} className="d-flex align-items-center">
                  <div>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {moment(values?.prevState?.roomCheckin).format(TIME_FORMAT)}
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {moment(values?.prevState?.roomCheckin).format(DATE_FORMAT)}
                    </Typography>
                  </div>
                  <span className="mx-4">&#8208;</span>
                  <div>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {moment(values?.prevState?.roomCheckout).format(TIME_FORMAT)}
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {moment(values?.prevState?.roomCheckout).format(DATE_FORMAT)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item lg={1} md={1} xs={0}>&rarr;</Grid>
              </>
            )
            : null
        }
        <Grid item lg={6} md={6} xs={12} className="d-flex align-items-center">
          <div>
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              {moment(values?.roomCheckin).format(TIME_FORMAT)}
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              {moment(values?.roomCheckin).format(DATE_FORMAT)}
            </Typography>
          </div>
          <span style={{ margin: '0px 30px' }}>&#8208;</span>
          <div>
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              {moment(getCheckout()).format(TIME_FORMAT)}
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              {moment(getCheckout()).format(DATE_FORMAT)}
            </Typography>
          </div>
        </Grid>
      </Grid>
      {
        ['lock', 'reject-lock'].includes(status)
          ? null
          : (
            <>
              {/* Guest */}
              <Grid container item lg={12} md={12} xs={12} className="mt-3">
                <InputLabel className={classes.itemTitle}>Số khách:</InputLabel>
                <Grid container>
                  {
                    values?.prevState
                      ? (
                        <>
                          <Grid item lg={5} md={5} xs={12}>
                            <Typography variant="body1" style={{ fontWeight: 500 }}>
                              {`${values?.prevState?.guest} khách`}
                            </Typography>
                          </Grid>
                          <Grid item lg={1} md={1}>&rarr;</Grid>
                        </>
                      )
                      : null
                  }
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {`${values.guest} khách`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Số phòng ngủ */}
              {
                values.limitRoom
                  ? (
                    <Grid container item lg={12} md={12} xs={12} className="mt-3">
                      <InputLabel className={classes.itemTitle}>Phòng ngủ:</InputLabel>
                      <Grid container>
                        {
                          values?.prevState
                            ? (
                              <>
                                <Grid item lg={5} md={5} xs={12}>
                                  <Typography variant="body1" style={{ fontWeight: 500 }}>
                                    {values?.prevState?.limitRoom ? 'Chỉ thuê 1 phòng ngủ' : 'Thuê toàn bộ'}
                                  </Typography>
                                </Grid>
                                <Grid item lg={1} md={1}>&rarr;</Grid>
                              </>
                            )
                            : null
                        }
                        <Grid item lg={6} md={6} xs={12}>
                          <Typography variant="body1" style={{ fontWeight: 500 }}>
                            Chỉ thuê 1 phòng ngủ
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                  : null
              }
              {/* Dịch vụ */}
              {
                room && room.hasService && (services.length || values.prevState?.serviceInfo?.length)
                  ? (
                    <Grid container className="mt-3">
                      <Grid item lg={12} md={12} xs={12}>
                        <InputLabel className={classes.itemTitle}>Dịch vụ:</InputLabel>
                      </Grid>
                      {/* Dịch vụ cũ */}
                      {
                        values.prevState?.serviceInfo && values.prevState?.serviceInfo.length
                          ? (
                            <>
                              <Grid item lg={5} md={5} xs={12}>
                                <InputLabel className="mt-1 font-weight-bold">{`${values.prevState?.serviceInfo?.map(s => s.name)?.join(', ')}`}</InputLabel>
                              </Grid>
                              <Grid item lg={1} md={1} xs={0}>&rarr;</Grid>
                            </>
                          )
                          : null
                      }
                      {/* Dịch vụ hiện tại */}
                      <Grid item lg={values.prevState ? 6 : 12} md={values.prevState ? 6 : 12} xs={12}>
                        <InputLabel className="mt-1 font-weight-bold">{`${services?.map(s => s.name)?.join(', ')}`}</InputLabel>
                      </Grid>
                    </Grid>
                  )
                  : null
              }
              {/* Ghi chú */}
              {
                values?.note || values.prevState?.rejectNote
                  ? (
                    <Grid container item lg={12} md={12} xs={12} className="mt-3">
                      <InputLabel className={classes.itemTitle}>Ghi chú:</InputLabel>
                      <Grid container>
                        {
                          values?.prevState
                            ? (
                              <>
                                <Grid item lg={5} md={5} xs={12}>
                                  <Typography variant="body1" style={{ fontWeight: 500 }}>
                                    {`${values?.prevState?.note}`}
                                  </Typography>
                                </Grid>
                                <Grid item lg={1} md={1} xs={0}>&rarr;</Grid>
                              </>
                            )
                            : null
                        }
                        <Grid item lg={6} md={6} xs={12}>
                          <Typography variant="body1" style={{ fontWeight: 500 }}>
                            {`${values.note}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                )
                  : null
              }
              {/* Thuê thêm giờ */}
              {
                addCheckoutPoint?.filter(item => item.status !== 'reject')?.length
                  ? (
                    <Grid container item lg={12} md={12} xs={12} className="mt-3">
                      <Typography className={classes.subTitle}>Giờ trả phòng đầu tiên:</Typography>
                      <Grid container spacing={3}>
                        <Grid item lg={6} md={6} xs={12}>
                          <Typography variant="body1" style={{ fontWeight: 500 }}>
                            {`- ${moment(checkout).format(DATETIME_FORMAT)}: ${formatPrice(roomPrice + guestPrice)} VND`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <br />
                      <Typography className={classes.subTitle}>Đặt ở thêm:</Typography>
                      {
                        addCheckoutPoint?.filter(item => item.status !== 'reject')?.map(item => (
                          <Grid container spacing={3}>
                            <Grid item lg={6} md={6} xs={12}>
                              <Typography variant="body1" style={{ fontWeight: 500 }}>
                                {`- ${moment(item.checkout).format(DATETIME_FORMAT)}: ${formatPrice(item.value)} VND`}
                              </Typography>
                            </Grid>
                            <Grid item lg={2} md={2} xs={12}>
                              <Chip
                                label={getAddCheckoutPointStatus(item.status)}
                                style={{ backgroundColor: item.status === 'paid' ? '#2979ff' : '#ffc107', color: 'white' }}
                                size="small"
                              />
                            </Grid>
                          </Grid>
                        ))
                      }
                      <br />
                    </Grid>
                  )
                  : null
              }
            </>
          )
      }
      {/* Thời gian setup */}
      {
        false && services?.length
          ? (
            <Grid container className="mt-3">
              {/* Thời điểm sử dụng dịch vụ */}
              <Grid item lg={6} md={6} xs={12}>
                <InputLabel className={classes.itemTitle}>Thời điểm sử dụng dịch vụ:</InputLabel>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {moment(useServiceAt).format(DATETIME_FORMAT)}
                </Typography>
              </Grid>
              {/* 2home nhận phòng */}
              <Grid item lg={6} md={6} xs={12}>
                <InputLabel className={classes.itemTitle}>2home nhận phòng:</InputLabel>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {moment(roomCheckin).format(DATETIME_FORMAT)}
                </Typography>
              </Grid>
            </Grid>
          )
          : null
      }
    </>
  )
}

BookingInfo.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  onRoomPriceRecalculate: PropTypes.func,
}

export default BookingInfo
