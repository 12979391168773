import React from 'react'
import PropTypes from 'prop-types'
import { SearchTextInput } from 'components'
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import { getFiltersFromUrl } from 'utils/url'
import { Search } from '@material-ui/icons'
import Filter from './Filters'

export const STATUS = [{
  value: 'valid',
  label: 'Còn hiệu lực',
}, {
  value: 'expired',
  label: 'Đã hết hạn',
}]

const CouponToolbar = ({
  classes,
  location,
  applyFilter,
  filterData,
}) => {
  const filter = (data) => {
    const {
      searchTxt,
      status,
      room,
      type,
    } = data
    applyFilter({
      searchTxt: searchTxt || undefined,
      status: status && status.length ? status : undefined,
      room: room || [],
      type: type || 'created',
    })
  }

  const onSearch = (textStr) => {
    const query = getFiltersFromUrl(location.search)
    filter({ ...query, searchTxt: textStr })
  }

  const groupByType = (data) => {
    filter(data)
  }

  return (
    <>
      {/* Title */}
      <Grid container spacing={1}>
        {/* Title */}
        <Grid item lg={9} md={8}>
          <Typography variant="h3" gutterBottom>Danh sách mã khuyến mại</Typography>
        </Grid>
      </Grid>
      {/* Filter */}
      <Grid container className={classes.filter}>
        {/* Room */}
        <Filter.Room
          value={filterData.room}
          onApply={room => filter({ ...filterData, room })}
        />
        {/* Status */}
        {
          filterData.type === 'created'
            ? (
              <Filter.Status
                data={STATUS}
                value={filterData.status}
                onApply={status => filter({ ...filterData, status })}
              />
            )
            : null
        }
      </Grid>
      {/* Danh sách đã tạo/Lịch sử sử dụng */}
      <Grid container spacing={1}>
        <Grid item lg={3} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${filterData.type === 'created' ? 'contained' : 'default'}`}
            color={`${filterData.type === 'created' ? 'primary' : 'default'}`}
            onClick={() => groupByType({ ...filterData, type: 'created' })}
          >
            <span className="mr-3">Danh sách đã tạo</span>
          </Button>
        </Grid>
        <Grid item lg={3} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${filterData.type === 'used' ? 'contained' : 'default'}`}
            color={`${filterData.type === 'used' ? 'primary' : 'default'}`}
            onClick={() => groupByType({ ...filterData, type: 'used' })}
          >
            Lịch sử sử dụng
          </Button>
        </Grid>
        {/* Search */}
        <Grid item lg={3} md={4} style={{ marginLeft: 'auto' }}>
          <SearchTextInput
            fullWidth
            className={classes.searchInput}
            placeholder="Tìm kiếm (theo mã, theo khách hàng, theo phòng, ...)"
            initValue={getFiltersFromUrl(location.search)?.searchTxt}
            debounceTime={800}
            onChange={str => onSearch(str)}
            leftIcon={<Search />}
            size="small"
            style={{ marginBottom: 0 }}
          />
        </Grid>
      </Grid>
    </>
  )
}

CouponToolbar.propTypes = {
  classes: PropTypes.shape(),
  applyFilter: PropTypes.func,
  location: PropTypes.shape(),
  filterData: PropTypes.shape(),
}

export default CouponToolbar
