import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Button,
  FormHelperText,
  Divider,
} from '@material-ui/core'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { findOne, create, update } from 'provider/service'
import * as commonApi from 'provider/common'
import MediaUpload from 'components/MediaUpload'
import CurrentInput from 'components/CurrencyInput'
import { useSnackbar } from 'notistack'
import BackgroundBackdrop from 'components/Backdrop'

const validateSchema = Yup.object().shape({})

const initService = {
  name: '',
  description: '',
  price: 0,
  prepareTime: 0,
  lockTime: 0,
  thumbnail: '',
  images: [],
  video: '',
  type: '',
  options: [],
  components: [],
  services: [],
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '900',
    marginBottom: 15,
  },
  formControl: {
    backgroundColor: 'white',
  },
  rowAlign: {
    margin: '0px -16px',
  },
  priceBox: {
    backgroundColor: '#e5e5e5',
    // borderRadius: 10,
  },
  mediaBox: {
    overflowX: 'auto',
    display: 'flex',
    backgroundColor: '#e5e5e5',
    marginBottom: 5,
    '& img': {
      width: 150,
      height: 150,
      padding: 5,
    },
  },
  optionBox: {
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
    border: 'solid 1px #d5d5d5',
  },
}))

const ServiceForm = (props) => {
  const { id, action, history } = props
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [service, setService] = useState(initService)
  const [types, setTypes] = useState([])
  const [options, setOptions] = useState([])
  const [components, setComponents] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchService = async () => {
    try {
      const resp = await findOne(id)
      setService({
        ...resp,
        components: resp.components.map(item => item.id),
        options: resp.options.map(item => item.id),
      })
    } catch (e) {
      console.log(e)
    }
  }

  const fetchType = async () => {
    try {
      const resp = await commonApi.getServiceType()
      setTypes(
        resp.data.map(item => ({
          value: item.id,
          label: item.name,
        })),
      )
    } catch (e) {
      console.log(e)
    }
  }

  const fetchOption = async () => {
    try {
      const resp = await commonApi.getServiceOption()
      setOptions(
        resp.data.map(item => ({
          value: item.id,
          label: item.title,
        })),
      )
    } catch (e) {
      console.log(e)
    }
  }

  const fetchComponent = async () => {
    try {
      const resp = await commonApi.getServiceComponent()
      setComponents(
        resp.data.map(item => ({
          value: item.id,
          label: item.name,
        })),
      )
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    // Fetch user data if in updating mode
    fetchType()
    fetchOption()
    fetchComponent()
    if (action === 'update') {
      fetchService()
    }
    // eslint-disable-next-line
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      let resp = null
      switch (action) {
        case 'update':
          await update({ ...data, price: parseInt(data.price.toString().replace(/,/g, ''), 0) })
          setService(data)
          setLoading(false)
          enqueueSnackbar('Cập nhật thông tin dịch vụ thành công.', { variant: 'success' })
          break
        default:
          resp = await create({ ...data, price: parseInt(data.price.toString().replace(/,/g, ''), 0) })
          history.push(`/service/${resp.id}/update`)
          setLoading(false)
          enqueueSnackbar('Thêm mới dịch vụ thành công.', { variant: 'success' })
      }
    } catch (e) {
      setLoading(false)
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}). Không thể cập nhật thông tin dịch vụ.`, { variant: 'error' })
    }
  }

  return (
    <BackgroundBackdrop loading={loading}>
      <Formik
        initialValues={service}
        enableReinitialize
        validationSchema={validateSchema}
        onSubmit={onSubmit}
      >
        {({
          values, touched, errors, handleChange, setFieldValue,
        }) => (
          <Form className={classes.root}>
            <Typography variant="h3">
              {action === 'update'
                ? 'Cập nhật thông tin dịch vụ'
                : 'Thêm mới dịch vụ'}
            </Typography>
            <br />
            <Typography className={classes.subTitle}>
            Thông tin dịch vụ:
            </Typography>
            {/* Name and price */}
            <Grid container spacing={4} className={classes.rowAlign}>
              {/* Name */}
              <Grid item lg={4} md={4}>
                <TextField
                  style={{ backgroundColor: 'white' }}
                  label="Tên dịch vụ"
                  variant="outlined"
                  name="name"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name && touched.name}
                  helperText={errors.name && touched.name && errors.name}
                  size="small"
                />
              </Grid>
              {/* Type */}
              <Grid item lg={4} md={4}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                  error={errors.components && touched.components}
                  size="small"
                >
                  <InputLabel
                    style={{ backgroundColor: 'white', padding: '0px 5px' }}
                    id="user-role"
                  >
                  Kiểu dịch vụ
                  </InputLabel>
                  <Select
                    labelId="user-role"
                    id="user-role-label"
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                  >
                    {types.map(item => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: 'red' }}>
                  {errors.role && touched.role && errors.role}
                </FormHelperText>
              </Grid>
              {/* Price */}
              <Grid item lg={4} md={4}>
                <CurrentInput
                  style={{ backgroundColor: 'white' }}
                  label="Giá dịch vụ"
                  variant="outlined"
                  name="price"
                  fullWidth
                  value={values.price}
                  onChange={handleChange}
                  error={errors.price && touched.price}
                  helperText={errors.price && touched.price && errors.price}
                  size="small"
                />
              </Grid>
            </Grid>
            {/* Prepare time and locking time */}
            <Grid container spacing={4} className={classes.rowAlign}>
              {/* Prepare time */}
              <Grid item lg={6} md={6}>
                <TextField
                  style={{ backgroundColor: 'white' }}
                  label="Thời gian chuẩn bị"
                  variant="outlined"
                  name="prepareTime"
                  fullWidth
                  value={values.prepareTime}
                  onChange={handleChange}
                  error={errors.prepateTime && touched.prepateTime}
                  helperText={
                  errors.prepateTime
                    && touched.prepateTime
                    && errors.prepateTime
                  }
                  size="small"
                />
              </Grid>
              {/* Locking time */}
              <Grid item lg={6} md={6}>
                <TextField
                  style={{ backgroundColor: 'white' }}
                  label="Thời gian khoá"
                  variant="outlined"
                  name="lockTime"
                  fullWidth
                  value={values.lockTime}
                  onChange={handleChange}
                  error={errors.lockTime && touched.lockTime}
                  helperText={
                    errors.lockTime && touched.lockTime && errors.lockTime
                  }
                  size="small"
                />
              </Grid>
            </Grid>
            {/* Components and Extra info */}
            <Grid container spacing={4}>
              {/* Components */}
              <Grid item lg={6} md={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                  error={errors.components && touched.components}
                  size="small"
                >
                  <InputLabel
                    style={{ backgroundColor: 'white', padding: '0px 5px' }}
                    id="user-role"
                  >
                  Thành phần
                  </InputLabel>
                  <Select
                    labelId="user-role"
                    id="user-role-label"
                    name="components"
                    value={values.components}
                    onChange={handleChange}
                    multiple
                  >
                    {components.map(item => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: 'red' }}>
                  {errors.role && touched.role && errors.role}
                </FormHelperText>
              </Grid>
              {/* Extra info */}
              <Grid item lg={6} md={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                  error={errors.options && touched.options}
                  size="small"
                >
                  <InputLabel
                    style={{ backgroundColor: 'white', padding: '0px 5px' }}
                    id="user-role"
                  >
                  Thông tin bổ sung
                  </InputLabel>
                  <Select
                    labelId="user-role"
                    id="user-role-label"
                    name="options"
                    value={values.options}
                    onChange={handleChange}
                    multiple
                  >
                    {options.map(item => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: 'red' }}>
                  {errors.options && touched.options && errors.options}
                </FormHelperText>
              </Grid>
            </Grid>
            <Divider
              style={{ margin: '20px auto', backgroundColor: '#d9d9d9' }}
            />
            {/* Danh sách ảnh/video */}
            <Typography className={classes.subTitle}>
            Danh sách ảnh và video:
            </Typography>
            <div style={{ marginBottom: 10 }}>
              <Field name="images">
                {({ field }) => (
                  <>
                    {field.value && field.value.length ? (
                      <div className={classes.mediaBox}>
                        {field.value.map(url => (
                          <div
                            onClick={() => setFieldValue('thumbnail', url)}
                            role="button"
                            tabIndex="-1"
                            onKeyDown={() => {}}
                          >
                            <img
                              src={url}
                              alt=""
                              style={
                              values.thumbnail === url
                                ? {
                                  border: 'solid 2px #a5a5a5',
                                  borderStyle: 'dashed',
                                }
                                : {}
                            }
                            />
                          </div>
                        ))}
                      </div>
                    ) : null}
                    <MediaUpload
                      title="Tải lên ảnh"
                      multiple
                      onSuccess={(urls) => {
                        setFieldValue('images', urls)
                        setFieldValue('thumbnail', urls[0])
                      }}
                      disabled={action === 'view'}
                    />
                  </>
                )}
              </Field>
            </div>
            <Grid lg={12} md={12}>
              <TextField
                style={{ backgroundColor: 'white' }}
                label="Đường dẫn video"
                variant="outlined"
                name="video"
                fullWidth
                value={values.video}
                onChange={handleChange}
                error={errors.video && touched.video}
                helperText={errors.video && touched.video && errors.video}
                size="small"
              />
            </Grid>
            <Divider
              style={{ margin: '20px auto', backgroundColor: '#d9d9d9' }}
            />
            {/* Mô tả dịch vụ */}
            <Typography className={classes.subTitle}>Mô tả dịch vụ:</Typography>
            <Grid container item lg={12} md={12}>
              <TextField
                style={{ backgroundColor: 'white' }}
                label="Viết nội dung mô tả cho dịch vụ ..."
                variant="outlined"
                name="description"
                fullWidth
                multiline
                rows={5}
                value={values.description}
                onChange={handleChange}
                error={errors.description && touched.description}
                helperText={
                  errors.description && touched.description && errors.description
                }
                size="small"
              />
            </Grid>
            <Divider
              style={{ margin: '20px auto', backgroundColor: '#d9d9d9' }}
            />
            {/* Create button */}
            <br />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {action === 'update' ? 'Cập nhật thông tin' : 'Tạo dịch vụ'}
            </Button>
          </Form>
        )}
      </Formik>
    </BackgroundBackdrop>
  )
}

ServiceForm.propTypes = {
  id: PropTypes.string,
  action: PropTypes.string,
  history: PropTypes.shape(),
}

export default ServiceForm
