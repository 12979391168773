import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  FormHelperText,
} from '@material-ui/core'
import * as commonApi from 'provider/common'
import GooglePlaceInput from 'components/GooglePlaceInput'
import AutoCompleteCreatable from 'components/AutoCompleteCreatable'

const BOOKING_TYPE = [
  {
    value: 'instant',
    label: 'Đặt nhanh',
  },
  {
    value: 'request',
    label: 'Chờ xác nhận',
  },
]

const BED_OPTIONS = [{
  value: 0,
  label: 'Studio',
}, {
  value: 1,
  label: '1 phòng ngủ',
}, {
  value: 2,
  label: '2 phòng ngủ',
}, {
  value: 3,
  label: '3 phòng ngủ',
}, {
  value: 4,
  label: '4 phòng ngủ',
}, {
  value: 5,
  label: '5 phòng ngủ',
}, {
  value: 6,
  label: '6 phòng ngủ',
}]

const GeneralInfo = ({
  classes,
  errors,
  touched,
  values,
  handleChange,
  setFieldValue,
}) => {
  const [districts, setDistricts] = useState([])
  const [buildings, setBuildings] = useState([])
  const [roomTypes, setRoomTypes] = useState([])
  const [sltDistrict, setSltDistrict] = useState(null)
  const [sltBuilding, setSltBuilding] = useState(null)

  const fetchDistrict = async (searchTxt) => {
    try {
      const resp = await commonApi.getDistrict(searchTxt)
      setDistricts(
        resp.data.map(item => ({
          value: item.id,
          title: item.name,
        })),
      )
    } catch (e) {
      // TODO
    }
  }

  const fetchBuilding = async (district, searchTxt) => {
    try {
      const resp = await commonApi.getBuilding({
        district,
        name: searchTxt,
      })
      setBuildings(
        resp.data.map(item => ({
          value: item.id,
          title: item.name,
        })),
      )
    } catch (e) {
      console.log(e)
      // TODO
    }
  }

  const fetchRoomType = async () => {
    try {
      const resp = await commonApi.getRoomType()
      setRoomTypes(
        resp.data.map(item => ({
          value: item.id,
          label: item.name,
        })),
      )
    } catch (e) {
      // TODO
    }
  }

  useEffect(() => {
    if (values.district && districts.length) {
      const district = districts.find(h => h.value === values.district)
      setSltDistrict({
        value: district.value,
        title: district.title,
      })
      fetchBuilding(district.value)
    }
    return () => {
    }
  }, [values.district, districts])

  useEffect(() => {
    if (values.building && buildings.length) {
      const building = buildings.find(h => h.value === values.building)
      if (building) {
        setSltBuilding({
          value: building.value,
          title: building.title,
        })
      } else {
        setSltBuilding(null)
      }
    }
    return () => {
    }
  }, [values.building, buildings])

  useEffect(() => {
    fetchDistrict()
    fetchRoomType()
  }, [])

  const onBuildingSelect = async (item) => {
    if (!item) {
      fetchBuilding(values.district)
    } else if (!item.value) {
      // Create new building if it isn't Database
      const newBuilding = await commonApi.createBuilding(item.title, values.district)
      setFieldValue('building', newBuilding.id)
    } else {
      setFieldValue('building', item.value)
    }
  }

  const onDistrictSelect = async (item) => {
    if (!item) {
      fetchDistrict()
    } else if (!item.value) {
      // Create new district if it isn't Database
      const newDistrict = await commonApi.createDistrict(item.title)
      setFieldValue('district', newDistrict.id)
    } else {
      setFieldValue('district', item.value)
    }
  }

  return (
    <>
      <Typography className={classes.subTitle}>Thông tin phòng:</Typography>
      {/* Name and Host */}
      <Grid container spacing={2} className={classes.rowAlign}>
        {/* Name */}
        <Grid item lg={6} md={6} xs={12}>
          <TextField
            style={{ backgroundColor: 'white' }}
            label="Tên phòng"
            variant="outlined"
            name="name"
            fullWidth
            value={values.name}
            onChange={handleChange}
            error={errors.name && touched.name}
            helperText={errors.name && touched.name && errors.name}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Nhập tên phòng"
          />
        </Grid>
      </Grid>
      {/* District, Building, Address */}
      <Grid container spacing={2} className={classes.rowAlign}>
        {/* District */}
        <Grid item lg={4} md={4} xs={12}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            style={{ backgroundColor: 'white' }}
            error={errors.district && touched.district}
            size="small"
          >
            <AutoCompleteCreatable
              defaultValue={sltDistrict}
              label="Khu vực"
              shrink
              placeholder="Chọn khu vực ..."
              suggestions={districts}
              onSelect={item => onDistrictSelect(item)}
              onChange={(searchStr) => {
                fetchDistrict(searchStr)
              }}
            />
          </FormControl>
          <FormHelperText style={{ color: 'red' }}>
            {errors.district && touched.district && errors.district}
          </FormHelperText>
        </Grid>
        {/* Building */}
        <Grid item lg={4} md={4} xs={12}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            style={{ backgroundColor: 'white' }}
            error={errors.building && touched.building}
            size="small"
          >
            <AutoCompleteCreatable
              defaultValue={sltBuilding}
              label="Toà nhà"
              shrink
              placeholder="Chọn toà nhà ..."
              suggestions={buildings}
              onSelect={item => onBuildingSelect(item)}
              onChange={(searchStr) => {
                fetchBuilding(values.district, searchStr)
              }}
            />
          </FormControl>
          <FormHelperText style={{ color: 'red' }}>
            {errors.building && touched.building && errors.building}
          </FormHelperText>
        </Grid>
        {/* Address */}
        <Grid item lg={4} md={4} xs={12}>
          <GooglePlaceInput
            value={values.address}
            onSelect={(location) => {
              setFieldValue('address', location.address)
              setFieldValue('mapLat', location.lat)
              setFieldValue('mapLng', location.lng)
            }}
            size="small"
          />
          <FormHelperText style={{ color: 'red' }}>
            {errors.address && touched.address && errors.address}
          </FormHelperText>
        </Grid>
      </Grid>
      {/* Map location, Booking Type */}
      <Grid container spacing={2} className={classes.rowAlign}>
        {/* Latitude */}
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            style={{ backgroundColor: 'white' }}
            label="Toạ độ (vĩ độ)"
            variant="outlined"
            name="mapLat"
            fullWidth
            value={values.mapLat}
            onChange={handleChange}
            error={errors.mapLat && touched.mapLat}
            helperText={errors.mapLat && touched.mapLat && errors.mapLat}
            size="small"
            placeholder="Nhập vĩ độ trên bản đồ"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        {/* Longtitude */}
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            style={{ backgroundColor: 'white' }}
            label="Toạ độ (kinh độ)"
            variant="outlined"
            name="mapLng"
            fullWidth
            value={values.mapLng}
            onChange={handleChange}
            error={errors.mapLng && touched.mapLng}
            helperText={errors.mapLng && touched.mapLng && errors.mapLng}
            size="small"
            placeholder="Nhập kinh độ trên bản đồ"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        {/* Room booking type */}
        <Grid item lg={4} md={4} xs={12}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            style={{ backgroundColor: 'white' }}
            error={errors.roomBookingType && touched.roomBookingType}
            size="small"
          >
            <InputLabel
              style={{ backgroundColor: 'white', padding: '0px 5px' }}
              id="reservation-status"
            >
              Chế độ
            </InputLabel>
            <Select
              labelId="reservation-status"
              id="reservation-status-label"
              name="roomBookingType"
              value={values.roomBookingType}
              onChange={handleChange}
            >
              {BOOKING_TYPE.map(item => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText style={{ color: 'red' }}>
            {errors.roomBookingType && touched.roomBookingType && errors.roomBookingType}
          </FormHelperText>
        </Grid>
      </Grid>
      {/* Guest max, bedroom, bed */}
      <Grid container spacing={2} className={classes.rowAlign}>
        {/* Guest max */}
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            style={{ backgroundColor: 'white' }}
            label="Số khách tối đa"
            variant="outlined"
            name="guestMax"
            fullWidth
            value={values.guestMax}
            onChange={handleChange}
            error={errors.guestMax && touched.guestMax}
            helperText={errors.guestMax && touched.guestMax && errors.guestMax}
            placeholder="Nhập số khách tối đa cho phép"
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </Grid>
        {/* bedroom */}
        <Grid item lg={4} md={4} xs={12}>
          {/* <TextField
            style={{ backgroundColor: 'white' }}
            label="Số phòng ngủ"
            variant="outlined"
            name="bedroomNum"
            fullWidth
            value={values.bedroomNum}
            onChange={handleChange}
            error={errors.bedroomNum && touched.bedroomNum}
            helperText={errors.bedroomNum && touched.bedroomNum && errors.bedroomNum}
            size="small"
            placeholder="Nhập số phòng ngủ ..."
            InputLabelProps={{
              shrink: true,
            }}
          /> */}
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            style={{ backgroundColor: 'white' }}
            error={errors.bedroomNum && touched.bedroomNum}
            size="small"
          >
            <InputLabel
              style={{ backgroundColor: 'white', padding: '0px 5px' }}
              id="bed-room"
            >
              Số phòng ngủ
            </InputLabel>
            <Select
              labelId="bed-room"
              id="reservation-bed-room"
              name="bedroomNum"
              value={values.bedroomNum}
              onChange={handleChange}
            >
              {BED_OPTIONS.map(item => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* bed */}
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            style={{ backgroundColor: 'white' }}
            label="Số giường"
            variant="outlined"
            name="bedNum"
            fullWidth
            value={values.bedNum}
            onChange={handleChange}
            error={errors.bedNum && touched.bedNum}
            helperText={errors.bedNum && touched.bedNum && errors.bedNum}
            size="small"
            placeholder="Nhập số phòng ..."
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      {/* bathroom, bathroomBox, type */}
      <Grid container spacing={2} className={classes.rowAlign}>
        {/* bathroom */}
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            style={{ backgroundColor: 'white' }}
            label="Số phòng tắm"
            variant="outlined"
            name="bathroomNum"
            fullWidth
            value={values.bathroomNum}
            onChange={handleChange}
            error={errors.bathroomNum && touched.bathroomNum}
            helperText={errors.bathroomNum && touched.bathroomNum && errors.bathroomNum}
            size="small"
            placeholder="Nhập số phòng tắm ..."
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {/* bathroom box */}
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            style={{ backgroundColor: 'white' }}
            label="Số bồn tắm"
            variant="outlined"
            name="bathroomBox"
            fullWidth
            value={values.bathroomBox}
            onChange={handleChange}
            error={errors.bathroomBox && touched.bathroomBox}
            helperText={errors.bathroomBox && touched.bathroomBox && errors.bathroomBox}
            size="small"
            placeholder="Nhập số bồn tắm ..."
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {/* Type */}
        <Grid item lg={4} md={4} xs={12}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            style={{ backgroundColor: 'white' }}
            error={errors.roomType && touched.roomType}
            size="small"
          >
            <InputLabel
              style={{ backgroundColor: 'white', padding: '0px 5px' }}
              id="reservation-status"
            >
              Loại căn hộ
            </InputLabel>
            <Select
              labelId="reservation-status"
              id="reservation-status-label"
              name="roomType"
              value={values.roomType}
              onChange={handleChange}
            >
              {roomTypes.map(item => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText style={{ color: 'red' }}>
            {errors.roomType && touched.roomType && errors.roomType}
          </FormHelperText>
        </Grid>
        {/* Square */}
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            style={{ backgroundColor: 'white' }}
            label="Diện tích (m2)"
            variant="outlined"
            name="square"
            fullWidth
            value={values.square}
            onChange={handleChange}
            error={errors.square && touched.square}
            helperText={errors.square && touched.square && errors.square}
            size="small"
            placeholder="Nhập diện tích phòng ..."
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {/* Room Number */}
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            style={{ backgroundColor: 'white' }}
            label="Số phòng/Số nhà"
            variant="outlined"
            name="roomNumber"
            fullWidth
            value={values.roomNumber}
            onChange={handleChange}
            error={errors.roomNumber && touched.roomNumber}
            helperText={errors.roomNumber && touched.roomNumber && errors.roomNumber}
            size="small"
            placeholder="Nhập số phòng/số nhà ..."
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

GeneralInfo.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default GeneralInfo
