const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '900',
    marginBottom: 15,
  },
  gridList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    backgroundColor: 'white',
  },
  customerInfo: {
    color: '#3f51b5',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  status: {
    fontSize: '1rem',
    color: 'white',
    fontWeight: '900',
  },
})

export default styles
